import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yourlist',
  templateUrl: './yourlist.component.html'
})
export class YourlistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
