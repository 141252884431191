import { Component, OnInit,AfterViewInit, ViewChild,ElementRef} from '@angular/core';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import {CartService} from '../cart-page/cart-page.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import * as metadata from '../../assets/data/default/product-listing.json'
import { config } from '../../configs/config';
import { MetaDataMapService } from '../meta-data-map.service';
import { ProductFilterComponent } from '../product-filter/product-filter.component'
import { ProductListingService } from '../product-listing/product-listing.service';
import { ProductFilterService } from '../product-filter/product-filter.service';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { CheckoutLocalStorageService } from '../checkout-new/checkout-local-storage.service';
import * as crypto from 'crypto-js';
import { CustomerLoginStorageService } from '../checkout-new/checkout-login/customer-login-storage.service';
import { LoaderService } from '../shared/loader/loader.service';

@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html'
})
export class ProductListingComponent implements OnInit {
  @ViewChild (ProductFilterComponent) productfilter : ProductFilterComponent
  @ViewChild('closeModal') private closeModal: ElementRef;

  fields = metadata.groups[0].fields;
  CONFIG = config;
  totalPrice = 0;
  path;
  banner = "";
  promotionData:any;
  public executionData=[];
  public brandValues:any = [];
  public categoryList:any = []
  public allInOneData:any = [];
  public allInOneData1:any = [];
  public include=false
  public days90=false
  public days30=false
  brandPush:any;
  cate:any;
  clearCategoryData:any;
  selectedIndex: number = null;
  subCategories: any;
  categoryType: any;
  public active;
  public cartData=[];
  public cartQuantity=[];
  public searchCategory;
  public priceStrikeOutInListScreen;
  public showPricekey;
  public strikeOutPriceKey;

  totalProducts:any = 0;
  showAlerts = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  facets;
  themes;
  finish;
  features;
  prices;
  size;
  thickness;
  color;
  type;
  material;
  products;
  breadcrumbs = 'Home > Ground Engaging Tools > <span class="active"><b>KHIN Teeth</b></span>';
  pricefilters = {
    minPrice: 0,
    maxPrice: 72
  };
  public minPrice = 0;
  public maxPrice = 72;
  public productsApiData:any;
  public categoryApiData:any;
  public countPerPage = 77;
  // public countPerPage = 25;
  public pageNumber = 0;
  public totalNumberOfProducts;
  // public currentPageInPagination = 1;
  public currentPageInPagination = 0;
  public itemsPerPage: number;
  public totalLength;
  public page;
  public filtersApiData;
  public brandProductsApiData;
  public category: string[];
  public filter='no';
  public search='no';
  public searchKey;
  public searchApiData;
  public brand='no';
  public last90Days='no';
  public combine = 'no';
  public outOfStockProductsApiData;
  public outOfStock;
  public last90DaysProductsApiData;
  public dataFilter='az';
  public days= 'no';
  public req_filter_data1;
  public req_filter_data2;
  public req_filter_data3;
  public req_filter_data4;
  public req_last90days_product_data;
  public req_last90days_product_data1;
  public req_last90days_product_data2;
  public req_last90days_product_data3;
  public req_search_data;

  pagenation:any;
  latest_products:any = "";
  brad:any;
  searchTypeValue:any;
  ninetyDaysLastCheck:any;
  totalItemsOrProducts:any = 0;
  categoryTobeShown:any = 'All Categories';
  currentRating = 4.5;
  enquiryForm:FormGroup;
  validateForm:boolean = false;
  memberId:any = "";
  checkoutAlerts:boolean = false;
  companyName;
  isfilter:boolean=false;

  constructor(
    public appService:AppService,
    private router: Router,
    private route: ActivatedRoute,
    private cartService : CartService,
    private localService: LocalStorageService,
    private alertService : AlertsService,
    private productListingService: ProductListingService,
    public metaDataMapService: MetaDataMapService,
    private productFilterService: ProductFilterService,
    private formBuilder: FormBuilder,
    private checkoutApiService: CheckoutApiService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
    private customerLoginStorageService: CustomerLoginStorageService,
    private loaderService: LoaderService
  ) { }
 
  ngOnInit(){
  //   window.addEventListener("beforeunload", function (e) {
  //     var confirmationMessage = "\o/";
  //     console.log("cond");
  //     e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
  //     return confirmationMessage;              // Gecko, WebKit, Chrome <34
  // });
    
    console.log("bombayyyy"+this.CONFIG.title);
   if(this.CONFIG.title === "Aiema"){
      this.countPerPage = 25;
   } else {
    this.countPerPage = 77;
   } 
   
      // this.getFilterByProduct(this.currentPageInPagination);
     this.productListingService.clearEvent();
     this.cartService.updateCartItem(this.appService.cartProducts.results)

    this.brandPush=[]
    this.cate=[]
    this.brad=[]
    this.pagenation=false;
    this.checkoutAlerts = true;
    // CLEAR ALL FUNCTIONALITY
    this.productListingService.currentData.subscribe(currentData => {
      if(currentData === 'clearAll'){
        if(this.latest_products === 'latest'){
          this.refreshLast90Days(1);
        }
        else{
          let topCategories = [];
          if(this.categoryList.length > 0){
            this.categoryList.forEach(element => {
              topCategories.push(element.name);
            });
            if(this.CONFIG.title !== "Aiema"){
              this.refreshProducts(topCategories);
            }
          }
        }
        
      } else{
      
  

  // FOR CLEAR ALL FUNCTIONLITY TO COVER LATEST PRODUCTS
  if(this.route.snapshot.queryParamMap){
    if(this.route.snapshot.queryParamMap.get('type') === "last90days" || this.CONFIG.title !== "Aiema"){
      this.latest_products="latest";
    }
  }
  
  
  this.route.queryParams.subscribe(params => {
    // SCROLL TO THE TOP
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 72; // For Chrome, Firefox, IE and Opera
    if (params.type=='filter') {
      console.log("1111")
      this.clearCategoryData=[]
      this.latest_products = "";
      this.combine='no';
      this.search='no';
      this.last90Days='no';
      this.ninetyDaysLastCheck = false;
      this.cate=[]
      this.route.queryParamMap.subscribe(params => this.category = params.getAll('terms'));
      this.categoryTobeShown = this.category[0];
      this.cate=this.category
      if(this.productfilter !== undefined){
        console.log("2222")
         this.productfilter.brands=[]
        this.productfilter.cate=[]
        this.productfilter.stockDetails=[]
        if(this.CONFIG.title !== "Aiema"){
          this.productfilter.clearCheckBox()
        }
        this.productfilter.active='';
      }
      console.log("3333")
      this.currentPageInPagination = 1;
      this.getFilterByProduct(this.currentPageInPagination);
      
    }
    else if (params.type=='searchresults') {
      this.latest_products = "";
      this.clearCategoryData=[]
      // this.breadCrumbService.updateAdminFormEvent('');
      this.combine='no';
      this.ninetyDaysLastCheck = false;
      this.filter='no';
      this.last90Days='no';
      if(this.productfilter !== undefined){
         this.productfilter.brands=[]
        this.productfilter.cate=[]
        this.productfilter.stockDetails=[]
        this.productfilter.clearCheckBox()
        this.productfilter.active='';
      }
      this.cate=[]
      this.brad=[]
      this.route.queryParamMap.subscribe(params => {
        this.searchKey = params.getAll('terms')[0]
        this.categoryTobeShown = params.getAll('terms')[0];
        this.searchTypeValue = params.getAll('terms')[1]}
        );
      if(this.searchTypeValue){
        if(this.searchTypeValue === "Brand"){
          this.brad=this.searchKey
        }else{
          this.cate=[this.searchKey]
        }
      }else{
        this.cate=[this.searchKey]
      }
      
   
    this.currentPageInPagination = 1;
      this.getSearchResult(this.currentPageInPagination);
    }
    else if (params.type=='all'){
      console.log('Janaki')
    }

  });

  this.route.queryParams.subscribe(params => {
    if (params.type=='last90days' && this.CONFIG.title !== "Aiema") {
      this.categoryTobeShown = 'Latest Prodcuts'
      this.latest_products = "latest"
      this.combine='no';
      this.filter='no';
      this.search='no';
      this.days='yes';
      this.ninetyDaysLastCheck = true; 
      if(this.productfilter !== undefined){
        this.productfilter.brands=[]
       this.productfilter.cate=[]
       this.productfilter.stockDetails=[]
      //  this.productfilter.clearCheckBox()
      if(this.CONFIG.title !== "Aiema"){
        this.productfilter.clearCheckBox()
      }
      }
    
      this.getProductByLast90Days(this.currentPageInPagination);
    } else if(params.type=='last90days' && this.CONFIG.title === "Aiema") {
      this.categoryTobeShown = 'All Categories'
      this.latest_products = ""
      this.combine='no';
      this.filter='no';
      this.search='no';
      this.days='yes';
      this.ninetyDaysLastCheck = true; 
      // this.ninetyDaysLastCheck = false; 
      // this.categoryList = [];
      if(this.productfilter !== undefined){
        this.productfilter.brands=[]
       this.productfilter.cate=[]
       this.productfilter.stockDetails=[]
      //  this.productfilter.clearCheckBox()
      }
      this.getAiemaProductByLast90Days(this.currentPageInPagination);
    }
  });
  


  this.route.queryParams.subscribe(params => {
    if (params.type=='combine') {
      this.search='no';
      this.filter='no';
      this.last90Days='no';
      if(this.CONFIG.title === "Aiema" || this.CONFIG.title !== "BombayHardware"){
        this.ninetyDaysLastCheck = false; 
      }
      this.route.queryParamMap.subscribe(params => this.allInOneData = params.getAll('terms'));
      this.route.queryParamMap.subscribe(params => this.allInOneData1 = params.getAll('terms'));
      this.getFilterByProductAll(this.currentPageInPagination);
    }
  });

    for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
      this.cartData.push(this.appService.cartProducts.results[i].product_id);
    }
  }
})

 this.priceStrikeOutInListScreen = this.localService.get('price_strikeout_in_list_screen');
 this.showPricekey = this.localService.get('show_price_key');
 this.strikeOutPriceKey = this.localService.get('strike_out_price_key');


this.enquiryForm = new FormGroup({
      name: this.formBuilder.control('', [Validators.required,Validators.maxLength(80)]),
      email: this.formBuilder.control('', [Validators.required,Validators.maxLength(50), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      phone_number: this.formBuilder.control('', [Validators.required, Validators.maxLength(10), Validators.pattern("^[+]*[(]{0,1}[0-9]{10}[)]{0,1}[-\s\./0-9]*$")]),
      category: this.formBuilder.control('', [Validators.required]),
      message: this.formBuilder.control('', [Validators.required,Validators.maxLength(600)])
})
  }

  get f() { return this.enquiryForm.controls; }

  addWishList(product:any) {
    product.select = !product.select;
  }
  alphabetsFilter() {
      if(this.filter=='yes' && this.combine!=='yes' && this.search!=='yes'){
        this.getFilterByProduct(this.currentPageInPagination);
      } else if(this.search=='yes' && this.combine!=='yes' && this.filter!=='yes'){
        this.getSearchResult(this.currentPageInPagination);
      } else if(this.last90Days=='yes' && this.combine!=='yes' && this.search!=='yes' && this.filter!=='yes'){
        this.getProductByLast90Days(this.currentPageInPagination);
      } else if(this.combine=='yes' && this.dataFilter=='az' && this.search!=='yes' && this.last90Days!=='yes'){ 
        this.getFilterByProductAll(this.currentPageInPagination);
      }
    
  }

  pageChange(event) {
    this.currentPageInPagination = event;
      if(this.filter=='yes' && this.combine!=='yes' && this.search!=='yes' && this.last90Days!=='yes'){
        this.getFilterByProduct(this.currentPageInPagination);
      } else if(this.search=='yes' && this.combine!=='yes' && this.filter!=='yes' && this.last90Days!=='yes'){
        this.getSearchResult(this.currentPageInPagination);
      } else if(this.last90Days=='yes' && this.combine!=='yes' && this.search!=='yes' && this.filter!=='yes'){
        this.getProductByLast90Days(this.currentPageInPagination);
      } else if(this.combine=='yes' && this.dataFilter=='az' && this.search!=='yes' && this.last90Days!=='yes' && this.filter!=='yes'){ 
        this.pagenation=true;
        this.getFilterByProductAll(this.currentPageInPagination);
      }else if(this.combine=='yes' && this.dataFilter=='za' && this.search!=='yes' && this.last90Days!=='yes' && this.filter!=='yes'){ 
        this.getFilterByProductAllZToA(this.currentPageInPagination);
      } else if( this.combine !=='yes'&& this.filter !=='yes' && this.search !=='yes' && this.days =='yes'){
        this.getAiemaProductByLast90Days(this.currentPageInPagination);
      }
  }

 //GET ALL BRANDS PRODUCTS
 getAllBrandProducts(event: number){
  this.currentPageInPagination = event;
  this.page = (event-1);
  if(this.dataFilter=='az'){
    var req_product_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit":(event),
          "pagesize": 77
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "name",
          "sortorder": -1
        },
        "subcategoryfilter":false
      }
    }
  } else if(this.dataFilter=='za'){
    var req_product_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit":(event),
          "pagesize": 77
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "name",
          "sortorder": +1
        },
        "subcategoryfilter":false
      }
    }
  }

 this.appService.postApiCall(req_product_data, ENDPOINTS.GET_ALL_PRODUCTS).subscribe(
   resp => {
     this.productsApiData = resp;
     this.products = this.productsApiData.result.data;
     this.totalNumberOfProducts = this.productsApiData.result.total;
    //  if(!this.isfilter)
    //  {
      //this.isfilter=true;
      this.categoryList = this.productsApiData.result.filters[0].categories;
     this.facets =  this.productsApiData.result.filters[1].brands;
     
     this.themes =  this.productsApiData.result.filters[2].themes;
     this.finish =  this.productsApiData.result.filters[3].finish;
     this.features =  this.productsApiData.result.filters[4].features;
     this.prices =  this.productsApiData.result.filters[5].prices;
     this.size =  this.productsApiData.result.filters[6].size;
     this.thickness =  this.productsApiData.result.filters[7].thickness;
     console.log("color111")
     this.color =  this.productsApiData.result.filters[8].color;
     this.type =  this.productsApiData.result.filters[9].type;
     this.material =  this.productsApiData.result.filters[10].product_material;
     //}
    //  this.totalLength = this.products ? this.products.totalLength : 0;
     this.totalLength = this.productsApiData.result.total ? this.productsApiData.result.total : 0;
     if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
      this.totalLength = this.totalItemsOrProducts = this.productsApiData.result.total.value ? this.productsApiData.result.total.value : 0;
     }
   },
   err => {
     if (err.error.error.message) {
       console.log(err.error.error.message)
     }
     else{
       console.log('Something bad happened; Please try again!')
     }
   })
}

 //GET FILTER PRODUCTS
 getFilterByProduct(event: number){
  console.log("inside111") 
  this.currentPageInPagination = event;
  this.filter='yes';
if(this.CONFIG.title==="BombayHardware" || this.CONFIG.title==="Deera"){
  if(this.dataFilter=='az'){
    this.req_filter_data1 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
       // "brand":[this.allInOneData[0]],
        "category":this.category,
      "last90days":false,
      "last30days":false,
      "includeoutofstock":false,
      "isglobalfilter":false,
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":25
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":-1
    },
    "subcategoryfilter":false
    
    }
   } 
   } else if(this.dataFilter=='za'){
    this.req_filter_data1 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
       // "brand":[this.allInOneData[0]],
        "category":this.category,
      "last90days":false,
      "last30days":false,
      "includeoutofstock":false,
      "isglobalfilter":false,
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":25
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":+1
    },
    "subcategoryfilter":false
    
    }
   }
   }

} else {

  if(this.dataFilter=='az'){
    this.req_filter_data1 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
       // "brand":[this.allInOneData[0]],
        "category":this.category,
      "last90days":false,
      "last30days":false,
      "includeoutofstock":false,
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":77
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":-1
    },
    "subcategoryfilter":false
    
    }
   } 
   } else if(this.dataFilter=='za'){
    this.req_filter_data1 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
       // "brand":[this.allInOneData[0]],
        "category":this.category,
      "last90days":false,
      "last30days":false,
      "includeoutofstock":false,
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":25
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":+1
    },
    "subcategoryfilter":false
    
    }
   }
   }

}


 this.appService.postApiCall(this.req_filter_data1, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
   resp => {
     this.filtersApiData = resp;
     this.products = this.filtersApiData.result.data;
     this.totalNumberOfProducts = this.filtersApiData.result.total;
    //  if(!this.isfilter)
    //  {
      //this.isfilter=true;
      this.categoryList = this.filtersApiData.result.filters[0].categories;
     this.facets =  this.filtersApiData.result.filters[1].brands;
     
     this.themes =  this.filtersApiData.result.filters[2].themes;
     this.finish =  this.filtersApiData.result.filters[3].finish;
     this.features =  this.filtersApiData.result.filters[4].features;
     this.prices =  this.filtersApiData.result.filters[5].prices;
     this.size =  this.filtersApiData.result.filters[6].size;
     this.thickness =  this.filtersApiData.result.filters[7].thickness;
     console.log("color222")
     this.color =  this.filtersApiData.result.filters[8].color;
     this.type =  this.filtersApiData.result.filters[9].type;
     this.material =  this.filtersApiData.result.filters[10].product_material;
    // }
   
     this.totalItemsOrProducts = this.filtersApiData.result.total.value ? this.filtersApiData.result.total.value : 0;
     
     this.totalProducts = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;
     this.totalLength = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;
     if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
      this.totalLength = this.totalItemsOrProducts = this.filtersApiData.result.total.value ? this.filtersApiData.result.total.value : 0;
     }
     
   },
   err => {
     if (err.error.error.message) {
       console.log(err.error.error.message)
     }
     else{
       console.log('Something bad happened; Please try again!')
     }
   })
}


getFilterByProductAll(event: number){
  console.log("inside222") 
  console.log("filter")
  this.combine='yes';
  this.include=false;
  this.days30=false;
  this.days90=false;
 
 if(this.allInOneData[2]){
   this.include=false;
   this.days30=false;
   this.days90=false;
  for(var i=0;i<this.allInOneData[2].split(',').length;i++){
    if(this.allInOneData[2].split(',')[i] =='Include Out of Stock'){
     this.include=true;
    }else if(this.allInOneData[2].split(',')[i] =='Last 90 days'){
      console.log("insideee")
     this.days90=true;
    }else if(this.allInOneData[2].split(',')[i] =='Last 30 days'){
    this.days30=true;
    }else{
     this.include=false;
     this.days30=false;
     this.days90=false;
    }

    
  }
 }

 this.currentPageInPagination = event;
 if(this.allInOneData.length === 0){
   this.allInOneData[0]=''
   this.allInOneData[1]=''
   
 }else{
  if(this.allInOneData[1].length === 0){
   
    //this.allInOneData[1]=this.cate
  }else{
    
    if(Array.isArray(this.allInOneData[1]) )
    {
     
    }
    else
    {
      this.allInOneData[1]=[this.allInOneData[1]]
    }
   
  }

 }
 
 var brandData= JSON.parse(JSON.stringify(this.allInOneData));

 if(this.pagenation !=true){
  this.allInOneData[0] = brandData[0].split(',');
   this.allInOneData[3]=brandData[3].split(',');
 this.allInOneData[4]=brandData[4].split(',');
   this.allInOneData[5]=brandData[5].split(',');
   this.allInOneData[6]=brandData[6].split(',');
   if(this.CONFIG.title!=="Deera" &&  this.CONFIG.title!=="ShubhCards"){
    // this.allInOneData[7]=brandData[7].split(',');
   this.allInOneData[8]=brandData[8].split(',');

   }
   this.allInOneData[7]=brandData[7].split(',');
   this.allInOneData[9]=brandData[9].split(',');
   this.allInOneData[10]=brandData[10].split(',');
   this.allInOneData[11]=brandData[11].split(',');
 }
 if(this.latest_products==="latest" && (this.productfilter==undefined||this.productfilter.active === "")){
  this.days90 = true;
  //this.allInOneData[1] = [];
  }
  else if(this.productfilter && this.productfilter.active === ""){
  

 
   if(this.clearCategoryData.length >0){
    this.allInOneData[1] = this.clearCategoryData;
   }
    
  
  }
 

 if(this.allInOneData[0].length === 1){
  this.allInOneData[0].forEach(element => {
   
    if(!element){
      if(this.brad.length >0){
        this.allInOneData[0]=[this.brad]
      }
      
    }
  });
}

if(this.days90===false){
  if(this.ninetyDaysLastCheck === true){
    this.days90=true
  }else{
    this.days90=false
  }
}
var sortField="created_on"
var sortOrderValue = 1

if(this.CONFIG.title==="BombayHardware" || this.CONFIG.title==="Deera"){
  this.req_filter_data2 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
    "brand":this.allInOneData[0]!="" && this.allInOneData[0]!=undefined && this.allInOneData[0]!=null?this.allInOneData[0]:[],
    "category":this.allInOneData[1],
    "theme":this.allInOneData[3]!="" && this.allInOneData[3]!=undefined && this.allInOneData[3]!=null?this.allInOneData[3]:[],
    "finish":this.allInOneData[4]!="" && this.allInOneData[4]!=undefined && this.allInOneData[4]!=null?this.allInOneData[4]:[],
    "feature":this.allInOneData[5]!="" && this.allInOneData[5]!=undefined && this.allInOneData[5]!=null?this.allInOneData[5]:[],
    "price":this.allInOneData[6]!="" && this.allInOneData[6]!=undefined && this.allInOneData[6]!=null?this.allInOneData[6]:[],
    "size":this.allInOneData[7]!="" && this.allInOneData[7]!=undefined && this.allInOneData[7]!=null?this.allInOneData[7]:[],
    "thickness":this.allInOneData[8]!="" && this.allInOneData[8]!=undefined && this.allInOneData[8]!=null?this.allInOneData[8]:[],
    "color":this.allInOneData[9]!="" && this.allInOneData[9]!=undefined && this.allInOneData[9]!=null?this.allInOneData[9]:[],
    "type":this.allInOneData[10]!="" && this.allInOneData[10]!=undefined && this.allInOneData[10]!=null?this.allInOneData[10]:[],
    "product_material":this.allInOneData[11]!="" && this.allInOneData[11]!=undefined && this.allInOneData[11]!=null?this.allInOneData[11]:[],

    
    "last90days":this.days90,
    "last30days":this.days30,
    "includeoutofstock":this.include,
    "isglobalfilter":false,
  },
  "pagination":true,
  "paginationDetails":{
      "limit":(event),
      "pagesize":77
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"name",
    "sortorder": this.dataFilter === "az"? -1 : +1
  },
  "subcategoryfilter":false
  }
  }
}
else {
  this.req_filter_data2 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
    "brand":this.allInOneData[0]!="" && this.allInOneData[0]!=undefined && this.allInOneData[0]!=null?this.allInOneData[0]:[],
    "category":this.allInOneData[1],
    "theme":this.allInOneData[3]!="" && this.allInOneData[3]!=undefined && this.allInOneData[3]!=null?this.allInOneData[3]:[],
    "finish":this.allInOneData[4]!="" && this.allInOneData[4]!=undefined && this.allInOneData[4]!=null?this.allInOneData[4]:[],
    "feature":this.allInOneData[5]!="" && this.allInOneData[5]!=undefined && this.allInOneData[5]!=null?this.allInOneData[5]:[],
    "price":this.allInOneData[6]!="" && this.allInOneData[6]!=undefined && this.allInOneData[6]!=null?this.allInOneData[6]:[],
    "size":this.allInOneData[7]!="" && this.allInOneData[7]!=undefined && this.allInOneData[7]!=null?this.allInOneData[7]:[],
    "thickness":this.allInOneData[8]!="" && this.allInOneData[8]!=undefined && this.allInOneData[8]!=null?this.allInOneData[8]:[],
    "color":this.allInOneData[9]!="" && this.allInOneData[9]!=undefined && this.allInOneData[9]!=null?this.allInOneData[9]:[],
    "type":this.allInOneData[10]!="" && this.allInOneData[10]!=undefined && this.allInOneData[10]!=null?this.allInOneData[10]:[],
    "product_material":this.allInOneData[11]!="" && this.allInOneData[11]!=undefined && this.allInOneData[11]!=null?this.allInOneData[11]:[],

    "last90days":this.days90,
    "last30days":this.days30,
    "includeoutofstock":this.include,
  },
  "pagination":true,
  "paginationDetails":{
      "limit":(event),
      "pagesize":77
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"name",
    "sortorder": this.dataFilter === "az"? -1 : +1
  },
  "subcategoryfilter":false
  }
  }
}

this.loaderService.emitLoading();
this.appService.postApiCall(this.req_filter_data2, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
  resp => {
    console.log("subscribe")
    
  //  this.allInOneData=[];
  this.pagenation=false;
    this.filtersApiData = resp;
    this.products = this.filtersApiData.result.data;
    this.totalNumberOfProducts = this.filtersApiData.result.total;
   
  if(!this.isfilter)
     {
      this.isfilter=true;
      if(this.categoryList.length ===0){
        this.categoryList = this.filtersApiData.result.filters[0].categories;
        
      }
    if(this.facets && this.facets.length === 0){
       this.facets =  this.filtersApiData.result.filters[1].brands;
    }
    if(this.allInOneData[0].length === 1){
      this.allInOneData[0].forEach(element => {
       
        if(!element){
          this.facets =  this.filtersApiData.result.filters[1].brands;
        }
      });
    }
  if(this.facets && this.facets.length === 0){
     this.facets =  this.filtersApiData.result.filters[1].brands;
  }
  if(this.allInOneData[0].length === 1){
    this.allInOneData[0].forEach(element => {
     
      if(!element){
        this.facets =  this.filtersApiData.result.filters[1].brands;
      }
    });
  }

     if(this.CONFIG.title !== "Deera" && this.CONFIG.title !== "BombayHardware"){
      this.themes =  this.filtersApiData.result.filters[2].themes;
      this.finish =  this.filtersApiData.result.filters[3].finish;
      this.features =  this.filtersApiData.result.filters[4].features;
      this.prices =  this.filtersApiData.result.filters[5].prices;
      this.size =  this.filtersApiData.result.filters[6].size;
      this.thickness =  this.filtersApiData.result.filters[7].thickness;
      console.log("color333")
      this.color =  this.filtersApiData.result.filters[8].color;
      this.type =  this.filtersApiData.result.filters[9].type;
      this.material =  this.filtersApiData.result.filters[10].product_material;
     }
     }
     if(this.CONFIG.title === "Deera" || this.CONFIG.title === "BombayHardware"){
      this.facets =  this.filtersApiData.result.filters[1].brands;
      this.themes =  this.filtersApiData.result.filters[2].themes;
      this.finish =  this.filtersApiData.result.filters[3].finish;
      this.features =  this.filtersApiData.result.filters[4].features;
      this.prices =  this.filtersApiData.result.filters[5].prices;
      this.size =  this.filtersApiData.result.filters[6].size;
      this.thickness =  this.filtersApiData.result.filters[7].thickness;
      console.log("color333")
      this.color =  this.filtersApiData.result.filters[8].color;
      this.type =  this.filtersApiData.result.filters[9].type;
      this.material =  this.filtersApiData.result.filters[10].product_material;
     }
    this.totalItemsOrProducts = this.filtersApiData.result.total.value ? this.filtersApiData.result.total.value : 0;
  
    //  this.totalLength = this.products ? this.products.totalLength : 0;
    this.totalLength = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;
    if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
      this.totalLength = this.totalItemsOrProducts = this.filtersApiData.result.total.value ? this.filtersApiData.result.total.value : 0;
     }
this.loaderService.emitComplete();
  },
  err => {
    if (err.error.error.message) {
      console.log(err.error.error.message)
    }
    else{
      console.log('Something bad happened; Please try again!')
    }
  })
}


getFilterByProductAllZToA(event: number){
  console.log("inside333") 
  this.combine='yes';
  this.include=false;
  this.days30=false;
  this.days90=false;
 if(this.allInOneData1[2]){
   this.include=false;
   this.days30=false;
   this.days90=false;
  for(var i=0;i<this.allInOneData1[2].split(',').length;i++){
    if(this.allInOneData1[2].split(',')[i] =='Include Out of Stock'){
     this.include=true;
    }else if(this.allInOneData1[2].split(',')[i] =='Last 90 days'){
     this.days90=true;
    }else if(this.allInOneData1[2].split(',')[i] =='Last 30 days'){
    this.days30=true;
    }else{
     this.include=false;
     this.days30=false;
     this.days90=false;
    }

    
  }
 }

 this.currentPageInPagination = event;
 if(this.allInOneData1.length === 0){
   this.allInOneData1[0]=''
   this.allInOneData1[1]=''
   
 }else{
  if(this.allInOneData1[1].length === 0){
    this.allInOneData1[1]=this.cate
  }else{
    // this.allInOneData1[1]=[this.allInOneData1[1]]
    this.allInOneData1[1]=this.allInOneData1[1]
  }
 }

 if(this.CONFIG.title==="BombayHardware" || this.CONFIG.title==="Deera"){
  this.req_filter_data3 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
    // "brand":this.allInOneData1[0]!="" && this.allInOneData1[0]!=undefined && this.allInOneData1[0]!=null?this.allInOneData1[0]:[],
    "brand":this.allInOneData1[0],
    "category":this.allInOneData1[1],
    "last90days":this.days90,
    "last30days":this.days30,
    "includeoutofstock":this.include,
    "isglobalfilter":false,
  },
  "pagination":true,
  "paginationDetails":{
      "limit":(event),
      "pagesize":77
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"id",
    "sortorder":+1
  },
  "subcategoryfilter":false
  }
  }
 }
 else {
  this.req_filter_data3 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
"user_id": null,
"extras"    :{
"find":{
  // "brand":this.allInOneData1[0]!="" && this.allInOneData1[0]!=undefined && this.allInOneData1[0]!=null?this.allInOneData1[0]:[],
  "brand":this.allInOneData1[0],
  "category":this.allInOneData1[1],
  "last90days":this.days90,
  "last30days":this.days30,
  "includeoutofstock":this.include,
},
"pagination":true,
"paginationDetails":{
    "limit":(event),
    "pagesize":77
},
"sorting":true,
"sortingDetails":{
    "sortfield":"id",
  "sortorder":+1
},
"subcategoryfilter":false
}
}
 }


this.appService.postApiCall(this.req_filter_data3, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
  resp => {
    this.allInOneData1=[];
    this.filtersApiData = resp;
    this.products = this.filtersApiData.result.data;
    // if(!this.isfilter)
    //  {
      //this.isfilter=true;
      this.categoryList = this.filtersApiData.result.filters[0].categories;
     this.facets =  this.filtersApiData.result.filters[1].brands;
     
     this.themes =  this.filtersApiData.result.filters[2].themes;
     this.finish =  this.filtersApiData.result.filters[3].finish;
     this.features =  this.filtersApiData.result.filters[4].features;
     this.prices =  this.filtersApiData.result.filters[5].prices;
     this.size =  this.filtersApiData.result.filters[6].size;
     this.thickness =  this.filtersApiData.result.filters[7].thickness;
     console.log("color444")
     this.color =  this.filtersApiData.result.filters[8].color;
     this.type =  this.filtersApiData.result.filters[9].type;
     this.material =  this.filtersApiData.result.filters[10].product_material;
     //}
    this.totalNumberOfProducts = this.filtersApiData.result.total;
    this.totalItemsOrProducts = this.filtersApiData.result.total.value ? this.filtersApiData.result.total.value : 0;
    this.totalLength = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;
    if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
      this.totalLength = this.totalItemsOrProducts = this.filtersApiData.result.total.value ? this.filtersApiData.result.total.value : 0;
     }

  },
  err => {
    if (err.error.error.message) {
      console.log(err.error.error.message)
    }
    else{
      console.log('Something bad happened; Please try again!')
    }
  })
}

//GET PRODUCTS BY LAST90DAYS
getProductByLast90Days(event: number){
  console.log("inside444") 
  this.currentPageInPagination = event;
  this.last90Days='yes';
  
if(this.CONFIG.title==="BombayHardware" || this.CONFIG.title==="Deera"){
  if(this.dataFilter=='az'){
    this.req_last90days_product_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
      "brand":[],
     "category":[],
    "last90days":true,
    "last30days":false,
    "includeoutofstock":false,
    "isglobalfilter":false,
  },
  "pagination":true,
  "paginationDetails":{
      "limit":(event),
      "pagesize":77
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"name",
    "sortorder":-1
  },
  
  }
  }
   } else if(this.dataFilter=='za'){
    this.req_last90days_product_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
        "brand":[],
       "category":[],
      "last90days":true,
      "last30days":false,
      "includeoutofstock":false,
      "isglobalfilter":false,
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":77
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":+1
    },
    
    }
    }
   }

} else {
  if(this.dataFilter=='az'){
    this.req_last90days_product_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
      "brand":[],
     "category":[],
    "last90days":true,
    "last30days":false,
    "includeoutofstock":false,
  },
  "pagination":true,
  "paginationDetails":{
      "limit":(event),
      "pagesize":77
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"name",
    "sortorder":-1
  },
  
  }
  }
   } else if(this.dataFilter=='za'){
    this.req_last90days_product_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
        "brand":[],
       "category":[],
      "last90days":true,
      "last30days":false,
      "includeoutofstock":false,
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":77
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":+1
    },
    
    }
    }
   }

}

 

 this.appService.postApiCall(this.req_last90days_product_data, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
   resp => {
     this.last90DaysProductsApiData = resp;
     this.products = this.last90DaysProductsApiData.result.data;
     this.totalNumberOfProducts = this.last90DaysProductsApiData.result.total;
    //  if(!this.isfilter)
    //  {
      //this.isfilter=true;
      this.categoryList = this.last90DaysProductsApiData.result.filters[0].categories;
      this.facets =  this.last90DaysProductsApiData.result.filters[1].brands;
      this.themes =  this.last90DaysProductsApiData.result.filters[2].themes;
      this.finish =  this.last90DaysProductsApiData.result.filters[3].finish;
      this.features =  this.last90DaysProductsApiData.result.filters[4].features;
      this.prices =  this.last90DaysProductsApiData.result.filters[5].prices;
      this.size =  this.last90DaysProductsApiData.result.filters[6].size;
     this.thickness =  this.last90DaysProductsApiData.result.filters[7].thickness;
     console.log("color555")
     this.color =  this.last90DaysProductsApiData.result.filters[8].color;
     this.type =  this.last90DaysProductsApiData.result.filters[9].type;
     this.material =  this.last90DaysProductsApiData.result.filters[10].product_material;
     //}
     
     this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
     
     if(this.CONFIG.title === 'Aiema'||this.CONFIG.title === "BombayHardware"){
      this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
     }
     if(this.facets.length > 0){
       this.facets.forEach(element => {
         this.brandPush.push(element.name)
       });
 
       this.productFilterService.updateAdminFormEvent(this.brandPush);
       
     }
     this.totalLength = this.last90DaysProductsApiData.result.total ? this.last90DaysProductsApiData.result.total : 0;
     if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
      this.totalLength = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
      if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
        this.totalLength = this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
       }
     }
   },
   err => {
     if (err.error.error.message) {
       console.log(err.error.error.message)
     }
     else{
       console.log('Something bad happened; Please try again!')
     }
   })
 }

 getAiemaProductByLast90Days(event: number){
  console.log("inside555") 
  console.log("paginationnnnn");
  this.currentPageInPagination = event;
  this.last90Days='no';
  
if(this.CONFIG.title==="BombayHardware" || this.CONFIG.title==="Deera"){
  if(this.dataFilter=='az'){
    this.req_last90days_product_data1 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
      "brand":[],
     "category":[],
    "last90days":false,
    "last30days":false,
    "includeoutofstock":false,
    "isglobalfilter":false,
  },
  "pagination":true,
  "paginationDetails":{
      "limit":(event),
      "pagesize":77
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"name",
    "sortorder":-1
  },
  
  }
  }
   } else if(this.dataFilter=='za'){
    this.req_last90days_product_data1 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
        "brand":[],
       "category":[],
      "last90days":false,
      "last30days":false,
      "includeoutofstock":false,
      "isglobalfilter":false,
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":25
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":+1
    },
    
    }
    }
   }
} else {
  if(this.dataFilter=='az'){
    this.req_last90days_product_data1 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
      "brand":[],
     "category":[],
    "last90days":false,
    "last30days":false,
    "includeoutofstock":false,
  },
  "pagination":true,
  "paginationDetails":{
      "limit":(event),
      "pagesize":25
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"name",
    "sortorder":-1
  },
  
  }
  }
   } else if(this.dataFilter=='za'){
    this.req_last90days_product_data1 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
        "brand":[],
       "category":[],
      "last90days":false,
      "last30days":false,
      "includeoutofstock":false,
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":77
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":+1
    },
    
    }
    }
   }
}

 

 this.appService.postApiCall(this.req_last90days_product_data1, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
   resp => {
     this.last90DaysProductsApiData = resp;
     this.products = this.last90DaysProductsApiData.result.data;
     this.totalNumberOfProducts = this.last90DaysProductsApiData.result.total;
    //  if(!this.isfilter)
    //  {
      //this.isfilter=true;
     this.categoryList = this.last90DaysProductsApiData.result.filters[0].categories;
     this.facets =  this.last90DaysProductsApiData.result.filters[1].brands;
     this.themes =  this.last90DaysProductsApiData.result.filters[2].themes;
     this.finish =  this.last90DaysProductsApiData.result.filters[3].finish;
     this.features =  this.last90DaysProductsApiData.result.filters[4].features;
     this.prices =  this.last90DaysProductsApiData.result.filters[5].prices;
     this.size =  this.last90DaysProductsApiData.result.filters[6].size;
     this.thickness =  this.last90DaysProductsApiData.result.filters[7].thickness;
     console.log("color666")
     this.color =  this.last90DaysProductsApiData.result.filters[8].color;
     this.type =  this.last90DaysProductsApiData.result.filters[9].type;
     this.material =  this.last90DaysProductsApiData.result.filters[10].product_material;
     //}
     this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
     
     if(this.CONFIG.title === 'Aiema'||this.CONFIG.title === "BombayHardware"){
      this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
     }
     if(this.facets.length > 0){
       this.facets.forEach(element => {
         this.brandPush.push(element.name)
       });
 
       this.productFilterService.updateAdminFormEvent(this.brandPush);
       
     }
     this.totalLength = this.last90DaysProductsApiData.result.total ? this.last90DaysProductsApiData.result.total : 0;
     if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
      this.totalLength = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
      if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
        this.totalLength = this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
       }
     }
   },
   err => {
     if (err.error.error.message) {
       console.log(err.error.error.message)
     }
     else{
       console.log('Something bad happened; Please try again!')
     }
   })
 }
 
  //GET SEARCH RESULT
  getSearchResult(event: number){
    console.log("inside666") 
   this.currentPageInPagination = event;
   this.search='yes';
   if(this.CONFIG.title==="BombayHardware" || this.CONFIG.title==="Deera"){
    if(this.dataFilter=='az'){
      this.req_search_data = {    
        "domain_name":ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras"    :{
        "find":{
          "search":this.searchKey,
          "isglobalfilter":false,
        },
        "pagination":true,
        "paginationDetails":{
            "limit":(event),
            "pagesize":77
        },
        "sorting":true,
        "sortingDetails":{
            "sortfield":"name",
          "sortorder":-1
        }
        }
       }
    } else if(this.dataFilter=='za'){
      this.req_search_data = {    
        "domain_name":ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras"    :{
        "find":{
          "search":this.searchKey,
           "isglobalfilter":false,
        },
        "pagination":true,
        "paginationDetails":{
            "limit":(event),
            "pagesize":77
        },
        "sorting":true,
        "sortingDetails":{
            "sortfield":"name",
          "sortorder":+1
        }
        }
       }
    }
   }
   else {
    if(this.dataFilter=='az'){
      this.req_search_data = {    
        "domain_name":ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras"    :{
        "find":{
          "search":this.searchKey,
        },
        "pagination":true,
        "paginationDetails":{
            "limit":(event),
            "pagesize":77
        },
        "sorting":true,
        "sortingDetails":{
            "sortfield":"name",
          "sortorder":-1
        }
        }
       }
    } else if(this.dataFilter=='za'){
      this.req_search_data = {    
        "domain_name":ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras"    :{
        "find":{
          "search":this.searchKey,
        },
        "pagination":true,
        "paginationDetails":{
            "limit":(event),
            "pagesize":77
        },
        "sorting":true,
        "sortingDetails":{
            "sortfield":"name",
          "sortorder":+1
        }
        }
       }
    }
   }
 
 
  this.appService.postApiCall(this.req_search_data, ENDPOINTS.GET_SEARCH_RESULT).subscribe(
    resp => {
      this.searchApiData = resp;
      this.products = this.searchApiData.result.data;
      this.totalNumberOfProducts = this.searchApiData.result.total;
    //   if(!this.isfilter)
    //  {
      //this.isfilter=true;
      this.categoryList = this.searchApiData.result.filters[0].categories;
     this.facets =  this.searchApiData.result.filters[1].brands;
     
     this.themes =  this.searchApiData.result.filters[2].themes;
     this.finish =  this.searchApiData.result.filters[3].finish;
     this.features =  this.searchApiData.result.filters[4].features;
     this.prices =  this.searchApiData.result.filters[5].prices;
     this.size =  this.searchApiData.result.filters[6].size;
     this.thickness =  this.searchApiData.result.filters[7].thickness;
     console.log("color777")
     this.color =  this.searchApiData.result.filters[8].color;
     this.type =  this.searchApiData.result.filters[9].type;
     this.material =  this.searchApiData.result.filters[10].product_material;
     //}
     this.totalItemsOrProducts = this.searchApiData.result.total.value ? this.searchApiData.result.total.value : 0;
      this.totalLength = this.searchApiData.result.total ? this.searchApiData.result.total : 0;
      if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
        this.totalLength = this.totalItemsOrProducts = this.searchApiData.result.total.value ? this.searchApiData.result.total.value : 0;
       }
 
    },
    err => {
      if (err.error.error.message) {
        console.log(err.error.error.message)
      }
      else{
        console.log('Something bad happened; Please try again!')
      }
    })
 }
 //GET PRODUCTS BY SORT
 getProductByLast90DaysSorting(event: number){
  console.log("inside777") 
   this.currentPageInPagination = event;
   if(this.CONFIG.title==="BombayHardware" || this.CONFIG.title==="Deera"){
    this.req_last90days_product_data2 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras" :{
    "find":{
    "brand":[],
    "category":[],
    "last90days":true,
    "last30days":false,
    "includeoutofstock":false,
     "isglobalfilter":false,
    },
    "pagination":true,
    "paginationDetails":{
    "limit":(event),
    "pagesize":77
    },
    "sorting":true,
    "sortingDetails":{
    "sortfield":"name",
    "sortorder":this.dataFilter === "az"? -1 : +1
    },
    }
    }
   } else {
    this.req_last90days_product_data2 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras" :{
    "find":{
    "brand":[],
    "category":[],
    "last90days":true,
    "last30days":false,
    "includeoutofstock":false,
    },
    "pagination":true,
    "paginationDetails":{
    "limit":(event),
    "pagesize":77
    },
    "sorting":true,
    "sortingDetails":{
    "sortfield":"name",
    "sortorder":this.dataFilter === "az"? -1 : +1
    },
    }
    }
   }

   this.appService.postApiCall(this.req_last90days_product_data2, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
   resp => {
   this.last90DaysProductsApiData = resp;
   this.products = this.last90DaysProductsApiData.result.data;
  //  if(!this.isfilter)
  //    {
      //this.isfilter=true;
      this.categoryList = this.last90DaysProductsApiData.result.filters[0].categories;
     this.facets =  this.last90DaysProductsApiData.result.filters[1].brands;
     
     this.themes =  this.last90DaysProductsApiData.result.filters[2].themes;
     this.finish =  this.last90DaysProductsApiData.result.filters[3].finish;
     this.features =  this.last90DaysProductsApiData.result.filters[4].features;
     this.prices =  this.last90DaysProductsApiData.result.filters[5].prices;
     this.size =  this.last90DaysProductsApiData.result.filters[6].size;
     this.thickness =  this.last90DaysProductsApiData.result.filters[7].thickness;
     console.log("color888")
     this.color =  this.last90DaysProductsApiData.result.filters[8].color;
     this.type =  this.last90DaysProductsApiData.result.filters[9].type;
     this.material =  this.last90DaysProductsApiData.result.filters[10].product_material;
     //}
   this.totalNumberOfProducts = this.last90DaysProductsApiData.result.total;
   this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
  
   this.totalLength = this.last90DaysProductsApiData.result.total ? this.last90DaysProductsApiData.result.total : 0;
   if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
    this.totalLength = this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
   }
   },
   err => {
   if (err.error.error.message) {
   console.log(err.error.error.message)
   }
   else{
   console.log('Something bad happened; Please try again!')
   }
   })
   }
 
   setPage(page: number) {
    
   }
 
   updateFilter(tags: any) {}
 
   // TO REFRESH ALL PRODUCTS ON CLEAR ALL FUNCTION
   refreshProducts(topCategories){
    console.log("inside888") 
     this.clearCategoryData=topCategories;
     this.currentPageInPagination = 1;
     if(this.CONFIG.title==="BombayHardware" || this.CONFIG.title==="Deera"){
      this.req_filter_data4 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras"    :{
      "find":{
        // "brand":[this.allInOneData[0]],
          "category":topCategories,
        "last90days":false,
        "last30days":false,
        "includeoutofstock":false,
        "isglobalfilter":false,
      },
      "pagination":true,
      "paginationDetails":{
          "limit":1,
          "pagesize":77
      },
      "sorting":true,
      "sortingDetails":{
          "sortfield":"name",
        "sortorder": this.dataFilter === "az"? -1 : +1
      },
      "subcategoryfilter":false
      
      }
    }
     } else {
      this.req_filter_data4 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras"    :{
      "find":{
        // "brand":[this.allInOneData[0]],
          "category":topCategories,
        "last90days":false,
        "last30days":false,
        "includeoutofstock":false,
      },
      "pagination":true,
      "paginationDetails":{
          "limit":1,
          "pagesize":77
      },
      "sorting":true,
      "sortingDetails":{
          "sortfield":"name",
        "sortorder": this.dataFilter === "az"? -1 : +1
      },
      "subcategoryfilter":false
      
      }
    }

     } 
 
     this.appService.postApiCall(this.req_filter_data4, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
       resp => {
         this.products = resp.result.data; 
         this.totalLength = resp.result.total ? resp.result.total : 0;
    //      if(!this.isfilter)
    //  {
      //this.isfilter=true;
      this.categoryList = resp.result.filters[0].categories;
     this.facets =  resp.result.filters[1].brands;
     
     this.themes =  resp.result.filters[2].themes;
     this.finish =  resp.result.filters[3].finish;
     this.features =  resp.result.filters[4].features;
     this.prices =  resp.result.filters[5].prices;
     this.size =  resp.result.filters[6].size;
     this.thickness =  resp.result.filters[7].thickness;
     console.log("color999")
     this.color =   resp.result.filters[8].color;
     this.type =   resp.result.filters[9].type;
     this.material =   resp.result.filters[10].product_material;
     //}
         this.totalItemsOrProducts = resp.result.total.value ? resp.result.total.value : 0;  
         this.totalLength = resp.result.total ? resp.result.total : 0;
         if(this.CONFIG.title === "Aiema"||this.CONFIG.title==="ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
          this.totalLength = this.totalItemsOrProducts = resp.result.total.value ? resp.result.total.value : 0;
         }     
       },
       err => {
         if (err.error.error.message) {
           console.log(err.error.error.message)
         }
         else{
           console.log('Something bad happened; Please try again!')
         }
       })
       }
 
   // TO REFRESH ALL PRODUCTS ON CLEAR ALL FUNCTION
  refreshLast90Days(event: number){
    console.log("inside999") 
   this.currentPageInPagination = event;
   this.last90Days='yes';

   if(this.CONFIG.title==="BombayHardware" || this.CONFIG.title==="Deera"){
    if(this.dataFilter=='az'){
      this.req_last90days_product_data3 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
        "brand":[],
       "category":[],
      "last90days":true,
      "last30days":false,
      "includeoutofstock":false,
       "isglobalfilter":false,
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":77
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":-1
    },
    
    }
     }
       } else if(this.dataFilter=='za'){
      this.req_last90days_product_data3 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras"    :{
      "find":{
          "brand":[],
         "category":[],
        "last90days":true,
        "last30days":false,
        "includeoutofstock":false,
        "isglobalfilter":false,
      },
      "pagination":true,
      "paginationDetails":{
          "limit":(event),
          "pagesize":77
      },
      "sorting":true,
      "sortingDetails":{
          "sortfield":"name",
        "sortorder":+1
      },
      
      }
      }
       }

   } else {
    if(this.dataFilter=='az'){
      this.req_last90days_product_data3 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
        "brand":[],
       "category":[],
      "last90days":true,
      "last30days":false,
      "includeoutofstock":false,
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":77
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":-1
    },
    
    }
     }
       } else if(this.dataFilter=='za'){
      this.req_last90days_product_data3 = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras"    :{
      "find":{
          "brand":[],
         "category":[],
        "last90days":true,
        "last30days":false,
        "includeoutofstock":false,
      },
      "pagination":true,
      "paginationDetails":{
          "limit":(event),
          "pagesize":77
      },
      "sorting":true,
      "sortingDetails":{
          "sortfield":"name",
        "sortorder":+1
      },
      
      }
      }
       }
   }
  
  
     this.appService.postApiCall(this.req_last90days_product_data3, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
    resp => {
      this.products = resp.result.data;
    //   if(!this.isfilter)
    //  {
      //this.isfilter=true;
      this.categoryList = resp.result.filters[0].categories;
     this.facets =  resp.result.filters[1].brands;
     
     this.themes =  resp.result.filters[2].themes;
     this.finish =  resp.result.filters[3].finish;
     this.features =  resp.result.filters[4].features;
     this.prices =  resp.result.filters[5].prices;
     this.size =  resp.result.filters[6].size;
     this.thickness =  resp.result.filters[7].thickness;
     console.log("colorjjj")
     this.color =   resp.result.filters[8].color;
     this.type =   resp.result.filters[9].type;
     this.material =   resp.result.filters[10].product_material;
    // }
       this.totalItemsOrProducts = resp.result.total.value ? resp.result.total.value : 0;
       this.totalLength = resp.result.total ? resp.result.total : 0;
       if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
        this.totalLength = this.totalItemsOrProducts = resp.result.total.value ? resp.result.total.value : 0;
       } 
    },
    err => {
      if (err.error.error.message) {
        console.log(err.error.error.message)
      }
      else{
        console.log('Something bad happened; Please try again!')
      }
     })
  }

addToCart(item)
{
  this.cartService.addToCart(item);
  for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
    this.cartData.push(this.appService.cartProducts.results[i].product_id);
  }
    
  if(this.CONFIG.name==='Deera'){
    let tempArray = this.appService.cartProducts.results;
    let obj =
    // {
    //     "domain_name": ORG_DETAILS.DOMAIN_NAME,
    //     "user_id": ORG_DETAILS.USER_ID,
    //     "extras": {
    //         "find": {
    //           "product_id": Number(this.appService.cartProducts.results[i].product_id),
    //           "product_cost": Number(this.appService.cartProducts.results[i].cost),
    //           "quantity": Number(this.appService.cartProducts.results[i].quantity)
    //         }
    //     }
    //   }

      {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
              "product_id": Number(item.id),
              "product_cost": Number(item.pricing.raw.cost),
              "quantity": 1
            }
        }
      }
  
    this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
      resp => {
        if (resp) {
          console.log("respppp")
          if (resp.success === 1 && resp.status_code === 200) {
            for(let j =0;j<this.appService.cartProducts.results.length;j++){ 
              if(this.appService.cartProducts.results[j].product_id===item.id){
            this.appService.cartProducts.results[j].product.discount_value = resp.result.data.discount_applied_to_product_value;
            this.appService.cartProducts.results[j].product.discount_amount = resp.result.data.discount_amount;
            this.appService.cartProducts.results[j].product.fixed_amount =  resp.result.data.fixed_amount;
            this.appService.cartProducts.results[j].product.discount_percentage = resp.result.data.discount_percentage;
            if(resp.result.data.discount_percentage>0)	
            {	
              this.appService.cartProducts.results[j]['fixed_amount'] = resp.result.data.discount_amount;	
            }	
            else{	
              this.appService.cartProducts.results[j]['fixed_amount'] =  resp.result.data.fixed_amount;	
            }	
            //this.appService.cartProducts.results[j]['discount_amount'] = resp.result.data.discount_amount;	
            //this.appService.cartProducts.results[j]['fixed_amount'] =  resp.result.data.fixed_amount;	
            this.appService.cartProducts.results[j]['discount_percentage'] = resp.result.data.discount_percentage;	
            console.log("this.appService.cartProducts.results[i].product.fixed_amount"+this.appService.cartProducts.results[j].product.fixed_amount);	
            this.cartService.updateCartItem(this.appService.cartProducts.results);
            console.log("this.appService.cartProducts.results[i].product.fixed_amount"+this.appService.cartProducts.results[j].product.fixed_amount);
              }
            }
            let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
            this.localService.set("cart", cartItemsString);
          }
          else if (resp.success === 0) {
            if (resp.message) {
            }
          }
          else if (resp.message && resp.status_code !== 200) {
  
          }
          else {
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
         
  
        }
        else if (err.error.error.message) {
          
        }
        else {
        
        }
      }
    )

  }
  // if(this.CONFIG.title === 'Deera') {
  //   this.getPromotionBanner(item);
  // }

}

increaseItem(index)
{
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0
   console.log("tempArrayyyy"+JSON.stringify(tempArray));
  let increment;
  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
    
  })

  
  if(this.CONFIG.name==='ShubhCards')
  {
      
    tempArray[index].quantity = (Number(tempArray[index].quantity) + tempArray[index].product.pricing.raw.incrementvalue)
  }
  else{
    if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  { 
    this.showAlerts = true;
    window.scrollTo(0,0);
   
    if(this.CONFIG.name==='BombayHardware')
    {
      this.alertService.error("Maximum allowed quantity is 1 only",this.options)
    } else if(this.CONFIG.name==='Deera'){
      tempArray[index].quantity = 0;
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    else {
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    return
  }
    tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
  }

  if(this.CONFIG.name==='Deera'){
    let obj =
    {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
              "product_id": Number(tempArray[index].product_id),
              "product_cost": Number(tempArray[index].cost),
              "quantity": Number(tempArray[index].quantity)
            }
        }
      }
  
    this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
      resp => {
        if (resp) {
          if (resp.success === 1 && resp.status_code === 200) {
            console.log("tempArray[index].quantity"+tempArray[index].product.id);
            console.log("tempArray[index].quantity"+tempArray[index].product.id);
            console.log("resp.result.data.discount_applied_to_product_value"+resp.result.data.discount_applied_to_product_value);
            tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
            tempArray[index].product.discount_amount = resp.result.data.discount_amount;
            tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
            tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
            if(resp.result.data.discount_percentage>0)	
            {	
              this.appService.cartProducts.results[index]['fixed_amount'] = resp.result.data.discount_amount;	
            }	
            else{	
              this.appService.cartProducts.results[index]['fixed_amount'] =  resp.result.data.fixed_amount;	
            }	
            this.cartService.updateCartItem(this.appService.cartProducts.results);	
console.log("*******************************************************************^%^$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")

            console.log("tempArray[index].product.discount_value"+tempArray[index].product.discount_value);
          }
          else if (resp.success === 0) {
            if (resp.message) {
              //this.alertService.error(resp.message, this.options);
            }
          }
          else if (resp.message && resp.status_code !== 200) {
            //this.alertService.error(resp.message, this.options);
  
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          //this.alertService.error('Session Time Out! Please login Again', this.options)
  
        }
        else if (err.error.error.message) {
          //this.alertService.error(err.error.error.message, this.options)
        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
  }
 
  //tempArray[index].quantity = (Number(tempArray[index].quantity) + 2)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
  if(this.CONFIG.name==='Deera'){
  let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
  this.localService.set("cart", cartItemsString);
  }
}

ChangeValue(index)
{
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  var moq=tempArray[index].product.pricing.raw.minimumorderquantity;
  if(this.CONFIG.name==='ShubhCards')
  {
    //let tempArray = this.appService.cartProducts.results;
    if(this.appService.cartProducts.results[index].quantity  < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.cartData = [];
        for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
          this.cartData.push(this.appService.cartProducts.results[i].product_id);
        }
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        this.showAlerts = true;

        this.alertService.error("Minimum Order Quantity for this product is "+moq,this.options);
        return
        
    }
    tempArray[index].quantity = (Number(tempArray[index].quantity));
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.updateCartItem(this.appService.cartProducts.results);  
  }
}



changeDeeraValue(index)
{
  console.log("indexxxx"+ this.appService.cartProducts.results[index].quantity)
  console.log("indexxxx"+index)
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0
   console.log("tempArrayyyy"+JSON.stringify(tempArray));
  let increment;
  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
    
  })
  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  { 
    this.showAlerts = true;
    window.scrollTo(0,0);
   
    if(this.CONFIG.name==='BombayHardware')
    {
      this.alertService.error("Maximum allowed quantity is 1 only",this.options)
    } else if(this.CONFIG.name==='Deera'){
      this.appService.cartProducts.results[index].quantity = 0;
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    } else {
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    return
  }
    // tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
  // let tempArray = this.appService.cartProducts.results;
  if(this.appService.cartProducts.results[index].quantity  < tempArray[index].product.pricing.raw.minimumorderquantity )
  {
      this.appService.cartProducts.results.splice(index,1);
      this.cartData = [];
      for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
        this.cartData.push(this.appService.cartProducts.results[i].product_id);
      }
      this.appService.totalCartItems = this.cartService.totalCartItems();
      this.cartService.updateCartItem(this.appService.cartProducts.results);
      if(this.CONFIG.name==='Deera'){
        let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
        this.localService.set("cart", cartItemsString);
        }
      return
      
  }
  
  // tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  if(this.CONFIG.name==='Deera')
  {
    //let tempArray = this.appService.cartProducts.results;
    let obj =
    {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
              "product_id": Number(tempArray[index].product_id),
              "product_cost": Number(tempArray[index].cost),
              "quantity": Number(tempArray[index].quantity)
            }
        }
      }
  
    this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
      resp => {
        if (resp) {
          if (resp.success === 1 && resp.status_code === 200) {
            console.log("tempArray[index].quantity"+tempArray[index].product.id);
            console.log("tempArray[index].quantity"+tempArray[index].product.id);
            console.log("resp.result.data.discount_applied_to_product_value"+resp.result.data.discount_applied_to_product_value);
            tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
            tempArray[index].product.discount_amount = resp.result.data.discount_amount;
            tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
            tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
            if(resp.result.data.discount_percentage>0)	
            {	
              this.appService.cartProducts.results[index]['fixed_amount'] = resp.result.data.discount_amount;	
            }	
            else{	
              this.appService.cartProducts.results[index]['fixed_amount'] =  resp.result.data.fixed_amount;	
            }	
            this.cartService.updateCartItem(this.appService.cartProducts.results);	
console.log("*******************************************************************^%^$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")

            console.log("tempArray[index].product.discount_value"+tempArray[index].product.discount_value);
          }
          else if (resp.success === 0) {
            if (resp.message) {
              //this.alertService.error(resp.message, this.options);
            }
          }
          else if (resp.message && resp.status_code !== 200) {
            //this.alertService.error(resp.message, this.options);
  
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          //this.alertService.error('Session Time Out! Please login Again', this.options)
  
        }
        else if (err.error.error.message) {
          //this.alertService.error(err.error.error.message, this.options)
        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )

    // tempArray[index].quantity = (Number(tempArray[index].quantity));
    // tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    // this.appService.cartProducts.results = tempArray;
    // this.appService.totalCartItems = this.cartService.totalCartItems();
    // this.cartService.updateCartItem(this.appService.cartProducts.results);  
    // tempArray[index].quantity = (Number(tempArray[index].quantity));
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    if(this.CONFIG.name==='Deera'){
    let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
    this.localService.set("cart", cartItemsString);
    }
  }
}


decreaseItem(index)
{
  let tempArray = this.appService.cartProducts.results;
  if(this.CONFIG.name==='ShubhCards')
  {
    //let tempArray = this.appService.cartProducts.results;
    if(this.appService.cartProducts.results[index].quantity - tempArray[index].product.pricing.raw.decrementvalue < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.cartData = [];
        for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
          this.cartData.push(this.appService.cartProducts.results[i].product_id);
        }
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        this.showAlerts = true;
        return
        
    }
    
    tempArray[index].quantity = (Number(tempArray[index].quantity) - tempArray[index].product.pricing.raw.decrementvalue)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.updateCartItem(this.appService.cartProducts.results);  
    
  }
  else{
    if(this.appService.cartProducts.results[index].quantity ===1 || this.appService.cartProducts.results[index].quantity  < tempArray[index].product.pricing.raw.minimumorderquantity )
    {
        this.appService.cartProducts.results.splice(index,1);
        this.cartData = [];
        for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
          this.cartData.push(this.appService.cartProducts.results[i].product_id);
        }
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        if(this.CONFIG.name==='Deera'){
          let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
          this.localService.set("cart", cartItemsString);
          }
        return
        
    }
    
    tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    if(this.CONFIG.name==='Deera'){
      let obj =
      {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
              "find": {
                "product_id": Number(tempArray[index].product_id),
                "product_cost": Number(tempArray[index].cost),
                "quantity": Number(tempArray[index].quantity)
              }
          }
        }
    
      this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
        resp => {
          if (resp) {
            if (resp.success === 1 && resp.status_code === 200) {
              console.log("tempArray[index].quantity"+tempArray[index].product.id);
              console.log("resp.result.data.discount_applied_to_product_value"+resp.result.data.discount_applied_to_product_value);
              tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
              tempArray[index].product.discount_amount = resp.result.data.discount_amount;
              tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
              tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
              this.cartService.updateCartItem(this.appService.cartProducts.results);	
              console.log("tempArray[index].product.discount_value"+tempArray[index].product.discount_value);
            }
            else if (resp.success === 0) {
              if (resp.message) {
                //this.alertService.error(resp.message, this.options);
              }
            }
            else if (resp.message && resp.status_code !== 200) {
              //this.alertService.error(resp.message, this.options);
    
            }
            else {
              //this.alertService.error('Something bad happened. Please try again!', this.options);
            }
          }
        },
        err => {
          if (err.error.statusCode === 403) {
            //this.alertService.error('Session Time Out! Please login Again', this.options)
    
          }
          else if (err.error.error.message) {
            //this.alertService.error(err.error.error.message, this.options)
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }
    this.appService.cartProducts.results = tempArray;
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    if(this.CONFIG.name==='Deera'){
      let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
      this.localService.set("cart", cartItemsString);
      }
  }
 
}

getPromotionBanner(item)
{
  let obj =
  {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
          "find": {
          }
      }
    }

  this.appService.postApiCall(obj,ENDPOINTS.PROMOTIONS_BANNER).subscribe(
    resp => {
      if (resp) {
        if (resp.success === 1 && resp.status_code === 200) {
          this.banner = resp.result.data[0]['eligible_banner_message']
          if(this.CONFIG.title ==='Deera'){
            this.promotionData = resp.result.data;
            // let tempArray = this.appService.cartProducts.results;
            
            // this.appService.cartProducts.results.forEach((item)=>
            // {
              console.log("1111111111"+item.name);
        
              this.promotionData.forEach((data)=>
              {
                console.log("3333333333"+item.pricing.raw.price);
                console.log("eeeeee"+data.product_discount.amount_or_percentage.discounted_percentage);
                // this.productData = data.product_discount.amount_or_percentage.product_details;
                console.log("productnnnn"+data.product_discount.amount_or_percentage.product_details[0].product_name)
                data.product_discount.amount_or_percentage.product_details.forEach((data1)=>{
                  console.log("data1.product_name"+data1.product_name);
                  console.log("item.product_name"+item.name);
                  if(data1.product_name===item.name){
                    console.log("trueeee");
                    this.executionData.push(data1.order_execution);
                    let max = this.executionData.reduce((op, item) => op = op > item ? op : item, 0);
                    console.log("max"+max);
                    if(max===data1.order_execution){
                    if(data.product_discount.amount_or_percentage.discounted_percentage!==0){
                      console.log("truwwwwwww");
                      function minusPercent(n,p) {
                        return n - (n * (p/100));
                      }
                      // let price = minusPercent(475,20);
                      let price = minusPercent(item.pricing.raw.price,data.product_discount.amount_or_percentage.discounted_percentage); 
                      // item.pricing.raw.price = price;
                      console.log("price"+price);
                      this.appService.cartProducts.results.forEach((item1)=>
                      {  
                        if(item.name===item1.product_name){
                          item1.cost=price
                          this.calculateTotal();
                          this.cartService.updateCartItem(this.appService.cartProducts.results);
                        }
                      })
      
                    } else if (data.product_discount.amount_or_percentage.discounted_amount!==0){
                      console.log("item.pricing.raw.price"+item.pricing.raw.price);
                      console.log("discounted_amount"+data.product_discount.amount_or_percentage.discounted_amount);
                     let price = item.pricing.raw.price - data.product_discount.amount_or_percentage.discounted_amount;
                    //  item.pricing.raw.price = price;
                     console.log("price"+price);
                     this.appService.cartProducts.results.forEach((item1)=>
                     {  
                       if(item.name===item1.product_name){
                         item1.cost=price
                         this.calculateTotal();
                         this.cartService.updateCartItem(this.appService.cartProducts.results);
                       }
                     })
                    }
                  }
                  }

                })
         
              })
            // })
          }
        }
        else if (resp.success === 0) {
          if (resp.message) {
            //this.alertService.error(resp.message, this.options);
          }
        }
        else if (resp.message && resp.status_code !== 200) {
          //this.alertService.error(resp.message, this.options);

        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    },
    err => {
      if (err.error.statusCode === 403) {
        //this.alertService.error('Session Time Out! Please login Again', this.options)

      }
      else if (err.error.error.message) {
        //this.alertService.error(err.error.error.message, this.options)
      }
      else {
        //this.alertService.error('Something bad happened. Please try again!', this.options);
      }
    }
  )


}

calculateTotal()
{

  if(this.appService.cartProducts.results.length > 0)
  {
    this.totalPrice = 0;
    this.appService.cartProducts.results.forEach((obj)=>
    {
        this.totalPrice += (obj.cost * obj.quantity);
    })
  }
  else
  {
    this.totalPrice = 0;
  }
}


clearAll()
  {
    this.productfilter.clearAll()
  }
  productDetails(id){
    if(this.CONFIG.title === 'Aiema'){
      this.router.navigate(['/company-details1',id], { relativeTo:this.route,  skipLocationChange: false })
    }
    else{
      this.router.navigate(['/product-details',id], { relativeTo:this.route,  skipLocationChange: false })
    }
  }
  mouseEnter(id) {
    document.getElementById(id).click();
  }

  mouseLeave(id) {
    document.getElementById(id).click();
    }

  filterItemsOfType(){
    if(this.CONFIG.title === 'Aiema'){
      // return this.products
      //  return this.products.filter(x => x.status != 'Rejected' || x.status != 'Approve');
      // return this.products.filter(x => x.status != 'Rejected' && x.status != 'Approve');
      // return this.products.filter(x => x.status === 'Approved');
      return this.products
    }
    else if(this.CONFIG.title === 'ShubhCards'||this.CONFIG.title === "BombayHardware" || this.CONFIG.title === "Deera"){
      return this.products
    }
    else{
      return this.products.filter(x => x.type != 'Variation');
    }
    
}

brandName(brandName){
  return brandName.length > 13 ? brandName.substring(0,11) + '...' : brandName;
}
prodName(prodName){
  return prodName.length > 24 ? prodName.substring(0,23) + ' ...' : prodName;
}


saveForm() {  
    
  if(this.enquiryForm.controls.name.value === ""){
    this.validateForm = true;
    // this.enquiryForm.controls['name'].setErrors({'incorrect':true});
  } else if (this.enquiryForm.controls.message.value === ""){

    this.validateForm = true;
  } else if(this.enquiryForm.controls.email.value === ""){

    this.validateForm = true;
  } else if(this.enquiryForm.controls.phone_number.value === ""){

    this.validateForm = true;
  }
 
  if (this.enquiryForm.valid) {
    this.validateForm = false;
    var formData = {}
    var formDataJson;
    let session_id = this.localService.get("session_id");

    
  
// let _key = enc.Utf8.parse("@NcRfUjXn2r5u7x!A%D*G-KaPdSgVkYp");
// let _iv =  enc.Utf8.parse('\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f');
// let encrypted =AES.encrypt (
// JSON.stringify({
//   "domain_name": ORG_DETAILS.DOMAIN_NAME,
//   "user_id": ORG_DETAILS.USER_ID,
//   "timestamp": new Date().getTime(),
//   "payload": {
//     "order_creation":{  
//     "name":this.enquiryForm.value.name,
//     "email":this.enquiryForm.value.email,
//     "phone_number":this.enquiryForm.value.phone_number,
//     "category":this.enquiryForm.value.category,
//     "message":this.enquiryForm.value.message,
//     "member_id":this.memberId,
//     "status":"New",
//     "system_label":"New"
//   }  
//   },
//   "extras": {
//     "find": {
//       "id": ""
//     }
//   }
// }), _key, {
//     iv: _iv,
//     format: crypto.format.Hex,
//     mode: crypto.mode.CTR,
//     padding: crypto.pad.NoPadding
// }).toString();
// let requestData = {
//   payload: encrypted
// }

let enquiry = {
  "name":this.enquiryForm.value.name,
  "email":this.enquiryForm.value.email,
  "phone_number":this.enquiryForm.value.phone_number,
  "category":this.enquiryForm.value.category,
  "message":this.enquiryForm.value.message,
  "member_id":this.memberId,
  "status":"New",
  "system_label":"New",
  "companyName":this.companyName,
}
let requestData = {
  domain_name: ORG_DETAILS.DOMAIN_NAME,
  user_id: ORG_DETAILS.USER_ID,
  payload: {
    order_creation: enquiry
  },
  extras: {
    find: {
      id: ""
    }
  }
}

// let requestData = {
//   payload: encrypted
// }
this.checkoutApiService.checkoutAPICall(ENDPOINTS.ORDER_UPDATION, requestData).then(
  respData => {
    let res:any = respData;
    if(res.success){
      console.log("111");
      let OrderId = res.data[0].id;
      this.closeModal.nativeElement.click();   
      this.checkoutLocalStorageService.setOrderId({orderId:OrderId});
      this.alertService.success('Request Submitted', this.options);
      this.enquiryForm.reset({  name:"",
      email:"",
      phone_number:"",
      category:"",
      message:""})
    }
  }
)
  }
 
    // formData["name"] = this.enquiryForm.value.name;
    // formData["email"] = this.enquiryForm.value.email;
    // formData["phone_number"] = this.enquiryForm.value.phone_number;
    // formData["category"] = this.enquiryForm.value.category;
    // formData["message"] = this.enquiryForm.value.message;
    // formData["member_id"] = this.memberId;
    // formData['status'] = 'New'
    // formDataJson = {
    //   domain_name: ORG_DETAILS.DOMAIN_NAME,
    //   user_id: ORG_DETAILS.USER_ID,
    //   payload: {
    //     enquiry_creation: formData
    //   }
    // }
    // MAKE A SERVICE CALL HERE...
    // this.appService.postApiCall(formDataJson, ENDPOINTS.ADD_ENQUIRY).subscribe(
    //   resp => {
    //     if (resp) {
    //       if (resp.success === 1 && resp.status_code === 200) {
    //         this.alertService.success('Request Received', this.options);
    //         window.scrollTo({top: 0, behavior: 'smooth'});

    //       }
    //       else if (resp.success === 0) {
    //         if (resp.message) {
    //           this.alertService.error(resp.message, this.options);
    //           window.scrollTo({top: 0, behavior: 'smooth'});
    //         }
    //       }
    //       else if (resp.message && resp.status_code !== 200) {
    //         this.alertService.error(resp.message, this.options);
    //         window.scrollTo({top: 0, behavior: 'smooth'});
    //       }
    //       else {
    //         this.alertService.error('Something bad happened. Please try again!', this.options);
    //         window.scrollTo({top: 0, behavior: 'smooth'});
    //       }
    //     }
    //   },
    //   err => {
    //     if (err.error.statusCode === 403) {
    //       this.alertService.error('Session Time Out! Please login Again', this.options)
    //       this.router.navigate([`/login-new`], { skipLocationChange: false });
    //     }
    //     else if (err.error.message) {
    //       this.alertService.error(err.error.message, this.options)
    //       window.scrollTo({top: 0, behavior: 'smooth'});
    //     }
    //     else if (err.error.error.message) {
    //       this.alertService.error(err.error.error.message, this.options)
    //       window.scrollTo({top: 0, behavior: 'smooth'});
    //     }
    //     else {
    //       this.alertService.error('Something bad happened. Please try again!', this.options);
    //       window.scrollTo({top: 0, behavior: 'smooth'});
    //     }
    //   }
    // )
    //   ;
  
}

submitEnquiry(product){
  let userId = this.localService.get('UserId');
    let aiemaUserName = this.localService.get('AiemaUserName');
    this.memberId = product.memberId;
    this.companyName = product.name;
    if(userId){
      this.enquiryForm.reset({name:"", message:"",})
      // this.enquiryForm.controls['name'].setValue(aiemaUserName)
      this.enquiryForm.controls['email'].setValue(this.customerLoginStorageService.getCustomerData().email)
      this.enquiryForm.controls['phone_number'].setValue(this.customerLoginStorageService.getCustomerData().mobileNo)
    this.enquiryForm.controls['category'].setValue(product.categories[0].categories)

    document.getElementById('launch-model').click();

  } else {
    this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
  }

}
}
