<div class="container-fluid px-1 py-5 mx-auto">
    <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-8 col-md-10 col-12 text-center mb-5">
            <div class="card3">
                <div class="row justify-content-left d-flex">
                    <div class="col-md-4 d-flex flex-column">
                        <div class="rating-box">
                            <h1 class="pt-4">{{product_rating}}</h1>
                            <p class="">out of 5</p>
                        </div>
                        <form [formGroup]="productRating" style="text-align:center;">
                        <div class="form-group row" *ngIf="review_list.length !== 0">
                            <div class="rating">
                              <input type="radio" id="star5" formControlName="rating" value=5 /><label for="star5" title="Excellent">5 stars</label>
                              <input type="radio" id="star4" formControlName="rating" value=4 /><label for="star4" title="Good">4 stars</label>
                              <input type="radio" id="star3" formControlName="rating" value=3 /><label for="star3" title="Not bad">3 stars</label>
                              <input type="radio" id="star2" formControlName="rating" value=2 /><label for="star2" title="Bad">2 stars</label>
                              <input type="radio" id="star1" formControlName="rating" value=1 /><label for="star1" title="Worst">1 star</label>
                            </div>
                            </div>
                        </form>
                        <!-- <div> <span class="fa fa-star star-active mx-1"></span> <span class="fa fa-star star-active mx-1"></span> <span class="fa fa-star star-active mx-1"></span> <span class="fa fa-star star-active mx-1"></span> <span class="fa fa-star star-inactive mx-1"></span> </div> -->
                    </div>
                    <div class="col-md-8">
                        <div class="rating-bar0 justify-content-center">
                            <table class="text-left mx-auto">
                                <tr>
                                    <td class="rating-label">Excellent</td>
                                    <td class="rating-bar">
                                        <div class="bar-container">
                                            <div class="bar-5" id="bar-5" [style.width.%]=width5></div>
                                        </div>
                                    </td>
                                    <td class="text-right">{{five_star}}</td>
                                </tr>
                                <tr>
                                    <td class="rating-label">Good</td>
                                    <td class="rating-bar">
                                        <div class="bar-container">
                                            <div class="bar-4" id="bar-4" [style.width.%]=width4></div>
                                        </div>
                                    </td>
                                    <td class="text-right">{{four_star}}</td>
                                </tr>
                                <tr>
                                    <td class="rating-label">Average</td>
                                    <td class="rating-bar">
                                        <div class="bar-container">
                                            <div class="bar-3" id="bar-3" [style.width.%]=width3></div>
                                        </div>
                                    </td>
                                    <td class="text-right">{{three_star}}</td>
                                </tr>
                                <tr>
                                    <td class="rating-label">Poor</td>
                                    <td class="rating-bar">
                                        <div class="bar-container">
                                            <div class="bar-2" id="bar-2" [style.width.%]=width2></div>
                                        </div>
                                    </td>
                                    <td class="text-right">{{two_star}}</td>
                                </tr>
                                <tr>
                                    <td class="rating-label">Terrible</td>
                                    <td class="rating-bar">
                                        <div class="bar-container">
                                            <div id="bar-1" class="bar-1" [style.width.%]=width1></div>
                                        </div>
                                    </td>
                                    <td class="text-right">{{one_star}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngFor = "let list of review_list;let i=index;">
            <div class="card3">
                <div class="row d-flex">
                    
                    <div class="d-flex flex-column">
                        <h3 class="mt-2 mb-0">{{list.posted_by}}</h3>
                        <!-- <div class="form-group row"> -->
                            <!-- <div class="col-md-1" style="float: right;">
                            <p class="text-left"><span class="text-muted">{{list.rating}}</span></p>
                            </div> -->
                            <div class="row d-flex" style="margin-top: -1;">
                    
                                <div class="d-flex flex-column">
                                    <!-- <h4 class="blue-text mt-3" style="float: left !important;">{{list.title}}</h4> -->
                                    <div class="inner-star-icon" style="padding-left: 15px;">
                                        <span [class]="list.rating >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                        <span [class]="list.rating >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                        <span [class]="list.rating >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                        <span [class]="list.rating >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                        <span  [class]="list.rating >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                    </div>
                                </div>
                            </div>
                                                      
                        <!-- </div> -->
                    </div>
                    <div class="ml-auto">
                        <p class="text-muted pt-5 pt-sm-3">{{list.reviewed_date}}</p>
                    </div>
                </div>

                <div class="row d-flex" style="margin-top: -1;">
                    
                    <div class="d-flex flex-column">
                        <h4 class="blue-text mt-3" style="float: left !important;">{{list.title}}</h4>
                        <div>
                            <p class="content"  style="float: left !important;">{{list.comments}}</p>
                        </div>
                    </div>
                </div>
                <!-- <div>
                    <p class="content">{{list.comments}}</p>
                    <h4 class="blue-text mt-3">{{list.title}}</h4>
                    <p  class="content">{{list.comments}}</p>
                </div> -->

                <!-- <div class="row text-left"> -->

                    <!-- <h4 class="blue-text mt-3" style="float: left !important;">{{list.title}}</h4>
                    <p class="content"  style="float: left !important;">{{list.comments}}</p> -->
                    <!-- <p class="content">{{list.comments}}</p> -->
                <!-- </div> -->
            </div>
            </div>
        </div>
    </div>
</div>