import { Component, OnInit } from '@angular/core';
import { Base64 } from 'js-base64';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarService} from '../navbar/navbar.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AlertsService } from '../shared/alerts/alerts.service';

import { CheckoutLocalStorageService as  CheckoutLocalStorageServiceNew} from '../checkout-new/checkout-local-storage.service';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { LoaderService } from '../shared/loader/loader.service';
import {config} from '../../configs/config';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html'
})
export class OrderConfirmationComponent implements OnInit {
  orderId:any = "";
  orderNumer:any = "";
  tablebookingamount:any = "";
  tablebookingdate:any = "";
  tablebookingtime:any = "";
  no_of_persons:any = "";
  orderSummary:any = "";
  shippingAddress:any = "";
  userInfoForm: FormGroup;
  validateForm: boolean = false;
  // validatepasswordLoginForm: boolean = false;
  showPasswordField: Boolean = true;
  showNameField: Boolean = true;
  showEmailField: Boolean = true;
  CONFIG = config;
  showPswd: Boolean = false;
  customerData: any;
  orderconfirmation:Boolean=true;

  showalertmsg: boolean = true;

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  paymentMethod:any = "";

  awaitingStatus = {
    status:"",
    id:0
  }

  confirmStatus = {
    sttaus:"",
    id:0
  }

  activateOrder:any = "";
  customerDetails:any;

  constructor(
    private formBuilder: FormBuilder,
    private localService: LocalStorageService,
    private route: ActivatedRoute,
    private navbarService: NavbarService,
    private appService: AppService,
    private alertService: AlertsService,
    private router: Router,
    private checkoutLocalStorageService1: CheckoutLocalStorageServiceNew,
    private checkoutApiService: CheckoutApiService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.customerDetails = JSON.parse(this.localService.get('CustomerData'))
    this.loaderService.emitLoading();
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.userInfoForm = new FormGroup({
      name: this.formBuilder.control('', [Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[a-zA-Z][a-z A-Z]*$")]),
      email: this.formBuilder.control('', [Validators.maxLength(50), Validators.email, Validators.pattern("^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$")]),
      new_password: this.formBuilder.control('', [Validators.minLength(6), Validators.maxLength(20)]),
      confirm_password: this.formBuilder.control('', [])
    }, { validators: OrderConfirmationComponent.checkPswd })

    if(this.route.snapshot.queryParamMap){
      this.appService.removeSession();
      this.appService.createSession();
      this.checkoutLocalStorageService1.clearOrderConfirmation();
      this.orderId = Number(this.route.snapshot.queryParamMap.get('id'))
      if(this.orderId==0)
      {
        this.orderId = Number(this.route.snapshot.queryParamMap.get('tbid'))
        this.orderconfirmation=false;
      }
      if(this.orderconfirmation)
      {
        this.getAllOrderDetails();
      }
      else{
        this.gettablebookingdetails();
      }
      
     // this.validateOrder(this.orderId);
    }
    this.loaderService.emitComplete();
    // this.customerData = this.customerService.getCustomerData();
    // this.getUserDetails()
  }
  get f() { return this.userInfoForm.controls; }
  static checkPswd(group: AbstractControl): { [key: string]: boolean } {
    let pass = group.get('new_password').value;
    let confirmPass = group.get('confirm_password').value;
    return pass === confirmPass ? null : { passwdNotMatch: true }
  }

  validpasswd(): void {
    if (this.userInfoForm.hasError('passwdNotMatch')) {
      this.userInfoForm.controls['confirm_password'].setErrors({ 'incorrect': true })
    }
  }
  showPassword() {
    this.showPswd = !this.showPswd;
  }
  getUserDetails(){
    let reqData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "phone_number": this.customerData.Mobile.toString()
        }
      }
    }
    // API CALL TO FIND EXISTING/NEW USER
    this.appService.postApiCall(reqData, ENDPOINTS.GETALL_USERS).subscribe(
      resp => {
        if(resp.result.data[0].email){
          this.showEmailField = false;
        }
        else{
          this.showEmailField = true;
        }
        if(resp.result.data[0].password){
          this.showPasswordField = false;
        }
        else{
          this.showPasswordField = true;
        }
        if(resp.result.data[0].first_name){
          this.showNameField = false;
        }
        else{
          this.showNameField = true;
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  createAccount(){
    if(this.showPassword && this.userInfoForm.value.new_password!='' && this.userInfoForm.value.confirm_password==''){
      this.alertService.error('please confirm the password!',this.options)
      return
    }
    if(this.userInfoForm.valid){
      this.validateForm=false;
      let userVariables={};
      if(this.showEmailField){
        userVariables['email'] = this.userInfoForm.value.email
      }
      if(this.showNameField){
        userVariables['first_name'] = this.userInfoForm.value.name
      }
      if(this.showPasswordField){
        userVariables['password'] = this.userInfoForm.value.new_password
      }
      if(!this.userInfoForm.value.new_password && !this.userInfoForm.value.email && !this.userInfoForm.value.name){
        this.alertService.error('please fill the form', this.options)
        return
      }
      let requestBody = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "payload":{
          "update_customer": userVariables
        },
        "extras": {
          "find": {
            "id": this.customerData.userId
          }
        }
      }
      this.appService.postApiCall(requestBody, ENDPOINTS.UPDATE_CUSTOMER).subscribe(
        resp => {
          this.showalertmsg = true;
          this.alertService.success(resp.message, this.options)
          if(this.userInfoForm.value.name){
          this.localService.set('UserName', this.userInfoForm.value.name);
          this.appService.userName=this.userInfoForm.value.name;
          }
          if (resp.success === 1 && resp.status_code === 200) {
            setTimeout(() => {
              this.router.navigate(['/']);              
            }, 1000);
          }
        },
        err => {
          if (err.error.message) {
            console.log(err.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        });
  }
    else{
      this.validateForm=true;
    }

  }
  gettablebookingdetails () {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "id": this.orderId
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.GET_TABLE_BOOKING).subscribe(
      resp => {
        var orderData = resp.result.data[0];
        
       this.paymentMethod = orderData.payment.payment_method;
       this.tablebookingdate = orderData.date;
        this.tablebookingtime=orderData.time;
        this.orderNumer = orderData.id;
        this.tablebookingamount=orderData.amount;
        this.no_of_persons=orderData.no_of_persons;
        // if(resp.result.data[0].system_label.toUpperCase() === 'AWAITING PAYMENT'){
        //   this.updateOnlinePaymentOrder();
        // }
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }
  getAllOrderDetails() {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "id": this.orderId
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.GETORDERDETAILS).subscribe(
      resp => {
        var orderData = resp.result.data[0];
        if(orderData.shipping.shipping_addresses.length > 0){
          this.shippingAddress = orderData.shipping.shipping_addresses[0];
        }
        
        this.paymentMethod = orderData.payment.payment_method;
        if(this.paymentMethod === "ICICI Payment Gateway"){
          this.paymentMethod = "Online Payment"
        }
        this.orderNumer = orderData.order_number
        this.orderSummary = orderData.order_review.order_summary;
        // if(resp.result.data[0].system_label.toUpperCase() === 'AWAITING PAYMENT'){
        //   this.updateOnlinePaymentOrder();
        // }
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }

  updateOnlinePaymentOrder(){
    this.getOrderStatus().then(
      respData => {
        let res:any = respData;
        if(res){
          let requestData = 
              {
                  domain_name: ORG_DETAILS.DOMAIN_NAME,
                  user_id: ORG_DETAILS.USER_ID,
                  payload: {
                      order: {
                          status: this.confirmStatus.sttaus,
                          system_label: "Confirmed",
                          status_id: this.confirmStatus.id
                      },
                      new_value: {
                          status: this.confirmStatus.sttaus,
                          system_label: "Confirmed"
                      },
                      old_value: {
                          status: this.awaitingStatus.status,
                          system_label: "Awaiting Payment"
                      }
                  },
                  extras: {
                      find: {
                          id: this.orderId
                      }
                  }
          }
          this.appService.postApiCall(requestData, ENDPOINTS.ORDER_UPDATION).subscribe(
            resp => {
              console.log("555")
              if(resp.success === 1){

              }
            },
            err => {
            
            })
        }
      })    
  }

  getOrderStatus(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_ORDERSTATUS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let orderapiData:any = res.data;
            orderapiData.forEach(element => {
              if(element.system_label.toUpperCase() === 'AWAITING PAYMENT'){
                this.awaitingStatus.status = element.custom_label;
                this.awaitingStatus.id = element.id;
              }
              if(element.system_label.toUpperCase() === 'CONFIRMED'){
                this.confirmStatus.sttaus = element.custom_label;
                this.confirmStatus.id = element.id;
              }
              resolve(true);
            });
          }
        }
      )
    })
  }

  validateOrder(orderId){
    let requestData = {
      domain_name:ORG_DETAILS.DOMAIN_NAME,
      orderid:orderId,
      confirmationpage:1,
      failurepage:0,
      customer_id:this.customerDetails.userid
    }
    this.appService.postApiCall(requestData, ENDPOINTS.ORDER_ACTIVATION).subscribe(
      resp => {
        if(resp.success === 1){
          this.activateOrder = "success";
          this.getAllOrderDetails();
          this.loaderService.emitComplete();
        }
        else{
          this.activateOrder = "failure";
          this.loaderService.emitComplete();
        }
      },
      err => {
        this.activateOrder = "failure";
        this.loaderService.emitComplete();
      })
  }

}
