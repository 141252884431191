import { Component, OnInit,AfterViewInit } from '@angular/core';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import {CartService} from '../cart-page/cart-page.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import * as metadata from '../../assets/data/default/product-listing.json'
import { MetaDataMapService } from '../meta-data-map.service';
import {config} from '../../configs/config'

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html'
})
export class ProductListComponent implements OnInit {

  config=config;
  totalPrice = 0;
  banner = "";
  public executionData=[];
  promotionData:any;
  productData:any;
  public productData1=[];
  imageNotFound=config.noImageAvailable;
  fields = metadata.groups[0].fields;
  public productsApiData:any;
  public totalProductLength:any;
  public productList=[];
  public products=[];
  public products1=[];
  public cartItems=[];
  public cartItems1=[];
  public totalNumberOfProducts;
  public totalLength;
  allCategories: any;
  allCategories1: any;
  public category;
  public category1;
  public categoryIcon;
  public searchKey;
  public categoryName;
  public categoryName1;
  public subCategory;
  subCategory1:any=[];
  subCategory2:any=[];
  public filtersApiData;
  variantsArray:any=[];
  public categoryList:any = [];
  facets;
  currentRating="0";
  totalProducts:any = 0;
  public click='no';
  public search = 'no';
  selectedIndex: number = null;
  subCategories: any;
  categoryType: any;
  public active;
  public cartData=[];
  public cartQuantity=[];
  public searchCategory;
  existingCartProduts = [];
  showAlerts = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  showPricse:boolean=false;
  checkVariantInCart:any;
  price:any;
  activeMenu: string;
  sizeVariable:any;
  addCart = false;
  isDisabled: boolean = false;
  showVariant:any;
  showHint:boolean
  sizeVariant:boolean=false;
  selected_category : string = '';
  categoryNameFromHome : any;
  public dataPromo="";
  reqData1;

  constructor(
    public appService:AppService,
    private router: Router,
    private route: ActivatedRoute,
    private cartService : CartService,
    private localService: LocalStorageService,
    private alertService : AlertsService,
    public metaDataMapService: MetaDataMapService
  ) {
    if (this.router.getCurrentNavigation().extras.state){
      this.selected_category = this.router.getCurrentNavigation().extras.state.category;
    }
   }

  ngOnInit(){
    // this.getAllProducts();
    if(this.selected_category != ''){
      console.log(this.selected_category)
      this.getProductByFilter(this.selected_category)
    }
      this.getAllCategories();
    this.cartService.updateCartItem(this.appService.cartProducts.results)
    this.route.queryParams.subscribe(params => {
      if (params.type=='searchresults') {
        this.search='yes';
        this.route.queryParamMap.subscribe(params => {
        if(params.getAll('terms')[0]){
          console.log("dataaaaaaa");
          this.searchKey = params.getAll('terms')[0];
          this.categoryType = params.getAll('terms')[1];
          this.searchCategory =params.getAll('category')[0];
          if(this.searchCategory!==undefined){
            this.searchKey = this.searchCategory;
              this.getAllCategories();
            this.category = this.searchKey;
            this.category1 = this.searchKey;
            this.active = this.searchKey;
            this.subCategory = '';
          } else {
              this.getAllCategories();
            this.category = this.searchKey;
            this.category1 = this.searchKey;
            this.active = this.searchKey;
            this.subCategory = '';
          }
        }

        // if (this.categoryType==='Category'){
        
        // }
        });
      }
    });

    for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
      this.cartData.push(this.appService.cartProducts.results[i].product_id);
    }

  }

   //GET ALL PRODUCTS
   getAllProducts(){

      var req_product_data = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
          },
          "pagination": true,
          "paginationDetails": {
            "limit":(event),
            "pagesize": 20
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "id",
            "sortorder": -1
          }
        }
      }
  
   this.appService.postApiCall(req_product_data, ENDPOINTS.GET_ALL_PRODUCTS).subscribe(
     resp => {
       this.productsApiData = resp;
       this.products = this.productsApiData.result.data;
       this.totalNumberOfProducts = this.productsApiData.result.total;
      //  this.totalLength = this.products ? this.products.totalLength : 0;
       this.totalLength = this.productsApiData.result.total ? this.productsApiData.result.total : 0;
     },
     err => {
       if (err.error.error.message) {
         console.log(err.error.error.message)
       }
       else{
         console.log('Something bad happened; Please try again!')
       }
     })
 }   

 emitter(e){
   console.log(e)
 }
 showPrice(e){

  this.showHint=false;
  let item=e[0];
  let productId=e[2];
  this.products.forEach(element => {
  
    if(element.id === productId){
      element.showHint=false;
       element.checkVariantInCart=item.id;
       element.sizeVariable = item;
       element.pricee=item.pricing.raw.price
   element.showPricse=true;
   element.isactive=item.option;
   element.isDisabled=false;
  
    }else{
      // element.showPricse=false;
    }
  });   
 }
 getAllCategories() {
  if(config.title == 'Haven'){
  this.reqData1 = {
    "domain_name": ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras": {
      "find": {
      },
      "pagination": false,
      "paginationDetails": {
        "limit": 1,
        "pagesize": 25
      },
      "sorting": true,
      "sortingDetails": {
        "sortfield": "sort_order",
        "sortorder": -1
      }
    }
  }
} else {
  this.reqData1 = {
    "domain_name": ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras": {
      "find": {
      },
      "pagination": false,
      "paginationDetails": {
        "limit": 1,
        "pagesize": 25
      },
      "sorting": true,
      "sortingDetails": {
       "sortfield": "name",
        "sortorder": -1
      }
    }
  }
}
  this.appService.postApiCall(this.reqData1, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
    resp => {
      if(config.title == 'Tams'){
        this.allCategories = this.sortByKey(resp.result.data,'name');
      }
      else{
        this.allCategories = resp.result.data;
      }
      if (this.search=='no'){
        for(let i =0;i<this.allCategories.length;i++){
          this.category = this.allCategories[0].name;
          this.active = this.allCategories[0].name;
        }
      } else if(this.search=='yes'){
          this.category = this.searchKey
      }
 
      this.getFilterByProduct();
    },
    err => {
      if (err.error.error.message) {
        console.log(err.error.error.message)
      }
      else {
        console.log('Something bad happened; Please try again!')
      }
    })
}

sortByKey(array, key) {
  return array.sort(function(a, b) {
      var x = a[key];
      var y = b[key];

      if (typeof x == "string")
      {
          x = (""+x).toLowerCase(); 
      }
      if (typeof y == "string")
      {
          y = (""+y).toLowerCase();
      }

      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  });
}

setIndex(index: number) {
  this.selectedIndex = index;
}


    getProductByFilter(category){
      this.category = category.name;
      this.category1 = category.name;
      this.categoryNameFromHome = category.name
      this.categoryIcon = category.category_icon ? category.category_icon : undefined;
      this.active = category.name;
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "name",
            "sortorder": -1
          }
        }
      }
      this.appService.postApiCall(reqData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
        resp => {
          this.allCategories1 = resp.result.data;
          this.subCategory = '';
          if(this.allCategories1.length > 1){
            for(let i =0;i<this.allCategories1.length;i++){
              if(this.allCategories1[i].name == this.category){
                this.categoryName='';
                // for(let i =0;i<this.allCategories1[i].length;i++){
                if(this.allCategories1[i].subcategories!==undefined){
                  this.categoryName=this.allCategories1[i].name;
                  // this.subCategory1.push(this.allCategories1[i].subcategories);
                  this.subCategory2 = this.subCategory1.concat(this.allCategories1[i].subcategories);
                }
                // }
              }
            }
          }
        },
        err => {
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })
      this.getFilterByProduct();
        }


getProductByFilter1(category){
  
  this.subCategory = category;
  this.category = category;
  this.active = category;
  this.getFilterByProduct();
}

//GET FILTER PRODUCTS
getFilterByProduct(){ 

{
  var req_filter_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
     // "brand":[this.allInOneData[0]],
      "category":[this.category],
    "last90days":false,
    "last30days":false,
    "includeoutofstock":false,
   
  },
  "pagination":true,
  "paginationDetails":{
      "limit":1,
      "pagesize":1000
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"name",
    "sortorder":-1
  },
  "subcategoryfilter":false
  
  }
 } 
 } 

 this.appService.postApiCall(req_filter_data, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
   resp => {


     this.filtersApiData = resp;
     this.products = this.filtersApiData.result.data;
     this.products=this.products.filter(element=>{
       return element.variant!=undefined || element.product_type == 'Simple'
     })
     this.productList=[];
     this.totalProductLength = 0
     this.products.forEach(element => {
     
       if(element.product_type != 'Variation'){
        this.productList.push(element)
        this.totalProductLength=this.productList.length;
            }
      if((element.product_type == 'Variable' || element.type == 'Variable')&& element.variant !=undefined){
      element.sizeVariable=element.variant.option_values[0];
      element.checkVariantInCart=element.variant.option_values[0].id;
       element.showHint=true;
      // element.range=element.pricing.raw.price_range.split("-");
       element.showPricse =false;
       element.showVariant=true;
       element.price=element.pricing.raw.price_range;
      //  element.isDisabled=true;
      
       element.showOption=true;
       
    
     }else{
       element.sizeVariable=element.name;
       element.showPricse = true;
       element.showVariant=false;
       element.price=element.pricing.raw.price;
       element.showOption=false;

     }
    });
     this.totalNumberOfProducts = this.filtersApiData.result.total;
     this.categoryList = this.filtersApiData.result.filters[0].categories;
     this.facets =  this.filtersApiData.result.filters[1].brands;
     this.totalProducts = this.filtersApiData.result.total.value ? this.filtersApiData.result.total.value : 0;
     this.totalLength = this.filtersApiData.result.total.value ? this.filtersApiData.result.total.value : 0;
     
   },
   err => {
     if (err.error.error.message) {
       console.log(err.error.error.message)
     }
     else{
       console.log('Something bad happened; Please try again!')
     }
   })
}

increaseItem(index)
{
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0

  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  { 
    this.showAlerts = true;
    window.scrollTo(0,0);
    // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    if(config.title==='BombayHardware')
    {
      this.alertService.error("Maximum allowed quantity is 1 only",this.options)
    } else {
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    return
  }
  tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}

decreaseItem(index)
{
  if(this.appService.cartProducts.results[index].quantity === 1)
  {
      this.appService.cartProducts.results.splice(index,1);
      this.cartData = [];
      for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
        this.cartData.push(this.appService.cartProducts.results[i].product_id);
      }
      this.appService.totalCartItems = this.cartService.totalCartItems();
      this.cartService.updateCartItem(this.appService.cartProducts.results);
      return
      
  }
  let tempArray = this.appService.cartProducts.results;
  tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}

calculateTotal()
{

  if(this.appService.cartProducts.results.length > 0)
  {
    this.totalPrice = 0;
    this.appService.cartProducts.results.forEach((obj)=>
    {
        this.totalPrice += (obj.cost * obj.quantity);
    })
  }
  else
  {
    this.totalPrice = 0;
  }
}

addToCart(item)
{
  this.cartService.addToCart(item);
  for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
  this.cartData.push(this.appService.cartProducts.results[i].product_id);
  }
if(this.config.title === 'Haven') {
  this.getPromotionBanner(item);
}
}

getPromotionBanner(item)
{
  let obj =
  {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
          "find": {

          }
      }

  }

  this.appService.postApiCall(obj,ENDPOINTS.PROMOTIONS_BANNER).subscribe(
    resp => {
      if (resp) {
        if (resp.success === 1 && resp.status_code === 200) {
          this.banner = resp.result.data[0]['eligible_banner_message']
          // if(this.config.title ==='Haven'){
          //   this.promotionData = resp.result.data;
           
          //     console.log("1111111111"+item.name);
        
          //     this.promotionData.forEach((data)=>
          //     {
          //       console.log("3333333333"+item.pricing.raw.price);
          //       console.log("eeeeee"+data.product_discount.amount_or_percentage.discounted_percentage);
              
          //       console.log("productnnnn"+data.product_discount.amount_or_percentage.product_details[0].product_name)
          //       data.product_discount.amount_or_percentage.product_details.forEach((data1)=>{
          //         console.log("data1.product_name"+data1.product_name);
          //         console.log("item.product_name"+item.name);
          //         if(data1.product_name===item.name){
          //           console.log("trueeee");
          //           this.executionData.push(data1.order_execution);
          //           let max = this.executionData.reduce((op, item) => op = op > item ? op : item, 0);
          //           console.log("max"+max);
          //           if(max===data1.order_execution){
          //           if(data.product_discount.amount_or_percentage.discounted_percentage!==0){
          //             console.log("truwwwwwww");
          //             function minusPercent(n,p) {
          //               return n - (n * (p/100));
          //             }
                    
          //             let price = minusPercent(item.pricing.raw.price,data.product_discount.amount_or_percentage.discounted_percentage); 
                    
          //             console.log("price"+price);
          //             this.appService.cartProducts.results.forEach((item1)=>
          //             {  
          //               if(item.name===item1.product_name){
          //                 item1.cost=price
          //                 this.calculateTotal();
          //                 this.cartService.updateCartItem(this.appService.cartProducts.results);
          //               }
          //             })
      
          //           } else if (data.product_discount.amount_or_percentage.discounted_amount!==0){
          //             console.log("item.pricing.raw.price"+item.pricing.raw.price);
          //             console.log("discounted_amount"+data.product_discount.amount_or_percentage.discounted_amount);
          //            let price = item.pricing.raw.price - data.product_discount.amount_or_percentage.discounted_amount;
                  
          //            console.log("price"+price);
          //            this.appService.cartProducts.results.forEach((item1)=>
          //            {  
          //              if(item.name===item1.product_name){
          //                item1.cost=price
          //                this.calculateTotal();
          //                this.cartService.updateCartItem(this.appService.cartProducts.results);
          //              }
          //            })
          //           }
          //         }
          //         }

          //       })
         
          //     })
            
          // }
        }
        else if (resp.success === 0) {
          if (resp.message) {
            //this.alertService.error(resp.message, this.options);
          }
        }
        else if (resp.message && resp.status_code !== 200) {
          //this.alertService.error(resp.message, this.options);

        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    },
    err => {
      if (err.error.statusCode === 403) {
        //this.alertService.error('Session Time Out! Please login Again', this.options)

      }
      else if (err.error.error.message) {
        //this.alertService.error(err.error.error.message, this.options)
      }
      else {
        //this.alertService.error('Something bad happened. Please try again!', this.options);
      }
    }
  )


}

loadExistingCart()
{
  this.existingCartProduts= [];
  this.appService.cartProducts.results.forEach(value=>
    {
      this.existingCartProduts.push(value.product_id);
    })
}

productDetails(id){
  this.router.navigate(['/product-details',id], { relativeTo:this.route,  skipLocationChange: false })
}

addToCarts(item) {
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0
  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  {
    this.showAlerts = true;
    window.scrollTo(0,0);
    // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    if(config.title==='BombayHardware')
    {
      this.alertService.error("Maximum allowed quantity is 1 only",this.options)
    } else {
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    return
  }
  if(item.product_type == 'Simple'){

    this.cartService.addToCart(item);
    
  }
  else if(item.product_type == 'Variation'){

    this.cartService.addToCart(item);
    
  }
else if (item.product_type =='Variable'){
  item.sizeVariable.brand={
    name:"",
   }
   item.sizeVariable.categories=[{
     categories:"",
     id:1
   }]
   item.sizeVariable.assets=item.assets;
   item.sizeVariable.name = item.name + ' - ' + item.sizeVariable.option
  item.sizeVariable.parentcategories=[]
  this.cartService.addToCart(item.sizeVariable)
}
}

}
