import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators} from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { AmazingTimePickerService } from 'amazing-time-picker';
import {ENDPOINTS, ORG_DETAILS} from '../app.config';
import {AppService} from '../app.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import { NavbarService} from '../navbar/navbar.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import {DatePickerComponent} from 'ng2-date-picker';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { LoaderService } from '../shared/loader/loader.service';

@Component({
  selector: 'app-gift-meal',
  templateUrl: './gift-meal.component.html'
})
export class GiftMealComponent implements OnInit {

  
  Default1: FormGroup;
  public addOutlet = true;
  validateForm : boolean = false ;
  showAlerts :boolean = false;
  acceptanceTiming : any;
  restaurentAddressList : any;
  timeslot : any;
  outletList = [];
  slots : any[]=[];
  giftMealServices: any[]=[];
  mindeliveryDate: any = "";
  maxDate:any="";
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  otpResendDuration:Number;
  resendMsg:string;
  maximumDaysAllowedToBook:any;
  description:any;
  image:any;
  minimumDaysAllowedToBook:any;
  noOfPerson:Number;
  showmsg1: boolean = false;
  showmsg2: boolean = false;
  slotTime:any;
  datePickerConfig = {
    format : "yyyy-MM-DD",
    min : moment().format("YYYY-MM-DD")
  }
  showmsg3: boolean = false;

  @ViewChild('dayPicker') datePicker: DatePickerComponent;

  

  constructor(private formBuilder : FormBuilder,private router: ActivatedRoute,private routerUrl : Router,private timePick : AmazingTimePickerService, private apiService : AppService,private alertService : AlertsService,
    private navbarService: NavbarService,
    private datePipe : DatePipe,
    private checkoutApiService: CheckoutApiService,
    private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');

    
    this.Default1= new FormGroup({name: this.formBuilder.control("",[Validators.required,Validators.minLength(3),Validators.maxLength(80),Validators.pattern("^[a-zA-Z][a-z A-Z]*$")]),
    email_address: this.formBuilder.control("",[Validators.required,Validators.pattern("^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$"),Validators.maxLength(50)]),
    mobile_number: this.formBuilder.control("",[Validators.required,Validators.minLength(8),Validators.maxLength(10),Validators.pattern("[0-9]*")]),
    date: this.formBuilder.control("",[Validators.required,]),
    time: this.formBuilder.control("",[Validators.required,]),
    no_of_persons: this.formBuilder.control("",[Validators.required,Validators.minLength(1),Validators.maxLength(2),Validators.pattern("[0-9]*")]),
    address: this.formBuilder.control("",[Validators.required,Validators.minLength(5),Validators.maxLength(500),]),
    add_outlet:this.formBuilder.control('', [Validators.required]),
    notes: this.formBuilder.control('', [Validators.maxLength(1000)]),
    otp: this.formBuilder.control('',[Validators.required]),})

  this.getOutletData();
  this.getServices();
  
  this.getAcceptanceTimings()
  }

  get D1(){
    return this.Default1.controls
    }

    getServices(){
      let formDataJson = {
        domain_name : ORG_DETAILS.DOMAIN_NAME,
        user_id : ORG_DETAILS.USER_ID,
        extras:{
          find:{
            
          }
        }
      }

      this.apiService.postApiCall(formDataJson,ENDPOINTS.GETALL_GIFTMEALSETTINGS).subscribe(
        resp => {
          if (resp) {
            if (resp.success === 1 && resp.status_code === 200) {
              this.giftMealServices=resp.result.data[0].services;
              this.maximumDaysAllowedToBook =  resp.result.data[0].maximum_days_allowed_to_book;
              this.description = resp.result.data[0].description;
              this.image = resp.result.data[0].image;
              this.minimumDaysAllowedToBook =  resp.result.data[0].minimum_days_allowed_to_book;
              var dtToday = new Date();
              var month: any = dtToday.getMonth() + 1;
              var day: any = dtToday.getDate();
              var year = dtToday.getFullYear();
              if (month < 10)
                month = '0' + month.toString();
              if (day < 10)
                day = '0' + day.toString();
              // this.mindeliveryDate = year + '-' + month + '-' + day;
              // this.mindeliveryDate = new Date(new Date().getTime() +  5 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
              this.mindeliveryDate = new Date(new Date().getTime() +  this.minimumDaysAllowedToBook * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
              console.log("dateeeeesssss"+new Date(new Date().getTime() +  this.minimumDaysAllowedToBook * 24 * 60 * 60 * 1000));
              var newdate = new Date(new Date().getTime() +  this.minimumDaysAllowedToBook * 24 * 60 * 60 * 1000);
              // var today = new Date().toISOString().split('T')[0];
              // this.maxDate = new Date(new Date().getTime() +  25 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
              // this.maxDate = new Date(newdate.getTime() +  25 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
              this.maxDate = new Date(newdate.getTime() +  this.maximumDaysAllowedToBook * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
            }
            else if (resp.success === 0) {
              if (resp.message) {
                this.alertService.error(resp.message, this.options);
              }
            }
            else if (resp.message && resp.status_code !== 200) {
              this.alertService.error(resp.message, this.options);
              
            }
            else {
              this.alertService.error('Something bad happened. Please try again!', this.options);
            }
          }
        },
        err => {
          if (err.error.statusCode === 403) {
            this.alertService.error('Session Time Out! Please login Again', this.options)
            this.routerUrl.navigate([`/login-new`], { skipLocationChange: false });
          }
          else if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else {
            this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }

    setSlot(time){
      if(this.Default1.value.add_outlet === "" || this.Default1.value.add_outlet === null){
        console.log("pass")
        this.Default1.controls.add_outlet.setErrors({"required": true});
        this.validateForm = true;
      } else if(this.Default1.value.date === "" || this.Default1.value.date === null){
        console.log("pass")
        this.Default1.controls.date.setErrors({"required": true});
        this.validateForm = true;
      } 
      else {
           
        this.Default1.controls['time'].setValue(time);
        console.log("timeeee"+this.Default1.controls.time.value)
        this.slotTime = this.Default1.controls.time.value;
        this.showmsg2 = false;
        // for(let i = 0;i<this.tableBookingSlot.length;i++)
        // {
          // if(this.tableBookingSlot[i].slot_time==time){
            // this.tableBookingForm.controls['no_of_persons'].setValue(this.tableBookingSlot[i].available_seats);
            // this.noOfPerson=this.tableBookingSlot[i].available_seats;
          // }
        // }
        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
            "find": {
              "date":this.Default1.value.date,
              "time":time,
              "add_outlet":this.Default1.value.add_outlet,
            }
          }
        }
        this.apiService.postApiCall(requestData, ENDPOINTS.GETALL_GIFTMEALAVAILABLESEAT).subscribe(
          resp => {
            if(resp.success === 1){
              // this.noOfPerson = resp.result.data[0].maximum_persons;
              this.noOfPerson = resp.result.data;
            }
            else{
              if(resp.message){
                this.alertService.error(resp.message, this.options)
              }          
              else{
                this.alertService.error('Something bad happened; Please try again!', this.options)
              }
            }          
          },
          err => {
            if (err.error.error.message) {
              this.alertService.error(err.error.error.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          })
      }
    
    }

    saveForm()
    {  

      console.log("valueeeee"+this.Default1.value.time)
      if(this.Default1.value.time === "" || this.Default1.value.time === null){
        console.log("pass")
        this.showmsg2 = true;
        this.validateForm = true;
      }
      if(parseInt(this.Default1.value.no_of_persons) > this.noOfPerson){
        console.log("pass2222")
        this.Default1.controls.no_of_persons.setErrors({'incorrect':true});
        this.validateForm = true;
       this.showmsg1 = true;
      }

      console.log("111")
      this.showAlerts = true;

      let currentData = new Date()

      let date = currentData.getDate() < 10 ? "0"+ (currentData.getDate()).toString() : (currentData.getDate()).toString()

      let month = currentData.getMonth() + 1 < 10 ? "0" + (currentData.getMonth()+ 1).toString() : (currentData.getMonth()+ 1).toString()

      let hour = currentData.getHours() < 10 ? "0"+ (currentData.getHours()).toString() : (currentData.getHours()).toString()

      let minute = currentData.getMinutes() < 10 ? "0"+ (currentData.getMinutes()).toString() : (currentData.getMinutes()).toString()
      
      let currentTime = hour + ":" + minute
      
      //TODAY DATE
      let today = currentData.getFullYear().toString() + "-" + month + "-" +  date

      //FIVE DAY FROM CURRENT DATE
      var fiveDay = this.datePipe.transform(new Date(new Date().getTime()+(5*24*60*60*1000)),"yyyy-MM-dd")

      if (this.Default1.value.date === "" || this.Default1.value.date === null )
      {
        this.Default1.controls.date.setErrors({"required": true});
        this.validateForm = true;
        return
      }
      console.log("222")
      //CHECKING 5 DAY WITH SELECTED DATE
      // else if(this.Default1.value.date < fiveDay)
      // {
      //   this.Default1.controls.date.setErrors({"previous_date": true});
      //   this.validateForm = true;
      //   return
      // }
      // else
      // {
      //   this.Default1.controls.date.setErrors(null)
      // }
      

        if(this.Default1.valid)
        // if(this.Default1.valid && this.addOutlet === true)
        {
          this.loaderService.emitLoading();
          console.log("333")
            this.validateForm = false;

            let data = this.Default1.value ;

            data["payment_date"] = today
            data["payment_mode"]  =  'Internet Banking'
            data['payment_status'] =  'Unpaid'
            data['total_amount'] = 0
            data['visiting_date'] = data['date']
            data['status'] = 'Booked'

            this.giftMealServices.forEach((ele)=>
            {
              data['total_amount'] += Number(ele.default_price)
              ele.remarks = ""
            })

            data['services'] = this.giftMealServices
            console.log("444")

            let formDataJson = {
              // domain_name: this.authTokenService.getDomain(),
              // user_id: this.authTokenService.getUserId(),
              domain_name : ORG_DETAILS.DOMAIN_NAME,
              user_id : ORG_DETAILS.USER_ID,
              
              payload: {
                giftmeal_creation : data
              },
              extras: {
                find: {
                  
                }
              }
            }

             console.log("555")
            this.verifyOTP(this.Default1.controls.mobile_number.value, this.Default1.controls.otp.value).then(
              respData1 => {
                console.log("666")
                let res1:any = respData1;
                if(res1.status){
            this.apiService.postApiCall(formDataJson,ENDPOINTS.GIFT_MEAL_CREATE).subscribe(
              resp => {
                if (resp) {
                  if (resp.success === 1 && resp.status_code === 200) {
                    window.scrollTo(0,0);
                    this.alertService.success("Gift Meal Added!",this.options);
                    this.cancelForm();
                    this.slotTime = "";
                    this.noOfPerson = 0;
                    this.loaderService.emitComplete();
                    window.scrollTo({top: 0, behavior: 'smooth'});
                  }
                  else if (resp.success === 0) {
                    if (resp.message) {
                      this.alertService.error(resp.message, this.options);
                    }
                  }
                  else if (resp.message && resp.status_code !== 200) {
                    this.alertService.error(resp.message, this.options);
                    
                  }
                  else {
                    this.alertService.error('Something bad happened. Please try again!', this.options);
                  }
                }
              },
              err => {
                if (err.error.statusCode === 403) {
                  this.alertService.error('Session Time Out! Please login Again', this.options)
                  this.routerUrl.navigate([`/login-new`], { skipLocationChange: false });
                }
                else if (err.error.error.message) {
                  this.alertService.error(err.error.error.message, this.options)
                }
                else {
                  this.alertService.error('Something bad happened. Please try again!', this.options);
                }
              }
            )
            }else
            {
                this.validateForm = true;
                this.loaderService.emitComplete();
                this.alertService.error('Please Enter Valid OTP!', this.options);
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
          })
        }
        else
        {
            this.validateForm = true;
        }
    }

    pickTime()
    {
      const amazingTimePicker = this.timePick.open({
        time: this.Default1.value.time,
        theme:'light',
        arrowStyle: {
          background:'#afacac',
        }
      });

      amazingTimePicker.afterClose().subscribe(time=>
        {
          this.Default1.controls.time.setValue(time)
        })
    }

    cancelForm()
    {
      this.Default1.reset({name: "",
      email_address: "",
      mobile_number: "",
      date: "",
      time: "",
      notes:"",
      add_outlet:"",
      no_of_persons: "",
      address: ""})
    }

    getAcceptanceTimings(){
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {}
        }
      }
      this.apiService.postApiCall(requestData, ENDPOINTS.ACCEPTANCE_TIMINGS).subscribe(
        resp => {
          if(resp.success === 1 && resp.result.data.length > 0){
  
            this.acceptanceTiming = resp.result.data[0]

            this.getOutletData()
          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        })
    }

    // PROCESSING METHODS
    getOutletData(){
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {}
        }
      }
      this.apiService.postApiCall(requestData, ENDPOINTS.GET_OUTLETS).subscribe(
        resp => {
          if(resp.success === 1){
            this.restaurentAddressList = resp.result.data[0]
            this.timeslot = Number(this.restaurentAddressList.time_slot.substring(0,2))
            this.outletList = resp.result.data;
            // this.findSlots();
            this.getSlot();
          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        })
    }

    handleOutlet(evt) {
      console.log("evt.target.value"+evt.target.value)
      if (evt.target.value === "" || evt.target.value === null) {
        this.addOutlet = true
      }
      else {
        this.outletList.forEach((element)=>
        {
          if (element.name == evt.target.value)
          {
           if(element.allow_table_booking === 'No'){
            this.addOutlet = false
           } else if(element.allow_table_booking === 'Yes'){
            this.addOutlet = true
           }
          }
        })
      }

      this.getGiftMealSettings1();
    
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "date":this.Default1.value.date,
            "time":this.Default1.value.time,
            "add_outlet":this.Default1.value.add_outlet,
          }
        }
      }
      this.apiService.postApiCall(requestData, ENDPOINTS.GETALL_GIFTMEALAVAILABLESEAT).subscribe(
        resp => {
          if(resp.success === 1){
            // this.noOfPerson = resp.result.data[0].maximum_persons;
            this.noOfPerson = resp.result.data;
          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options)
            }          
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          }          
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        })
    }


    // findSlots() {

    //     let date = moment().format("YYYY-MM-DD")

    //     this.acceptanceTiming.delivery_acceptance_timing.forEach(shift => {
    //       this.slots = [];
    //       var from = moment(date + " " + shift.from ,"YYYY-MM-DD hh:mm a");
    //       var to = moment(date + " " + shift.to,"YYYY-MM-DD hh:mm a");
    //       while (from <= to) {
    //       this.slots.push(from.format("hh:mm a"));

    //       from = from.add(this.timeslot, 'minutes');
    //       }
    //     })

    //   }


      getSlot(){
        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
            "find": {
            }
          }
        }
        this.apiService.postApiCall(requestData, ENDPOINTS.GETALL_GIFTMEALTIMESLOT).subscribe(
          resp => {
            if(resp.success === 1){
              // this.tableBookingSlot = resp.result.data;
              this.slots = resp.result.data;
            }
            else{
              if(resp.message){
                this.alertService.error(resp.message, this.options)
              }          
              else{
                this.alertService.error('Something bad happened; Please try again!', this.options)
              }
            }          
          },
          err => {
            if (err.error.error.message) {
              this.alertService.error(err.error.error.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          })
      }
       


      verifyMobileNumber(){
        console.log("111")
        if(this.Default1.controls.mobile_number.valid){
          console.log("222")
          this.loaderService.emitLoading();
          this.showmsg3=false;
        // console.log("lllllll"+this.Default1.controls.mobile_number.value)
        // if(this.Default1.controls.mobile_number.value.length == ""){
        //   console.log("hhhh"+this.Default1.controls.mobile_number.value)
        //   this.Default1.controls['mobile_number'].setErrors({'incorrect':true});
        // }
        // if(this.Default1.controls.mobile_number.value.length !== 10){
        //   this.Default1.controls['mobile_number'].setErrors({'incorrect':true});
        // }

        this.sendOTP(this.Default1.controls.mobile_number.value).then(
          respData => {
            let res:any = respData;
            if(res){
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.Default1.controls.mobile_number.value;
              this.loaderService.emitComplete();
              this.alertService.success('OTP has been sent to '+this.Default1.controls.mobile_number.value, this.options);
              window.scrollTo({top: 0, behavior: 'smooth'});
            } 
          }
        )
      } else {
        console.log("333")
        this.Default1.controls['mobile_number'].setErrors({'pattern':true});
        this.showmsg3=true;
    }
      }


      handleDate(evt) {
        console.log("evt.target.value"+evt.target.value)
        if(this.Default1.value.add_outlet === "" || this.Default1.value.add_outlet === null){
          console.log("pass")
          this.Default1.controls.add_outlet.setErrors({"required": true});
          this.validateForm = true;
        } else if(this.Default1.value.date === "" || this.Default1.value.date === null){
          console.log("passyyyyy")
          this.Default1.controls.date.setErrors({"required": true});
          this.validateForm = true;
        } if ((this.Default1.value.add_outlet === "" || this.Default1.value.add_outlet === null) && this.Default1.value.date){
          this.Default1.reset();
        }
        else {
        this.getGiftMealSettings1();
      }
    }


      sendOTP(mobileNo){
        this.showAlerts=true;
        return new Promise((resolve, reject) => {
          let requestData = {
            "domain_name": ORG_DETAILS.DOMAIN_NAME,
            "data": {
                "otp": {
                    "countrycode": "+91",
                    "mobile": mobileNo,
                    "otppurpose": "login"
                }
            }
          }
          this.checkoutApiService.checkoutAPICall(ENDPOINTS.SEND_OTP, requestData).then(
            respData => {
              let res:any = respData;
              if(res.success === 1){
                resolve(true);
              }
              else{
                resolve(false);
                  console.log("res.message"+res.message)
                  this.alertService.error(res.message, this.options);
              }
            }
          )
        })
      }
    
      verifyOTP(mobile, otp){
        return new Promise((resolve, reject) => {
        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "data": {
              "otp": {
                  "userotp": otp,
                  "mobile": mobile
              }
            }
          }
        this.checkoutApiService.checkoutAPICall(ENDPOINTS.VERIFY_OTP, requestData).then(
          respdata => {
            let res:any = respdata;
            if(res.success === 1){
              resolve({status:true, msg:res.message});
            }
            else{
              resolve({status:false, msg:res.message});
            }
        })
        })
      }

      getGiftMealSettings1(){
        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
            "find": {
              "add_outlet": this.Default1.value.add_outlet,
              "date":this.Default1.value.date
            }
          }
        }
        this.apiService.postApiCall(requestData, ENDPOINTS.GETALL_TABLEBOOKINGTIMESLOT).subscribe(
          resp => {
            if(resp.success === 1){
              this.slots = resp.result.data;
            }
            else{
              if(resp.message){
                this.alertService.error(resp.message, this.options)
              }          
              else{
                this.alertService.error('Something bad happened; Please try again!', this.options)
              }
            }          
          },
          err => {
            if (err.error.error.message) {
              this.alertService.error(err.error.error.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          })
      }

}
