import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../../app.config';
import { CheckoutInitialdataService } from '../checkout-initialdata.service';
import { CheckoutOrderdetailsService } from './checkout-orderdetails.service';
import { OrderSummaryInitialService } from '../order-summary/order-summary-initial.service';
import { CheckoutOrdersdetailsStorageService } from './checkout-ordersdetails-storage.service';
import { CheckoutCalculationsService } from '../checkout-calculations.service';
import { CheckoutOrderdetailsEventsService } from './checkout-orderdetails-events.service';
import { CheckoutOrderdetailsOrderService } from './checkout-orderdetails-order.service';
import { CheckoutPaymentApiService } from '../checkout-payment/checkout-payment-api.service';
import { CheckoutPaymentService } from '../checkout-payment/checkout-payment.service';
import { CheckoutShippingEventsService } from '../checkout-shipping/checkout-shipping-events.service';
import { CheckoutShippingApiService } from '../checkout-shipping/checkout-shipping-api.service';
import { config } from '../../../configs/config';
import { LoaderService } from '../../shared/loader/loader.service';
import { LocalStorageService } from '../../auth-service/local-storage.service';

@Component({
  selector: 'app-checkout-orderdetails',
  templateUrl: './checkout-orderdetails.component.html'
})
export class CheckoutOrderdetailsComponent implements OnInit {

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  CONFIG = config;

  cartProducts = [];

  showDeliverInstructions:boolean = false;
  showOrderComments:boolean = false;
  validateorderInstructionsForm = false;
  validateordernotesForm = false;
  orderInstructionsForm:FormGroup;
  orderNotesForm:FormGroup;
  editorderData:boolean = true;

  orderValidationData = {
    minOrderAmount:0,
    maxOrderQuantity:0,
    minOrderQuantity:0
  }

  constructor(
    public appService:AppService,
    private checkoutInitialdataService: CheckoutInitialdataService,
    private formBuilder: FormBuilder,
    private checkoutOrderdetailsService: CheckoutOrderdetailsService,
    private orderSummaryInitialService: OrderSummaryInitialService,
    private checkoutOrdersdetailsStorageService: CheckoutOrdersdetailsStorageService,
    private checkoutCalculationsService: CheckoutCalculationsService,
    private checkoutOrderdetailsEventsService: CheckoutOrderdetailsEventsService,
    private checkoutOrderdetailsOrderService: CheckoutOrderdetailsOrderService,
    private checkoutPaymentApiService: CheckoutPaymentApiService,
    private checkoutPaymentService: CheckoutPaymentService,
    private checkoutShippingEventsService: CheckoutShippingEventsService,
    private checkoutShippingApiService: CheckoutShippingApiService,
    private router: Router,
    public loaderService:LoaderService,
    private localService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.checkoutOrderdetailsEventsService.clearEvent();
    this.checkoutOrderdetailsEventsService.currentData.subscribe(
      currentData => {
        if(currentData === "resetOrder"){
          this.resetOrderDetails();
        }
        if(currentData === "editOrder"){
          this.editOrderDetails();
        }
      }
    )
    this.getOrderValidations();
    this.getOrderCommentSettings();
    this.getProductList();
    this.orderInstructionsForm = new FormGroup({
      orderInstructions: this.formBuilder.control('',[Validators.maxLength(1000)]),
    })

    this.orderNotesForm = new FormGroup({
      orderNotes: this.formBuilder.control('',[Validators.maxLength(1000)]),
    })
  }

  get f(){
    return this.orderInstructionsForm.controls;
  }

  get f1(){
    return this.orderNotesForm.controls;
  }

  resetOrderDetails(){
    this.editorderData = false;
  }

  getProductList(){
    this.checkoutInitialdataService.getCartItems().then(
      respData => {
        let res:any = respData;
        if(res){
          this.cartProducts = this.checkoutInitialdataService.cartProducts;
        }
      }
    )
  }

  saveOrderDetails(){
    let total_quantities = 0
    this.cartProducts.forEach(
      product => {
        total_quantities = total_quantities + (product.quantity);
      }
    )
    if(total_quantities < this.orderValidationData.minOrderQuantity && this.orderValidationData.minOrderQuantity > 0){
      this.notify.emit({type:"alert", msg: "Sorry, to order atleast you should add "+this.orderValidationData.minOrderQuantity+ "items.", msgType:"error"})
    }
    else if(this.checkoutCalculationsService.orderTotal < this.orderValidationData.minOrderAmount && this.orderValidationData.minOrderAmount > 0){
      this.notify.emit({type:"alert", msg: "Minimum Amount to order is"+this.orderValidationData.minOrderAmount, msgType:"error"})
    }
    else{
      if(this.orderInstructionsForm.valid && this.orderNotesForm.valid){
        this.validateorderInstructionsForm = false;
        this.validateordernotesForm = false;
        this.checkoutOrdersdetailsStorageService.setOrderInstractions({
          orderInstrcutions: this.orderInstructionsForm.value.orderInstructions,
          orderComments: this.orderNotesForm.value.orderNotes
        })
        this.checkoutOrderdetailsOrderService.setDeliveryNotes(this.orderInstructionsForm.value.orderInstructions, this.orderNotesForm.value.orderNotes)
        this.editorderData = false;
        this.getPaymentMethods();
        this.notify.emit({step:"next"})
      }
      else{
        if(this.orderInstructionsForm.invalid){
          this.validateorderInstructionsForm = true;
        }
        if(this.orderNotesForm.invalid){
          this.validateordernotesForm = true;
        }
      }
    }
  }

  editOrderDetails(){
    this.editorderData = true;
    this.notify.emit({name:"closeRemaining"})
  }

  ChangeValue(index)
{
 
  if(this.CONFIG.name=="ShubhCards")
    {
      
      if(this.cartProducts[index].quantity < this.cartProducts[index].product.pricing.raw.minimumorderquantity){
        this.cartProducts.splice(index, 1)
        this.checkoutOrderdetailsService.updateCart(this.cartProducts)
        this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
        if(this.cartProducts.length === 0){
          this.notify.emit("emptyCart");
        }
      }else{
        this.cartProducts[index].quantity = this.cartProducts[index].quantity;
        this.checkoutOrderdetailsService.updateCart(this.cartProducts);
        this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
      }
      if(this.cartProducts.length === 0){
        this.notify.emit("emptyCart");
      }
      else{
        if(this.checkoutShippingApiService.selectedLogistics === "DELHIVERY"){
          this.checkoutShippingEventsService.updateAdminFormEvent("deliveryCharges");
        }}}
      
}

changeDeeraValue(product,index)
{
  console.log("incrementtt")
  this.loaderService.emitLoading("checkout");
  let total_quantities = 0
  this.cartProducts.forEach(
    product => {
      total_quantities = total_quantities + (product.quantity);
    }
  )
  if(total_quantities >= this.orderValidationData.maxOrderQuantity && this.orderValidationData.maxOrderQuantity > 0){
    if(this.CONFIG.name==='Deera'){
      this.cartProducts[index].quantity = 0;
      this.notify.emit({type:"alert", msg: "Sorry, you can not add more than "+ this.orderValidationData.maxOrderQuantity + " items.", msgType:"error"})

    } else {
      this.notify.emit({type:"alert", msg: "Sorry, you can not add more than "+ this.orderValidationData.maxOrderQuantity + " items.", msgType:"error"})
    }
  }
  if(this.cartProducts[index].quantity < this.cartProducts[index].product.pricing.raw.minimumorderquantity){
    console.log("1111")
    this.cartProducts.splice(index, 1)
    this.checkoutOrderdetailsService.updateCart(this.cartProducts)
    this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
    if(this.cartProducts.length === 0){
      this.notify.emit("emptyCart");
    }
  }
  else{
    if(this.CONFIG.name==='Deera'){
      let obj =
      {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
              "find": {
                "product_id": Number(this.cartProducts[index].product_id),
                "product_cost": Number(this.cartProducts[index].cost),
                "quantity": Number(this.cartProducts[index].quantity)
              }
          }
        }
    
      this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
        resp => {
          if (resp) {
            if (resp.success === 1 && resp.status_code === 200) {
              console.log("this.cartProducts[index].quantity"+this.cartProducts[index].product.id);
              console.log("this.cartProducts[index].quantity"+this.cartProducts[index].product.id);
              console.log("resp.result.data.discount_applied_to_product_value"+resp.result.data.discount_applied_to_product_value);
              this.cartProducts[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
              this.cartProducts[index].product.discount_amount = resp.result.data.discount_amount;
              this.cartProducts[index].product.fixed_amount = resp.result.data.fixed_amount;
              this.cartProducts[index].product.discount_percentage = resp.result.data.discount_percentage;
              console.log("tempArray[index].product.discount_value"+this.cartProducts[index].product.discount_value);
              this.checkoutOrderdetailsService.updateCart(this.cartProducts);
              if(this.CONFIG.name==='Deera'){
                let cartItemsString = JSON.stringify(this.cartProducts);
                this.localService.set("cart", cartItemsString);
                }
            }
            else if (resp.success === 0) {
              if (resp.message) {
                //this.alertService.error(resp.message, this.options);
              }
            }
            else if (resp.message && resp.status_code !== 200) {
              //this.alertService.error(resp.message, this.options);
    
            }
            else {
              //this.alertService.error('Something bad happened. Please try again!', this.options);
            }
          }
        },
        err => {
          if (err.error.statusCode === 403) {
            //this.alertService.error('Session Time Out! Please login Again', this.options)
    
          }
          else if (err.error.error.message) {
            //this.alertService.error(err.error.error.message, this.options)
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }
    this.checkoutOrderdetailsService.updateCart(this.cartProducts);
    this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
    if(this.cartProducts.length === 0){
      this.notify.emit("emptyCart");
      }
    if(this.CONFIG.name==='Deera'){
      let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
      this.localService.set("cart", cartItemsString);
      }
    // console.log(this.checkoutShippingApiService.selectedLogistics, "deldfhsd")
    if(this.checkoutShippingApiService.selectedLogistics === "DELHIVERY"){
      this.checkoutShippingEventsService.updateAdminFormEvent("deliveryCharges");
    }
}
}


  decrementQuantity(product, index){
    if(this.CONFIG.name=="ShubhCards")
    {
      
      if(this.cartProducts[index].quantity - this.cartProducts[index].product.pricing.raw.decrementvalue < this.cartProducts[index].product.pricing.raw.minimumorderquantity){
        this.cartProducts.splice(index, 1)
        this.checkoutOrderdetailsService.updateCart(this.cartProducts)
        this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
        if(this.cartProducts.length === 0){
          this.notify.emit("emptyCart");
        }
      }
      else{
        this.cartProducts[index].quantity = this.cartProducts[index].quantity -this.cartProducts[index].product.pricing.raw.decrementvalue;
        this.checkoutOrderdetailsService.updateCart(this.cartProducts);
        this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
      }
      if(this.cartProducts.length === 0){
        this.notify.emit("emptyCart");
      }
      else{
        if(this.checkoutShippingApiService.selectedLogistics === "DELHIVERY"){
          this.checkoutShippingEventsService.updateAdminFormEvent("deliveryCharges");
        } 
      }

    }
    else{
      this.loaderService.emitLoading("checkout");
      if(product.quantity == 1 || this.cartProducts[index].quantity < this.cartProducts[index].product.pricing.raw.minimumorderquantity){
        console.log("1111")
        this.cartProducts.splice(index, 1)
        this.checkoutOrderdetailsService.updateCart(this.cartProducts)
        this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
        if(this.cartProducts.length === 0){
          this.notify.emit("emptyCart");
        }
      }
      else{
        console.log("2222")
        this.cartProducts[index].quantity = this.cartProducts[index].quantity - 1;
        if(this.CONFIG.name==='Deera'){
          let obj =
          {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              "user_id": ORG_DETAILS.USER_ID,
              "extras": {
                  "find": {
                    "product_id": Number(this.cartProducts[index].product_id),
                    "product_cost": Number(this.cartProducts[index].cost),
                    "quantity": Number(this.cartProducts[index].quantity)
                  }
              }
            }
        
          this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
            resp => {
              if (resp) {
                if (resp.success === 1 && resp.status_code === 200) {
                  console.log("tempArray[index].quantity"+this.cartProducts[index].product.id);
                  console.log("resp.result.data.discount_applied_to_product_value"+resp.result.data.discount_applied_to_product_value);
                  this.cartProducts[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
                  this.cartProducts[index].product.discount_amount = resp.result.data.discount_amount;
                  this.cartProducts[index].product.fixed_amount = resp.result.data.fixed_amount;
                  this.cartProducts[index].product.discount_percentage = resp.result.data.discount_percentage;
                  console.log("tempArray[index].product.discount_value"+this.cartProducts[index].product.discount_value);
                  this.checkoutOrderdetailsService.updateCart(this.cartProducts);
                  if(this.CONFIG.name==='Deera'){
                    let cartItemsString = JSON.stringify(this.cartProducts);
                    this.localService.set("cart", cartItemsString);
                    }
                }
                else if (resp.success === 0) {
                  if (resp.message) {
                    //this.alertService.error(resp.message, this.options);
                  }
                }
                else if (resp.message && resp.status_code !== 200) {
                  //this.alertService.error(resp.message, this.options);
        
                }
                else {
                  //this.alertService.error('Something bad happened. Please try again!', this.options);
                }
              }
            },
            err => {
              if (err.error.statusCode === 403) {
                //this.alertService.error('Session Time Out! Please login Again', this.options)
        
              }
              else if (err.error.error.message) {
                //this.alertService.error(err.error.error.message, this.options)
              }
              else {
                //this.alertService.error('Something bad happened. Please try again!', this.options);
              }
            }
          )
        }
        this.checkoutOrderdetailsService.updateCart(this.cartProducts);
        if(this.CONFIG.name==='Deera'){
          let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
          this.localService.set("cart", cartItemsString);
          }
        this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
      }
      if(this.cartProducts.length === 0){
        this.notify.emit("emptyCart");
      }
      else{
        if(this.checkoutShippingApiService.selectedLogistics === "DELHIVERY"){
          this.checkoutShippingEventsService.updateAdminFormEvent("deliveryCharges");
        } 
      }
    }
    
  }

  incrementQuantity(product, index){
    if(this.CONFIG.name=="ShubhCards")
    {
      let total_quantities = 0
      this.cartProducts.forEach(
        product => {
          total_quantities = total_quantities + (product.quantity);
        }
      )
      this.cartProducts[index].quantity = this.cartProducts[index].quantity + this.cartProducts[index].product.pricing.raw.incrementvalue;
        this.checkoutOrderdetailsService.updateCart(this.cartProducts);
        this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
        // console.log(this.checkoutShippingApiService.selectedLogistics, "deldfhsd")
        if(this.checkoutShippingApiService.selectedLogistics === "DELHIVERY"){
          this.checkoutShippingEventsService.updateAdminFormEvent("deliveryCharges");
        }
    }
    else{
      console.log("incrementtt")
      this.loaderService.emitLoading("checkout");
      let total_quantities = 0
      this.cartProducts.forEach(
        product => {
          total_quantities = total_quantities + (product.quantity);
        }
      )
      if(total_quantities >= this.orderValidationData.maxOrderQuantity && this.orderValidationData.maxOrderQuantity > 0){
        if(this.CONFIG.name==='Deera'){
          this.cartProducts[index].quantity = 0;
          this.notify.emit({type:"alert", msg: "Sorry, you can not add more than "+ this.orderValidationData.maxOrderQuantity + " items.", msgType:"error"})
    
        } else {
        this.notify.emit({type:"alert", msg: "Sorry, you can not add more than "+ this.orderValidationData.maxOrderQuantity + " items.", msgType:"error"})
        }
      }
      else{
        this.cartProducts[index].quantity = this.cartProducts[index].quantity + 1;
        if(this.CONFIG.name==='Deera'){
          let obj =
          {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              "user_id": ORG_DETAILS.USER_ID,
              "extras": {
                  "find": {
                    "product_id": Number(this.cartProducts[index].product_id),
                    "product_cost": Number(this.cartProducts[index].cost),
                    "quantity": Number(this.cartProducts[index].quantity)
                  }
              }
            }
        
          this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
            resp => {
              if (resp) {
                if (resp.success === 1 && resp.status_code === 200) {
                  console.log("this.cartProducts[index].quantity"+this.cartProducts[index].product.id);
                  console.log("this.cartProducts[index].quantity"+this.cartProducts[index].product.id);
                  console.log("resp.result.data.discount_applied_to_product_value"+resp.result.data.discount_applied_to_product_value);
                  this.cartProducts[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
                  this.cartProducts[index].product.discount_amount = resp.result.data.discount_amount;
                  this.cartProducts[index].product.fixed_amount = resp.result.data.fixed_amount;
                  this.cartProducts[index].product.discount_percentage = resp.result.data.discount_percentage;
                  console.log("tempArray[index].product.discount_value"+this.cartProducts[index].product.discount_value);
                  this.checkoutOrderdetailsService.updateCart(this.cartProducts);
                  if(this.CONFIG.name==='Deera'){
                    let cartItemsString = JSON.stringify(this.cartProducts);
                    this.localService.set("cart", cartItemsString);
                    }
                }
                else if (resp.success === 0) {
                  if (resp.message) {
                    //this.alertService.error(resp.message, this.options);
                  }
                }
                else if (resp.message && resp.status_code !== 200) {
                  //this.alertService.error(resp.message, this.options);
        
                }
                else {
                  //this.alertService.error('Something bad happened. Please try again!', this.options);
                }
              }
            },
            err => {
              if (err.error.statusCode === 403) {
                //this.alertService.error('Session Time Out! Please login Again', this.options)
        
              }
              else if (err.error.error.message) {
                //this.alertService.error(err.error.error.message, this.options)
              }
              else {
                //this.alertService.error('Something bad happened. Please try again!', this.options);
              }
            }
          )
        }
        this.checkoutOrderdetailsService.updateCart(this.cartProducts);
        this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
        if(this.CONFIG.name==='Deera'){
          let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
          this.localService.set("cart", cartItemsString);
          }
        // console.log(this.checkoutShippingApiService.selectedLogistics, "deldfhsd")
        if(this.checkoutShippingApiService.selectedLogistics === "DELHIVERY"){
          this.checkoutShippingEventsService.updateAdminFormEvent("deliveryCharges");
        }
    }
   
    }
  }

  getOrderCommentSettings(){
    this.checkoutOrderdetailsService.getCheckoutSettings().then(
      respData => {
        let res:any = respData;
        this.showOrderComments = res;
      })
  }

  orderDetailsExists(){
    if(this.checkoutOrdersdetailsStorageService.getOrderInstructions()){
      this.orderInstructionsForm.patchValue({
        orderInstructions: this.checkoutOrdersdetailsStorageService.getOrderInstructions().orderInstrcutions
      })
      this.orderNotesForm.patchValue({
        orderNotes: this.checkoutOrdersdetailsStorageService.getOrderInstructions().orderComments
      })
    }
  }

  getOrderValidations(){
    // GET ORDER VALIDATION VALUES
    this.checkoutInitialdataService.getOrderValidations().then(
      respData => {
        let res:any = respData;
        this.orderValidationData = res;
      }
    )
  }

  getPaymentMethods(){
    this.checkoutPaymentApiService.getPaymentMethods().then(
      respData => {
        let paymentMethods = [];
        let res:any = respData[0];
        if(res.offline_methods && res.offline_methods.length > 0){
          res.offline_methods.forEach(element => {
            paymentMethods.push(element);
          });
        }
        if(res.online_methods && res.online_methods.length > 0){
          res.online_methods.forEach(element => {
            paymentMethods.push(element);
          });
        }

        if(paymentMethods.length === 1){
          this.checkoutPaymentService.updateAdminFormEvent("submitPayment");
          // this.hidePaymentSection = true;
        }

      })
  }

  redirectPAge(product){
 
    this.router.navigate(['/product-listing'], {queryParams: {category:[product.product.categories[0].categories]},  skipLocationChange: false });
  }

}
