import { Component, OnInit } from '@angular/core';

import { CheckoutApiService } from '../checkout-api.service';
import { CheckoutCalculationsService } from '../checkout-calculations.service';
import { CheckoutInitialdataService } from '../checkout-initialdata.service';
import { CheckoutSettingsService } from '../checkout-settings.service';
import { CheckoutTaxService } from '../checkout-tax.service';
import { OrderSummaryInitialService } from './order-summary-initial.service';
import { OrderSummaryOrderService } from './order-summary-order.service';
import { CheckoutShippingApiService } from '../checkout-shipping/checkout-shipping-api.service';
import { CheckoutShippingOrderService } from '../checkout-shipping/checkout-shipping-order.service';
import { ENDPOINTS,ORG_DETAILS } from '../../app.config';
import { config } from '../../../configs/config';
import { LoaderService } from '../../shared/loader/loader.service';
import { LocalStorageService } from '../../auth-service/local-storage.service';
import { CheckoutDeliveryStorageService } from '../checkout-delivery/checkout-delivery-storage.service';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html'
})
export class OrderSummaryComponent implements OnInit {

  PERCENTAGE_LABEL = "PERCENTAGE";
  FIXEDAMOUNT_LABLE = "FIXED AMOUNT";
  CONFIG = config;

  SummaryData = {
    subTotal:{
      displayName:"SubTotal",
      amount:0,
      show:false
    },
    itemCount:0,
    handlingFee:{
      displayName:"",
      amount:0,
      show:false
    },
    discount:{
      displayName:"",
      amount:0,
      show:false
    },
    shippingCharges:{
      displayName:"",
      amount:0,
      show:false,
      free:false
    },
    tax:{
      displayName:"Tax",
      amount:0,
      show:false
    },
    total:{
      displayName:"Total",
      amount:0,
      show:false
    }
  }

  discountData = {
    type:"",
    rate:0,
    displaymsg:""
  }

  HanglingFee = {
    name:"",
    type:"",
    rate:0
  }

  discountTax:boolean = false;
  shippingTax:boolean = false;
  bannerMsg:any = "";
  deliveryData:any;
  minimumOrderAmount:any;
  productlevelhandlingfee:boolean=false;
  productlevelpackagecost:Number=0;
  banner = "";
  promotionData:any;
  public executionData=[];
  public percentageValues=[];
  public quantityValues=[];
  public quantityValues1=[];
  public quantityValues2=[];
  public amountValues=[];

  constructor(
    public appService:AppService,
    private checkoutApiService: CheckoutApiService,
    private checkoutCalculationsService: CheckoutCalculationsService,
    private checkoutInitialdataService: CheckoutInitialdataService,
    private checkoutSettingsService: CheckoutSettingsService,
    private checkoutTaxService: CheckoutTaxService,
    private orderSummaryInitialService: OrderSummaryInitialService,
    private orderSummaryOrderService: OrderSummaryOrderService,
    private checkoutShippingApiService: CheckoutShippingApiService,
    private checkoutShippingOrderService: CheckoutShippingOrderService,
    private loaderService: LoaderService,
    private localStorageService: LocalStorageService,
    private checkoutDeliveryStorageService: CheckoutDeliveryStorageService
  ) { }

  ngOnInit(): void {
    this.orderSummaryInitialService.clearEvent();
    this.getInitialData();
    // this.getPromotionBanner();
    console.log("dataaaa333");
    this.getPromotionBanner1();
    //alert(this.localStorageService.get("pincode"))
    this.orderSummaryInitialService.currentData.subscribe(currentData => {
      let data:any = currentData;
      if(data.name === "discount"){
        this.calculateDiscountAmount(data.value);
      }
      else if(data.name === "handlingFee"){
        this.calculateHandlingFee(data.value);
      }
      else if(data.name === "shippingCharges"){
        this.calculateShippingCharges(data.value);
      }
      else if(data.name === "Free Shipping Products"){
        this.freeShippingProducts(data.value);
      }
      else if(data.name === "pickdropCharges"){
        this.pickdropCharges(data.value);
      }
      else if(data.name === "flatratecharges"){
        this.flatRateCharges(data.value);
      }
      else if(data.name === "delhiveryCharges"){
        this.delhiveryCharges(data.value);
      }
      else if(data.name === "dunzoCharges"){
        this.dunzooCharges(data.value);
      }
      else if(data.name === "fixedShippingCharges"){
        this.fixedShipping();
      }
      else if(data === "updateProducts"){
        if(ORG_DETAILS.NAME==='ShubhCards' || ORG_DETAILS.NAME==='Deera')
        {
          let ozipcode
        if(this.checkoutDeliveryStorageService.getDeliveryInfo()){
          ozipcode = this.checkoutDeliveryStorageService.getDeliveryInfo().zipcode;
        }
        else{
          this.getRestaurentdetailsforShipping().then(
            respData1 =>{
              let res1:any = respData1;
              ozipcode = res1.zipcode;
              let dzipcode = this.checkoutShippingOrderService.shippingAddress.zipcode;
              let weight = "500";             

              this.checkoutShippingApiService.getDelhiveryCharges(weight,ozipcode,dzipcode).then(
                respdata=>{
                  this.SummaryData.shippingCharges.displayName = "Delivery Charges";

                  this.SummaryData.shippingCharges.show = true;
                  

                  this.SummaryData.shippingCharges.amount = Number(respdata);
                  // if(ORG_DETAILS.NAME==='Deera'){
                  //    this.SummaryData.discount.amount=0;
                  // }
                  console.log("dataaaa111");
                  this.updateAmounts();
                  this.quantityValues1 = [];
                  this.getPromotionBanner1();
                })
            })
          }
        }     
        else{
          console.log("dataaaa222");
          this.updateAmounts();
          // this.getPromotionBanner();
        }
        // this.updateAmounts();
        if(this.discountData.type !== ""){
          this.calculateDiscountAmount({discount : this.discountData.rate,
            type : this.discountData.type,
            displayName : this.discountData.displaymsg,
            displayMsg : this.bannerMsg})
        }
      }
      else if(data.name === "removeShipping"){
        this.removeDeliveryCharges();
      }
      else if(data.name === "removeDiscount"){
        this.removeDiscount();
      }
    })
  }
   f1() {
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.STORE_SETTING_GET_ALL).then(
        respData => {
          let res:any = respData;
     this.productlevelhandlingfee=res.data[0].others.package_settings.product_package_cost?res.data[0].others.package_settings.product_package_cost:false;
        }
      ).then(
  
  
        respdata=>{
          this.checkoutInitialdataService.cartProducts.forEach(element=>{
                  
            var quantity=element.quantity;
            //var package_cost=element;
           var package_cost=element.product.pricing.raw.package_cost?element.product.pricing.raw.package_cost:0;
            var product_package_cost=quantity*package_cost;
            this.productlevelpackagecost=Number(this.productlevelpackagecost)+product_package_cost;
            
          })
          resolve(1);
        }
      )
        
    });
}

 f21() {
  this.checkoutSettingsService.getShippingSettings().then(
    respData => {
      let res:any = respData;
      if(res){
        this.HanglingFee = {
          name:this.checkoutSettingsService.initialHanglingFee.name,
          type:this.checkoutSettingsService.initialHanglingFee.type,
          rate:this.checkoutSettingsService.initialHanglingFee.rate
        }
        this.SummaryData.handlingFee.displayName = this.checkoutSettingsService.initialHanglingFee.name;
        this.checkoutCalculationsService.calculateHandlingFee(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.checkoutSettingsService.initialHanglingFee.type, this.checkoutSettingsService.initialHanglingFee.rate).then(
          respData1 => {
            let res1:any = respData1;
            if(this.productlevelhandlingfee)
            {
              this.SummaryData.handlingFee.amount=Number(this.productlevelpackagecost);
            }
            else
            {
              this.SummaryData.handlingFee.amount = res1;
            }
            if(this.SummaryData.handlingFee.amount > 0){
              this.SummaryData.handlingFee.show = true;
            }
            else{
              this.SummaryData.handlingFee.show = false;
            }
          }
        )
      }
    }
  )
}

getPromotionBanner()
{
  let obj =
  {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
          "find": {
          }
      }
    }

  this.appService.postApiCall(obj,ENDPOINTS.PROMOTIONS_BANNER).subscribe(
    resp => {
      if (resp) {
        if (resp.success === 1 && resp.status_code === 200) {
          this.banner = resp.result.data[0]['eligible_banner_message']
          if(this.CONFIG.title ==='Deera'){
            this.promotionData = resp.result.data;
            let tempArray = this.appService.cartProducts.results;
            console.log("tempArray"+JSON.stringify(tempArray))
            tempArray.forEach((item)=>
            {
              console.log("1111111111"+item.product.name);
        
              this.promotionData.forEach((data)=>
              {
                console.log("3333333333"+item.sub_total);
                console.log("eeeeee"+data.product_discount.amount_or_percentage.discounted_percentage);
                // this.productData = data.product_discount.amount_or_percentage.product_details;
                // console.log("productnnnn"+data.product_discount.amount_or_percentage.product_details[0].product_name)
                if(data.product_discount.amount_or_percentage.product_details){
                  data.product_discount.amount_or_percentage.product_details.forEach((data1)=>{
                    console.log("data1.product_name"+data1.product_name);
                    console.log("item.product_name"+item.product.name);
                    if(data1.product_name===item.product.name){
                      console.log("trueeee");
                      this.executionData.push(data1.order_execution);
                      let max = this.executionData.reduce((op, item) => op = op > item ? op : item, 0);
                      console.log("max"+max);
                      if(max===data1.order_execution){
                      if(data.product_discount.amount_or_percentage.discounted_percentage!==0){
                        console.log("truwwwwwww");
                        function minusPercent(n,p) {
                          return n - (n * (p/100));
                        }
                        function minusPercent1(n,p) {
                          return (n * (p/100));
                        }
                        let price = minusPercent(item.sub_total,data.product_discount.amount_or_percentage.discounted_percentage); 
                        let price1 = minusPercent1(item.sub_total,data.product_discount.amount_or_percentage.discounted_percentage)
                        console.log("price"+price1);
                        this.SummaryData.discount.show = true;
                        // this.SummaryData.discount.amount = price1;                      
                        this.percentageValues.push(price1);
        
                      } else if (data.product_discount.amount_or_percentage.discounted_amount!==0){
                        console.log("item.pricing.raw.price"+item.sub_total);
                        console.log("discounted_amount"+data.product_discount.amount_or_percentage.discounted_amount);
                       let price = item.sub_total - data.product_discount.amount_or_percentage.discounted_amount;
                      //  item.pricing.raw.price = price;
                       console.log("price"+price);
                       this.SummaryData.discount.show = true;
                      //  this.SummaryData.discount.amount = data.product_discount.amount_or_percentage.discounted_amount;  
                       this.amountValues.push(data.product_discount.amount_or_percentage.discounted_amount);
                      }
                    }
                    }
  
                  })
                }

               else if(data.quantity_discount.amount_or_percentage.product_details){
                  data.quantity_discount.amount_or_percentage.product_details.forEach((data1)=>{
                    console.log("data1.product_name"+data1.product_name);
                    console.log("item.product_name"+item.product.name);
                    if(data1.product_name===item.product.name){
                      console.log("item"+JSON.stringify(item));
                      console.log("trueeee");
            
                      // if(data.quantity_discount.amount_or_percentage.discount_type[0]==="Percentage"){
                        // console.log("truwwwwwww");
                        // function minusPercent(n,p) {
                        //   return n - (n * (p/100));
                        // }
                        // function minusPercent1(n,p) {
                        //   return (n * (p/100));
                        // }
                        // let price = minusPercent(item.sub_total,data.product_discount.amount_or_percentage.discounted_percentage); 
                        // let price1 = minusPercent1(item.sub_total,data.product_discount.amount_or_percentage.discounted_percentage)
                       if(item.product.discount_amount!==0){
                        let price1 = item.product.discount_amount;
                        console.log("price"+price1);
                        this.SummaryData.discount.show = true;
                        // this.SummaryData.discount.amount = price1;                      
                        this.quantityValues.push(price1);
                       } else if(item.product.fixed_amount!==0){
                        let price1 = item.product.fixed_amount;
                        console.log("price"+price1);
                        this.SummaryData.discount.show = true;
                        // this.SummaryData.discount.amount = price1;                      
                        this.quantityValues.push(price1);
                       }
              
        
                      // } 
                      // else if (data.product_discount.amount_or_percentage.discounted_amount!==0){
                        // console.log("item.pricing.raw.price"+item.sub_total);
                        // console.log("discounted_amount"+data.product_discount.amount_or_percentage.discounted_amount);
                      //  let price = item.sub_total - data.product_discount.amount_or_percentage.discounted_amount;
                      //  item.pricing.raw.price = price;
                      //  console.log("price"+price);
                      //  this.SummaryData.discount.show = true;
                      //  this.SummaryData.discount.amount = data.product_discount.amount_or_percentage.discounted_amount;  
                      //  this.amountValues.push(data.product_discount.amount_or_percentage.discounted_amount);
                      // }
                    // }
                    }
  
                  })
           

                }
              })
            })
            // for(let i =0;i<this.percentageValues.length;i++){ 
            //   console.log("array"+this.percentageValues[i]);
            // }
            
            const result = this.percentageValues.reduce((accumulator, current) => {
              return accumulator + current;
            }, 0);

            console.log(result);

            const result1 = this.amountValues.reduce((accumulator, current) => {
              return accumulator + current;
            }, 0);

            console.log(result1);

            const result3 = this.quantityValues.reduce((accumulator, current) => {
              return accumulator + current;
            }, 0);

            console.log(result3);

            this.SummaryData.discount.amount = result + result1 +result3;

          }
        }
        else if (resp.success === 0) {
          if (resp.message) {
            //this.alertService.error(resp.message, this.options);
          }
        }
        else if (resp.message && resp.status_code !== 200) {
          //this.alertService.error(resp.message, this.options);

        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    },
    err => {
      if (err.error.statusCode === 403) {
        //this.alertService.error('Session Time Out! Please login Again', this.options)

      }
      else if (err.error.error.message) {
        //this.alertService.error(err.error.error.message, this.options)
      }
      else {
        //this.alertService.error('Something bad happened. Please try again!', this.options);
      }
    }
  )


}


getPromotionBanner1()
{       
          if(this.CONFIG.title ==='Deera'){
           
            let tempArray = this.appService.cartProducts.results;
            console.log("tempArray"+JSON.stringify(tempArray))
            tempArray.forEach((item)=>
            {
            if(item.product.discount_amount!==0){
              let price1 = item.product.discount_amount;
              console.log("price"+price1);
              this.SummaryData.discount.show = true;
              // this.SummaryData.discount.amount = price1;                      
              this.quantityValues1.push(price1);
            }
            if(item.product.fixed_amount!==0){
              let price2 = item.product.fixed_amount;
              console.log("price"+price2);
              this.SummaryData.discount.show = true;
              // this.SummaryData.discount.amount = price1;                      
              this.quantityValues1.push(price2);
            }
            })

            const result3 = this.quantityValues1.reduce((accumulator, current) => {
              return accumulator + current;
            }, 0);

            console.log(result3);

            this.SummaryData.discount.amount = result3;

            // this.SummaryData.discount.amount = result + result1 +result3;

          }
        
       
    }


  getInitialData(){
    this.f1().then(res => this.f21());
   
    // GET CART ITEMS
    this.checkoutInitialdataService.getCartItems().then(
      respData => {
        let res:any = respData;
        if(res){
          this.checkoutCalculationsService.calculateSubTotal(this.checkoutInitialdataService.cartProducts).then(
            respData1 => {
              let res1:any = respData1;
              this.SummaryData.subTotal.amount = res1;
              this.SummaryData.subTotal.show = true;
              this.SummaryData.itemCount = this.checkoutInitialdataService.cartProducts.length;
            }
          )
        }
      }
    )
    // CALCULATE HANDLING FEE
    
    // CALCULATE TAX 
    this.checkoutSettingsService.getTaxSettings().then(
      respData => {
        let res:any = respData;
        if(res){
          this.checkoutSettingsService.getTaxRates().then(
            respData1 => {
              let res1:any = respData1;
              if(res1){
                this.checkoutTaxService.getProductTaxList(this.checkoutInitialdataService.cartProducts, this.checkoutSettingsService.taxclasses).then(
                  respData2 => {
                    let res2:any = respData2;
                    console.log("res2"+JSON.stringify(res2))
                    this.checkoutCalculationsService.calculateTotalTax(res2, this.SummaryData.handlingFee.amount, this.checkoutSettingsService.handlingFeeTax.rate, this.SummaryData.discount.amount, this.SummaryData.shippingCharges.amount, this.discountTax, this.shippingTax, this.checkoutSettingsService.shippingTax.rate, this.checkoutSettingsService.productTax.rate).then(
                      respData2 => {
                        let res2:any = respData2;
                        this.SummaryData.tax.amount = res2;
                        this.SummaryData.tax.show = true;
                        this.SummaryData.total.amount = this.checkoutCalculationsService.calculateTotal(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.SummaryData.handlingFee.amount, this.SummaryData.shippingCharges.amount, this.SummaryData.tax.amount);
                        if(this.SummaryData.total.amount > 0){
                          this.SummaryData.total.show = true;
                        }
                        else{
                          this.SummaryData.total.show = false;
                        }
                        this.orderSummaryOrderService.orderSummary = {
                          sub_total : this.SummaryData.subTotal.amount,
                          packing_cost : this.SummaryData.handlingFee.amount,
                          shipping_cost : this.SummaryData.shippingCharges.free ? 0 : this.SummaryData.shippingCharges.amount,
                          tax : this.SummaryData.tax.amount,
                          discount_amount: this.SummaryData.discount.amount,
                          order_total_amount : this.SummaryData.total.amount,
                          handling_display:this.SummaryData.handlingFee.displayName,
                          discount_display:this.SummaryData.discount.displayName
                        }
                      }
                    )

                  }
                )
              }
            }
          )
        }
      }
    )
  }

  calculateDiscountAmount(discountDetails){
    this.discountData.rate = discountDetails.discount;
    this.discountData.type = discountDetails.type;
    this.discountData.displaymsg = discountDetails.displayName;
    this.bannerMsg = discountDetails.displayMsg;
    if(discountDetails.type === this.PERCENTAGE_LABEL){
      this.checkoutInitialdataService.getCartItems().then(
        respData => {
          let res:any = respData;
          if(res){
            this.checkoutCalculationsService.calculateSubTotal(this.checkoutInitialdataService.cartProducts).then(
              respData1 => {
                let res1:any = respData1;
                this.orderSummaryOrderService.orderObj.discountPercentage = discountDetails.discount;
                this.checkoutCalculationsService.calculateDiscount(res1, discountDetails.discount).then(
                  respData2 => {
                    let res2:any = respData2;
                    this.SummaryData.discount.amount = res2;
                    this.orderSummaryOrderService.orderObj.discountAmount = this.SummaryData.discount.amount;
                    this.orderSummaryOrderService.orderObj.discountType = discountDetails.type;
                    this.SummaryData.discount.displayName = discountDetails.displayName;
                    if(this.SummaryData.discount.amount > 0){
                      this.SummaryData.discount.show = true;
                    }
                    else{
                      this.SummaryData.discount.show = false;
                    }
                    this.SummaryData.total.amount = this.checkoutCalculationsService.calculateTotal(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.SummaryData.handlingFee.amount, this.SummaryData.shippingCharges.amount, this.SummaryData.tax.amount);
                    this.updateAmounts();
                  }
                )
              }
            )
          }
        }
      )
    }
    else{
      this.SummaryData.discount.amount = discountDetails.discount;
      this.orderSummaryOrderService.orderObj.discountAmount = discountDetails.discount;
      this.orderSummaryOrderService.orderObj.discountType = discountDetails.type;
      if(this.SummaryData.discount.amount > 0){
        this.SummaryData.discount.displayName = discountDetails.displayName;
        this.SummaryData.discount.show = true;
        this.updateAmounts();
        this.checkDiscountTax();
      }
      else{
        this.SummaryData.discount.displayName = "";
        this.SummaryData.discount.show = false;
      }
    }
  }

  calculateHandlingFee(handlingFeeDetails){
    this.HanglingFee = handlingFeeDetails;
    this.updateAmounts();
  }

  calculateShippingCharges(shippingDetails){
    if(shippingDetails.type === "Free Shipping"){
      if(shippingDetails.data.minOrder === "" || shippingDetails.data.minOrder === 0){
        this.SummaryData.shippingCharges.free = true;
        this.SummaryData.shippingCharges.displayName = shippingDetails.data.displayName
        this.SummaryData.shippingCharges.show = true;
        
        this.updateAmounts();
      }
      else if(shippingDetails.data.minOrder > 0){
        this.deliveryData = shippingDetails.delivery;
        this.minimumOrderAmount = shippingDetails.data.minOrder;
        this.checkforFreeShipping();
      }
    }
    this.loaderService.emitComplete();
  }

  checkforFreeShipping(){
    if(this.minimumOrderAmount <= (this.SummaryData.subTotal.amount - this.SummaryData.discount.amount)){
      this.SummaryData.shippingCharges.free = true;
      this.SummaryData.shippingCharges.displayName = "Delivery Charges";
      this.SummaryData.shippingCharges.show = true;
      
      this.updateAmounts();
    }
    else{
      // DELIVERY CHRAGES
      this.SummaryData.shippingCharges.free = false;
      this.checkoutShippingApiService.getDeliveryCharges().then(
        respData => {
          let res:any = respData;
          if(res.deliverType !== "Flat Rate"){
            this.SummaryData.shippingCharges.displayName = "Delivery Charges";
            this.SummaryData.shippingCharges.show = true;
            
            this.SummaryData.shippingCharges.amount = res.deliveryCharge;
            this.updateAmounts();
          }
          else{
            this.checkoutShippingApiService.getFlatRateCharges().then(
              respData1 => {
                let res1:any = respData1;
                let flatData = {
                  delivery:{},
                  data:res1
                }
                this.flatRateCharges(flatData);
              }
            )
          }
        }
      )
    }
    this.loaderService.emitComplete();
  }

  freeShippingProducts(deliveryObj){
    this.deliveryData = deliveryObj.delivery;
    this.SummaryData.shippingCharges.free = true;
    this.SummaryData.shippingCharges.displayName = "Delivery Charges";
    this.SummaryData.shippingCharges.show = true;
    
    this.loaderService.emitComplete();
  }

  pickdropCharges(pickdropdetails){
    if(pickdropdetails.data > 0){
      this.SummaryData.shippingCharges.free = false;
      this.SummaryData.shippingCharges.displayName = "Delivery Charges";
      this.SummaryData.shippingCharges.show = true;
      
      this.SummaryData.shippingCharges.amount = pickdropdetails.data;
      this.updateAmounts();
    }
    else{
      this.SummaryData.shippingCharges.show = false;
    }
    this.loaderService.emitComplete();
  }

  delhiveryCharges(delihiverydetails){
    if(delihiverydetails.data){
      this.SummaryData.shippingCharges.free = false;
      this.SummaryData.shippingCharges.displayName = "Delivery Charges";
      this.SummaryData.shippingCharges.show = true;
      
      this.SummaryData.shippingCharges.amount = delihiverydetails.data;
      this.updateAmounts();
    }
    else{
      this.SummaryData.shippingCharges.show = false;
    }
    this.loaderService.emitComplete();
  }

  dunzooCharges(dunzodetails){
    if(dunzodetails.data != undefined){
      this.SummaryData.shippingCharges.free = false;
      this.SummaryData.shippingCharges.displayName = "Delivery Charges";
      this.SummaryData.shippingCharges.show = true;
      
      this.SummaryData.shippingCharges.amount = dunzodetails.data;
      this.updateAmounts();
    }
    else{
      this.SummaryData.shippingCharges.show = false;
    }
    this.loaderService.emitComplete();
  }


  flatRateCharges(flatRateObj){
    this.deliveryData = flatRateObj.delivery;
    let flatData: any = flatRateObj.data;
    if(flatData.type.toUpperCase() === "PER ORDER" && flatData.rate > 0){
      this.SummaryData.shippingCharges.free = false;
      this.SummaryData.shippingCharges.displayName = flatData.diaplayName;
      this.SummaryData.shippingCharges.amount = flatData.rate;
      this.SummaryData.shippingCharges.show = true;
      console.log("tamssssssssssssssssssssss"+this.SummaryData.shippingCharges.show)
      this.updateAmounts();
      this.checkShippingTax();
    }
    else if(flatData.type.toUpperCase() === "PER ITEM" && flatData.rate > 0){
      this.checkoutInitialdataService.getCartItems().then(
        respData => {
          let res:any = respData;
          if(res){
            this.SummaryData.shippingCharges.free = false;
            this.SummaryData.shippingCharges.displayName = flatData.diaplayName;
            this.SummaryData.shippingCharges.amount = flatData.rate * this.checkoutInitialdataService.cartProducts.length;
            this.SummaryData.shippingCharges.show = true;
            
            this.updateAmounts();
            this.checkShippingTax();
          }
        }
      )
    }
    this.loaderService.emitComplete();
  }

  removeDeliveryCharges(){
    this.SummaryData.shippingCharges.show = false;
    this.SummaryData.shippingCharges.displayName = "";
    this.SummaryData.shippingCharges.amount = 0;
    this.SummaryData.shippingCharges.free = false;
    this.shippingTax = false;
    this.updateAmounts();
  }
  
  checkShippingTax(){
    this.checkoutSettingsService.getTaxSettings().then(
      respData => {
        let res:any = respData;
        if(res){
          if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
            this.shippingTax = true;
            this.updateAmounts();
          }
          else{
            this.shippingTax = false;
            this.updateAmounts();
          }
        }
      }
    )
  }

  removeDiscount(){
    if(this.SummaryData.discount.show){
      this.orderSummaryOrderService.orderObj.discountPercentage = 0;
      this.SummaryData.discount.amount = 0;
      this.orderSummaryOrderService.orderObj.discountAmount = 0;
      this.orderSummaryOrderService.orderObj.discountType = "";
      this.SummaryData.discount.displayName = "";
      this.SummaryData.discount.show = false;
      this.discountTax = false;
      this.bannerMsg = "";
      this.discountData.rate = 0;
      this.discountData.displaymsg = "";
      this.discountData.type = "";
      this.updateAmounts();
    }
  }

  checkDiscountTax(){
    this.checkoutSettingsService.getTaxSettings().then(
      respData => {
        let res:any = respData;
        if(res){
          if(this.checkoutSettingsService.taxCalculationSettings.apply_discount_on_prices.toUpperCase() === "EXCLUDING TAX"){
            this.discountTax = true;
            this.updateAmounts();
          }
          else{
            this.discountTax = false;
            this.updateAmounts();
          }
        }
      }
    )
  }

  updateAmounts(){
    this.loaderService.emitLoading("checkout");
    this.orderSummaryOrderService.setFreeShipping(this.SummaryData.shippingCharges.free);
    this.orderSummaryOrderService.orderObj.shippingCharges = this.SummaryData.shippingCharges.amount;
    // GET CART ITEMS
    this.checkoutInitialdataService.getCartItems().then(
      respData => {
        let res:any = respData;
        if(res){
          this.SummaryData.itemCount = this.checkoutInitialdataService.cartProducts.length;
          // SUBTOTAL
          this.checkoutCalculationsService.calculateSubTotal(this.checkoutInitialdataService.cartProducts).then(
            respData1 => {
              let res1:any = respData1;
              this.SummaryData.subTotal.amount = res1;
              // HANDLING FEE
              this.checkoutSettingsService.getShippingSettings().then(
                respData2 => {
                  let res2:any = respData2;
                  if(res){
                    this.SummaryData.handlingFee.displayName = this.checkoutSettingsService.initialHanglingFee.name;
                    this.checkoutCalculationsService.calculateHandlingFee(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.HanglingFee.type, this.HanglingFee.rate).then(
                      respData3 => {
                        let res3:any = respData3;
                        //this.SummaryData.handlingFee.amount = res3;
                        if(this.productlevelhandlingfee)
                        {
                          this.SummaryData.handlingFee.amount=Number(this.productlevelpackagecost);
                        }
                        else
                        {
                          this.SummaryData.handlingFee.amount = res3;
                        }
                        if(this.SummaryData.handlingFee.amount > 0){
                          this.SummaryData.handlingFee.show = true;
                        }
                        else{
                          this.SummaryData.handlingFee.show = false;
                        }
                        // TAX CALCULATION
                        this.checkoutSettingsService.getTaxSettings().then(
                          respData4=> {
                            let res4:any = respData4;
                            if(res4){
                              this.checkoutSettingsService.getTaxRates().then(
                                respData5 => {
                                  let res5:any = respData5;
                                  if(res5){
                                    this.checkoutTaxService.getProductTaxList(this.checkoutInitialdataService.cartProducts, this.checkoutSettingsService.taxclasses).then(
                                      respData6 => {
                                        let res6:any = respData6;
                                        this.checkoutCalculationsService.calculateTotalTax(res6, this.SummaryData.handlingFee.amount, this.checkoutSettingsService.handlingFeeTax.rate, this.SummaryData.discount.amount, this.SummaryData.shippingCharges.amount, this.discountTax, this.shippingTax, this.checkoutSettingsService.shippingTax.rate, this.checkoutSettingsService.productTax.rate).then(
                                          respData7 => {
                                            let res7:any = respData7;
                                            this.SummaryData.tax.amount = res7;
                                            this.SummaryData.total.amount = this.checkoutCalculationsService.calculateTotal(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.SummaryData.handlingFee.amount, this.SummaryData.shippingCharges.amount, this.SummaryData.tax.amount);
                                            if(this.SummaryData.total.amount > 0){
                                              this.SummaryData.total.show = true;
                                            }
                                            else{
                                              this.SummaryData.total.show = false;
                                            }
                                            this.loaderService.emitComplete();
                                            this.orderSummaryOrderService.orderSummary = {
                                              sub_total : this.SummaryData.subTotal.amount,
                                              packing_cost : this.SummaryData.handlingFee.amount,
                                              shipping_cost : this.SummaryData.shippingCharges.free ? 0 : this.SummaryData.shippingCharges.amount,
                                              tax : this.SummaryData.tax.amount,
                                              discount_amount: this.SummaryData.discount.amount,
                                              order_total_amount : this.SummaryData.total.amount,
                                              handling_display:this.SummaryData.handlingFee.displayName,
                                              discount_display:this.SummaryData.discount.displayName
                                            }
                                            this.updateAmounts1();                                           
                                          }
                                        )
                                      }
                                    )
                                  }
                                }
                              )
                            }
                          }
                        )
                      }
                    )
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  updateAmounts1(){
    this.checkeFreeShipping().then(
      respData => {
        let res:any = respData;
        if(res){
          this.loaderService.emitLoading("checkout");
          this.orderSummaryOrderService.setFreeShipping(this.SummaryData.shippingCharges.free);
          this.orderSummaryOrderService.orderObj.shippingCharges = this.SummaryData.shippingCharges.amount;
          // GET CART ITEMS
          this.checkoutInitialdataService.getCartItems().then(
            respData => {
              let res:any = respData;
              if(res){
                this.SummaryData.itemCount = this.checkoutInitialdataService.cartProducts.length;
                // SUBTOTAL
                this.checkoutCalculationsService.calculateSubTotal(this.checkoutInitialdataService.cartProducts).then(
                  respData1 => {
                    let res1:any = respData1;
                    this.SummaryData.subTotal.amount = res1;
                    // HANDLING FEE
                    this.checkoutSettingsService.getShippingSettings().then(
                      respData2 => {
                        let res2:any = respData2;
                        if(res){
                          this.SummaryData.handlingFee.displayName = this.checkoutSettingsService.initialHanglingFee.name;
                          this.checkoutCalculationsService.calculateHandlingFee(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.HanglingFee.type, this.HanglingFee.rate).then(
                            respData3 => {
                              let res3:any = respData3;
                             // this.SummaryData.handlingFee.amount = res3;
                             if(this.productlevelhandlingfee)
                             {
                               this.SummaryData.handlingFee.amount=Number(this.productlevelpackagecost);
                             }
                             else
                             {
                               this.SummaryData.handlingFee.amount = res3;
                             }
                              if(this.SummaryData.handlingFee.amount > 0){
                                this.SummaryData.handlingFee.show = true;
                              }
                              else{
                                this.SummaryData.handlingFee.show = false;
                              }
                              // TAX CALCULATION
                              this.checkoutSettingsService.getTaxSettings().then(
                                respData4=> {
                                  let res4:any = respData4;
                                  if(res4){
                                    this.checkoutSettingsService.getTaxRates().then(
                                      respData5 => {
                                        let res5:any = respData5;
                                        if(res5){
                                          this.checkoutTaxService.getProductTaxList(this.checkoutInitialdataService.cartProducts, this.checkoutSettingsService.taxclasses).then(
                                            respData6 => {
                                              let res6:any = respData6;
                                              this.checkoutCalculationsService.calculateTotalTax(res6, this.SummaryData.handlingFee.amount, this.checkoutSettingsService.handlingFeeTax.rate, this.SummaryData.discount.amount, this.SummaryData.shippingCharges.amount, this.discountTax, this.shippingTax, this.checkoutSettingsService.shippingTax.rate, this.checkoutSettingsService.productTax.rate).then(
                                                respData7 => {
                                                  let res7:any = respData7;
                                                  this.SummaryData.tax.amount = res7;
                                                  this.SummaryData.total.amount = this.checkoutCalculationsService.calculateTotal(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.SummaryData.handlingFee.amount, this.SummaryData.shippingCharges.amount, this.SummaryData.tax.amount);
                                                  if(this.SummaryData.total.amount > 0){
                                                    this.SummaryData.total.show = true;
                                                  }
                                                  else{
                                                    this.SummaryData.total.show = false;
                                                  }
                                                  this.loaderService.emitComplete();
                                                  this.orderSummaryOrderService.orderSummary = {
                                                    sub_total : this.SummaryData.subTotal.amount,
                                                    packing_cost : this.SummaryData.handlingFee.amount,
                                                    shipping_cost : this.SummaryData.shippingCharges.free ? 0 : this.SummaryData.shippingCharges.amount,
                                                    tax : this.SummaryData.tax.amount,
                                                    discount_amount: this.SummaryData.discount.amount,
                                                    order_total_amount : this.SummaryData.total.amount,
                                                    handling_display:this.SummaryData.handlingFee.displayName,
                                                    discount_display:this.SummaryData.discount.displayName
                                                  }                                           
                                                }
                                              )
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                          )
                        }
                      }
                    )
                  }
                )
              }
            }
          )
        }
      }
    )
  }

  checkeFreeShipping(){
    return new Promise((resolve, reject) => {
      if(this.minimumOrderAmount > 0){
        if(this.minimumOrderAmount <= (this.SummaryData.subTotal.amount - this.SummaryData.discount.amount)){
          this.SummaryData.shippingCharges.free = true;
          this.SummaryData.shippingCharges.displayName = "Delivery Charges";
          this.SummaryData.shippingCharges.show = true;
          
          this.SummaryData.shippingCharges.amount = 0;
          resolve(true);
        }
        else{
          // DELIVERY CHRAGES
          this.SummaryData.shippingCharges.free = false;
          this.checkoutShippingApiService.getDeliveryCharges().then(
            respData => {
              let res:any = respData;
              if(res.deliverType !== "Flat Rate"){
                this.SummaryData.shippingCharges.displayName = "Delivery Charges";
                this.SummaryData.shippingCharges.show = true;
                
                this.SummaryData.shippingCharges.amount = res.deliveryCharge;
                this.orderSummaryOrderService.orderObj.shippingMethodName = "";
                resolve(true);
              }
              else{
                this.checkoutShippingApiService.getFlatRateCharges().then(
                  respData1 => {
                    let res1:any = respData1;
                    let flatData: any = res1;
                    this.orderSummaryOrderService.orderObj.shippingMethodName = "Flat Rate";
                    if(flatData.type.toUpperCase() === "PER ORDER" && flatData.rate > 0){
                      this.SummaryData.shippingCharges.free = false;
                      this.SummaryData.shippingCharges.displayName = flatData.diaplayName;
                      this.SummaryData.shippingCharges.amount = flatData.rate;
                      this.SummaryData.shippingCharges.show = true;
                      
                      this.checkoutSettingsService.getTaxSettings().then(
                        respData => {
                          let res:any = respData;
                          if(res){
                            if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                              this.shippingTax = true;
                              resolve(true);
                            }
                            else{
                              this.shippingTax = false;
                              resolve(true);
                            }
                          }
                        }
                      )
                    }
                    else if(flatData.type.toUpperCase() === "PER ITEM" && flatData.rate > 0){
                      this.checkoutInitialdataService.getCartItems().then(
                        respData => {
                          let res:any = respData;
                          if(res){
                            this.SummaryData.shippingCharges.free = false;
                            this.SummaryData.shippingCharges.displayName = flatData.diaplayName;
                            this.SummaryData.shippingCharges.amount = flatData.rate * this.checkoutInitialdataService.cartProducts.length;
                            this.SummaryData.shippingCharges.show = true;
                            
                            this.checkoutSettingsService.getTaxSettings().then(
                              respData => {
                                let res:any = respData;
                                if(res){
                                  if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                    this.shippingTax = true;
                                    resolve(true);
                                  }
                                  else{
                                    this.shippingTax = false;
                                    resolve(true);
                                  }
                                }
                              }
                            )
                          }
                        }
                      )
                    }
                  })
              }
          })
        }
      }
      else{
        resolve(false);
      }
    })
    
  }

  getRestaurentdetailsforShipping(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.OUTLET).then(
        respData => {
          let res:any = respData;
          if(res.success){
            if(res.data.length > 0){
              resolve (res.data[0])
            }
          }
        }
      )
    })
  }

  fixedShipping(){
    this.checkoutShippingApiService.getDeliveryCharges().then(
      respData => {
        let res:any = respData;
        this.checkoutShippingOrderService.setShippingMethod(res.deliverType, res.deliveryCharge, "");
        this.SummaryData.shippingCharges.amount = res.deliveryCharge;
        if(res.deliverType !== "Flat Rate"){
          this.SummaryData.shippingCharges.displayName = "Delivery Charges";
          this.SummaryData.shippingCharges.show = true;
          
          this.SummaryData.shippingCharges.amount = res.deliveryCharge;
          this.orderSummaryOrderService.orderObj.shippingMethodName = "";
          this.orderSummaryOrderService.orderObj.shippingCharges = this.SummaryData.shippingCharges.amount;
          this.SummaryData.total.amount = this.checkoutCalculationsService.calculateTotal(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.SummaryData.handlingFee.amount, this.SummaryData.shippingCharges.amount, this.SummaryData.tax.amount);
          this.loaderService.emitComplete();
        }
        else{
          this.checkoutShippingApiService.getFlatRateCharges().then(
            respData1 => {
              let res1:any = respData1;
              let flatData: any = res1;
              this.orderSummaryOrderService.orderObj.shippingMethodName = "Flat Rate";
              if(flatData.type.toUpperCase() === "PER ORDER" && flatData.rate > 0){
                this.SummaryData.shippingCharges.free = false;
                this.SummaryData.shippingCharges.displayName = flatData.diaplayName;
                this.SummaryData.shippingCharges.amount = flatData.rate;
                this.SummaryData.shippingCharges.show = true;
                
                this.checkoutSettingsService.getTaxSettings().then(
                  respData => {
                    let res:any = respData;
                    if(res){
                      if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                        this.shippingTax = true;
                      }
                      else{
                        this.shippingTax = false;
                      }
                    }
                  }
                )
              }
              else if(flatData.type.toUpperCase() === "PER ITEM" && flatData.rate > 0){
                this.checkoutInitialdataService.getCartItems().then(
                  respData => {
                    let res:any = respData;
                    if(res){
                      this.SummaryData.shippingCharges.free = false;
                      this.SummaryData.shippingCharges.displayName = flatData.diaplayName;
                      this.SummaryData.shippingCharges.amount = flatData.rate * this.checkoutInitialdataService.cartProducts.length;
                      this.SummaryData.shippingCharges.show = true;
                      
                      this.checkoutSettingsService.getTaxSettings().then(
                        respData => {
                          let res:any = respData;
                          if(res){
                            if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                              this.shippingTax = true;
                            }
                            else{
                              this.shippingTax = false;
                            }
                          }
                        }
                      )
                    }
                  }
                )
              }
              this.loaderService.emitComplete();
            })
        }
    })
  }

}
