<!-- <section>
    <div class="jumbotron jumbotron-fluid">
        <div class="container">
            <div class="text-center">
                <h1>My Enquiry</h1>
                <div><span class="home-breadcrumb" routerLink="">Home</span>
                    <span> <i class="fa fa-long-arrow-right px-2"></i> My Enquiry</span>
                </div>
            </div>
        </div>
    </div>


    <div class="row justify-content-center">
        <div class="col-md-8 col-sm-12">
            <h4 class="pt-4">
                2 item(s) in your Enquiry.
            </h4>
                

            <div class="cart-table-container">
                <table class="cart-table table table-responsive">
                    <thead>
                        <th style="width: 3%;"></th>
                        <th style="width: 10%;" >Product Name</th>
                        <th style="width: 8%;" >SKU</th>
                        <th style="width: 6%;" >Brand</th>
                        <th style="width: 6%;">Status</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="cursor: pointer;">
                                
                            </td>
                            <td style="cursor: pointer;">
                               
                                <span style="float: left">
                                    <h5>Test</h5>
                                </span>
                            </td>
                            <td>
                               
                                <span style="float: left">
                                    <h5>0000</h5>
                                </span>
                            </td>
                            <td>
                               
                                <span style="float: left">
                                    <h5>Test</h5>
                                </span>
                            </td>
                            
                            <td>
                                <span style="float: left">
                                    <h5>New</h5>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <hr>
       
            <div class="row pt-3" style="width :100%;">

                    <button class="btn btn-primary inq-btn-st submit-action">CONTINUE TO SUBMIT ENQUIRY</button>
            </div>
            <br>
        </div>
    </div>
    </section> -->


    <main role="main">
        <div class="container">
            <br>
            <h4 class="text-primary">My Enquiry</h4>
            <div class="my-orders">
                <div class="box-orders" style="text-align: center;" *ngIf='!data.length'>
                    <h5>Details not found.</h5>
                </div>
                <div style="overflow-x:auto;">
                    <table class="table box-orders"  *ngIf='data.length'>
                        <thead>
                            <tr>
                                <th>Enquiry ID</th>
                                <th>Enquiry Date</th>
                                 <th>Quantity</th> 
                                <th>Enquiry Status</th>
                                <!-- <th></th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let list of data;let i=index">
                                <td style="cursor: pointer;" id={{list.id}}>{{list.id}}</td>
                                <td>{{list.created_on}}</td>
                                <!-- <td>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{list.order_review.order_summary.order_total_amount}}</td> -->
                                <td>{{list.total_quantity}}</td>
                                <td>{{list.status}}</td>
                                <!-- <td>
                                    <button class="btn btn-md btn-primary mb-1" *ngIf='list.system_label=="New"'
                                        (click)='cancelOrder(i)'>Cancel</button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
    
                <div class="row" *ngIf='total_count > 5'>
                    <div class="col-md-12 ml-auto mr-auto">
                        <div class="float-right">
                            <app-pagination [totalRecords]="total_count" [recordsPerPage]="5"
                                (onPageChange)="displayActivePage($event)"></app-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </main>