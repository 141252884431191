<section id="am-product">
<div class="content container-fluid">
    <div class="ampage-header">
        <div class="row">
            <div class="col-sm-12">
                <h3 class="am-page-title">Company Profile</h3>
                <div class="breadCrumbListing"><span class="home-breadcrumb" routerLink="">Home</span>
                    <span><i class="px-2">/</i> Profile</span>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0" *ngIf="product">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="profile-view">
                        <div class="profile-img-wrap">
                            <div class="profile-img">
                                <img style="object-fit: contain;width: auto;
                                height : auto;
                                max-height: 100%;
                                max-width: 100%;" [src]="product.company_profile.logo">
                            </div>
                        </div>
                        <div class="profile-basic">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="profile-info-left">
                                        <h3 class="user-name m-t-0 mb-0">{{product.name}}</h3>
                                        <h6 class="text-warning">{{product.categories[0].categories}}</h6>
                                        <ul class="personal-info">
                                            <li *ngIf="owner">
                                                <div class="ami-title">CEO/Owner:</div>
                                                <div class="ami-text">{{owner}}</div>
                                            </li>
                                            <li *ngIf="establised">
                                                <div class="ami-title">Established In:</div>
                                                <div class="ami-text">{{establised}}</div>
                                            </li>
                                            <li *ngIf="product.company_profile.nature_of_business">
                                                <div class="ami-title">Business:</div>
                                                <div class="ami-text">{{product.company_profile.nature_of_business}}</div>
                                            </li>
                                            <li *ngIf="product.company_profile.type_of_services">
                                                <div class="ami-title">Services:</div>
                                                <div class="ami-text">{{product.company_profile.type_of_services}}</div>
                                            </li>
                                            <li *ngIf="product.company_profile.specialized_in">
                                                <div class="ami-title">Specialization:</div>
                                                <div class="ami-text">{{product.company_profile.specialized_in}}</div>
                                            </li>
                                        </ul>
                                        <button class="btn btn-primary" style="border-radius: 5px !important; font-size: 1rem; padding: 5px 25px;margin-top: 30px;" (click)="submitEnquiry()">Submit Enquiry</button>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <ul class="personal-info">
                                        <li class="ai-mt">
                                            <div class="ami-title">Website:</div>
                                            <div class="ami-text"><a [attr.href]="'//' + product.contact_details.website" target="_blank" target="_blank">{{product.contact_details.website}}</a></div>
                                        </li>
                                        <li *ngIf="product.contact_details.landline" class="ai-mt">
                                            <div class="ami-title">Landline:</div>
                                            <div class="ami-text"><a href="tel:{{product.contact_details.landline}}">{{product.contact_details.landline}}</a></div>
                                        </li>
                                        <li *ngIf="product.contact_details.contact_person_name" class="ai-mt">
                                            <div class="ami-title">Contact:</div>
                                            <div class="ami-text">{{product.contact_details.contact_person_name}}</div>
                                        </li>
                                        <li *ngIf="product.contact_details.mobile" class="ai-mt">
                                            <div class="ami-title">Mobile:</div>
                                            <div class="ami-text"><a href="tel:{{product.contact_details.mobile}}">{{product.contact_details.mobile}}</a></div>
                                        </li>
                                        <li *ngIf="product.contact_details.email" class="ai-mt">
                                            <div class="ami-title">Email:</div>
                                            <div class="ami-text"><a href = "mailto: {{product.contact_details.email}}">{{product.contact_details.email}}</a></div>
                                        </li>
                                        <li *ngIf="product.company_details.location" class="ai-mt">
                                            <div class="ami-title">Company Location:</div>
                                            <div class="ami-text">{{product.company_details.location}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-3" *ngIf="product">
        <div class="card-body">
            <h3 class="amcard-tile" style="margin-bottom: 20px;">About Us</h3>
            <div class="row">
                <div class="col-md-3">
                    <div><img style="height: 200px; width: 300px;" src="https://aiema.net/wp-content/uploads/2017/10/DSC_0265.jpg"></div>
                </div>
                <div class="col-md-8" style="padding-left: 50px;">
                    <p class="text-muted">{{product.description.short_desc}}</p>
                </div>
            </div>
            <!-- <div class="d-flex justify-content-around">
                
                <p class="text-muted">{{product.description.short_desc}}</p>
            </div> -->
        </div>
    </div>
</div>
</section>
<div>
    <button id="launch-model" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal" data-target="#statusModel">
        Launch demo modal
    </button>
    <div class="modal fade" id="statusModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" style="max-width: 640px !important;position: relative !important;bottom: -30px !important; -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;-moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important; -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;">
            <div class="modal-content status-model-container">
                <div class="modal-body" style="border: none !important;">
                    <div class="model-content m-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <div class="title-container">
                            <div class="m-title">Enquiry Form</div>
                        </div>
                        <form [formGroup]="enquiryForm" class="gift-form" novalidate>
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <label for="name" class="form-label">Name <span class="required">*</span></label>
                                </div>
                                <div class="col-md-7">
                                    <input type="text" class="form-control" id="name"
                                        [class.is-invalid]="f.name.invalid && validateForm" formControlName="name" required>
                                    <div class="invalid-feedback" *ngIf="f.name.errors">
                                        <div *ngIf="f.name.errors.required">
                                            Name is required.
                                        </div>
                                        <div *ngIf="f.name.errors.maxlength">
                                            Name can't be more than 80 characters long.
                                        </div>
                                        <div *ngIf="f.name.errors.pattern">
                                            Name is invalid.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <label for="content" class="form-label">Email Address </label>
                                </div>
                                <div class="col-md-7">
                                    <input type="email" class="form-control" id="email_address"
                                        [class.is-invalid]="(f.email_address.invalid && validateForm)"
                                        formControlName="email_address" maxlength="50" email>
                                    <div class="invalid-feedback" *ngIf="f.email_address.errors">
                                        <div *ngIf="f.email_address.errors.maxlength">
                                            Seneder Email can't be more than 50 characters long.
                                        </div>
                                        <div *ngIf="f.email_address.errors.email || f.email_address.errors.pattern">
                                            Email must be a valid email address
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <label for="mobile_number" class="form-label">Mobile Number<span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-md-7">
                                    <input type="text" class="form-control" id="mobile_number"
                                        [class.is-invalid]="f.mobile_number.invalid && validateForm" formControlName="mobile_number"
                                        required>
                                    <div class="invalid-feedback" *ngIf="f.mobile_number.errors">
                                        <div *ngIf="f.mobile_number.errors.required">
                                            Mobile Number is required.
                                        </div>
                                        <div *ngIf="f.mobile_number.errors.maxlength">
                                            Mobile Number can't be more than 15 characters long.
                                        </div>
                                        <div *ngIf="f.mobile_number.errors.pattern">
                                            Mobile Number is invalid.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <label for="category" class="form-label">Category<span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-md-7">
                                    <input type="text" class="form-control" id="category"
                                        [class.is-invalid]="f.category.invalid && validateForm" formControlName="category"
                                        required readonly>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <label for="category" class="form-label">Message<span
                                            class="required">*</span></label>
                                </div>
                                <div class="col-md-7">
                                    <textarea class="form-control" id="message"
                                        [class.is-invalid]="f.message.invalid && validateForm" formControlName="message"
                                        required rows="5"></textarea>
                                </div>
                            </div>
                            <div style="margin-bottom: 45px;">
                                <button class="btn btn-primary" style="border-radius: 5px !important; font-size: 1rem; padding: 5px 25px;margin-top: 5px;float: right;">SUBMIT</button>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
