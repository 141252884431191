import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { AppService } from '../app.service';
import { ProductDetailsMainSlider, ProductDetailsThumbSlider } from '../shared/data/slider';
import * as metadata from '../../assets/data/default/product-details.json';
import { MetaDataMapService } from '../meta-data-map.service'
import { CartService } from '../cart-page/cart-page.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { AlertsService } from '../shared/alerts/alerts.service';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';
import {config} from 'src/configs/config';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { zip } from 'rxjs';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html'
})
export class ProductDetailsComponent implements AfterViewInit {

  config=config;
  totalPrice = 0;
  imageNotFound=config.noImageAvailable
  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;
  public activeSlide: any = 0;
  queryParams: any;
  newProductDetails: any;
  activeMenu: string
  currentRating = 0;
  dataMap = {};
  price: any;
  sizeVariable: any;
  respData: any;
  showPrice = false;
  checkVariantInCart: any;
  isValid = false;
  groups = [];
  isDisabled: boolean = false;
  showSize = false;
  variantName: any;
  public executionData=[];
  variants = [];
  priceOfVariant: any;
  showHint: any;
  generalInfo:boolean=false;
  addCart = false;
  descriptionData: any;
  innnerhtmlData: any;
  additionData:any;
  perPeice:any;
  isActive:any;
  public cartData = [];
  productType: any;
  showAlerts = false;
  productQuantity1 = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  tableHead=[];
  bulletPoints;
  congratulationsBannerMessage;
  eligibleBannerMessage=[];
  upsellBannerMessage;
  tableData=[];
  shortDescription:any;
  otherDescription:any;
  validateForm: boolean;
  mainImagePreview: any
  showImage: boolean = false;
  showVideo: boolean = false;
  image_preview: boolean = false;
  showInstruction: boolean =false;
  video_preview: boolean = false;
  reviews="0"
  imageAssets=[];
  availableLocation:boolean=false;
  notAvailableLocation:boolean=false;
  banner = "";
  promotionData:any;
  customOptions: OwlOptions = {
    autoplay: false,
    loop: false,
    navSpeed: 300,
    stagePadding: 130,
    navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      10: {
        items: 2
      },
      20: {
        items: 3
      },


    },
    nav: true,
  }
  instructionDesc:any;
  ImageVideoPreview = [];
  mainImage: any
  mainViewVideo: any;
  displayURL: any;
  attributeTitle:any;
  showData:boolean=false;
  productId:any;
  detailForm:FormGroup;
  numPattern = /^[0-9]*$/;
  showSpinner:boolean = false;
  locationName:string;
  courierObj:any;
  courierName:any;
  estdDelivery:any;
  showStorage:boolean=false;
  public category;
  newActive;
  public priceStrikeOutInDetailScreen;
  public showPricekey;
  public strikeOutPriceKey;
  public congratulationBanner;
  public upsellBanner;
  public percentageValue;
  public fixedAmount;
  public discountValue;
  public promotionType;
  public productQuantity=0;
  public onloadId;
  public onloadCost;
  public onloadQuantity;
  public newId;

  
  constructor(
    public appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public metaDataMapService: MetaDataMapService,
    private cartService: CartService,
    private localService: LocalStorageService,
    private alertService: AlertsService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,


  ) { }
  themes:any;
  ngAfterViewInit(): void {
    this.changeTab(this.groups[5].fields[0].labelname)

  }
  staticAttributes = [
    {
      attr_name: "Category",
      attr_value: "-"
    },
    {
      attr_name: "Brand",
      attr_value: "-"
    },
    {
      attr_name: "SKU",
      attr_value: "-"
    },
    {
      attr_name: "Sale Price",
      attr_value: "-"
    },
    {
      attr_name: "Stock Status",
      attr_value: "-"
    }
  ]
ngOnInit(): void {

  this.detailForm = new FormGroup({
    zipcode: this.formBuilder.control('',[Validators.minLength(6),Validators.maxLength(6),Validators.pattern(this.numPattern,)])
  })
  this.newActive = 0;
    this.cartService.updateCartItem(this.appService.cartProducts.results)
    this.groups = metadata['default'].groups;
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.queryParams = this.activatedRoute.snapshot;
      this.productId=this.queryParams.params['id'];
      if (this.queryParams.params['id']) {
        let requestBody = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
            "find": {
              "id": Number(this.queryParams.params['id'])
            },
            "pagination": false,
            "paginationDetails": {
              "limit": 0,
              "pageSize": 2
            },
            "sorting": true,
            "sortingDetails": {
              "sortfield": "",
              "sortorder": 1
            }
          }
        }
        this.getProductDetailsById(requestBody)
        }
    })
    for (let i = 0; i < this.appService.cartProducts.results.length; i++) {
    this.cartData.push(this.appService.cartProducts.results[i].product_id);
    }
    console.log("this.appService.cartProducts.results"+JSON.stringify(this.appService.cartProducts.results))
    if(ORG_DETAILS.NAME==='Deera'){
    for (let j = 0; j < this.appService.cartProducts.results.length; j++) {
      console.log("this.newProductDetails.id"+this.queryParams.params['id'])
      console.log("this.appService.cartProducts.results[j].product_id"+this.appService.cartProducts.results[j].product_id)
      if(this.appService.cartProducts.results[j].product_id===Number(this.queryParams.params['id'])){
        console.log("insideee")
        this.onloadId = this.appService.cartProducts.results[j].product_id;
        this.onloadCost = this.appService.cartProducts.results[j].product.pricing.raw.cost;
        console.log("this.appService.cartProducts.results[j].product.quantity"+this.appService.cartProducts.results[j].quantity)
        this.onloadQuantity = this.appService.cartProducts.results[j].quantity;
      }
      }

   
      let tempArray = this.appService.cartProducts.results;
      let obj =
      {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
              "find": {
                "product_id": Number(this.onloadId),
                "product_cost": Number(this.onloadCost),
                "quantity": Number(this.onloadQuantity)
              }
          }
        }
    
      this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
        resp => {
          if (resp) {
            if (resp.success === 1 && resp.status_code === 200) {
              for (let k = 0; k < this.appService.cartProducts.results.length; k++) {
                console.log("tempArray[index].quantity"+this.appService.cartProducts.results[k].product.id);
              if(this.appService.cartProducts.results[k].product.id===Number(this.queryParams.params['id'])){
              this.appService.cartProducts.results[k].product.discount_value =  Number(resp.result.data.discount_applied_to_product_value);
              this.appService.cartProducts.results[k].product.discount_amount =  Number(resp.result.data.discount_amount);
              this.appService.cartProducts.results[k].product.fixed_amount =  Number(resp.result.data.fixed_amount);
              this.appService.cartProducts.results[k].product.discount_percentage = Number(resp.result.data.discount_percentage);
              this.congratulationBanner = resp.result.data.congratulations_banner_message;
              // this.upsellBanner = resp.result.data.upsell_banner_message;
              this.percentageValue = Number(resp.result.data.discount_percentage);
              this.fixedAmount = Number(resp.result.data.fixed_amount);
              this.discountValue = Number(resp.result.data.discount_applied_to_product_value);
              this.promotionType = resp.result.data.promotion_type;
              this.productQuantity = Number(this.appService.cartProducts.results[k].quantity);
             
                console.log("trueeee")
                this.productQuantity1 = true;
            }
          }

              // console.log("productQuantity"+this.productQuantity);
              // this.productQuantity1 = true;
              console.log("deeraaaa33"+JSON.stringify( this.appService.cartProducts.results))
            }
            else if (resp.success === 0) {
              if (resp.message) {
              }
            }
            else if (resp.message && resp.status_code !== 200) {
    
            }
            else {
            }
          }
        },
        err => {
          if (err.error.statusCode === 403) {
    
          }
          else if (err.error.error.message) {
          }
          else {
          }
        }
      )
  
    }

    this.priceStrikeOutInDetailScreen = this.localService.get('price_strikeout_in_detail_screen');
    this.showPricekey = this.localService.get('show_price_key');
    this.strikeOutPriceKey = this.localService.get('strike_out_price_key');
  }
  get f(){
    return this.detailForm.controls;
  }
  imagePreview(i,index) {
    this.image_preview = true;
    this.video_preview = false;
    this.mainImagePreview = i.image_source;
    console.log("indexxxx"+index)
    this.isActive=index
    this.newActive = index
  }
  videoPreview(videoUrl) {
    this.video_preview = true;
    this.image_preview = false;
    this.mainViewVideo = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl.link)
    document.getElementById('launch-video1').click();
  }
  finndBroken() {
    this.newProductDetails.assets[0].image_source = this.imageNotFound

  }
  increaseItem(index)
{
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0

  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  
  if(ORG_DETAILS.NAME==='ShubhCards')
  {
      
    tempArray[index].quantity = (Number(tempArray[index].quantity) + tempArray[index].product.pricing.raw.incrementvalue)
  }
  else{
    if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  { 
    this.showAlerts = true;
    window.scrollTo(0,0);
    // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    if(config.title==='BombayHardware')
    {
      this.alertService.error("Maximum allowed quantity is 1 only",this.options)
    } else if(config.title==='Deera'){
      tempArray[index].quantity = 0;
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    else {
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    return
  }
    tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
  }
  if(ORG_DETAILS.NAME==='Deera'){
    let obj =
    {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
              "product_id": Number(tempArray[index].product_id),
              "product_cost": Number(tempArray[index].cost),
              "quantity": Number(tempArray[index].quantity)
            }
        }
      }
  
    this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
      resp => {
        if (resp) {
          if (resp.success === 1 && resp.status_code === 200) {
            console.log("tempArray[index].quantity"+tempArray[index].product.id);
            tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
            tempArray[index].product.discount_amount = resp.result.data.discount_amount;
            tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
            tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
            this.congratulationBanner = resp.result.data.congratulations_banner_message;
            // this.upsellBanner = resp.result.data.upsell_banner_message;
            this.percentageValue = Number(resp.result.data.discount_percentage);
            this.fixedAmount = Number(resp.result.data.fixed_amount);
            this.discountValue = Number(resp.result.data.discount_applied_to_product_value);
            this.promotionType = resp.result.data.promotion_type;
            this.productQuantity = Number(tempArray[index].quantity);
            console.log("Number(tempArray[index].quantity)"+Number(tempArray[index].quantity))
            console.log("productQuantity"+this.productQuantity)
            this.productQuantity1 = true;
            if(resp.result.data.discount_percentage>0)	
            {	
              this.appService.cartProducts.results[index]['fixed_amount'] = resp.result.data.discount_amount;	
            }	
            else{	
              this.appService.cartProducts.results[index]['fixed_amount'] =  resp.result.data.fixed_amount;	
            }	
            this.cartService.updateCartItem(this.appService.cartProducts.results);	
console.log("*******************************************************************^%^$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")

          }
          else if (resp.success === 0) {
            if (resp.message) {
              //this.alertService.error(resp.message, this.options);
            }
          }
          else if (resp.message && resp.status_code !== 200) {
            //this.alertService.error(resp.message, this.options);
  
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          //this.alertService.error('Session Time Out! Please login Again', this.options)
  
        }
        else if (err.error.error.message) {
          //this.alertService.error(err.error.error.message, this.options)
        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
  }

  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
  if(ORG_DETAILS.NAME==='Deera'){
    let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
    this.localService.set("cart", cartItemsString);
    }
}
ChangeValue(index)
{
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  var moq=tempArray[index].product.pricing.raw.minimumorderquantity;
  if(ORG_DETAILS.NAME==='ShubhCards')
  {
    //let tempArray = this.appService.cartProducts.results;
    if(this.appService.cartProducts.results[index].quantity  < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.cartData = [];
        for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
          this.cartData.push(this.appService.cartProducts.results[i].product_id);
        }
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        this.showAlerts = true;
        window.scrollTo(0,0);
        this.alertService.error("Minimum Order Quantity for this product is "+moq,this.options);
        return
        
    }
    tempArray[index].quantity = (Number(tempArray[index].quantity));
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.updateCartItem(this.appService.cartProducts.results);  
  }
}


changeDeeraValue(index)
{
  console.log("indexxxx"+index)
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0

  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  { 
    this.showAlerts = true;
    window.scrollTo(0,0);
    // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    if(config.title==='BombayHardware')
    {
      this.alertService.error("Maximum allowed quantity is 1 only",this.options)
    } else if(config.title==='Deera'){
      tempArray[index].quantity = 0;
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    } 
    else {
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    return
  }
    // tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
    if(this.appService.cartProducts.results[index].quantity  < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.cartData = [];
        for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
          this.cartData.push(this.appService.cartProducts.results[i].product_id);
        }
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        if(config.title==='Deera'){
          let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
          this.localService.set("cart", cartItemsString);
          }
        return
        
    }
  if(ORG_DETAILS.NAME==='Deera')
  {
    //let tempArray = this.appService.cartProducts.results;
    let obj =
    {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
              "product_id": Number(tempArray[index].product_id),
              "product_cost": Number(tempArray[index].cost),
              "quantity": Number(tempArray[index].quantity)
            }
        }
      }
  
    this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
      resp => {
        if (resp) {
          if (resp.success === 1 && resp.status_code === 200) {
            console.log("tempArray[index].quantity"+tempArray[index].product.id);
            tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
            tempArray[index].product.discount_amount = resp.result.data.discount_amount;
            tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
            tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
            this.congratulationBanner = resp.result.data.congratulations_banner_message;
            // this.upsellBanner = resp.result.data.upsell_banner_message;
            this.percentageValue = Number(resp.result.data.discount_percentage);
            this.fixedAmount = Number(resp.result.data.fixed_amount);
            this.discountValue = Number(resp.result.data.discount_applied_to_product_value);
            this.promotionType = resp.result.data.promotion_type;
            this.productQuantity = Number(tempArray[index].quantity);
            console.log("Number(tempArray[index].quantity)"+Number(tempArray[index].quantity))
            console.log("productQuantity"+this.productQuantity)
            this.productQuantity1 = true;
            if(resp.result.data.discount_percentage>0)	
            {	
              this.appService.cartProducts.results[index]['fixed_amount'] = resp.result.data.discount_amount;	
            }	
            else{	
              this.appService.cartProducts.results[index]['fixed_amount'] =  resp.result.data.fixed_amount;	
            }	
            this.cartService.updateCartItem(this.appService.cartProducts.results);	
console.log("*******************************************************************^%^$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")

          }
          else if (resp.success === 0) {
            if (resp.message) {
              //this.alertService.error(resp.message, this.options);
            }
          }
          else if (resp.message && resp.status_code !== 200) {
            //this.alertService.error(resp.message, this.options);
  
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          //this.alertService.error('Session Time Out! Please login Again', this.options)
  
        }
        else if (err.error.error.message) {
          //this.alertService.error(err.error.error.message, this.options)
        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )

    // tempArray[index].quantity = (Number(tempArray[index].quantity));
    // tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    // this.appService.cartProducts.results = tempArray;
    // this.appService.totalCartItems = this.cartService.totalCartItems();
    // this.cartService.updateCartItem(this.appService.cartProducts.results);  
    // tempArray[index].quantity = (Number(tempArray[index].quantity));
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    if(ORG_DETAILS.NAME==='Deera'){
    let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
    this.localService.set("cart", cartItemsString);
    }
  }
}


decreaseItem(index)
{
  let tempArray = this.appService.cartProducts.results;
  if(ORG_DETAILS.NAME==='ShubhCards')
  {

    if(this.appService.cartProducts.results[index].quantity- tempArray[index].product.pricing.raw.decrementvalue < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.cartData = [];
        for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
          this.cartData.push(this.appService.cartProducts.results[i].product_id);
        }
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        return
        
    }

    tempArray[index].quantity = (Number(tempArray[index].quantity) - tempArray[index].product.pricing.raw.decrementvalue)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.updateCartItem(this.appService.cartProducts.results);

  }

  else{
    if(this.appService.cartProducts.results[index].quantity === 1 || this.appService.cartProducts.results[index].quantity  < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
      console.log("decrement111")
        this.appService.cartProducts.results.splice(index,1);
        this.cartData = [];
        if(ORG_DETAILS.NAME==='Deera'){
          // this.appService.cartProducts.results[index].quantity = 0;
          // this.appService.cartProducts.results[index].discount_value = 0;
          // this.appService.cartProducts.results[index].discount_percentage = 0;
          // this.appService.cartProducts.results[index].discount_amount = 0;
          // this.appService.cartProducts.results[index].fixed_amount = 0;
          // this.upsellBanner = "";
          this.congratulationBanner = "";
          this.percentageValue = 0;
          this.fixedAmount = 0;
          this.discountValue = 0;
          this.promotionType = 0;
          this.productQuantity = 0;
        }
        for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
          this.cartData.push(this.appService.cartProducts.results[i].product_id);
        }
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        if(ORG_DETAILS.NAME==='Deera'){
          let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
          this.localService.set("cart", cartItemsString);
          }
        return
        
    }

    tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    console.log("decrement222")
    if(ORG_DETAILS.NAME==='Deera'){
      let obj =
      {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
              "find": {
                "product_id": Number(tempArray[index].product_id),
                "product_cost": Number(tempArray[index].cost),
                "quantity": Number(tempArray[index].quantity)
              }
          }
        }
    
      this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
        resp => {
          if (resp) {
            if (resp.success === 1 && resp.status_code === 200) {
              console.log("tempArray[index].quantity"+tempArray[index].product.id);
              tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
              tempArray[index].product.discount_amount = resp.result.data.discount_amount;
              tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
              tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
              this.congratulationBanner = resp.result.data.congratulations_banner_message;
              // this.upsellBanner = resp.result.data.upsell_banner_message;
              this.percentageValue = Number(resp.result.data.discount_percentage);
              this.fixedAmount = Number(resp.result.data.fixed_amount);
              this.discountValue = Number(resp.result.data.discount_applied_to_product_value);
              this.promotionType = resp.result.data.promotion_type;
              this.productQuantity = Number(tempArray[index].quantity);
              console.log("Number(tempArray[index].quantity)"+Number(tempArray[index].quantity))
              console.log("productQuantity"+this.productQuantity)
              this.productQuantity1 = true;
              this.cartService.updateCartItem(this.appService.cartProducts.results);	
            }
            else if (resp.success === 0) {
              if (resp.message) {
                //this.alertService.error(resp.message, this.options);
              }
            }
            else if (resp.message && resp.status_code !== 200) {
              //this.alertService.error(resp.message, this.options);
    
            }
            else {
              //this.alertService.error('Something bad happened. Please try again!', this.options);
            }
          }
        },
        err => {
          if (err.error.statusCode === 403) {
            //this.alertService.error('Session Time Out! Please login Again', this.options)
    
          }
          else if (err.error.error.message) {
            //this.alertService.error(err.error.error.message, this.options)
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }
    this.appService.cartProducts.results = tempArray;
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    if(ORG_DETAILS.NAME==='Deera'){
      let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
      this.localService.set("cart", cartItemsString);
      }

  }
  
}

  onConfirm(items) {
    this.sizeVariable = items;
    this.isValid = true
    this.showPrice = true
    this.isDisabled = false;
    this.activeMenu = items.option
    this.checkVariantInCart = items.id
    this.priceOfVariant = items.pricing.raw.price


  }
  changeTab(tab) {
    document.getElementById("nav-" + tab + "tab").setAttribute("class", "nav-item nav-link active")
    document.getElementById("nav-" + tab).setAttribute("class", "tab-pane fade show active")

    this.groups[5].fields.forEach(element => {
      if (element.labelname != tab) {
        document.getElementById("nav-" + element.labelname + "tab").setAttribute("class", "nav-item nav-link")
        document.getElementById("nav-" + element.labelname).setAttribute("class", "tab-pane fade")
      }
    });
  }
  productListing(){
		//this.router.navigate([`/productListing`], { queryParams: {term:'', type: 'last90days' }, relativeTo: this.activatedRoute, skipLocationChange: false });
    if(config.title==='Tams'){
      // this.router.navigate([`/product`], { queryParams: { }, relativeTo: this.activatedRoute, skipLocationChange: false });
      this.router.navigate([`/product`], { queryParams: { terms: [this.category, 'category'], type: 'searchresults' }, relativeTo: this.activatedRoute, skipLocationChange: false });
    } else {
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.activatedRoute,  skipLocationChange: false });

    }
	
    }

    checkServiceAvailablity(){

    if(this.detailForm.valid){
    this.showSpinner=true;
    let zipcode = this.detailForm.controls.zipcode.value;
     let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {
        "courier_serviceability": {
            "pickup_postcode": 600005,
            "delivery_postcode": zipcode,
            "cod": 0,
            "weight": 1
        }
        }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.DELIVERY_AVAILABILITY).subscribe(
      resp=>{
        if(resp){
          if(resp.success == 1){
            if(resp.result.data.status == 200){
            let recommended_id = resp.result.data.data.recommended_courier_company_id;
          
            resp.result.data.data.available_courier_companies.forEach(element => {
              if(element.courier_company_id == recommended_id && element.blocked == 0){
                this.courierObj = element;
                this.courierName = this.courierObj.courier_name;
                this.estdDelivery = this.courierObj.etd;
                this.showSpinner=false;
                this.availableLocation = true;
                this.notAvailableLocation = false;
                return;
                
                
              }
            
            });
          }else{
            this.showSpinner=false;
            this.notAvailableLocation = true;
            this.availableLocation=false;
          }
          }else{
            this.showSpinner=false;
            this.notAvailableLocation = true;
            this.availableLocation=false;
          }
        }
      },
      err => {
        console.log("ERROR:", err);
        if (err.error.message) {
          console.log(err.error.message);
        }
        else {
          console.log(err.error.message);
        }
      }
    )}else{
      this.notAvailableLocation = false;
      this.availableLocation=false;
      this.showSpinner=false;
      this.validateForm=true;
     
    }
}
    checkZipcode(){
      
      if(this.detailForm.valid){
        this.showSpinner=true;
    let zipcode = this.detailForm.controls.zipcode.value;
     let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {
        "service_availability": {
        "filter_codes": zipcode
        }
        }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.SERVICE_AVAILABILITY).subscribe(
      
      resp=>{
        
        
        if(resp){
         
          console.log(resp)
          if(resp.success == 1){
      
            
            if(resp.result.data && resp.result.data.delivery_codes && resp.result.data.delivery_codes.length>0){
              resp.result.data.delivery_codes.forEach(element => {
              if(element.postal_code.pin == zipcode && element.postal_code.repl == 'Y'){
                this.locationName=element.postal_code.inc;
                this.showSpinner=false;
                this.availableLocation = true;
                this.notAvailableLocation = false;
              }else{
                this.showSpinner=false;
                this.notAvailableLocation = true;
                this.availableLocation=false;
              }
              });
            
              

            }else{
              this.showSpinner=false;
              this.notAvailableLocation = true;
              this.availableLocation=false;
            }
          }
        }
      },
      err => {
        console.log("ERROR:", err);
        if (err.error.message) {
          console.log(err.error.message);
        }
        else {
          console.log(err.error.message);
        }
      }
    )
      }else{
        this.notAvailableLocation = false;
        this.availableLocation=false;
        this.showSpinner=false;
        this.validateForm=true;
       
      }
    }

  getProductDetailsById(requestBody: any) {
    // document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.appService.postApiCall(requestBody, ENDPOINTS.PRODUCT_DETAILS).subscribe(
      resp => {
        if (resp) {
          console.log(resp)

            if (resp.success == 1) {
          this.newProductDetails = resp.result.data[0];
          this.newId = resp.result.data[0].id;

          // if(this.config.title==='Deera'){
          //  if (resp.result.data[0].quantity===0){
          //     this.productQuantity1 =true;
          //  }
          // }
            this.imageAssets=resp.result.data[0].assets;
            this.category = resp.result.data[0].categories[0].categories;
            console.log("this.category"+this.category)
            

            if(this.newProductDetails.assets[0].image_source == ''){
              this.mainImage=this.imageNotFound;
              this.mainImagePreview=this.imageNotFound;
            }
            else{
            this.mainImage = this.newProductDetails.assets[0].image_source;

            }
           

            if(this.newProductDetails.videos!=undefined && this.newProductDetails.videos!=""){
            this.newProductDetails.videos.forEach(element => {
              let tempVariableVideo = element
              // tempVariableVideo["type"]="video"
              tempVariableVideo["safeLink"] = this.sanitizer.bypassSecurityTrustResourceUrl(element.link)
              this.ImageVideoPreview.push(tempVariableVideo)
            });
          }
          if(config.title!=='Tams' && config.title!=='Deera' && config.title!=='Haven'){
            if(this.newProductDetails.theme!=undefined && this.newProductDetails.theme!=""){
              this.newProductDetails.theme.forEach(element => {
                
                this.themes=this.themes+','+element.name;
              });
            }
            this.themes=this.themes.replace("undefined,", "");
          }

            this.respData = resp.result.data[0].description.description
            let shortDesc = resp.result.data[0].description.short_desc.replace(/\\n/g, '');
            this.shortDescription=shortDesc.replace(/\\s/g, '');
            if(resp.result.data[0].description.other_description){
            let otherDesc = resp.result.data[0].description.other_description.replace(/\\n/g, '');
            this.otherDescription=otherDesc.replace(/\\s/g, '');
            }
            this.instructionDesc = resp.result.data[0].description.instruction_description;
            // let bulletpoints = resp.result.data[0].description.bullet_points.replace(/\\n/g, '');
            // this.bulletPoints=bulletpoints.replace(/\\s/g, '');
            this.bulletPoints= resp.result.data[0].description.bullet_points;
            // this.eligibleBannerMessage= resp.result.data[0].discounts_text;
            if(resp.result.data[0].discounts_text){
              resp.result.data[0].discounts_text.forEach(element => {
                 
                 console.log("111111"+new Date(element.start_date))
                 console.log("222222"+new Date())
                 console.log("111111"+new Date(element.end_date))
                //  if(new Date(element.start_date)==new Date() || new Date(element.end_date)==new Date()){
                //   if(new Date(element.start_date).getDate()==new Date().getDate() || new Date(element.end_date).getDate()==new Date().getDate()){
                
                //   const now =  new Date().getHours() + ':' + new Date().getMinutes();
                //   var standarddate = '01/01/2011'
                //   console.log("format"+ (standarddate+''+element.start_time))


                //   console.log("correct1"+ (Date.parse('01/01/2011 10:20') > Date.parse('01/01/2011 5:10')))
                //   console.log("correct2"+ (Date.parse(standarddate+''+element.start_time) > Date.parse(standarddate+''+now)))
                
                //   console.log("000"+(element.end_time<now))
                //   if(element.start_time>=now && element.end_time<=now){
                //     console.log("000"+(element.eligible_banner_message))
                //     this.eligibleBannerMessage.push(element.eligible_banner_message)
                //   }
                //  }
                  // if(new Date(element.start_date)>=new Date() && new Date(element.end_date)<=new Date()){
                    if(new Date()>= new Date(element.start_date) && new Date() <= new Date(element.end_date)){
                  // if(new Date("2022-12-31")<=new Date() && new Date("2022-12-31")>=new Date()){
                  console.log("444"+(element.eligible_banner_message))
                  this.eligibleBannerMessage.push(element.eligible_banner_message)
                 }
              })
            }
            // this.congratulationsBannerMessage= resp.result.data[0].discounts_text.congratulations_banner_message;
            // this.upsellBannerMessage= resp.result.data[0].discounts_text.upsell_banner_message;
            if(resp.result.data[0].discounts_text){
              resp.result.data[0].discounts_text.forEach(element => {
                if(element.upsell_banner_message){
                  console.log("upselllll"+element.upsell_banner_message)
                  this.upsellBannerMessage= element.upsell_banner_message;
                }
              })
            }
            this.innnerhtmlData = this.respData.replace(/\\n/g, '');
            this.innnerhtmlData = this.innnerhtmlData.replace(/\\s/g, '')
            this.additionData=resp.result.data[0].description.bullet_points;
            this.perPeice = resp.result.data[0].pricing.raw.price / resp.result.data[0].unit;
          //to check producttype is varaible//
            if (this.newProductDetails.product_type == "Variable" || this.newProductDetails.type == "Variable" )  {
              this.showSize = true;
              this.variantName = this.newProductDetails.variant !=undefined? this.newProductDetails.variant.name : "" ;
              this.showHint = true;
              this.isDisabled = true;
            }
            else {
              this.showSize = false;
              this.showHint = false;
              this.isDisabled = false
            }
          //to get options from variants//
        if (  resp.result.data[0].variant!=undefined && resp.result.data[0].variant.option_values.length > 0) {
         this.variants = resp.result.data[0].variant.option_values;
        }
          //reviews//
            this.currentRating=this.newProductDetails.reviews !=undefined ? this.newProductDetails.reviews.average: "0";
            this.reviews=this.newProductDetails.reviews !=undefined ? this.newProductDetails.reviews.total_reviews:"0";
            
            if( this.newProductDetails.additional_info !=undefined &&  this.newProductDetails.additional_info !=""){
              this.showData=true;
            }else{
              this.showData=false;
            }
            if( this.newProductDetails.additional_info.how_to_store !=undefined &&  this.newProductDetails.additional_info.how_to_store !=""){
              this.showStorage=true;
            }else{
              this.showStorage=false;
            }
            if(this.newProductDetails.attributes!=undefined && this.newProductDetails.attributes[0].section_title!=undefined){
            this.attributeTitle = this.newProductDetails.attributes[0].section_title;
            this.newProductDetails.attributes[0].header_name.split("-").forEach(element => {
              this.tableHead.push(element)
            });
          
          this.tableHead.push(this.newProductDetails.attributes[0].header_value)
          this.newProductDetails.attributes.forEach(element => {
            
            element.attr.forEach(element => {
              let tempArray=[]
              element.attr_name.split("/").forEach(elementTwo => {
                tempArray.push(elementTwo)
              });
              tempArray.push(element.attr_value)
              this.tableData.push(tempArray)
            });
          //  element.attr[0].attr_name.split("/").forEach(elementtwo => {
          //    tempArray.push(elementtwo)

          //  });
          //  tempArray.push(element.attr[0].attr_value)
          //  this.tableData.push(tempArray)
          });

        }
          this.descriptionData = this.newProductDetails.categories[0].categories;
          console.log("description", this.descriptionData)
          this.productType = resp.result.data[0].type;
              
            if(this.newProductDetails.hasOwnProperty('description')&& this.newProductDetails.description.instruction_title != "" && this.newProductDetails.description.instruction_description !== undefined ){
                this.showInstruction=true;
              }
              else{
                this.showInstruction=false;
              }
              if (this.newProductDetails.pricing.raw.price == "") {
              this.price = this.newProductDetails.pricing.raw.price_range
            }
            else {
              this.price = this.newProductDetails.pricing.raw.price
            }
            this.setStaticAttributes();
          }
          else {
            console.log(resp.message);
          }
        }
      },
      err => {
        console.log("ERROR:", err);
        if (err.error.message) {
          console.log(err.error.message);
        }
        else {
          console.log(err.error.message);
        }
      }
    )
  }
setStaticAttributes() {
    if (this.newProductDetails.categories) {
      if (this.newProductDetails.categories.length > 0) {
        this.staticAttributes[0].attr_value = this.newProductDetails.categories[0].categories;
        this.descriptionData = this.newProductDetails.categories[0].categories;

      }
    }
    if (this.newProductDetails.brand) {
      this.staticAttributes[1].attr_value = this.newProductDetails.brand.name;
    }
    this.staticAttributes[2].attr_value = this.newProductDetails.sku;
  }
  addToCarts(item)
{
  this.cartService.addToCart(item);
  for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
  this.cartData.push(this.appService.cartProducts.results[i].product_id);
  }

}
 addToCart() {
  if (this.showSize) {
  if (this.newProductDetails.hasOwnProperty('variant')) {
        // item.pricing.raw.price=this.priceOfVariant
        console.log("deeraaaa11")
        let cartdetails = this.sizeVariable;
        cartdetails.brand = { name: "" }
        cartdetails.categories = [];
        cartdetails.assets = this.newProductDetails.assets
        cartdetails.name = this.newProductDetails.name + ' - ' + this.sizeVariable.option
        cartdetails.parentcategories = this.newProductDetails.parentcategories;
        this.cartService.addToCart(cartdetails)
      }
      else {
        console.log("deeraaaa22")
        this.cartService.addToCart(this.newProductDetails)
      }
    }
    else {
      console.log("deeraaaa33")
      this.cartService.addToCart(this.newProductDetails);
      }
    this.addCart = true
    console.log("deeraaaa44")
    for (let i = 0; i < this.appService.cartProducts.results.length; i++) {
      this.cartData.push(this.appService.cartProducts.results[i].product_id);
    }
      if(ORG_DETAILS.NAME==='Deera'){
        let tempArray = this.appService.cartProducts.results;
        let obj =
        // {
        //     "domain_name": ORG_DETAILS.DOMAIN_NAME,
        //     "user_id": ORG_DETAILS.USER_ID,
        //     "extras": {
        //         "find": {
        //           "product_id": Number(this.appService.cartProducts.results[i].product_id),
        //           "product_cost": Number(this.appService.cartProducts.results[i].cost),
        //           "quantity": Number(this.appService.cartProducts.results[i].quantity)
        //         }
        //     }
        //   }

          {
            "domain_name": ORG_DETAILS.DOMAIN_NAME,
            "user_id": ORG_DETAILS.USER_ID,
            "extras": {
                "find": {
                  "product_id": Number(this.newProductDetails.id),
                  "product_cost": Number(this.newProductDetails.pricing.raw.cost),
                  "quantity": 1
                }
            }
          }
      
        this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
          resp => {
            if (resp) {
              if (resp.success === 1 && resp.status_code === 200) {
                for (let j = 0; j < this.appService.cartProducts.results.length; j++) {
                  console.log("tempArray[index].quantity"+this.appService.cartProducts.results[j].product.id);
                  if(this.appService.cartProducts.results[j].product.id===this.newProductDetails.id){
                    this.appService.cartProducts.results[j].product.discount_value =  Number(resp.result.data.discount_applied_to_product_value);
                    this.appService.cartProducts.results[j].product.discount_amount =  Number(resp.result.data.discount_amount);
                    this.appService.cartProducts.results[j].product.fixed_amount =  Number(resp.result.data.fixed_amount);
                    this.appService.cartProducts.results[j].product.discount_percentage = Number(resp.result.data.discount_percentage);
                    this.congratulationBanner = resp.result.data.congratulations_banner_message;
                    this.upsellBanner = resp.result.data.upsell_banner_message;
                    this.percentageValue = Number(resp.result.data.discount_percentage);
                    this.fixedAmount = Number(resp.result.data.fixed_amount);
                    this.discountValue = Number(resp.result.data.discount_applied_to_product_value);
                    this.promotionType = resp.result.data.promotion_type;
                    this.productQuantity = Number(this.appService.cartProducts.results[j].quantity);
                    this.productQuantity1 = true;
                    console.log("deeraaaa33"+JSON.stringify( this.appService.cartProducts.results))
                    if(resp.result.data.discount_percentage>0)	
                    {	
                      this.appService.cartProducts.results[j]['fixed_amount'] = resp.result.data.discount_amount;	
                    }	
                    else{	
                      this.appService.cartProducts.results[j]['fixed_amount'] =  resp.result.data.fixed_amount;	
                    }	
                    //this.appService.cartProducts.results[j]['discount_amount'] = resp.result.data.discount_amount;	
                    //this.appService.cartProducts.results[j]['fixed_amount'] =  resp.result.data.fixed_amount;	
                    this.appService.cartProducts.results[j]['discount_percentage'] = resp.result.data.discount_percentage;	
                    console.log("this.appService.cartProducts.results[i].product.fixed_amount"+this.appService.cartProducts.results[j].product.fixed_amount);	
                    this.cartService.updateCartItem(this.appService.cartProducts.results);

                  }
                }
                if(ORG_DETAILS.NAME==='Deera'){
                  let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
                  this.localService.set("cart", cartItemsString);
                  }
              }
              else if (resp.success === 0) {
                if (resp.message) {
                }
              }
              else if (resp.message && resp.status_code !== 200) {
      
              }
              else {
              }
            }
          },
          err => {
            if (err.error.statusCode === 403) {
      
            }
            else if (err.error.error.message) {
            }
            else {
            }
          }
        )
    
      }
    
    // if(this.config.title === 'Deera') {
    //   this.getPromotionBanner(this.newProductDetails);
    // }
}


buyNow() {
  if (this.showSize) {
  if (this.newProductDetails.hasOwnProperty('variant')) {
        // item.pricing.raw.price=this.priceOfVariant
        console.log("deeraaaa11")
        let cartdetails = this.sizeVariable;
        cartdetails.brand = { name: "" }
        cartdetails.categories = [];
        cartdetails.assets = this.newProductDetails.assets
        cartdetails.name = this.newProductDetails.name + ' - ' + this.sizeVariable.option
        cartdetails.parentcategories = this.newProductDetails.parentcategories;
        this.cartService.addToCart(cartdetails)
      }
      else {
        console.log("deeraaaa22")
        this.cartService.addToCart(this.newProductDetails)
      }
    }
    else {
      console.log("deeraaaa33")
      this.cartService.addToCart(this.newProductDetails);
      }
    this.addCart = true
    console.log("deeraaaa44")
    for (let i = 0; i < this.appService.cartProducts.results.length; i++) {
      this.cartData.push(this.appService.cartProducts.results[i].product_id);
    }
      if(ORG_DETAILS.NAME==='Deera'){
        let tempArray = this.appService.cartProducts.results;
        let obj =
        // {
        //     "domain_name": ORG_DETAILS.DOMAIN_NAME,
        //     "user_id": ORG_DETAILS.USER_ID,
        //     "extras": {
        //         "find": {
        //           "product_id": Number(this.appService.cartProducts.results[i].product_id),
        //           "product_cost": Number(this.appService.cartProducts.results[i].cost),
        //           "quantity": Number(this.appService.cartProducts.results[i].quantity)
        //         }
        //     }
        //   }

          {
            "domain_name": ORG_DETAILS.DOMAIN_NAME,
            "user_id": ORG_DETAILS.USER_ID,
            "extras": {
                "find": {
                  "product_id": Number(this.newProductDetails.id),
                  "product_cost": Number(this.newProductDetails.pricing.raw.cost),
                  "quantity": 1
                }
            }
          }
      
        this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
          resp => {
            if (resp) {
              if (resp.success === 1 && resp.status_code === 200) {
                for (let j = 0; j < this.appService.cartProducts.results.length; j++) {
                  console.log("tempArray[index].quantity"+this.appService.cartProducts.results[j].product.id);
                  if(this.appService.cartProducts.results[j].product.id===this.newProductDetails.id){
                    this.appService.cartProducts.results[j].product.discount_value =  Number(resp.result.data.discount_applied_to_product_value);
                    this.appService.cartProducts.results[j].product.discount_amount =  Number(resp.result.data.discount_amount);
                    this.appService.cartProducts.results[j].product.fixed_amount =  Number(resp.result.data.fixed_amount);
                    this.appService.cartProducts.results[j].product.discount_percentage = Number(resp.result.data.discount_percentage);
                    this.congratulationBanner = resp.result.data.congratulations_banner_message;
                    this.upsellBanner = resp.result.data.upsell_banner_message;
                    this.percentageValue = Number(resp.result.data.discount_percentage);
                    this.fixedAmount = Number(resp.result.data.fixed_amount);
                    this.discountValue = Number(resp.result.data.discount_applied_to_product_value);
                    this.promotionType = resp.result.data.promotion_type;
                    this.productQuantity = Number(this.appService.cartProducts.results[j].quantity);
                    this.productQuantity1 = true;
                    console.log("deeraaaa33"+JSON.stringify( this.appService.cartProducts.results))
                    if(resp.result.data.discount_percentage>0)	
                    {	
                      this.appService.cartProducts.results[j]['fixed_amount'] = resp.result.data.discount_amount;	
                    }	
                    else{	
                      this.appService.cartProducts.results[j]['fixed_amount'] =  resp.result.data.fixed_amount;	
                    }	
                    //this.appService.cartProducts.results[j]['discount_amount'] = resp.result.data.discount_amount;	
                    //this.appService.cartProducts.results[j]['fixed_amount'] =  resp.result.data.fixed_amount;	
                    this.appService.cartProducts.results[j]['discount_percentage'] = resp.result.data.discount_percentage;	
                    console.log("this.appService.cartProducts.results[i].product.fixed_amount"+this.appService.cartProducts.results[j].product.fixed_amount);	
                    this.cartService.updateCartItem(this.appService.cartProducts.results);

                  }
                }
                if(ORG_DETAILS.NAME==='Deera'){
                  let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
                  this.localService.set("cart", cartItemsString);
                  this.router.navigate(['/checkout-new']);
                  }
              }
              else if (resp.success === 0) {
                if (resp.message) {
                }
              }
              else if (resp.message && resp.status_code !== 200) {
      
              }
              else {
              }
            }
          },
          err => {
            if (err.error.statusCode === 403) {
      
            }
            else if (err.error.error.message) {
            }
            else {
            }
          }
        )
    
      }
    
    // if(this.config.title === 'Deera') {
    //   this.getPromotionBanner(this.newProductDetails);
    // }
}

buyNow1(){
  if(ORG_DETAILS.NAME==='Deera'){
    this.router.navigate(['/checkout-new']);
  }

}

getPromotionBanner(item)
{
  let obj =
  {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
          "find": {
          }
      }
    }

  this.appService.postApiCall(obj,ENDPOINTS.PROMOTIONS_BANNER).subscribe(
    resp => {
      if (resp) {
        if (resp.success === 1 && resp.status_code === 200) {
          this.banner = resp.result.data[0]['eligible_banner_message']
          if(this.config.title ==='Deera'){
            this.promotionData = resp.result.data;
            // let tempArray = this.appService.cartProducts.results;
            
            // this.appService.cartProducts.results.forEach((item)=>
            // {
              console.log("1111111111"+item.name);
        
              this.promotionData.forEach((data)=>
              {
                console.log("3333333333"+item.pricing.raw.price);
                console.log("eeeeee"+data.product_discount.amount_or_percentage.discounted_percentage);
                // this.productData = data.product_discount.amount_or_percentage.product_details;
                console.log("productnnnn"+data.product_discount.amount_or_percentage.product_details[0].product_name)
                data.product_discount.amount_or_percentage.product_details.forEach((data1)=>{
                  console.log("data1.product_name"+data1.product_name);
                  console.log("item.product_name"+item.name);
                  if(data1.product_name===item.name){
                    console.log("trueeee");
                    this.executionData.push(data1.order_execution);
                    let max = this.executionData.reduce((op, item) => op = op > item ? op : item, 0);
                    console.log("max"+max);
                    if(max===data1.order_execution){
                    if(data.product_discount.amount_or_percentage.discounted_percentage!==0){
                      console.log("truwwwwwww");
                      function minusPercent(n,p) {
                        return n - (n * (p/100));
                      }
                      // let price = minusPercent(475,20);
                      let price = minusPercent(item.pricing.raw.price,data.product_discount.amount_or_percentage.discounted_percentage); 
                      // item.pricing.raw.price = price;
                      console.log("price"+price);
                      this.appService.cartProducts.results.forEach((item1)=>
                      {  
                        if(item.name===item1.product_name){
                          item1.cost=price
                          this.calculateTotal();
                          this.cartService.updateCartItem(this.appService.cartProducts.results);
                        }
                      })
      
                    } else if (data.product_discount.amount_or_percentage.discounted_amount!==0){
                      console.log("item.pricing.raw.price"+item.pricing.raw.price);
                      console.log("discounted_amount"+data.product_discount.amount_or_percentage.discounted_amount);
                     let price = item.pricing.raw.price - data.product_discount.amount_or_percentage.discounted_amount;
                    //  item.pricing.raw.price = price;
                     console.log("price"+price);
                     this.appService.cartProducts.results.forEach((item1)=>
                     {  
                       if(item.name===item1.product_name){
                         item1.cost=price
                         this.calculateTotal();
                         this.cartService.updateCartItem(this.appService.cartProducts.results);
                       }
                     })
                    }
                  }
                  }

                })
         
              })
            // })
          }
        }
        else if (resp.success === 0) {
          if (resp.message) {
            //this.alertService.error(resp.message, this.options);
          }
        }
        else if (resp.message && resp.status_code !== 200) {
          //this.alertService.error(resp.message, this.options);

        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    },
    err => {
      if (err.error.statusCode === 403) {
        //this.alertService.error('Session Time Out! Please login Again', this.options)

      }
      else if (err.error.error.message) {
        //this.alertService.error(err.error.error.message, this.options)
      }
      else {
        //this.alertService.error('Something bad happened. Please try again!', this.options);
      }
    }
  )


}

calculateTotal()
{

  if(this.appService.cartProducts.results.length > 0)
  {
    this.totalPrice = 0;
    this.appService.cartProducts.results.forEach((obj)=>
    {
        this.totalPrice += (obj.cost * obj.quantity);
    })
  }
  else
  {
    this.totalPrice = 0;
  }
}

closeVideo(){
  var videos = document.querySelectorAll('iframe, video');
	Array.prototype.forEach.call(videos, function (video) {
		if (video.tagName.toLowerCase() === 'video') {
			video.pause();
		} else {
			var src = video.src;
			video.src = src;
		}
	});  
}
}
