
                <!---------sidebar-->
<app-alerts *ngIf="showAlerts"></app-alerts>

<div class="container inq-mt-st">
    <section class="row">
        <div class="col-md-3">
            <div class="inq-menu-left-st" *ngIf="config.title !== 'Haven'">
                <!-----------tams------>
                <div *ngIf="config.title != 'Haven'">
                                <ul *ngFor="let category of allCategories;let i = index" [class.active]="selectedIndex === i"
                    (click)="setIndex(i)">

                    <li *ngIf="config.title != 'Mayan'"[class.active]="active === category.name || categoryName===category.name" class="active"
                        (click)="getProductByFilter(category);">
                        {{category.name}}
                    </li>
                    <li *ngIf="config.title == 'Mayan'" [class.active]="(categoryNameFromHome != undefined) ? (categoryNameFromHome===category.name || categoryName===category.name) : active === category.name"
                        (click)="getProductByFilter(category);">
                        {{category.name}}
                    </li>
                    <div *ngIf="categoryName===category.name">
                        <ul *ngFor="let subCategory of subCategory2;let i = index">
                            <li [class.active]="active === subCategory.name"
                                (click)="getProductByFilter1(subCategory.name)">{{subCategory.name}}
                            </li>
                        </ul>
                    </div>
                </ul>
                </div>
            </div>
            <div class="inq-menu-left1-st" *ngIf="config.title === 'Haven'">
                <!----------haven---->
                <div *ngIf="config.title == 'Haven'">

                <ul *ngFor="let category of allCategories;let i = index" [class.active]="selectedIndex === i"
                (click)="setIndex(i)">

                <li [class.active]="active === category.name || categoryName===category.name" class="active"
                    (click)="getProductByFilter(category);">
                    <img [src]=" category.category_icon ? category.category_icon : 'https://d18xkwaipu1whh.cloudfront.net/CommonAsset/canape.jpg'" class="mb-2" width="18" height="21">
                    {{category.name}}
                </li>
                <div *ngIf="categoryName===category.name">
                    <ul *ngFor="let subCategory of subCategory2;let i = index">
                        <li style="margin-left: 65px!important;"  [class.active]="active === subCategory.name"
                            (click)="getProductByFilter1(subCategory.name)">{{subCategory.name}}
                        </li>
                    </ul>
                </div>
            </ul>
            </div>
            <!------>
            </div>
        </div>
        <div class="col-md-9">
            <div class="inq-menu-right-st">
                <!----------breadcrumb----->
<h2></h2>
         <!------------tams----->
                <div class="inq-title-st" *ngIf="config.title != 'Haven'">
                    <h3 *ngIf="config.title != 'Mayan' && subCategory!==undefined" class="mb-4">{{category1}} </h3>
                    <h3 *ngIf="config.title != 'Mayan' && subCategory===undefined" class="mb-4">{{category}} </h3>
                    <h3 *ngIf="config.title == 'Mayan' && subCategory!==undefined" class="mb-4" style="color:#3c707c ;">{{category1}} </h3>
                    <h3 *ngIf="config.title == 'Mayan' && subCategory===undefined" class="mb-4" style="color:#3c707c ;">{{category}} </h3>
                    <h4 *ngIf="subCategory!==undefined">{{subCategory}}</h4>
                    <p>{{totalProductLength}} ITEMS</p>
                </div>
                <!-----------haven----->
                <div class="inq-title-st" *ngIf="config.title == 'Haven'">
                    <h3 *ngIf="subCategory!==undefined" class="mb-4">{{category1}} <img
                            [src]="categoryIcon ? categoryIcon : 'https://d18xkwaipu1whh.cloudfront.net/CommonAsset/starter.jpg'" class="pb-2" height="50" width="50"></h3>
                    <h3 *ngIf="subCategory===undefined" class="mb-4">{{category}} <img
                            [src]="categoryIcon ? categoryIcon : 'https://d18xkwaipu1whh.cloudfront.net/CommonAsset/starter.jpg'" class="pb-2" height="50" width="50"></h3>
                    <h4 *ngIf="subCategory!==undefined">{{subCategory}}</h4>
                    <p>{{totalProducts}} ITEMS</p>
                </div>
                <!-- ---------Prodict TAMS listing-->

                <div *ngIf="totalLength>0 && config.title === 'Tams' || config.title ==='Mayan' || config.title ==='BBold'" class="thumb">
                    <div *ngFor="let product of productList ;let i = index">
                        <div class="row inq-pro-sec-st listProduct">
                            <div [class]="config.title=='Tams'? 'product-list-cards' : 'mayan-product-list-cards'" style="color: lightgray;">
                                <div>
                                    <!------------tams img------>
                                    <img [src]="product.assets[0].image_source" id="img-list"
                                                        (click)="productDetails(product.id)"
                                                        onerror="this.onerror=null;this.src='assets/images/tams/NoImage.png';"
                                                        alt="{{ product.name}}" class="product-list-image">
                                </div>
                                <div class="product-list-description">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <p *ngIf="config.title != 'Mayan'"  (click)="productDetails(product.id)" class="product-list-tile">{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</p>
                                            <p *ngIf="config.title == 'Mayan'"class="product-list-tile-mayan">{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</p>
                                        </div>
                                        <div>
                                            <p [class]="config.title=='Tams'? 'product-list-price' : 'mayan-product-list-price'"  *ngIf="!product.showPricse">
                                                ₹{{product.variant.option_values[0].pricing.raw.price}}.00</p>
                                                <p [class]="config.title=='Tams'? 'product-list-price' : 'mayan-product-list-price'" *ngIf="product.showPricse && product.product_type=='Variable'" >₹{{product.pricee}}.00</p>
                                                <!-- <p class="product-list-price"*ngIf="product.showPricse && product.product_type =='Simple'" >₹{{product.pricing.raw.price}}.00</p> -->

                                                <!-- <p class="product-list-price"*ngIf="!product.showPricse">₹{{product.price}}.00</p> -->
                                                <!-- <p class="product-list-price"*ngIf="!product.showPricse" style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:5px !important">&nbsp;  ₹{{product.pricing.raw.price_range.split('-')[1] + '.00'}}</p> -->
                                                <!-- <p class="product-list-price"*ngIf="product.showPricse && (product.product_type=='Variable')" >₹{{product.pricee}}.00</p> -->
                                                <p [class]="config.title=='Tams' ? 'product-list-price' : 'mayan-product-list-price'" *ngIf="product.showPricse &&( product.product_type =='Simple' ||product.product_type =='Variation')" >₹{{product.pricing.raw.price}}.00</p>
                                                <!-- <p class="product-list-price"  *ngIf="product.product_type=='Simple' ||product.product_type =='Variation' "style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price + '.00'}}</p>  -->
                                                <p [class]="config.title=='Tams'? 'product-list-price' : 'mayan-product-list-price'"  *ngIf="product.product_type=='Variable'"style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price_range_from}}-{{product.pricing.raw.price_range_to}}</p>
                                        </div>
                                    </div>
                                    <div *ngIf="config.title === 'Tams' && product.shipping">
                                        <small style="color: #FF6E40;">
                                            {{product.shipping.weight === 5000 ? 'Yields 12.5kg batter': 'Yields 2.5kg batter'}}
                                        </small>
                                    </div>
                                    <!----------variants------>
            <app-shared-variants  [product]="product" [products]="products" (selectEmitter)="showPrice($event)"></app-shared-variants>


            <!-- <div>

                <label class='note'  style="font-size: 11px !important; font-weight: 500 !important;color: black !important;">Note: Please select option to add item in
                    cart</label>

            </div> -->

                                    <!---------------------reviews---------------->
                                    <div class="d-flex justify-content-between mt-1 review-section" style="padding-top: 15px;">
                                        <div class="inner-star-icon pd-1">
                                            <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                            <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                            <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                            <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                            <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                            <a *ngIf="config.title == 'Tams'" routerLink="/product-review/{{product.id}}" style="color:#000000 !important;font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a>
                                            <a *ngIf="config.title == 'Mayan'" routerLink="/product-review/{{product.id}}" style="color:#000000 !important;font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a>
                                            <a *ngIf="config.title == 'BBold'" routerLink="/product-review/{{product.id}}" style="color:#ffffff !important;font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a>

                                        </div>
                                        <div>
                                            <!-- <a class="product-list-cart">ADD TO CART +</a> -->
                                             <!--------- button---------->

                                    <!-------------Add to cart-->
                                    <button class="cartButton" style="font-size: small;font-weight: bold;width:80px;background-color: transparent !important;border:1px solid #3c707c !important;color: #3c707c !important;background-color:grey;"
                                    (click)="addToCarts(product)"
                                   *ngIf="(config.title ==='Tams') && ((product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  ))">ADD
                                   <span> <i class="fa fa-shopping-cart"></i></span></button>
                                   <button class="cartButton" style="font-size: small;font-weight: bold;width:80px;background-color: transparent !important;border:1px solid #3c707c !important;color: #3c707c !important;background-color:grey;"
                                   (click)="addToCarts(product)"
                                  *ngIf="(config.title ==='BBold') && ((product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  ))">ADD
                                  <span> <i class="fa fa-shopping-cart"></i></span></button>
                                   <button  class="mayancartButton" style="font-size: small;font-weight: bold;width:80px;;border:1px solid #3c707c !important;color: #000000 !important;"
                                    (click)="addToCarts(product)"
                                   *ngIf="(config.title ==='Mayan') && ((product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  ))">ADD
                                   <span> <i class="fa fa-shopping-cart"></i></span></button>
                               <!---------view cart button-->
                               <div *ngIf="appService.productIdinCart.indexOf(product.id) != -1 || appService.productIdinCart.indexOf(product.checkVariantInCart) != -1 ">
                               <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                               <div [class]="config.title=='Tams'?'viewCart':'mayanviewCart'"   style="font-size: small;font-weight: bold;padding-left:4px;padding-right:4px;"   *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >


                                   <span>
                                       <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                           style="font-size:12px;cursor:pointer;"></i></span>
                                   <span class="inq-sp-2"
                                       style="color: white;">{{cart.quantity}}</span>
                                   <span>
                                       <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                           style="font-size:12px;cursor:pointer;"></i></span>
                              </div>

                               </div>
                               </div>


                                        </div>
                                    </div>

                                    <!-- <div class="d-flex justify-content-end mt-1">
                                        <a class="product-list-cart">ADD TO CART +</a>
                                    </div> -->
                                </div>
                            </div>
                        </div>


                    </div>
                    </div>


                    <!--------------------------------------------------------->
                    <!---------------haven listing------------------->
                    <div *ngIf="config.title == 'Haven' || 'Dosapark' && config.title !== 'BBold' && config.title !== 'Tams' && config.title !== 'Mayan'">
                    <div class="row mt-4 inq-pro-sec-st" *ngFor="let product of products;let i = index">
                        <div class="col-md-8">
                            <div class="col-md-10 inq-fo-des-in-st">
                                <div class="inq-dis-sec-st">
                                    <div class="inq-ft-st mb-1 row">
                                        <div class="col-md-1" id="veg-img-prt" *ngIf="fields[0].visibility">
                                            <!-- <img src="assets/images/veg.svg" alt="veg-items"> -->
                                        </div>

                                        <div class="col-md-11 inq-pro-col-le-st">
                                            <!-- <h5 *ngIf="fields[1].visibility">{{product.name}}</h5> -->
                                            <h5 *ngIf="fields[1].visibility" style="cursor: pointer;font-weight: 600;" (click)="productDetails(product.id)">{{metaDataMapService.findData(product, fields[1].nestedinfo)}}</h5>
                                            <div class="inner-star-icon">
                                            <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                            <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                            <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                            <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                            <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                            <a routerLink="/product-review/{{product.id}}"> {{product && product.reviews ? product.reviews.total_reviews : 0}} customer reviews</a>
                                        </div>
                                            <span *ngIf="fields[3].visibility"><strong><i
                                                        class="fa fa-rupee"></i>{{metaDataMapService.findData(product, fields[3].nestedinfo)}}</strong></span>
                                                        <span style="float:right; font-size: 14px;color: #805245!important;font-weight: 600!important;" *ngIf="product.stock_status == 'Available'"><i class="fa fa-check-circle" style="font-size:20px;color:#04AA6D;"></i>In Stock</span>
                                                        <span style="float:right; font-size: 14px;color: #805245!important;font-weight: 600!important;" *ngIf="product.stock_status != 'Available' && product.stock_status != ''"><i class="fa fa-times" style="font-size:20px;color:#FF0000;"></i>Out Of Stock</span>
                                                        <p style="padding-top: 5px !important;font-weight: 500;">{{product.description.description}}</p>

                                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="inq-fo-im-st ">
                                <div
                                    *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                                    <div class="w3-container" style="border-style: solid;
                                    border-color: #B5C99A;height: 100%;width: 100%;border-radius: 10px;object-fit: contain;" >   
                                    <img [src]="product.assets[0].image_source" alt="..."
                                        class="img-fluid product-image"  style="cursor: pointer;" (click)="productDetails(product.id)"></div>
                                </div>
                                <div >   
                                <div *ngIf="product.assets.length===0 || !product.assets[0].image_source">
                                    <img src="assets/images/No_Image_old.png"  style="cursor: pointer;" (click)="productDetails(product.id)" class="img-fluid product-image">
                                </div></div>
                                <div *ngIf="appService.cartProducts.results.length > 0">

                                    <div *ngIf="cartData.includes(product.id)">
                                        <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                                            <div class="" *ngIf="cart.product_id===product.id && product.stock_status == 'Available'">
                                                <div class="inq-cart-bw-st add-cart1" style="left: 10px;">
                                                    <span>
                                                        <i class="fa fa-minus" (click)="decreaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                                    <span class="inq-sp-2"
                                                        style="color: white;">{{cart.quantity}}</span>
                                                    <span>
                                                        <i class="fa fa-plus" (click)="increaseItem(i)"
                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="inq-cart-bw-st add-cart" *ngIf="!cartData.includes(product.id) && product.stock_status == 'Available'"
                                        (click)="addToCart(product)">
                                        <span class="inq-sp-1"></span>
                                        <span class="inq-sp-2 inq-secondary">ADD</span>
                                        <span class="inq-sp-3"></span>

                                    </div>

                                </div>
                                <div *ngIf="appService.cartProducts.results.length === 0">
                                    <div class="inq-cart-bw-st add-cart" (click)="addToCart(product)" *ngIf="product.stock_status == 'Available'">
                                        <span class="inq-sp-1"></span>
                                        <span class="inq-sp-2 inq-secondary">ADD</span>
                                        <span class="inq-sp-3"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="inq-pro-hr-st">
                    </div>
                    </div>
                       <!-------------->


                </div>
                <!---------------------------------------->
                <div *ngIf="config.title != 'Mayan' && totalLength<1">
                    <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/noresult.jpeg" class="img-fluid1">
                    <img src="assets/images/noresult.jpeg" class="img-fluid1">
                </div>
                <div *ngIf="config.title == 'Mayan' && totalProductLength <1">
                    <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/noresult.jpeg" class="img-fluid1">
                </div>

        </div>
    </section>
</div>
