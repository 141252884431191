<section>
  <div style="padding: 30px;padding-left: 18%;" *ngIf="config.title==='Haven'">
    <!-- <h3 class="text-center">TERMS & CONDITIONS</h3> -->
    <!-- <div [innerHTML]="content"></div> -->
    <div class="cardtext" style="height: 100% !important;">
      <div class="card-header" id="delivery-card">
          <!-- <h2 class="card-txt" style="color: #7e8552;">TERMS & CONDITIONS</h2> -->
          <h2 class="text-center card-txt" style="color: #7e8552;">TERMS & CONDITIONS</h2>
          <h4 class="card-txt" style="color: #805245;margin-top: 10px;">1. ACCEPTANCE OF TERMS</h4>
              <p class="text-black">Vescor Foods(Holding company of <b>havensampoorna.com</b>) provides our service to you, including making payments and food pick-up or delivery requests to the following Terms of Service ("Terms"). The Terms cover all information, data, and services at <b>www.havensampoorna.com</b>("Site"). We may, from time to time, update these Terms, and the date of last revision will be available at the top of this page, so please be sure to check back periodically. By using the Site, you are acknowledging and accepting these Terms.</p>
              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">2. USER SUBMISSIONS AND CONDUCT</h4>
                  <p class="text-black">The services provided on the Site, including making food pick-up and delivery requests, also grant you and others the ability to write, post, transmit and display user generated submissions, comments, recommendations and reviews on the Site (“User Submissions”) under the following conditions:<br>
                    All User Submissions are subject to review by Vescor Foods in its sole discretion and can be edited or removed without cause or notification.<br>
                    You will not create or submit false, profane, sexually explicit or controversial reviews about any other users, third-parties or participating restaurants, and participate in good faith on the Site.<br>
                    You will not submit information or materials that contain copyrighted, trademark or other intellectual property, including images, text and designs, without the express written permission of the owner.<br>
                    You are authorized to share information and User Submissions from our Site to personal accounts with social networking service providers including Facebook, and Twitter accounts.<br>
                    You will not upload or transmit harmful or destructive files, including viruses.<br>
                    You will comply with India regulations and laws regarding online conduct and submissions, and will not use the Site for illegal or disruptive purposes.<br>
                    <br>
                    Vescor Foods does not endorse or claim to represent or be affiliated with any User Submissions. Although Vescor Foods will strive to review or edit User Submissions, it does not control or monitor User Submissions, represent that the User Submissions are accurate and disclaims any responsibility in connection therewith.</p>
                    <h4 class="card-txt" style="color: #805245;margin-top: 10px;">3.INFORMATION YOU PROVIDE</h4>
                    <p class="text-black">We may ask you for certain information in order to provide you with services you request, such as food delivery. By providing information on our Site, such as in conjunction with a request for services, you agree to provide accurate, current and complete information about yourself as prompted (such information being the "User Data") and maintain and promptly update your User Data to keep it accurate, current and complete. If you provide any information that is inaccurate, not current or incomplete, or if Vescor Foods has a reasonable ground to suspect that such information is inaccurate, not current or incomplete, Vescor Foods may deny you access to areas of the Site or Vescor Foods services, at its sole discretion.

                      When you provide us with User Data, you may receive certain commercial communications from Vescor Foods related to content found on the Site. You may opt out of receiving these communications at any time by sending an email to <b>admin@havensampoorna.com.</b></p>
                      <h4 class="card-txt" style="color: #805245;margin-top: 10px;">4.PRIVACY POLICY</h4>
                      <p class="text-black">Your User Data, as well as information that you may provide at other times during your use of the Site, is subject to our Privacy Policy, which is incorporated by reference into these Terms. </p>
                      <h4 class="card-txt" style="color: #805245;margin-top: 10px;">5.PARTICIPATING RESTAURANTS AND USERS</h4>
                      <p class="text-black">The following sets forth the obligations, responsibilities and communications between the users and the participating restaurants and related third parties:

                        Food delivery cancellations and changes can be done by directly contacting the us.<br>
                        User can check his order status after logging into his/her account.<br>
                        Users have the obligation to confirm and make sure that the payment amount for an order placed on the Site is correct before placing the order.<br>
                        Menu content, price, availability and restaurant information are subject to change without notice or cause.</p>
                        <h4 class="card-txt" style="color: #805245;margin-top: 10px;">6.ACCOUNT PASSWORD AND SECURITY</h4>
                        <p class="text-black">The Site may contain features that require registration ("Restricted Areas"). You are not required to register to use the Site, but may not be able to access some content without registration. At the time of registration for online account access, you will be required to select a User ID and password for use on the Site. You are responsible for maintaining the confidentiality of your password, and are fully responsible for all uses of your password, whether by you or others. You agree to (i) keep your password confidential and not have it with anyone else; (ii) immediately notify Vescor Foods of any unauthorized use of your password or account identifier or any other breach of security; and (iii) use only your password to access the Site's Restricted Areas. Vescor Foods cannot and will not be liable for any loss or damage arising from your failure to comply with this Section.<br>

                          You acknowledge and agree that Vescor Foods is authorized to act on instructions received through use of your password, and that Vescor Foods may, but is not obligated to, deny access or block any transaction made through use of your password without prior notice if we believe your password is being used by someone other than you, or for any other reason.<br>
                          
                          You acknowledge, consent and agree that Vescor Foods may access, preserve and disclose your account information and content if required to do so by law or in a good faith belief that such access, preservation, or disclosure is reasonably necessary: (i) to comply with legal process; (ii) to enforce the Terms; (iii) to respond to claims that any content violates the rights of third parties; (iv) to respond to your requests for customer service; or (v) to protect the rights, property, or personal safety of Vescor Foods, its users and the public.</p>
                          <h4 class="card-txt" style="color: #805245;margin-top: 10px;">7.BILLING INFORMATION</h4>
                          <p class="text-black">You warrant that you have the appropriate authority and power to validly accept the following payment terms (“Payment Terms”):<br>

                            You represent and warrant that you are over 18.<br>
                            If you are using a credit card, you represent and warrant that the credit card is issued in your name and you will pay all charges incurred in the course of using that card on the Site.<br>
                            All personal information, credit card information and all other details you provide in relation to making reservations are accurate and complete.<br>
                            <br>
                            We reserve the right to alter, suspend or terminate the Payment Terms temporarily or permanently at any time.</p>
                            <h4 class="card-txt" style="color: #805245;margin-top: 10px;">8.NON-COMMERCIAL USE</h4>
                            <p class="text-black">By using the Site, you acknowledge and agree that the Site is for non-commercial, personal and social networking uses only. You may copy information from the Site for personal use, such as to view, print, or email the information, but you warrant that you will not, under any other conditions, reproduce, duplicate, copy, sell, trade, resell, modify, distribute, decompile, disassemble, or reverse engineer any portion of the Site.</p>
                            <h4 class="card-txt" style="color: #805245;margin-top: 10px;">9.MODIFICATIONS TO SERVICE</h4>
                            <p class="text-black">Vescor Foods reserves the right at any time to modify or discontinue, temporarily or permanently, the Site (or any part thereof), including the Restricted Areas, with or without notice. You agree that Vescor Foods shall not be liable to you or any third party for any modification, suspension or discontinuance of the Site.</p>
                            <h4 class="card-txt" style="color: #805245;margin-top: 10px;">10.DOWNLOADED SOFTWARE</h4>
                            <p class="text-black">If you download any software from the Site, you agree that Vescor Foods has not transferred or granted any rights in and to the software, and all of the rights therein, including intellectual property rights, belong to Vescor Foods.</p>
                            <h4 class="card-txt" style="color: #805245;margin-top: 10px;">11.TERMINATION</h4>
                            <p class="text-black">You agree that Vescor Foods may, in its sole and absolute discretion and without notice or liability to you or any third party, immediately terminate your access to the Site or the Restricted Areas. Grounds for such termination shall include, but not be limited to, (i) breaches or violations of the Terms or other agreements or guidelines, (ii) requests by law enforcement or government agencies, (iii) a request by you (self-initiated account deletions), (iv) discontinuance or material modification of the Site (or any part thereof), (v) unexpected technical or security issues or problems, and (vi) extended periods of inactivity. Termination of your account(s) may include removal of access to all offerings within the Site's Restricted Areas, at Vescor Foods's sole discretion, the deletion of all of your Site account information and other content associated with your Site account (or any part thereof), and barring further use of the Site' Restricted Areas.</p>
                            <h4 class="card-txt" style="color: #805245;margin-top: 10px;">12.DEALINGS WITH ADVERTISERS AND THIRD PARTIES</h4>
                            <p class="text-black">Your correspondence or business dealings with, or participation in promotions of, advertisers and other third parties found on or through the Site, including making reservations or cancellations, and any other Terms, conditions, warranties or representations associated with such dealings, are solely between you and such third party. You agree that Vescor Foods shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers or third parties on the Site.</p>
                            <h4 class="card-txt" style="color: #805245;margin-top: 10px;">13.LINKS AND ADVERTISEMENTS</h4>
                            <p class="text-black">The Site may provide links to other website or resources. Because Vescor Foods has no control over such Site and resources, you acknowledge and agree that Vescor Foods is not responsible for the availability or reliability of such external Site or resources, and does not endorse and is not responsible or liable for any content, advertising, products, or other materials on or available from such Site or resources. You further acknowledge and agree that Vescor Foods shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.

                              Such links do not imply Vescor Foods’ endorsement of information or material on any other site and Vescor Foods disclaims all liability with regard to your access to and use of such linked website or resources.<br>
                              
                              Any advertisements displayed on the Site are meant for commercial use. Vescor Foods reserves the right to display and remove advertisements at its discretion.</p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">14.INDEMNITY</h4>
                              <p class="text-black">You agree to indemnify, defend, and hold Vescor Foods, its officers, employees, agents, affiliates and partners, harmless from any claim or demand, including reasonable advocates' fees, made by any third party due to or arising out of your use of the Site, any transaction resulting from use of the Site, your connection to the Site, your violation of these Terms, your submission, posting, or transmission of personally identifiable information or other data to the Site, and/or your violation of any rights of another.</p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">15.OUR PROPRIETARY RIGHTS</h4>
                              <p class="text-black">
                                All title, ownership and intellectual property rights in and to the Site, including images, text, pictorial works, graphic designs, slogans, marks, recordings and computer programs (“Content) are owned by Vescor Foods or its licensors. You acknowledge and agree that the Site contains proprietary and confidential information that is protected by applicable intellectual property and other laws. Except as expressly authorized by Vescor Foods, you agree not to modify, rent, lease, loan, sell, distribute or create derivative works based on the Site, in whole or in part, or based upon any materials found on the Site. Advertisements for third-party entities are not considered Content for the purposes of this Section. However, these advertisements are owned or licensed by their respective third-party entities and subject to protections similar to those set forth in these Terms.
                              </p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">16.DISCLAIMER OF WARRANTIES</h4>
                              <p class="text-black">
                                You expressly understand and agree that:<br>
                                your use of the site is at your sole risk. The site are provided on an "as is" and "as available" basis. Vescor foods expressly disclaims all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, accuracy, and non-infringement. Vescor foods does not warrant the accuracy, currency, or completeness of any information relating to prices or availability. Vescor foods assumes no responsibility for the timeliness, deletion, mis-delivery or failure to provide any content or to store any personalization settings or user materials.

                                Any content downloaded or otherwise obtained through the site is downloaded and used at your sole discretion and risk and you will be solely responsible for any damage to your computer system or loss of data that results from the download or use of any such content.<br>
                                No advice or information, whether oral or written, obtained by you from vescor foods or through or from the site shall create any warranty not expressly stated in the terms.
                              </p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">17.LIMITATION ON LIABILITY</h4>
                              <p class="text-black">
                                You understand and agree that vescor foods shall not be liable to you for any direct, indirect, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses (even if vescor foods had been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the site; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the site; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the vescor foods site; or (v) any other matter relating to the site.
                              </p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">18.COPYRIGHT</h4>
                              <p class="text-black">
                                The Site is protected by copyright laws. Except for your use under the Terms herein, you may not modify, reproduce or distribute the content, design or layout of the Site, or any portion thereof, without the prior written permission of Vescor Foods.
                              </p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">19.DISPUTES</h4>
                              <p class="text-black">
                                All disputes will be settled within the jurisdiction of Chennai.
                              </p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">20.NO THIRD PARTY BENEFICIARIES</h4>
                              <p class="text-black">
                                You agree that, except as otherwise expressly provided in the Terms, there shall be no third party beneficiaries to these Terms.
                              </p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">21.GENERAL INFORMATION</h4>
                              <p class="text-black">
                                Entire Agreement. The Terms and the Privacy Policy constitute the entire agreement between you and Vescor Foods with respect to the Site and supersede any prior agreements, oral or written, between you and Vescor Foods.<br>

                                Waiver and Severability of Terms. The failure of Vescor Foods to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.<br>
                                
                                Statute of Limitations. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the use of the Site or the Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred.<br>
                                
                                Section Titles. The section titles in the Terms are for convenience only and have no legal or contractual effect.
                              </p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">22.VIOLATIONS</h4>
                              <p class="text-black">
                                You agree that you will report any violations of the Terms to <b>admin@havensampoorna.com.</b>
                              </p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">23.CANCELLATION</h4>
                              <p class="text-black">
                                Orders once placed cannot be cancelled.
                              </p>
                              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">24.REFUND</h4>
                              <p class="text-black">
                                For the transactions which money was deducted but you didn’t get the order confirmation hence food not delivered, will be refunded back to the respective bank account that was used for online payment transaction.<br>
                                Orders that were delivered beyond the max time of two hours from the accepted delivery time will be refunded
                              </p>
                          </div>
  </div>
  </div>

  <div style="padding: 30px;" *ngIf="config.title!=='Haven'">
    <h3 class="text-center">TERMS & CONDITIONS</h3>
    <div [innerHTML]="content"></div>
   
       
       
                          </div>

  
</section>