import { Component, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, Form } from '@angular/forms';
import {DatePickerComponent} from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ENDPOINTS,ORG_DETAILS } from '../../app.config';
import { CheckoutDeliveryService } from './checkout-delivery.service';
import { CheckoutDeliveryStorageService } from './checkout-delivery-storage.service';
import { OrderSummaryInitialService } from '../order-summary/order-summary-initial.service';
import { CheckoutDeliveryEventsService } from './checkout-delivery-events.service';
import { CheckoutDeliveryOrderService } from './checkout-delivery-order.service';
import { CheckoutShippingOrderService } from '../checkout-shipping/checkout-shipping-order.service';
import { OrderSummaryOrderService } from '../order-summary/order-summary-order.service';
import { HeaderService } from '../../header/header.service';
import { LocalStorageService } from '../../auth-service/local-storage.service'
import {CheckoutInitialdataService} from '../checkout-initialdata.service'



@Component({
  selector: 'app-checkout-delivery',
  templateUrl: './checkout-delivery.component.html',
  providers: [DatePipe]
})
export class CheckoutDeliveryComponent implements OnInit {

  datePickerConfig = {
    format : "yyyy-MM-DD",
    min : moment().format("YYYY-MM-DD"),
    drops:'up',
    unSelectOnClick:false
  }

  @ViewChild('dayPicker') datePicker: DatePickerComponent;

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  PERCENTAGE_LABEL = "PERCENTAGE";
  FIXEDAMOUNT_LABLE = "FIXED AMOUNT";
  deliveryForm:FormGroup;
  validateDeliveryForm:boolean = false;
  deliverType:any = null;
  preferredTimings:any;
  deliveryInfo = {
    enableDelivery:true,
    deliveryDisplayName:"",
    enablePickinStore:true,
    pickinStoreDisplayName:"",
    handlingFee:{
      delivery:{
        rate:0,
        name:"",
        type:""
      },
      pickinstore:{
        rate:0,
        name:"",
        type:""
      }
    }
  }
  branchvalue:any;
   deliverydetails:any={};
  outlets:any = []
  acceptanceTiming: any;
  timeslot = 30;
  date:any;
  today:any;
  slots = [];
  slotRange = [];
  firstSlot = "";

  deliveryInformation = {
    delivery_type: "",
    delivery_preferred_time: "",
    delivery_date: "",
    delivery_time: "",
    outlet_id: 0,
    outlet_name: "",
    outlet_latitude: "",
    outlet_longitude: "",
    enabled:false,
    distance:"",
    pincode:""
}

  deliveryDataExisits:boolean = false;
  istoday:boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private checkoutDeliveryService: CheckoutDeliveryService,
    private datePipe: DatePipe,
    private checkoutDeliveryStorageService: CheckoutDeliveryStorageService,
    private orderSummaryInitialService: OrderSummaryInitialService,
    private checkoutDeliveryEventsService: CheckoutDeliveryEventsService,
    private checkoutDeliveryOrderService: CheckoutDeliveryOrderService,
    private checkoutShippingOrderService: CheckoutShippingOrderService,
    private orderSummaryOrderService: OrderSummaryOrderService,
    private headerService: HeaderService,
    private localService :LocalStorageService,
    private checkoutinitial:CheckoutInitialdataService

  ) { 
    this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.today = moment();
  }

  ngOnInit(): void {
    this.checkoutDeliveryEventsService.clearEvent();
    this.checkoutDeliveryEventsService.currentData.subscribe(
      currentData => {
        if(currentData === "resetDelivery"){
          this.resetDelivery();
        }
        else if(currentData === "editDelivery"){
          this.editDeliveryInfo();
        }
      }
    )
    this.deliveryForm = new FormGroup({
      deliveryType: this.formBuilder.control('',[]),
      outlet: this.formBuilder.control('',[Validators.required]),
      preferredTime : this.formBuilder.control('',[Validators.required]),
      deliverDate: this.formBuilder.control('',[]),
      deliverTime: this.formBuilder.control('',[])
    })
    
   

    this.getInitialData();
    // this.deliveryDataExists();
  }

  get f(){
    return this.deliveryForm.controls;
  }
  cal()
  {
    this.datePicker.api.open();
  }
deliverytime;
expiry;
prior_time;
  getInitialData(){
    this.checkoutinitial.getCheckoutFlow('INDIVIDUAL CUSTOMER').then(
      respData => {
        let res:any = respData;
        

       res.forEach(element=>{

        if(element.step_name=='Delivery')
        {
             this.deliverydetails=element.delivery_details!=null?element.delivery_details:{};
             
        }
       })
       if(Object.keys(this.deliverydetails).length===0)
       {
        var delivery_preferred_time=['Now','Later']
        this.deliverydetails['delivery_preferred_time']=delivery_preferred_time
       }
       else
       {
        this.deliverytime=this.deliverydetails.delivery_time;
        this.expiry=this.deliverydetails.expiry_day_for_pre_order!=null?this.deliverydetails.expiry_day_for_pre_order:0;
        this.prior_time=this.deliverydetails.prior_time!=null?this.deliverydetails.prior_time:0;
        if(this.expiry==0)
        {
          this.datePickerConfig['max']=moment().add(this.expiry,'days').format('YYYY-MM-DD');
        }
        else
        {
          this.datePickerConfig['max']=moment().add(this.expiry-1,'days').format('YYYY-MM-DD');
        }
     
       }
       
    })
    this.checkoutDeliveryService.getHandlingFeeInfo().then(
      respData => {
        let res:any = respData;
        this.deliveryInfo = res;
        if(this.deliveryInfo.enableDelivery){
          this.deliverType = "Delivery"
        }
        else{
          this.deliverType = "PickUp In Store"
        }
    })
    this.checkoutDeliveryService.getOutletData().then(
      respData1 => {
        let res1:any = respData1;
        this.outlets = res1;
        if(this.outlets.length==1)
        {

          this.deliveryForm.controls.outlet.setValue(this.outlets[0].id.toString());
        }
        

    })
    this.checkoutDeliveryService.getAcceptanceTimings().then(
      respData2 => {
        let res2:any = respData2;
        this.acceptanceTiming = res2;
      }
    )
  }

  getDeliveyType(dtype){
    this.deliverType = dtype;
  }

  saveDeliveryInfo(){
    if(this.deliveryForm.value.preferredTime === 'Later'||this.deliveryForm.value.preferredTime === 'Pre Order'){
      if(!this.deliveryForm.value.deliverDate){
        this.validateDeliveryForm = true;
        this.deliveryForm.controls['deliverDate'].setErrors({'incorrect':true})
      }
      if(!this.deliveryForm.value.deliverTime){
        this.validateDeliveryForm = true;
        this.deliveryForm.controls['deliverTime'].setErrors({'incorrect':true})
      }
    }
    else {
      if(!this.isSoltAvailabalityNow()){
        this.validateDeliveryForm = true;
        this.deliveryForm.controls['preferredTime'].setErrors({'incorrect':true})
      }
    }
    if(this.deliveryForm.valid){
      this.validateDeliveryForm = false;
      if(this.deliveryForm.controls.deliverDate.value !== ""){
        this.getDiscounts(this.deliveryForm.value.deliverDate, this.deliveryForm.value.deliverTime)
      }
      this.deliveryInformation.delivery_type = this.deliverType;
      this.deliveryInformation.delivery_preferred_time = this.deliveryForm.value.preferredTime;
      if(this.deliveryForm.value.preferredTime === "Later"||this.deliveryForm.value.preferredTime === "Pre Order"){
        this.deliveryInformation.delivery_date = this.deliveryForm.value.deliverDate;
        this.deliveryInformation.delivery_time = this.deliveryForm.value.deliverTime;
        this.checkoutDeliveryOrderService.selectedDateTime = this.deliveryForm.value.deliverDate+ " " + this.getDeliveryTime(this.deliveryForm.value.deliverTime);
        
      }
      else{
        this.deliveryInformation.delivery_date = new Date().toString();
        this.deliveryInformation.delivery_time = new Date().toLocaleTimeString();
      }
      let selectedOutlet = this.outlets.find(outlet => outlet.id === Number(this.deliveryForm.value.outlet))
      this.deliveryInformation.outlet_id = selectedOutlet.id;
      this.deliveryInformation.outlet_name = selectedOutlet.name;
      this.deliveryInformation.outlet_latitude = selectedOutlet.latitude;
      this.deliveryInformation.outlet_longitude = selectedOutlet.longitude;
      this.deliveryInformation.enabled = selectedOutlet.enable_distance_radius;
      this.deliveryInformation.distance = selectedOutlet.distance_radius;
      this.deliveryInformation.pincode = selectedOutlet.zipcode;
      this.deliveryDataExisits = true;
      this.checkoutDeliveryStorageService.setDeliveryInfo(this.deliveryInformation);
      this.checkoutDeliveryOrderService.setDeliveryObject({
        outlet_id : selectedOutlet.id,
        outlet_name : selectedOutlet.name,
        outlet_latitude: selectedOutlet.latitude,
        outlet_longitude: selectedOutlet.longitude,
        delivery_preferred_time: this.deliveryForm.value.preferredTime,
        delivery_date: this.deliveryInformation.delivery_date,
        delivery_time: this.deliveryInformation.delivery_time,
        delivery_type: this.deliverType
      })
      this.updateHandlingFee(this.deliverType);
      this.notify.emit({"step":"next", "type":this.deliverType});
      if(this.deliverType === ""){
        // this.checkoutShippingOrderService.setShippingMethod(this.deliveryInfo.pickinStoreDisplayName, "", "");
      }
    
      //this.validateDeliveryForm = true;
    }
    else{
      this.validateDeliveryForm = true;
    }
  }

  getPreferredTimings(event){
    this.preferredTimings = event.target.value;
    this.deliveryInformation.delivery_time = "";

    if(this.preferredTimings == "Now"||"Express Delivery 90 Mins")
    {
      this.date = moment().format('YYYY-MM-DD')
      this.deliveryForm.controls.deliverDate.setValue(moment().format('YYYY-MM-DD'))
      this.deliveryForm.controls.deliverTime.setValue("00:00")
    }
  }

  dateChanged() {
    this.deliveryForm.controls.deliverTime.setValue('');
    this.slots = [];
    this.slotRange = [];
    if(this.deliveryForm.value.preferredTime == "Later"||this.deliveryForm.value.preferredTime == "Pre Order"){
      this.date = this.deliveryForm.value.deliverDate;
    }
    else{
      this.date = moment().format("YYYY-MM-DD");
    }
    this.today = moment();
    this.findSlots();
  }

  findSlots() {
    if (this.date == this.today.format("YYYY-MM-DD")) {
      this.acceptanceTiming.delivery_acceptance_timing.forEach(shift => {
        this.slots = [];
        var from = moment(this.date + " " + shift.from,"YYYY-MM-DD hh:mm A");
        var to = moment(this.date + " " + shift.to,"YYYY-MM-DD hh:mm A");
        if (this.today >= from && this.today <= to) {
          while (from <= to) {
            if (from >= this.today) {
              this.createTimeSlotRange(from.format("hh:mm a"));
              this.slots.push(from.format("hh:mm a"));
            }
            from = from.add(this.timeslot, 'minutes');
          }
        }
        else if (this.today <= to) {
          while (from <= to) {
            this.createTimeSlotRange(from.format("hh:mm a"));
            this.slots.push(from.format("hh:mm a"));
            from = from.add(this.timeslot, 'minutes');
          }
        }
      });
    }
    else if (moment(this.date + " 00:00","YYYY-MM-DD hh:mm") >= this.today){
      this.acceptanceTiming.delivery_acceptance_timing.forEach(shift => {
        this.slots = [];
        var from = moment(this.date + " " + shift.from,"YYYY-MM-DD hh:mm A");
        var to = moment(this.date + " " + shift.to,"YYYY-MM-DD hh:mm A");
        while (from <= to) {
            this.createTimeSlotRange(from.format("hh:mm a"));
            this.slots.push(from.format("hh:mm a"));
            from = from.add(this.timeslot, 'minutes');
        }
      });
    }
    else{
      console.log("invalid dates");
    }
  }

  isSoltAvailabalityNow(){
    if (this.date == this.today.format("YYYY-MM-DD")) {
      var flag = false;
      this.acceptanceTiming.delivery_acceptance_timing.forEach(shift => {
        var from = moment(this.date + " " + shift.from,"YYYY-MM-DD hh:mm A");
        var to = moment(this.date + " " + shift.to,"YYYY-MM-DD hh:mm A");
        if (this.today >= from && this.today <= to && !flag) {
          flag = true;
        }
      });
      return flag;
    }
    else {
      return false;
    }
  }

  createTimeSlotRange(slot){
    if(ORG_DETAILS.NAME=='Nesma'&&this.deliverytime!=undefined)
    {
     
     
     //  alert(priorapplied);
    //  alert(this.deliveryForm.value.deliverDate);
    this.slotRange=this.deliverytime
     var todatdate=moment().format('YYYY-MM-DD');
     if(todatdate==this.deliveryForm.value.deliverDate)
     {
      this.deliverytime.forEach(element=>{
        
        var date = new Date();
        date.setHours(0, 0, 0, 0);
        var b;
        if(element.includes('AM'))
        {
          var e=element.split('AM')
          var f=e[0].split(':');
  
         b= moment(date).add(f[0], 'hours').add(f[1],'minutes');
        }
        else{
          var e=element.split('PM')
          var f=e[0].split(':');
          var g=Number(f[0])+12;
         var h=g.toString()
          b= moment(date).add(h, 'hours').add(f[1],'minutes');
        }
        var priorapplied= moment(new Date(b)).subtract(this.prior_time, 'minutes');
        var nowtime=moment();
   if(nowtime>priorapplied)
   {
    //this.deliverytime.splice(element)
    this.slotRange = this.slotRange.filter(item => (item!=element));
   }
 
       })
     }
     else{
      this.slotRange=this.deliverytime
     }
     //yyyy-MM-dd
     
      
    
      


    }else{
      if (this.slots.length > 0){
        let slotString = this.slots[this.slots.length - 1]
        this.slotRange.push(slotString)
      }
      else{
        this.firstSlot = slot;
      }
    }
   
  }

  editDeliveryInfo(){
    // this.slots = [];
    // this.slotRange = [];
    // if(this.deliveryInformation.delivery_preferred_time == "Later"){
    //   this.date = this.deliveryInformation.delivery_date;
    // }
    // else{
    //   this.date = moment().format("YYYY-MM-DD");
    // }
    // this.today = moment();
    // this.findSlots();
    // this.deliverType = this.deliveryInformation.delivery_type;
    // this.preferredTimings = this.deliveryInformation.delivery_preferred_time;

    // if(this.deliveryInformation.delivery_preferred_time === "Later"){
    //   this.deliveryForm.patchValue({
    //     deliveryType: '',
    //     outlet: this.deliveryInformation.outlet_id,
    //     preferredTime : this.deliveryInformation.delivery_preferred_time,
    //     deliverDate: this.deliveryInformation.delivery_date,
    //     deliverTime: this.deliveryInformation.delivery_time
    //   })
    //   this.deliveryForm.controls.deliverTime.setValue(this.deliveryInformation.delivery_time)
    // }
    // else{
    //   this.deliveryForm.patchValue({
    //     deliveryType: '',
    //     outlet: this.deliveryInformation.outlet_id,
    //     preferredTime : this.deliveryInformation.delivery_preferred_time,
    //     deliverDate: '',
    //     deliverTime: ''
    //   })
    // }
    this.deliveryDataExisits = false;
    this.notify.emit({name:"closeRemaining"})
  }

  // deliveryDataExists(){
  //   if(this.checkoutDeliveryStorageService.getDeliveryInfo()){
  //     this.deliveryInformation = this.checkoutDeliveryStorageService.getDeliveryInfo();
  //     this.getDiscounts(this.deliveryInformation.delivery_date, this.deliveryInformation.delivery_time);
  //     this.updateHandlingFee(this.deliveryInformation.delivery_type);
  //     this.deliverType = this.deliveryInformation.delivery_type;
  //     this.deliveryDataExisits = true;
  //     this.notify.emit({"step":"next"});
  //   }
  // }

  getDiscounts(ddate, dtime){
    let hours = (new Date(ddate +' '+dtime).getTime() - new Date().getTime())/3.6e+6
    this.checkoutDeliveryService.getDiscountData(ddate, dtime, hours).then(
      respData => {
        let res:any = respData;
        if(res !== ""){
          if(res.type === this.FIXEDAMOUNT_LABLE){
            this.orderSummaryInitialService.updateAdminFormEvent({name: "discount", value: res});
          }
          else if(res.type === this.PERCENTAGE_LABEL){
            this.orderSummaryInitialService.updateAdminFormEvent({name: "discount", value: res});
          }
          else{
            this.orderSummaryOrderService.orderObj.discountAmount = 0;
            this.orderSummaryOrderService.orderObj.discountTax = 0;
            this.orderSummaryInitialService.updateAdminFormEvent({name: "removeDiscount"});
          }
        }
        else{
          this.orderSummaryOrderService.orderObj.discountAmount = 0;
          this.orderSummaryOrderService.orderObj.discountTax = 0;
          this.orderSummaryInitialService.updateAdminFormEvent({name: "removeDiscount"});
        }
      }
    )
  }

  updateHandlingFee(type){
    this.checkoutDeliveryService.getHandlingFeeInfo().then(
      respData => {
        let res:any = respData;
        this.deliveryInfo = res;
        if(type === "Delivery"){
          this.orderSummaryInitialService.updateAdminFormEvent({name: "handlingFee", value: this.deliveryInfo.handlingFee.delivery});
        }
        else{
          this.orderSummaryInitialService.updateAdminFormEvent({name: "handlingFee", value: this.deliveryInfo.handlingFee.pickinstore});
        }
    })
    
  }

  resetDelivery(){
    this.deliveryDataExisits = true;
  }

  getDeliveryTime(dtime){
    var time = dtime;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if(AMPM == "pm" && hours<12) hours = hours+12;
    if(AMPM == "am" && hours==12) hours = hours-12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if(hours<10) sHours = "0" + sHours;
    if(minutes<10) sMinutes = "0" + sMinutes;
    return(sHours + ":" + sMinutes);
  }
}
