<div class="faq-page" *ngIf="CONFIG.title !== 'Deera'">
  <br>
<br>
<h2 class="text-center text-primary"> How can we help you?</h2>
<br>
<form class="example">
  <div class="row">
    <div class="col-sm-1"></div>
    <input class="col-sm-10" type="text" placeholder="&#xF002;  Search..  " name="search" (keyup)="filter($event)">
    <!-- <button type="submit"><i class="fa fa-search"></i></button> -->
    <div class="col-sm-1"></div>
  </div>
</form>

<br>
<br>

<div class="container">
  <div *ngFor="let item of dataOptions;let i = index;">
    <button class="accordion" (click)="toggleAccordian($event, i)" id="{{item.title}}"> {{item.title}} </button>
    <div class="panel" *ngFor="let child of item.childProperties" hide="!item.isActive">
      <p> {{child.description}} </p>
    </div>
    <!-- <hr> -->
  </div>
  
</div>

<br><br><br>
</div>


<section>
  <div style="padding: 30px;padding-left: 18%;"  *ngIf="CONFIG.title==='Deera'">
  <div class="cardtext1" style="height: 100% !important;">
    <div class="card-header">
  <!-- <div class="row"> -->
    <!-- <div class="col-sm-1"></div> -->
    <!-- <input class="col-sm-10" type="text" placeholder="&#xF002;  Search..  " name="search" (keyup)="filter($event)"> -->
    <!-- <button type="submit"><i class="fa fa-search"></i></button> -->
    <!-- <div class="col-sm-1"></div> -->
  <!-- </div> -->
  <!-- <div class="row"> -->
    <!-- <div class="col-md-12" style="margin-top:20px;text-align:left"> -->
      <h2 class="text-center text-primary"> FAQ</h2>
      <!-- <p style="color: #2554C7;"><b>Ziplock Pouch / Stand up Pouch / Standee Pouch</b></p>
      <p><b>Size Chart</b></p>
      <p>In case you are unsure about what size to order, you can order our size sampler here or you can call us on our Customer care number. We will be happy to guide you. </p>
      <p><b>1.What is the standee pouch made of ? </b></p>
      <p>A.	Brown kraft paper pouch is made of brown kraft paper on the outer layer and the inner layer is made of polythene food grade plastic</p>
      <p><b>2.How much can I fit in a stand up pouch ? </b></p>
      <p>A.	Each of our pouches come in different sizes which start from 50 gm and up to 3kg depending on the products that are going inside the pouch.</p>
      <p><b>3.What sizes are the ziplock pouches available in ?</b> </p>
      <p>A.	We have different sizes and different colours available with us. Please refer to the size chart here(link to size chart)</p>
      <p><b>4.How will I know which is the right size ziplock pouch for my needs ? </b></p>
      <p>A.	We want our customers to find the right size for their needs. Please click here(link to product and size chart) to find a chart which would help you decide on the right side.</p>
      <p><b>5.What colours are the pouches available in ? </b></p>  
      <p>A.	We have plenty of colour options for your needs. Please click here(link to photo gallery) to check out the options we have.</p>
      <p><b>6.I would like to order 500 pieces. It only shows “Pack of 25”. What can I do ?</b></p>
      <p>A.	While buying the pouches, you can search for a pack of 25 option and choose quantity as 20. Discounts are automatically applied to your cart.</p>
      <p><b>7.Will I get discounts if I order more ? </b></p>
      <p>A.	Yes, the more quantity you add the higher discounts you get</p>
      <p><b>8.Is the pouch suitable for storing beads ? </b> </p>
      <p>A.	Yes, you can store beads. </p>
      <p><b>9.Is the pouch suitable for carrying small clothes ? </b></p>
      <p>A.	Yes, the large size pouches can be used to pack small sized baby clothes or under garments, napkins etc</p>
      <p><b>10. Can I use the pouch to store liquids like oily pickles ? </b></p>
      <p>A.	The kraft paper pouches are not suitable for the same. However, the plastic colour pouches and silver pouches can be used for this purpose. Please click here(link for plastic colour pouches and silver pouches)</p>
      <p><b>11.Can I use the pouch to store items in my freezer ? </b></p>
      <p>A.	Yes</p>
      <p><b>12.Can I wash the ziplock pouch under water ? </b></p>
      <p>A. No</p>
      <p><b>13.Can I print on the ziplock pouch ?</b></p>
      <p>A.	Yes</p>
      <p><b>14.Can I stick my brand labels on the stand up pouch ? </b></p>
      <p>A. Yes</p>
      <p><b>15.Is the standee pouch heat sealable ? </b></p>  
      <p>A. Yes</p>
      <p><b>16.Are these pouches biodegradable ? </b></p>
      <p>A.No, these are not bio degradable. But, these are reusable over multiple times.</p>
      <p><b>17.Are these pouches recyclable ?</b></p>
      <p>A.Yes</p>
      <p><b>18. Can I reuse the ziplock pouch ?</b></p>
      <p>A.Yes</p>
      <p><b>Cupcake Liners / Expandable cake cups</b></p>
      <p>Size Chart</p><br>
      <p><b>In case you are unsure about what size to order, you can order our size sampler here or you can call us on our Customer care number. We will be happy to guide you. </b></p>
      <p><b>What sizes are available in the cake cups ? </b></p>
      <p><b>What are the expandable cupcake cups ? </b></p>
      <p><b>Can I put these liners in a microwave oven ? </b></p>
      <p><b>Are these liners suitable for baking muffins ? </b></p>
      <p><b>Are these cupcake papers suitable for young children ? </b></p>
      <p><b>What temperature settings should I use in the oven ?</b></p>
      <p><b>Are these cupcake liners bio degradable ? </b></p>
      <p><b>Are these cupcake cups reusable ? </b></p>
      <p><b>I need a large number of these for my business. Do I get discounts when I order more ? </b></p>
      <br>
      <br>
      <p style="color: #2554C7;"><b>Disposable wooden Cutlery</b></p>
      <p><b>Size Chart</b></p>
      <p>In case you are unsure about what size to order, you can order our size sampler <b>here</b> or you can call us on our Customer care number. We will be happy to guide you.</p>
      <p><b>I want to order a large quantity for a party. Are you able to provide me with pricing by the box ? </b></p>
      <p><b>Are the wooden cutlery bio degradable ? </b></p>
      <p><b>Are these cupcake cups reusable ? </b></p>
      <p><b>How is this cutlery eco-friendly ?  </b></p>
      <br>
      <br>
      <p style="color: #2554C7;"><b>Bagasse Plates, Bagasse Bowls & Bagasse Water Cups</b></p>
      <p><b>Size Chart</b></p>
      <p>In case you are unsure about what size to order, you can order our size sampler <b>here</b> or you can call us on our Customer care number. We will be happy to guide you. </p>
      <p><b>I want to order a large quantity for a party. Are you able to provide me with pricing by the box ? </b></p>
      <p><b>What is bagasse ? </b></p>
      <p><b>Is the bagasse serveware bio degradable ? </b></p>
      <p><b>Are these plates, bowls and cups reusable ? </b></p>
      <p><b>How is this an eco-friendly option ? </b></p> -->
      <!-- <p class="innerHTML" [innerHTML]="content1"></p> -->
      <!-- <div class="innerHTML" [innerHTML]="content1"></div> -->
        <div id= "faq-content"></div>
      <p style="color: #2554C7;"><b>If You Didn't Find The Answer Please Contact Us</b></p>
    <div style="text-align:center">
      <button class="btn btn-primary mb-1 buttons" routerLink="/contact-us">Contact Us</button>
    </div>
    
    <!-- </div>   -->
  <!-- </div> -->
</div>
</div>
</div>
</section>