import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {CartService} from '../../cart-page/cart-page.service';

import {Router} from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import {ENDPOINTS, ORG_DETAILS} from '../../app.config';
import { createElementCssSelector } from '@angular/compiler';
import {config} from '../../../configs/config';
import { CartNewService } from '../../cart-new/cart-new.service';
import { LocalStorageService } from '../../auth-service/local-storage.service';

@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.component.html'
})
export class MiniCartComponent implements OnInit {
  CONFIG = config;
  cartData:any = [];

  constructor(public appService : AppService,private cartService : CartService,private datePipe: DatePipe,private router : Router, private cartNewService: CartNewService, private localService: LocalStorageService) { }

  ngOnInit(): void {
   

    if(this.cartNewService.getCartItems() !== null){
      this.cartData = this.cartNewService.getCartItems();
     
      if( this.router.url.includes('/confirmation'))
      {
        
       //this.cartData.splice(0,this.cartData.length)

          this.removeallcartItem(this.cartData.length)
        
        
      }
    }
    this.cartNewService.currentData.subscribe(
      currentData => {
        let curData:any = currentData;
        if(curData.type === "cartData"){
          this.cartData = curData.value;
        }
      }
    )
  }

  removeItem(index)
  {
    this.appService.cartProducts.results.splice(index,1);
    this.cartService.updateCartItem(this.appService.cartProducts.results);
  }

  goToCheckout() {

    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }

    this.appService.postApiCall(requestData, ENDPOINTS.ACCEPTANCE_TIMINGS).subscribe(
      resp => {
        if (resp.success === 1 && resp.result.data.length > 0) {

          var today = moment();

          var acceptanceTiming = resp.result.data[0].order_acceptance_timing;

          var timeAvailable: boolean;

          if (acceptanceTiming === []) {
            this.router.navigate(['/checkout-new'])
            return
          }
          else {
            acceptanceTiming.forEach(element => {
              let from = moment(today.format('YYYY-MM-DD') + " " + element.from,"YYYY-MM-DD hh:mm A")
              let to = moment(today.format('YYYY-MM-DD') + " " + element.to,"YYYY-MM-DD hh:mm A")

              if (today >= from && today <= to) {
                timeAvailable = true
                return
              }
            });
          }
          if (timeAvailable === true) {
            this.router.navigate(['/checkout-new'])
          }
          else {
            if("CONFIG.title === 'Nesma'"){
              this.router.navigate(['/cart-new'],{ state: { shop: false } })
            }
            else{
              this.router.navigate(['/cart'],{ state: { shop: false } })
            }
            
          }

        }
        else
        {
          if("CONFIG.title === 'Nesma'"){
            this.router.navigate(['/cart-new'])
          }
          else{
            this.router.navigate(['/cart'])
          }
          
        }
      },
      err => {
        if("CONFIG.title === 'Nesma'"){
          this.router.navigate(['/cart-new'])
        }
        else{
          this.router.navigate(['/cart'])
        }
      })
  }

  removecartItem(index){
    this.cartData.splice(index,1);
    this.cartNewService.updateToCart(this.cartData);
  }
  removeallcartItem(length){
    this.cartData.splice(0,length);
    this.cartNewService.updateToCart(this.cartData);
  }

}
