import {ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MapsAPILoader } from '@agm/core';

import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { CustomerLoginService } from '../checkout-new/checkout-login/customer-login.service';
import { CustomerLoginStorageService } from '../checkout-new/checkout-login/customer-login-storage.service';
import { ENDPOINTS,ORG_DETAILS } from '../app.config';
import { AlertsService } from '../shared/alerts/alerts.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { AppService } from '../app.service';
import { NavbarService} from '../navbar/navbar.service';
import { RegistrationOfferApiService } from './registration-offer-api.service';
import { RegistrationOfferOrderService } from './registration-offer-order.service';
import { RegistrationOfferInitiationApiService } from './registration-offer-initiation-api.service';

const numPattern = /^[0-9]*$/;
const emailPattern = /^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$/i
const namePattern = /^[a-zA-Z\s.]*$/;

@Component({
  selector: 'app-login-new',
  templateUrl: './login-new.component.html'
})
export class LoginNewComponent implements OnInit {

  BUSINESS_CUSTOMER:string = "BUSINESS CUSTOMER";
  INDIVIDUAL_CUSTOMER:string = "INDIVIDUAL CUSTOMER";
  checkoutAlerts:boolean = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  backToUrl = "/";

  screenType = "initial";
  hideMobile = true;
  userIdType = "";
  loggedinAs = "";
  emailormobile = "";
  showLoginpaswd:boolean = false;
  showregistrationPswd:boolean = false;
  showForgotPswd:boolean = false;
  showotpwithoutpswd:boolean = false;
  showUpdateEmailForm1:boolean = false;
  showUpdateEmailForm3:boolean = false;
  customerType:string = "individual";
  enableBusinessUser:boolean = false;
  emailExist:boolean = false;
  passwordExists:boolean = false;
  resultMessage: any;

  forgotMobile:any;
  is_mandatory = {
    individual:null,
    business:null
  }
  otpResendDuration:Number
  optionss = {
    types: [],
    componentRestrictions: { country: 'IN' },
  };

  validateInitialForm:boolean = false;
  validatePasswordForm:boolean = false;
  validateInitialRegistration:boolean = false;
  validateIndividualRegistration:boolean = false;
  validateBusinessRegistration:boolean = false;
  validateForgotPasswordForm:boolean = false;
  validateOtpLoginWithoutPswd:boolean = false;
  validaterequestOTPLoginForm:boolean = false;
  validateUpdateEmailForm1:boolean = false;
  validateUpdateEmailForm2:boolean = false;
  validateUpdateEmailForm3:boolean = false;

  initialForm: FormGroup;
  passwordForm: FormGroup;
  initialRegistration: FormGroup;
  individualRegistration: FormGroup;
  businessRegistration: FormGroup;
  forgotPasswordForm: FormGroup;
  otpLoginWithoutPswd: FormGroup;
  requestOTPLoginForm: FormGroup;
  updateEmailForm1: FormGroup;
  updateEmailForm2: FormGroup;
  updateEmailForm3: FormGroup;

  // REGISTRATION OFFER DETAILS
  registrationOfferDetails = {
    available:false,
    inidividual:false,
    business:false,
    product_ids:[]
  }

  validateShippingForm:boolean = false;
  location_bloked:boolean = false;
  is_deliverable:boolean = true;
  pickupLatitude:any;
  pickupLongitude:any;
  offerAvailed = false;
  shippingForm: FormGroup;
  latitude;
  longitude;
  zoom;
  geocoder;
  enabledistanceValidation = false;
  distance = 0;
  validAddress:any = "";
  productId:any = "";
  selectedAddress:any = null;

   // BUTTON SPINNERS
   continue1:boolean = false;
   continue2:boolean = false;
   signup1:boolean = false;
   signup2:boolean = false;
   resendMsg:string;
   saveaddSpinner:boolean = false;

   // FOR OFFER
   individualCustomerRegistration:boolean = false;
   businessCustomerRegistration:boolean = false;
   offerOrderId:any = "";

  constructor(
    private formBuilder: FormBuilder,
    private checkoutApiService: CheckoutApiService,
    private customerLoginService: CustomerLoginService,
    private customerLoginStorageService: CustomerLoginStorageService,
    private alertService: AlertsService,
    private appService: AppService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private navbarService: NavbarService,
    private registrationOfferApiService: RegistrationOfferApiService,
    private mapsAPILoader: MapsAPILoader,
    private registrationOfferOrderService: RegistrationOfferOrderService,
    private registrationOfferInitiationApiService: RegistrationOfferInitiationApiService
  ) {
    if (this.router.getCurrentNavigation() != undefined && this.router.getCurrentNavigation() != null) {
      if (this.router.getCurrentNavigation().extras.state) {
        this.resultMessage = this.router.getCurrentNavigation().extras.state.result;
      }
    }
   }

  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params['params'].url) {
        this.backToUrl = params['params'].url;
      }
      if (params['params'].productId) {
        this.productId = params['params'].productId;
      }
    })

    this.shippingForm = new FormGroup({
      location: this.formBuilder.control('',[Validators.required]),
      flat_no: this.formBuilder.control('',[Validators.required]),
      landmark: this.formBuilder.control('',[]),
      city: this.formBuilder.control('',[]),
      state:this.formBuilder.control('',[]),
      country: this.formBuilder.control('',[]),
      zipcode: this.formBuilder.control('',[]),
      mobile: this.formBuilder.control('', [Validators.required, Validators.pattern(numPattern), Validators.maxLength(10), Validators.minLength(10)]),
      email: this.formBuilder.control('', [Validators.pattern(emailPattern), Validators.maxLength(50)]),
      full_name: this.formBuilder.control('', [Validators.required, Validators.pattern(namePattern), Validators.maxLength(50)]),
      default_shipping: this.formBuilder.control(false, []),
      latitude: this.formBuilder.control('', []),
      longitude: this.formBuilder.control('', [])
    })

    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder
    });
    this.zoom = 15;

    this.getBusinessSettings();
    this.checkoutAlerts = true;
    this.initialForm = new FormGroup({
      email: this.formBuilder.control('',[Validators.required]),
      mobile: this.formBuilder.control('',[]),
    })

    this.passwordForm = new FormGroup({
      mobileoremail: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.required]),
    })

    this.forgotPasswordForm =  new FormGroup({
      mobileoremail: this.formBuilder.control('',[]),
      otp: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
    })

    this.otpLoginWithoutPswd = new FormGroup({
      mobileoremail: this.formBuilder.control('',[]),
      otp: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.minLength(6), Validators.maxLength(20)])
    })

    this.requestOTPLoginForm = new FormGroup({
      mobileoremail: this.formBuilder.control('', []),
      otp: this.formBuilder.control('',[Validators.required])
    })

    this.updateEmailForm1 =  new FormGroup({
      email: this.formBuilder.control('', []),
      mobile: this.formBuilder.control('',[]),
      otp: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.minLength(6), Validators.maxLength(20)])
    })

    this.updateEmailForm2 =  new FormGroup({
      email: this.formBuilder.control('', []),
      mobile: this.formBuilder.control('',[]),
      otp: this.formBuilder.control('',[Validators.required]),
    })

    this.updateEmailForm3 =  new FormGroup({
      email: this.formBuilder.control('', []),
      mobile: this.formBuilder.control('',[]),
      password: this.formBuilder.control('',[Validators.required, Validators.minLength(6), Validators.maxLength(20)])
    })


    this.initialRegistration = new FormGroup({
      mobile: this.formBuilder.control('',[]),
      email: this.formBuilder.control('', []),
      otp: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.minLength(6), Validators.maxLength(20)]),
      userType: this.formBuilder.control('',[])
    })

    this.individualRegistration = new FormGroup({
      name: this.formBuilder.control('',[Validators.required, Validators.maxLength(80), Validators.minLength(2), Validators.pattern(namePattern)]),
      email: this.formBuilder.control('', [Validators.pattern(emailPattern), Validators.required]),
    })

    this.businessRegistration = new FormGroup({
      name: this.formBuilder.control('',[Validators.required, Validators.maxLength(80), Validators.minLength(2), Validators.pattern(namePattern)]),
      company: this.formBuilder.control('',[Validators.minLength(1), Validators.maxLength(80)]),
      company_address: this.formBuilder.control('',[]),
      gst_no: this.formBuilder.control('',[Validators.maxLength(15), Validators.minLength(15)]),
      pan_no: this.formBuilder.control('',[Validators.maxLength(10), Validators.minLength(10)]),
    })
  }

  ngAfterViewInit(): void {
    this.alertService.clear();
    if (this.resultMessage !== "") {
      window.scrollTo(0,0);
      this.alertService.success(this.resultMessage, this.options);
      this.cdr.detectChanges();
    }
  }

  // REGISTRATION OFFER
  get f11(){
    return this.shippingForm.controls;
  }

  isDeliverable(){
    return new Promise((resolve, reject) => {
      this.registrationOfferApiService.getOutletDetails().then(
        respData => {
          let res:any = respData;
          if(res){
            this.enabledistanceValidation = res.enabled;
            this.distance = res.distance;
            var latitude:number = this.pickupLatitude = res.latitude;
            var longitude:number = this.pickupLongitude = res.longitude;
            var restaurent = new google.maps.LatLng(latitude, longitude);
            var selectedLocation = new google.maps.LatLng(this.latitude, this.longitude);
            const distance = google.maps.geometry.spherical.computeDistanceBetween(restaurent, selectedLocation);
            let dimensions = distance / 1000;
            this.registrationOfferApiService.distance = dimensions
            resolve(dimensions);
          }
        })
    })
  }

  selectLocation(event) {
    this.latitude = event.coords.lat;
    this.longitude  = event.coords.lng;
    this.zoom = 15;
    this.getLocationdetails();
  }

  cancelSave(){
    this.backtoHome();
    this.shippingForm.reset();
    this.offerAvailed = false;
    this.selectedAddress = null;
    this.is_deliverable = true;
    this.location_bloked = false;
  }

  saveAddress(){
    this.saveaddSpinner = true;
    if(this.shippingForm.value.location !== this.validAddress){
      this.saveaddSpinner = false;
      this.validateShippingForm = true;
      this.shippingForm.controls['location'].setErrors({'incorrect1':true});
    }
    if(this.shippingForm.value.mobile === "0000000000"){
      this.saveaddSpinner = false;
      this.validateShippingForm = true;
      this.shippingForm.controls['mobile'].setErrors({'incorrect':true});
    }
    var re = /'/gi;
    var re1 = / /gi;
    let name = this.shippingForm.value.full_name;
    if(name !== ""){
      name = name.replace(re, "")
      name = name.replace(re1, "")
      if(name.length === 0){
        this.saveaddSpinner = false;
        this.validateShippingForm = true;
        this.shippingForm.controls['full_name'].setErrors({'incorrect':true});
      }
    }
    if(this.shippingForm.valid){
      this.validateShippingForm = false;
      this.selectedAddress = {
        customer_id:0,
        alias:"",
        first_name:this.shippingForm.value.full_name,
        last_name:"",
        company_name:"",
        entire_location:this.shippingForm.value.location,
        address1:this.shippingForm.value.flat_no,
        landmark: this.shippingForm.value.landmark,
        city: this.shippingForm.value.city,
        state: this.shippingForm.value.state,
        country: this.shippingForm.value.country,
        country_code:"104",
        zipcode: this.shippingForm.value.zipcode,
        email: this.shippingForm.value.email,
        phone_number: this.shippingForm.value.mobile,
        is_preferred_address:this.shippingForm.value.default_shipping,
        address_type:"",
        longitude:this.longitude,
        latitude:this.latitude
      }
      this.registrationOfferApiService.selectedAddress = this.selectedAddress;
      this.registrationOfferInitiationApiService.setOrderDetails().then(
        resData => {
          let resp:any = resData;
          if(resp){
            this.saveaddSpinner = false;
            this.backtoHome();
          }
        }
      );
    }
    else{
      this.saveaddSpinner = false;
      this.validateShippingForm = true;
    }
  }

  editCustomerAddress(){
    let address = this.selectedAddress;
    this.shippingForm.patchValue({
      location: address.entire_location,
      flat_no: address.address1,
      landmark: address.landmark,
      city: address.city,
      state:address.state,
      country: address.country,
      zipcode: address.zipcode,
      mobile: address.phone_number,
      email: address.email,
      full_name: address.first_name,
      default_shipping: address.is_preferred_address,
      latitude: address.latitude,
      longitude:address.longitude
    })
    this.longitude = address.longitude;
    this.latitude = address.latitude;
    this.validAddress = address.entire_location;
    this.geocoder.geocode({ 'location': { lat: address.latitude, lng: address.longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 14
        }
        else {}
      }
    })
    this.openMsgModel();
  }


  addAddress(address){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {
        "create_address": address
      }
    }
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.CREATE_CUSTOMER_ADDRESSES, requestData).then(
      respData => {
        let res:any = respData;
        if(res.success){

        }
      }
    )

  }

  changeAddress(address:any){
    this.shippingForm.controls.location.setValue(address.formatted_address);
    this.latitude = address.geometry.location.lat()
    this.longitude = address.geometry.location.lng()
    this.getLocationdetails();
    this.isDeliverable().then(
      respData => {
        let res:any = respData;
        if(this.enabledistanceValidation && this.distance > 0){
          if(res > this.distance){
            this.is_deliverable = false;
            this.shippingForm.controls['location'].setErrors({'incorrect':true});
          }
          else{
            this.is_deliverable = true;
          }
        }
        else{
          this.is_deliverable = true;
        }
      }
    )
  }

  getLocationdetails(){
    this.geocoder.geocode({ 'location': { lat: this.latitude, lng: this.longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 14;
          this.validAddress = results[0].formatted_address;
          this.shippingForm.controls.location.setValue(results[0].formatted_address);
          results[0].address_components.forEach(element => {
            if (element.types.includes("administrative_area_level_2")) {
              this.shippingForm.controls.city.setValue(element.long_name);
            }
            if (element.types.includes("administrative_area_level_1")) {
              this.shippingForm.controls.state.setValue(element.long_name);
            }
            if (element.types.includes("country")){
              this.shippingForm.controls.country.setValue(element.long_name);
            }
            if (element.types.includes("postal_code")){
              this.shippingForm.controls.zipcode.setValue(element.long_name);
            }
          });
          this.isDeliverable().then(
            respData => {
              let res:any = respData;
              if(this.enabledistanceValidation && this.distance > 0){
                if(res > this.distance){
                  this.is_deliverable = false;
                  this.shippingForm.controls['location'].setErrors({'incorrect':true});
                }
                else{
                  this.is_deliverable = true;
                }
              }
              else{
                this.is_deliverable = true;
              }
            }
          )
        }
      }
    })
  }

  getCurrentLocation(){
    this.longitude = null;
    this.latitude = null;
    navigator.geolocation.getCurrentPosition(position => {
      this.location_bloked = false;
      this.latitude = position.coords.latitude
      this.longitude = position.coords.longitude
      this.getLocationdetails();
    }, error => {
      if(error.code === 1){
        this.location_bloked = true;
      }
    }, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    })

  }

  get f(){
    return this.initialForm.controls;
  }

  get f1(){
    return this.passwordForm.controls;
  }

  get f2(){
    return this.initialRegistration.controls;
  }

  get f3(){
    return this.individualRegistration.controls;
  }

  get f4(){
    return this.businessRegistration.controls;
  }

  get f5(){
    return this.forgotPasswordForm.controls;
  }

  get f6(){
    return this.otpLoginWithoutPswd.controls;
  }

  get f7(){
    return this.requestOTPLoginForm.controls;
  }

  get f8(){
    return this.updateEmailForm1.controls;
  }

  get f9(){
    return this.updateEmailForm3.controls;
  }

  get f10(){
    return this.updateEmailForm2.controls;
  }

  getBusinessSettings(){
    this.customerLoginService.getBusinessUserSettings().then(
      respData => {
        let resp:any = respData;
        this.enableBusinessUser = resp;
        // GET CHECKOUT SETTINGS
        this.customerLoginService.checkoutSettings().then(
          settings => {
            let setting:any = settings;
            if(setting.mandatory){
              setting.customers.forEach(custType => {
                if(custType.toUpperCase() === this.INDIVIDUAL_CUSTOMER){
                  this.is_mandatory.individual = true;
                }
                if(custType.toUpperCase() === this.BUSINESS_CUSTOMER){
                  this.is_mandatory.business = true;
                }
                if(custType === "ALL"){
                  this.is_mandatory.individual = true;
                  this.is_mandatory.business = true;
                }
              });

            }
          }
        )
      }
    )
  }

  changeForm(formType){
    if(formType === "initialstate"){
      this.initialForm.reset();
      this.validateInitialForm = false;
      this.hideMobile = true;
      this.passwordForm.reset();
      this.validatePasswordForm = false;
      this.initialRegistration.reset();
      this.validateInitialRegistration = false;
      this.screenType = "initial"
      this.resetForms();
      this.registrationOfferApiService.clearOnChange();
    }
    else if(formType === 'loggedOut'){
      this.initialForm.reset();
      this.validateInitialForm = false;
      this.hideMobile = true;
      this.passwordForm.reset();
      this.validatePasswordForm = false;
      this.initialRegistration.reset();
      this.validateInitialRegistration = false;
      this.individualRegistration.reset();
      this.validateIndividualRegistration = false;
      this.businessRegistration.reset();
      this.validateBusinessRegistration = false;
      this.screenType = "loggedOut";
      this.resetForms();
      this.registrationOfferApiService.clearOnChange();
    }
    else if(formType === 'forgotPassword'){
      if(this.userIdType === "email"){
        this.forgotMobile = this.passwordForm.controls.mobileoremail.value;

        this.forgotPasswordForm.controls.mobileoremail.setValue(this.passwordForm.controls.mobileoremail.value);
        this.sendOTPToEmail(this.passwordForm.controls.mobileoremail.value).then(
          respData1 => {
            let res1:any = respData1;
            if(res1){
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.forgotMobile
              this.alertService.success('OTP has been sent to your registered Email.', this.options);
              this.screenType = "forgotPassword";
            }
          }
        )
      }
      else{

        this.forgotMobile = this.passwordForm.controls.mobileoremail.value;
        this.forgotPasswordForm.controls.mobileoremail.setValue(this.passwordForm.controls.mobileoremail.value);
        this.sendOTP(this.passwordForm.controls.mobileoremail.value).then(
          respData1 => {
            let res1:any = respData1;
            if(res1){
            this.otpResendDuration = new Date().getTime() + 5*60*1000;
            this.resendMsg=this.forgotMobile

            this.alertService.success('OTP has been sent to your registered Mobile Number.', this.options);
              this.screenType = "forgotPassword";
            }
          }
        )
      }

    }
  }

  resetForms(){
    this.initialForm.reset();
    this.passwordForm.reset();
    this.initialRegistration.reset();
    this.individualRegistration.reset();
    this.businessRegistration.reset();
    this.forgotPasswordForm.reset();
    this.validateInitialForm = false;
    this.validatePasswordForm = false;
    this.validateInitialRegistration = false;
    this.validateIndividualRegistration = false;
    this.validateBusinessRegistration = false;
    this.validateForgotPasswordForm = false;
    this.shippingForm.reset();
    this.validateShippingForm = false;
    this.customerType = "individual";
    this.offerAvailed = false;
    this.location_bloked = false;
    this.is_deliverable = true;
    this.pickupLatitude = undefined;
    this.pickupLongitude = undefined;
    this.offerAvailed = false;
    this.latitude = undefined;
    this.longitude = undefined;
    // this.zoom = undefined;
    // this.geocoder = undefined;
    this.enabledistanceValidation = false;
    this.distance = 0;
    this.validAddress = "";
    this.selectedAddress = null;
  }

  verifyUserDetails(){
    this.continue1 = true;
    var re_mobile = new RegExp(numPattern);
    if(re_mobile.test(this.initialForm.controls.mobile.value)){
      if(this.initialForm.controls.mobile.value.length !== 10){
        this.continue1 = false;
        this.validateInitialForm = true;
        this.initialForm.controls['mobile'].setErrors({'incorrect':true});
      }
      else{
          this.getUserDetails(this.initialForm.controls.mobile.value, 'mobile').then(
            respDt => {
              let respData:any = respDt;
              if(respData.exist){
                this.validateInitialForm = true;
                if(respData.email){
                  this.continue1 = false;
                  this.initialForm.controls['mobile'].setErrors({'exists':true});
                }
                else{
                  if(respData.password){
                    this.updateEmailForm3.controls.email.setValue(this.initialForm.controls.email.value);
                    this.updateEmailForm3.controls.mobile.setValue(this.initialForm.controls.mobile.value);
                    this.screenType = "updateEmailForm3";
                    this.continue1 = false;
                  }
                  else{
                    this.updateEmailForm1.controls.email.setValue(this.initialForm.controls.email.value);
                    this.updateEmailForm1.controls.mobile.setValue(this.initialForm.controls.mobile.value);
                    this.sendOTPToEmail(this.initialForm.controls.email.value).then(
                      respData => {
                        let res:any = respData;
                        if(res){
                          this.screenType = "updateEmailForm1";
                          this.continue1 = false;
                          this.otpResendDuration = new Date().getTime() + 5*60*1000;
                          this.resendMsg=this.initialForm.controls.email.value;
                          
                          this.alertService.success('OTP has been sent to '+ this.initialForm.controls.email.value, this.options);
                        }
                      }
                    )
                  }
                }

              }
              else{
                this.registrationOfferApiService.getRegistrationOffer().then(
                  regData => {
                    let regRes:any = regData;
                    if(regRes){
                      this.registrationOfferDetails = regRes;
                    }
                  }
                )
                this.initialRegistration.controls.mobile.setValue(this.initialForm.controls.mobile.value);
                this.initialRegistration.controls.email.setValue(this.initialForm.controls.email.value);
                this.sendOTP(this.initialForm.controls.mobile.value).then(
                  respData => {
                    if(respData){
                      this.hideMobile = true;
                      this.screenType = "initialRegistration";
                      this.continue1 = false;
                      this.otpResendDuration = new Date().getTime() + 5*60*1000;
                      this.resendMsg=this.initialRegistration.controls.mobile.value;
                      this.alertService.success('OTP has been sent to '+ this.initialRegistration.controls.mobile.value, this.options);
                    }
                  }
                )
              }
            }
          )
      }
    }
    else{
      this.continue1 = false;
      this.validateInitialForm = true;
      this.initialForm.controls['mobile'].setErrors({'incorrect':true});
    }
  }

  verifyUser(){
    if(this.initialForm.valid){
      this.continue2 = true;
      var re_email = new RegExp(emailPattern);
      var re_mobile = new RegExp(numPattern);
      if(re_email.test(this.initialForm.controls.email.value)){
        this.userIdType = "email";
        this.getUserDetails(this.initialForm.controls.email.value, 'email').then(
          respDat => {
            let respData:any = respDat;
            if(respData.exist){
              this.loggedinAs = "email";
              if(respData.password){
                this.screenType = "login";
                this.passwordForm.controls.mobileoremail.setValue(this.initialForm.controls.email.value);
                this.continue2 = false;
              }
              else{
                this.sendOTPToEmail(this.initialForm.controls.email.value).then(
                  respData => {
                    if(respData){
                      this.continue2 = false;
                      this.screenType = "otploginwithoutpswd";
                      this.otpLoginWithoutPswd.controls.mobileoremail.setValue(this.initialForm.controls.email.value);
                      this.otpResendDuration = new Date().getTime() + 5*60*1000;
                      this.resendMsg=this.initialForm.controls.email.value
                      this.alertService.success('OTP has been sent to '+ this.initialForm.controls.email.value, this.options);
                    }
                  }
                )

              }
            }
            else{
              this.hideMobile = false;
              this.continue2 = false;
              this.initialRegistration.controls.email.setValue(this.initialForm.controls.email.value);
            }
          }
        )
      }
      else if(re_mobile.test(this.initialForm.controls.email.value)){
          if(this.initialForm.controls.email.value.length !== 10){
            this.continue2 = false;
            this.validateInitialForm = true;
            this.initialForm.controls['email'].setErrors({'incorrect':true});
          }
          else{
            this.loggedinAs = "";
            this.userIdType = "mobile";
            this.getUserDetails(this.initialForm.controls.email.value, 'mobile').then(
              respDatas => {
                let respData:any = respDatas;
                if(respData.exist){
                  if(respData.password){
                    this.screenType = "login";
                    this.continue2 = false;
                    this.passwordForm.controls.mobileoremail.setValue(this.initialForm.controls.email.value);
                  }
                  else{
                    this.sendOTP(this.initialForm.controls.email.value).then(
                      respData => {
                        if(respData){
                          this.continue2 = false;
                          this.screenType = "otploginwithoutpswd";
                          this.otpLoginWithoutPswd.controls.mobileoremail.setValue(this.initialForm.controls.email.value);
                          this.otpResendDuration = new Date().getTime() + 5*60*1000;
                          this.resendMsg=this.initialForm.controls.email.value;
                          this.alertService.success('OTP has been sent to '+ this.initialForm.controls.email.value, this.options);
                        }
                      }
                    )
                  }

                }
                else{
                  this.registrationOfferApiService.getRegistrationOffer().then(
                    regData => {
                      let regRes:any = regData;
                      if(regRes){
                        this.registrationOfferDetails = regRes;
                      }
                    }
                  )
                  this.initialRegistration.controls.mobile.setValue(this.initialForm.controls.email.value);
                  this.sendOTP(this.initialForm.controls.email.value).then(
                    respData => {
                      if(respData){
                        this.hideMobile = true;
                        this.continue2 = false;
                        this.screenType = "initialRegistration";
                        this.otpResendDuration = new Date().getTime() + 5*60*1000;
                        this.resendMsg=this.initialRegistration.controls.mobile.value
                        this.alertService.success('OTP has been sent to '+ this.initialRegistration.controls.mobile.value, this.options);
                      }
                    }
                  )
                }
              }
            )
          }
        }
      else{
        this.continue2 = false;
          this.validateInitialForm = true;
          this.initialForm.controls['email'].setErrors({'incorrect':true});
        }
      }
    else{
      this.validateInitialForm = true;
    }
  }

  getUserDetails(mobileorEmail, type){
    return new Promise((resolve, reject) => {
      let reqData = {}
      if(type === "mobile"){
        reqData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
              "find": {
                  "phone_number": mobileorEmail.toString()
              }
          }
        }
      }
      else{
        reqData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
              "find": {
                  "email": mobileorEmail.toString()
              }
          }
        }
      }

      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let user = {
              exist:false,
              password:false,
              email:false
            }
            if(res.data.length > 0){
              user.exist = true;
              if(res.data[0].password && res.data[0].password !== ""){
                user.password = true;
              }
              if(res.data[0].email && res.data[0].email !== ""){
                user.email = true;
              }
              resolve(user)
            }
            else{
              resolve(user);
            }
          }
        }
      )
    })
  }

  sendOTPToEmail(email){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "data": {
            "otp": {
                "email": email
            }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.SEND_OTP_EMAIL, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success === 1){
            resolve(true);
          }
          else{
            resolve(false);
          }
        }
      )
    })
  }

  sendOTP(mobileNo){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "data": {
            "otp": {
                "countrycode": "+91",
                "mobile": mobileNo,
                "otppurpose": "login"
            }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.SEND_OTP, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success === 1){
            resolve(true);
          }
          else{
            resolve(false);
          }
        }
      )
    })
  }

  showLoginPassword(){
    this.showLoginpaswd = !this.showLoginpaswd;
  }

  showRegistrationPassword(){
    this.showregistrationPswd = !this.showregistrationPswd;
  }

  showForgotPassword(){
    this.showForgotPswd = !this.showForgotPswd;
  }

  showOTPWithoutPswd(){
    this.showotpwithoutpswd = !this.showotpwithoutpswd;
  }

  showUpdateEmailForm1M(){
    this.showUpdateEmailForm1 = !this.showUpdateEmailForm1;
  }

  showUpdateEmailForm3M(){
    this.showUpdateEmailForm3 = !this.showUpdateEmailForm3;
  }

  resendOTP(){
    this.sendOTP(this.initialRegistration.controls.mobile.value).then(
      respData => {
        let res:any = respData;
        if(res){
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
          this.resendMsg=this.initialRegistration.controls.mobile.value;
          this.alertService.success('OTP has been sent to '+ this.initialRegistration.controls.mobile.value, this.options);
        }
      }
    )
  }
  resendCode(resendType){

    if(this.otpResendDuration === undefined || this.otpResendDuration < new Date().getTime())
    {
      if(resendType == 'otplogin2'){
      this.resendOTPTo('otplogin2')
      }
      else if(resendType== 'otplogin1'){
      this.resendOTPTo('otplogin1')
      }
      else if(resendType== 'updateEmail1'){
        this.resendOTPTo('updateEmail1') 
        }
      else if(resendType== 'updateEmail2'){
        this.resendOTPTo('updateEmail2') 
        }
      else if(resendType== 'otp1'){
        this.changeForm('forgotPassword')

        }
      else if(resendType== 'forgot'){
        this.changeForm('forgotPassword')
         }
     }
    else
    {

        if(resendType == 'otplogin2'){
        this.alertService.error("OTP has been already sent to "+ this.resendMsg, this.options)

        }
        else if(resendType== 'otplogin1'){
          this.alertService.error("OTP has been already sent to  "+this.resendMsg, this.options)

        }
        else if(resendType== 'updateEmail1'){
          this.alertService.error("OTP has been already sent to "+this.resendMsg, this.options)

          }
        else if(resendType== 'updateEmail2'){
          this.alertService.error("OTP has been already sent to "+ this.resendMsg,this.options)
          }
        else if(resendType== 'otp1'){
            this.alertService.error("OTP has been already sent to "+ this.resendMsg, this.options)
            }
        else if(resendType== 'forgot'){
              this.alertService.error("OTP has been already sent to "+ this.resendMsg, this.options)
              }
        }
  }
  resendOTPTo(receiverField){
    if(receiverField === "otplogin1"){
      if(this.userIdType === "email"){
        this.sendOTPToEmail(this.otpLoginWithoutPswd.controls.mobileoremail.value).then(
          respData => {
            let res:any = respData;
            if(res){
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.otpLoginWithoutPswd.controls.mobileoremail.value;
              this.alertService.success('OTP has been sent to '+this.otpLoginWithoutPswd.controls.mobileoremail.value, this.options);
            }
          }
        )
      }
      else{
        this.sendOTP(this.otpLoginWithoutPswd.controls.mobileoremail.value).then(
          respData => {
            let res:any = respData;
            if(res){
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.otpLoginWithoutPswd.controls.mobileoremail.value
              this.alertService.success('OTP has been sent to '+this.otpLoginWithoutPswd.controls.mobileoremail.value, this.options);
            }
          }
        )
      }
    }
    else if(receiverField === "otplogin2"){
      if(this.userIdType === "email"){
        this.sendOTPToEmail(this.requestOTPLoginForm.controls.mobileoremail.value).then(
          respData => {
            let res:any = respData;
            if(res){
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.requestOTPLoginForm.controls.mobileoremail.value;
              this.alertService.success('OTP has been sent to '+this.requestOTPLoginForm.controls.mobileoremail.value);
            }
          }
        )
      }
      else{
        this.sendOTP(this.requestOTPLoginForm.controls.mobileoremail.value).then(
          respData => {
            let res:any = respData;
            if(res){
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.requestOTPLoginForm.controls.mobileoremail.value;
              this.alertService.success('OTP has been sent to '+this.requestOTPLoginForm.controls.mobileoremail.value, this.options);
            }
          }
        )
      }
    }
    else if(receiverField === "updateEmail1"){
      this.sendOTPToEmail(this.updateEmailForm1.controls.email.value).then(
        respData => {
          let res:any = respData;
          if(res){
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.updateEmailForm1.controls.email.value;
              this.alertService.success('OTP has been sent to '+this.updateEmailForm1.controls.email.value, this.options);
          }
        }
      )
    }
    else if(receiverField === "updateEmail2"){
      this.sendOTPToEmail(this.updateEmailForm2.controls.email.value).then(
        respData => {
          let res:any = respData;
          if(res){
            this.otpResendDuration = new Date().getTime() + 5*60*1000;
            this.resendMsg=this.updateEmailForm2.controls.email.value;
            this.alertService.success('OTP has been sent to '+this.updateEmailForm2.controls.email.value, this.options);
          }
        }
      )
    }
  }

  verifyOTP(mobile, otp){
    return new Promise((resolve, reject) => {
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "data": {
          "otp": {
              "userotp": otp,
              "mobile": mobile
          }
        }
      }
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.VERIFY_OTP, requestData).then(
      respdata => {
        let res:any = respdata;
        if(res.success === 1){
          resolve({status:true, msg:res.message});
        }
        else{
          resolve({status:false, msg:res.message});
        }
    })
    })
  }

  verifyOTPEmail(email, otp){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "data": {
            "otp": {
              "email": email,
              "userotp": otp
            }
          }
        }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.VERIFY_OTP, requestData).then(
        respdata => {
          let res:any = respdata;
          if(res.success === 1){
            resolve({status:true, msg:res.message});
          }
          else{
            resolve({status:false, msg:res.message});
          }
      })
      })
  }

  getUserType(val){
    this.registrationOfferApiService.clearFreeSample();
    this.customerType = val;
  }

  signinWithOtp(){
    if(this.initialRegistration.valid){
      this.validateInitialRegistration = false;
      this.verifyOTP(this.initialRegistration.controls.mobile.value, this.initialRegistration.controls.otp.value).then(
        respData => {
          let res:any = respData;
          if(res.status){
            let customer_type;
            let requestData = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              username: this.initialRegistration.controls.mobile.value,
              password: this.initialRegistration.controls.password.value,
              email: this.initialRegistration.controls.email.value,
              customer_type: this.customerType === "individual" ? "individual customer" : "business customer"
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
              respData1 => {
                let res1:any = respData1;
                if(res1.success === 1){
                  this.customerLoginStorageService.setCustomerData({
                    type: this.customerType === "individual" ? "individual customer" : "business customer",
                    userid:res1.user.id,
                    userName:res1.user.name ? res1.user.name:"",
                    mobileNo: this.initialRegistration.controls.mobile.value ? this.initialRegistration.controls.mobile.value:"",
                    email: this.initialRegistration.controls.email.value ? this.initialRegistration.controls.email.value : "",
                    subscribed:false,
                    logintype:this.loggedinAs
                  })
                  this.customerLoginStorageService.setCustomerObject({
                    customer_id:res1.user.id,
                    customer_name:res1.user.name,
                    phone_number: this.initialRegistration.controls.mobile.value,
                    is_email_opt_in:false
                  })
                  this.setUserData(res1.user.id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                  this.emailormobile = this.initialRegistration.controls.mobile.value;
                  this.userIdType = "mobile";
                  this.screenType = "loggedIn";
                  this.resetForms();
                }
                else{
                  this.alertService.error(res1.message, this.options);
                }

              }
            )
          }
          else{
            this.alertService.error(res.msg, this.options);
          }
        }
      )
    }
    else{
      this.validateInitialRegistration = true;
    }
  }

  signinupdateEmailForm1(){
    if(this.updateEmailForm1.valid){
      this.validateUpdateEmailForm1 = false;
      this.verifyOTPEmail(this.updateEmailForm1.controls.email.value, this.updateEmailForm1.controls.otp.value).then(
        respData => {
          let res:any = respData;
          if(res.status){
            let requestData = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              username: this.updateEmailForm1.controls.mobile.value,
              password: this.updateEmailForm1.controls.password.value,
              email: this.updateEmailForm1.controls.email.value,
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
              respData1 => {
                let res1:any = respData1;
                if(res1.success === 1){
                  let reqData = {
                    "domain_name": ORG_DETAILS.DOMAIN_NAME,
                    "user_id": ORG_DETAILS.USER_ID,
                    "extras": {
                        "find": {
                            "phone_number": this.updateEmailForm1.controls.mobile.value
                        }
                    }
                  }
                  this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
                    respData2 => {
                      let res2:any = respData2;
                      if(res2.success){
                        if(res2.data.length > 0){
                          this.screenType = "loggedIn";
                          this.userIdType = "mobile";
                          this.emailormobile = this.updateEmailForm1.controls.mobile.value;
                          let subscribed = false;
                          if(res2.data[0].has_also_subscribed){
                            subscribed = res2.data[0].has_also_subscribed;
                          }
                          this.customerLoginStorageService.setCustomerData({
                            type:res2.data[0].customer_type,
                            userid:res2.data[0].id,
                            userName:res1.user.name ? res1.user.name :"",
                            mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                            email: res2.data[0].email ? res2.data[0].email : "",
                            subscribed:subscribed,
                            logintype:this.loggedinAs
                          })
                          this.localStorageService.set('AiemaUserName', res2.data[0].name);
                          this.customerLoginStorageService.setCustomerObject({
                            customer_id:res2.data[0].id,
                            customer_name:res1.user.name,
                            phone_number:res2.data[0].phone_number,
                            is_email_opt_in:subscribed
                          })
                          this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                        }
                      }
                    })
                }
                else{
                  this.alertService.error(res1.message, this.options);
                }
              }
            )
          }
          else{
            this.alertService.error(res.msg, this.options);
          }
        })
    }
    else{
      this.validateUpdateEmailForm1 = true;
    }
  }

  signinupdateEmailForm3(){
    if(this.updateEmailForm3.valid){
      this.validateUpdateEmailForm3 = false;
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        email: this.updateEmailForm3.controls.email.value,
        username: this.updateEmailForm3.controls.mobile.value+"-"+ORG_DETAILS.DOMAIN_NAME,
        password: this.updateEmailForm3.controls.password.value
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.LOGIN, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success === 1){
            this.screenType = "loggedIn";
            this.userIdType = "mobile";
            this.emailormobile = this.updateEmailForm3.controls.mobile.value;
            let requestData1 = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              "user_id": ORG_DETAILS.USER_ID,
              "extras": {
                  "find": {
                      "phone_number": this.updateEmailForm3.controls.mobile.value
                  }
              }
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, requestData1).then(
              respData1 => {
                let res1:any = respData1;
                if(res1.success){
                  let subscribed = false;
                  if(res1.data[0].has_also_subscribed){
                    subscribed = res1.data[0].has_also_subscribed;
                  }
                  this.customerLoginStorageService.setCustomerData({
                    type:res1.data[0].customer_type,
                    userid:res1.data[0].id,
                    userName:res.user.name ? res.user.name : "",
                    mobileNo: res1.data[0].phone_number ? res1.data[0].phone_number : "",
                    email: res1.data[0].email ? res1.data[0].email : "",
                    subscribed:subscribed,
                    logintype:this.loggedinAs
                  })
                  this.customerLoginStorageService.setCustomerObject({
                    customer_id:res1.data[0].id,
                    customer_name:res.user.name,
                    phone_number:res1.data[0].phone_number,
                    is_email_opt_in:subscribed
                  })
                  this.setUserData(res1.data[0].id, res.user.domain_name, res.user.name, res.user.phoneNumber);
                }
              }
            )
            this.resetForms();
          }
          else{
            this.alertService.error(res.message, this.options);
          }
        })
    }
    else{
      this.validateUpdateEmailForm3 = true;
    }
  }

  signinupdateEmailForm2(){
    this.validateUpdateEmailForm2 = false;
    if(this.updateEmailForm2.valid){
      this.verifyOTPEmail(this.updateEmailForm2.controls.email.value, this.updateEmailForm2.controls.otp.value).then(
        respData => {
          let res:any = respData;
          if(res.status){
            let requestData = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              username: this.updateEmailForm2.controls.mobile.value,
              email: this.updateEmailForm2.controls.email.value,
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
              respData1 => {
                let res1:any = respData1;
                if(res1.success === 1){
                  let reqData = {
                    "domain_name": ORG_DETAILS.DOMAIN_NAME,
                    "user_id": ORG_DETAILS.USER_ID,
                    "extras": {
                        "find": {
                            "phone_number": this.updateEmailForm2.controls.mobile.value
                        }
                    }
                  }
                  this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
                    respData2 => {
                      let res2:any = respData2;
                      if(res2.success){
                        if(res2.data.length > 0){
                          this.screenType = "loggedIn";
                          this.userIdType = "mobile";
                          this.emailormobile = this.updateEmailForm2.controls.mobile.value;
                          let subscribed = false;
                          if(res2.data[0].has_also_subscribed){
                            subscribed = res2.data[0].has_also_subscribed;
                          }
                          this.customerLoginStorageService.setCustomerData({
                            type:res2.data[0].customer_type,
                            userid:res2.data[0].id,
                            userName:res1.user.name ? res1.user.name : "",
                            mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                            email: res2.data[0].email ? res2.data[0].email : "",
                            subscribed:subscribed,
                            logintype:this.loggedinAs
                          })
                          this.localStorageService.set('AiemaUserName', res2.data[0].name);
                          this.customerLoginStorageService.setCustomerObject({
                            customer_id:res2.data[0].id,
                            customer_name:res1.user.name,
                            phone_number:res2.data[0].phone_number,
                            is_email_opt_in:subscribed
                          })
                          this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                        }
                      }
                    })
                }
                else{
                  this.alertService.error(res1.message, this.options);
                }
              }
            )
          }
          else{
            this.alertService.error(res.msg, this.options);
          }
        })
    }
    else{
      this.validateUpdateEmailForm2 = true;
    }
  }

  signinwithOTPWTPassword(){
    if(this.otpLoginWithoutPswd.valid){
      if(this.userIdType === "email"){
        this.verifyOTPEmail(this.otpLoginWithoutPswd.controls.mobileoremail.value, this.otpLoginWithoutPswd.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let requestData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                password: this.otpLoginWithoutPswd.controls.password.value,
                username: this.otpLoginWithoutPswd.controls.mobileoremail.value,
              }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res1.success === 1){
                    let reqData = {
                      "domain_name": ORG_DETAILS.DOMAIN_NAME,
                      "user_id": ORG_DETAILS.USER_ID,
                      "extras": {
                          "find": {
                              "email": this.otpLoginWithoutPswd.controls.mobileoremail.value
                          }
                      }
                    }
                    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
                      respData2 => {
                        let res2:any = respData2;
                        if(res2.success){
                          if(res2.data.length > 0){
                            this.screenType = "loggedIn";
                            this.emailormobile = this.otpLoginWithoutPswd.controls.mobileoremail.value;
                            let subscribed = false;
                            if(res2.data[0].has_also_subscribed){
                              subscribed = res2.data[0].has_also_subscribed;
                            }
                            this.customerLoginStorageService.setCustomerData({
                              type:res2.data[0].customer_type,
                              userid:res2.data[0].id,
                              userName:res1.user.name ? res1.user.name : "",
                              mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                              email: res2.data[0].email ? res2.data[0].email:"",
                              subscribed:subscribed,
                              logintype:this.loggedinAs
                            })
                            this.localStorageService.set('AiemaUserName', res2.data[0].name);
                            this.customerLoginStorageService.setCustomerObject({
                              customer_id:res2.data[0].id,
                              customer_name:res1.user.name,
                              phone_number:res2.data[0].phone_number,
                              is_email_opt_in:subscribed
                            })
                            this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                          }
                        }
                      })
                  }
                  else{
                    this.alertService.error(res1.message, this.options);
                  }
                }
              )
            }
            else{
              this.alertService.error(res.msg, this.options);
            }
          }
        )
      }
      else if(this.userIdType === "mobile"){
        this.verifyOTP(this.otpLoginWithoutPswd.controls.mobileoremail.value, this.otpLoginWithoutPswd.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let requestData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                password: this.otpLoginWithoutPswd.controls.password.value,
                username: this.otpLoginWithoutPswd.controls.mobileoremail.value,
              }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res1.success === 1){
                    let reqData = {
                      "domain_name": ORG_DETAILS.DOMAIN_NAME,
                      "user_id": ORG_DETAILS.USER_ID,
                      "extras": {
                          "find": {
                              "phone_number": this.otpLoginWithoutPswd.controls.mobileoremail.value
                          }
                      }
                    }
                    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
                      respData2 => {
                        let res2:any = respData2;
                        if(res2.success){
                          if(res2.data.length > 0){
                            this.screenType = "loggedIn";
                            this.emailormobile = this.otpLoginWithoutPswd.controls.mobileoremail.value;
                            let subscribed = false;
                            if(res2.data[0].has_also_subscribed){
                              subscribed = res2.data[0].has_also_subscribed;
                            }
                            this.customerLoginStorageService.setCustomerData({
                              type:res2.data[0].customer_type,
                              userid:res2.data[0].id,
                              userName:res1.user.name ? res1.user.name:"",
                              mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                              email: res2.data[0].email ? res2.data[0].email : "",
                              subscribed:subscribed,
                              logintype:this.loggedinAs
                            })
                            this.localStorageService.set('AiemaUserName', res2.data[0].name);
                            this.customerLoginStorageService.setCustomerObject({
                              customer_id:res2.data[0].id,
                              customer_name:res1.user.name,
                              phone_number:res2.data[0].phone_number,
                              is_email_opt_in:subscribed
                            })
                            this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                          }
                        }
                      })
                  }
                  else{
                    this.alertService.error(res1.message, this.options);
                  }
                }
              )
            }
            else{
              this.alertService.error(res.msg, this.options);
            }
          }
        )
      }
    }
    else{
      this.validateOtpLoginWithoutPswd = true;
    }
  }

  signinwithOTPPassword(){
    if(this.requestOTPLoginForm.valid){
      this.validaterequestOTPLoginForm = false;
      if(this.userIdType === "email"){
        this.verifyOTPEmail(this.requestOTPLoginForm.controls.mobileoremail.value, this.requestOTPLoginForm.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let requestData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                username: this.requestOTPLoginForm.controls.mobileoremail.value,
              }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res1.success === 1){
                    let reqData = {
                      "domain_name": ORG_DETAILS.DOMAIN_NAME,
                      "user_id": ORG_DETAILS.USER_ID,
                      "extras": {
                          "find": {
                              "email": this.requestOTPLoginForm.controls.mobileoremail.value
                          }
                      }
                    }
                    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
                      respData2 => {
                        let res2:any = respData2;
                        if(res2.success){
                          if(res2.data.length > 0){
                            this.screenType = "loggedIn";
                            this.emailormobile = this.requestOTPLoginForm.controls.mobileoremail.value;
                            let subscribed = false;
                            if(res2.data[0].has_also_subscribed){
                              subscribed = res2.data[0].has_also_subscribed;
                            }
                            this.customerLoginStorageService.setCustomerData({
                              type:res2.data[0].customer_type,
                              userid:res2.data[0].id,
                              userName:res1.user.name ? res1.user.name : "",
                              mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                              email: res2.data[0].email ? res2.data[0].email : "",
                              subscribed:subscribed,
                              logintype:this.loggedinAs
                            })
                            this.localStorageService.set('AiemaUserName', res2.data[0].name);
                            this.customerLoginStorageService.setCustomerObject({
                              customer_id:res2.data[0].id,
                              customer_name:res1.user.name,
                              phone_number:res2.data[0].phone_number,
                              is_email_opt_in:subscribed
                            })
                            this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                          }
                        }
                      })
                  }
                  else{
                    this.alertService.error(res1.message, this.options);
                  }
                }
              )
            }
            else{
              this.alertService.error(res.msg, this.options);
            }
          }
        )
      }
      else if(this.userIdType === "mobile"){
        this.verifyOTP(this.requestOTPLoginForm.controls.mobileoremail.value, this.requestOTPLoginForm.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let requestData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                username: this.requestOTPLoginForm.controls.mobileoremail.value,
              }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res1.success === 1){
                    let reqData = {
                      "domain_name": ORG_DETAILS.DOMAIN_NAME,
                      "user_id": ORG_DETAILS.USER_ID,
                      "extras": {
                          "find": {
                              "phone_number": this.requestOTPLoginForm.controls.mobileoremail.value
                          }
                      }
                    }
                    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
                      respData2 => {
                        let res2:any = respData2;
                        if(res2.success){
                          if(res2.data.length > 0){
                            this.screenType = "loggedIn";
                            this.emailormobile = this.requestOTPLoginForm.controls.mobileoremail.value;
                            let subscribed = false;
                            if(res2.data[0].has_also_subscribed){
                              subscribed = res2.data[0].has_also_subscribed;
                            }
                            this.customerLoginStorageService.setCustomerData({
                              type:res2.data[0].customer_type,
                              userid:res2.data[0].id,
                              userName:res1.user.name ? res1.user.name : "",
                              mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                              email: res2.data[0].email ? res2.data[0].email : "",
                              subscribed:subscribed,
                              logintype:this.loggedinAs
                            })
                            this.localStorageService.set('AiemaUserName', res2.data[0].name);
                            this.customerLoginStorageService.setCustomerObject({
                              customer_id:res2.data[0].id,
                              customer_name:res1.user.name,
                              phone_number:res2.data[0].phone_number,
                              is_email_opt_in:subscribed
                            })
                            this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                          }
                        }
                      })
                  }
                  else{
                    this.alertService.error(res1.message, this.options);
                  }
                }
              )
            }
            else{
              this.alertService.error(res.msg, this.options);
            }
          }
        )
      }
    }
    else{
      this.validaterequestOTPLoginForm = true;
    }
  }

  requesttogetOTP(){
    this.requestOTPLoginForm.controls.mobileoremail.setValue(this.passwordForm.controls.mobileoremail.value);
    this.sendOTP(this.passwordForm.controls.mobileoremail.value).then(
      respData => {
        let res:any = respData;
        if(res){
          this.screenType = "requestOTPLoginForm";
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
          this.resendMsg=this.requestOTPLoginForm.controls.mobileoremail.value;
          this.alertService.success('OTP has been sent to '+ this.requestOTPLoginForm.controls.mobileoremail.value, this.options);
        }
      }
    )
  }

  signinwithPassword(){
    if(this.passwordForm.valid){
      this.validatePasswordForm = false;
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        username: this.passwordForm.controls.mobileoremail.value+"-"+ORG_DETAILS.DOMAIN_NAME,
        password: this.passwordForm.controls.password.value
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.LOGIN, requestData).then(
        respData => {
          let res:any = respData;
          // console.log("chk",res)
          if(res.success === 1){
            this.screenType = "loggedIn";
            this.emailormobile = this.passwordForm.controls.mobileoremail.value;
            let requestData1;
            if(this.userIdType === "email"){
              requestData1 = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                "user_id": ORG_DETAILS.USER_ID,
                "extras": {
                    "find": {
                        "email": this.passwordForm.controls.mobileoremail.value
                    }
                }
              }
            }
            else{
              requestData1 = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                "user_id": ORG_DETAILS.USER_ID,
                "extras": {
                    "find": {
                        "phone_number": this.passwordForm.controls.mobileoremail.value
                    }
                }
              }
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, requestData1).then(
              respData1 => {
                let res1:any = respData1;
                if(res1.success){
                  let subscribed = false;
                  if(res1.data[0].has_also_subscribed){
                    subscribed = res1.data[0].has_also_subscribed;
                  }
                  this.customerLoginStorageService.setCustomerData({
                    type:res1.data[0].customer_type,
                    userid:res1.data[0].id,
                    userName:res.user.name ? res.user.name : "",
                    mobileNo: res1.data[0].phone_number ? res1.data[0].phone_number : "",
                    email: res1.data[0].email ? res1.data[0].email : "",
                    subscribed:subscribed,
                    logintype:this.loggedinAs
                  })
                  this.customerLoginStorageService.setCustomerObject({
                    customer_id:res1.data[0].id,
                    customer_name:res.user.name,
                    phone_number:res1.data[0].phone_number,
                    is_email_opt_in:subscribed
                  })

                  this.setUserData(res1.data[0].id, res.user.domain_name, res.user.name,res.user.phoneNumber);
                }
              }
            )
            this.resetForms();
          }
          else{
            this.alertService.error(res.message, this.options);
          }
        }
      )
    }
    else{
      this.validatePasswordForm = true;
    }
  }

  individualUserRegistration(){
    if(this.initialRegistration.controls.password.value === ""){
      this.validateInitialRegistration = true;
      this.initialRegistration.controls['password'].setErrors({'incorrect':true});
    }
    if(this.initialRegistration.valid){
      this.signup1 = true;
      this.validateInitialRegistration = false;
      if(this.individualRegistration.valid){
        this.validateIndividualRegistration = false;
        this.verifyOTP(this.initialRegistration.controls.mobile.value, this.initialRegistration.controls.otp.value).then(
          respData1 => {
            let res1:any = respData1;
            if(res1.status){
              this.localStorageService.set('UserName', this.initialRegistration.value.name);
              this.appService.userName = this.initialRegistration.value.name;
              let user = {
                name: this.individualRegistration.controls.name.value,
                email: this.initialRegistration.controls.email.value === "" || this.initialRegistration.controls.email.value === null || this.initialRegistration.controls.email.value === undefined ? this.individualRegistration.controls.email.value : this.initialRegistration.controls.email.value,
                phone_number: this.initialRegistration.controls.mobile.value,
                password: this.initialRegistration.controls.password.value,
                customer_type: "individual customer"
              }
              let registrationData = {
                domain_name: ORG_DETAILS.DOMAIN_NAME,
                user_id: ORG_DETAILS.USER_ID,
                payload: {
                  create_customer: user
                }
              }
              // LOGIN FORM AUTOMATIC FILL
              this.passwordForm.controls.mobileoremail.setValue(this.initialRegistration.controls.mobile.value);
              this.passwordForm.controls.password.setValue(this.initialRegistration.controls.password.value);
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.REGISTRATION, registrationData).then(
                respData => {
                  let res:any = respData;
                  if(res.success){
                    this.alertService.success(res.message, this.options);
                    if(this.registrationOfferDetails.inidividual && this.offerAvailed){
                      this.individualCustomerRegistration = true;
                    }
                    this.signinwithPassword();
                    this.signup1 = false;
                    // this.changeForm('initialstate')
                    this.hideMobile = true;
                    // FOR REGISTERD USER OFFER
                    if(this.registrationOfferDetails.inidividual && this.offerAvailed){
                      let registerData = res.data[0];
                      this.registrationOfferOrderService.setCustomerDetails({
                        userid : registerData.id,
                        userName : registerData.name,
                        type : registerData.customer_type,
                        subscribed : false,
                        mobileNo : registerData.phone_number
                      }).then(
                        respDataL => {
                          let respL:any = respDataL;
                          if(respDataL){
                            this.registrationOfferInitiationApiService.initiateSampleOrder().then(
                              respDataO =>{
                                let resO:any = respDataO;
                                this.offerOrderId = resO;
                                if(resO){
                                  this.openMsgModel1()
                                  this.clearRegisterOffers();
                                }
                              }
                            )
                          }
                        }
                      )
                      this.selectedAddress.customer_id = registerData.id;
                      this.addAddress(this.selectedAddress);
                    }
                  }
                  else{
                    this.alertService.error(res.message, this.options);
                  }
              })
            }
            else{
              this.signup1 = false;
              this.alertService.error(res1.msg, this.options);
            }
        })
      }
      else{
        this.signup1 = false;
        this.validateIndividualRegistration = true;
      }
    }
    else{
      this.validateInitialRegistration = true;
    }
  }

  businessUserRegistration(){
    if(this.initialRegistration.controls.password.value === ""){
      this.validateInitialRegistration = true;
      this.initialRegistration.controls['password'].setErrors({'incorrect':true});
    }
    if(this.initialRegistration.valid){
      this.signup2 = true;
      this.validateInitialRegistration = false;
      if(this.businessRegistration.valid){
        this.validateBusinessRegistration = false;
        this.verifyOTP(this.initialRegistration.controls.mobile.value, this.initialRegistration.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let user = {
                name: this.businessRegistration.controls.name.value,
                email: this.initialRegistration.controls.email.value,
                phone_number: this.initialRegistration.controls.mobile.value,
                company_name: this.businessRegistration.controls.company.value,
                gst_number: this.businessRegistration.controls.gst_no.value,
                pan_number: this.businessRegistration.controls.pan_no.value,
                company_address: this.businessRegistration.controls.company_address.value,
                password: this.initialRegistration.controls.password.value,
                customer_type: "business customer"
              }
              let registrationData = {
                domain_name: ORG_DETAILS.DOMAIN_NAME,
                user_id: ORG_DETAILS.USER_ID,
                payload: {
                  create_customer: user
                }
              }
              // LOGIN FORM AUTOMATIC FILL
              this.passwordForm.controls.mobileoremail.setValue(this.initialRegistration.controls.mobile.value);
              this.passwordForm.controls.password.setValue(this.initialRegistration.controls.password.value);
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.REGISTRATION, registrationData).then(
                respData => {
                  let res:any = respData;
                  if(res.success){
                    this.alertService.success(res.message, this.options);
                    // this.changeForm('initialstate');
                    this.signup2 = false;
                    if(this.registrationOfferDetails.business){
                      this.businessCustomerRegistration = true;
                    }
                    this.signinwithPassword();
                    this.hideMobile = true;
                    // FOR REGISTERD USER OFFER
                    if(this.registrationOfferDetails.business && this.offerAvailed){
                      let registerData = res.data[0];
                    this.registrationOfferOrderService.setCustomerDetails({
                      userid : registerData.id,
                      userName : registerData.name,
                      type : registerData.customer_type,
                      subscribed : false,
                      mobileNo : registerData.phone_number
                    }).then(
                      respDataL => {
                        let respL:any = respDataL;
                        if(respDataL){
                          this.registrationOfferInitiationApiService.initiateSampleOrder().then(
                            respDataO =>{
                              let resO:any = respDataO;
                              this.offerOrderId = resO;
                              if(resO){
                                this.clearRegisterOffers();
                                this.openMsgModel1()
                              }
                            }
                          )
                        }
                      }
                    )
                    this.selectedAddress.customer_id = registerData.id;
                    this.addAddress(this.selectedAddress);
                    }
                  }
                  else{
                    this.signup2 = false;
                    this.alertService.error(res.message, this.options);
                  }
              })
            }
            else{
              this.signup2 = false;
              this.alertService.error(res.msg, this.options);
            }
          }
        )
      }
      else{
        this.signup2 = false;
        this.validateBusinessRegistration = true;
      }
    }
    else{
      this.validateInitialRegistration = true;
    }
  }

  forgotPassword(){
    if(this.forgotPasswordForm.valid){
      this.validateForgotPasswordForm = false;
      if(this.userIdType === "mobile"){
        this.verifyOTP(this.forgotMobile, this.forgotPasswordForm.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let reqData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                "user_id": ORG_DETAILS.USER_ID,
                "payload": {
                    "update": {
                        "password": this.forgotPasswordForm.controls.password.value
                    }
                },
                "extras": {
                    "find": {
                        "username": this.forgotMobile
                    }
                }
              }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.RESET_PASSWORD, reqData).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res1.success === 1){
                  let requestData1 = {
                      "domain_name": ORG_DETAILS.DOMAIN_NAME,
                      "user_id": ORG_DETAILS.USER_ID,
                      "extras": {
                          "find": {
                              "phone_number": this.forgotMobile
                          }
                      }
                    }
                    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, requestData1).then(
                      respData2 => {
                        let subscribed = false;
                        let res2:any = respData2;
                        if(res2.data[0].has_also_subscribed){
                          subscribed = res2.data[0].has_also_subscribed;
                        }
                        this.customerLoginStorageService.setCustomerData({
                          type:res2.data[0].customer_type,
                          userid:res2.data[0].id,
                          userName:res2.data[0].first_name + " " + res2.data[0].last_name ? res2.data[0].first_name + " " + res2.data[0].last_name : "",
                          mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                          email: res2.data[0].email ? res2.data[0].email : "",
                          subscribed:subscribed,
                          logintype:this.loggedinAs
                        })
                        this.localStorageService.set('AiemaUserName', res2.data[0].name);
                        this.customerLoginStorageService.setCustomerObject({
                          customer_id:res2.data[0].id,
                          customer_name:res2.data[0].first_name + " " + res2.data[0].last_name,
                          phone_number:res2.data[0].phone_number,
                          is_email_opt_in:subscribed
                        })
                        this.setUserData(res2.data[0].id, "", res2.data[0].first_name + " " + res2.data[0].last_name, res2.data[0].phoneNumber);
                        if(this.userIdType === "email"){
                          this.emailormobile = res2.data[0].email;
                        }
                        else{
                          this.emailormobile = res2.data[0].phone_number;
                        }
                        this.screenType = "loggedIn";
                        this.resetForms();
                      }
                    )
                  }
                }
              )
            }
            else{
              this.alertService.error(res.msg, this.options);
            }
          }
        )
      }
      else{
        this.verifyOTP(this.forgotPasswordForm.controls.mobileoremail.value, this.forgotPasswordForm.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let reqData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                "user_id": ORG_DETAILS.USER_ID,
                "payload": {
                    "update": {
                        "password": this.forgotPasswordForm.controls.password.value
                    }
                },
                "extras": {
                    "find": {
                        "username": this.forgotPasswordForm.controls.mobileoremail.value
                    }
                }
              }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.RESET_PASSWORD, reqData).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res1.success === 1){
                  let requestData1 = {
                      "domain_name": ORG_DETAILS.DOMAIN_NAME,
                      "user_id": ORG_DETAILS.USER_ID,
                      "extras": {
                          "find": {
                              "email": this.forgotPasswordForm.controls.mobileoremail.value
                          }
                      }
                    }
                    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, requestData1).then(
                      respData2 => {
                        let subscribed = false;
                        let res2:any = respData2;
                        if(res2.data[0].has_also_subscribed){
                          subscribed = res2.data[0].has_also_subscribed;
                        }
                        this.customerLoginStorageService.setCustomerData({
                          type:res2.data[0].customer_type,
                          userid:res2.data[0].id,
                          userName:res2.data[0].first_name + " " + res2.data[0].last_name ? res2.data[0].first_name + " " + res2.data[0].last_name : "",
                          mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                          email: res2.data[0].email ? res2.data[0].email : "",
                          subscribed:subscribed,
                          logintype:this.loggedinAs
                        })
                        this.localStorageService.set('AiemaUserName', res2.data[0].name);
                        this.customerLoginStorageService.setCustomerObject({
                          customer_id:res2.data[0].id,
                          customer_name:res2.data[0].first_name + " " + res2.data[0].last_name,
                          phone_number:res2.data[0].phone_number,
                          is_email_opt_in:subscribed
                        })
                        this.setUserData(res2.data[0].id, "", res2.data[0].first_name + " " + res2.data[0].last_name, res2.data[0].phoneNumber);
                        if(this.userIdType === "email"){
                          this.emailormobile = res2.data[0].email;
                        }
                        else{
                          this.emailormobile = res2.data[0].phone_number;
                        }
                        this.screenType = "loggedIn";
                        this.resetForms();
                      }
                    )
                  }
                }
              )
            }
            else{
              this.alertService.success(res.msg, this.options);
            }
          }
        )
      }
    }
    else{
      this.validateForgotPasswordForm = true;
    }
  }

  resendOTP1(){
    this.sendOTP(this.forgotMobile).then(
      respData => {
        let res:any = respData;
        if(res){
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
          this.resendMsg=this.forgotMobile
          this.alertService.success('OTP has been sent to '+ this.forgotMobile, this.options);
        }
      }
    )
  }

  requesttogetOTPpswd(){
    this.updateEmailForm2.controls.email.setValue(this.updateEmailForm3.controls.email.value);
    this.updateEmailForm2.controls.mobile.setValue(this.updateEmailForm3.controls.mobile.value);
    this.sendOTPToEmail(this.updateEmailForm3.controls.email.value).then(
      respData => {
        let res:any = respData;
        if(res){
          this.screenType = "updateEmailForm2"
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
          this.resendMsg=this.updateEmailForm3.controls.email.value;
          this.alertService.success('OTP has been sent to '+ this.updateEmailForm3.controls.email.value, this.options);
        }
      }
    )
  }

  setUserData(userId, domainName, userName, phone_number){
    this.localStorageService.set('UserId', userId);
    this.localStorageService.set('domainName', domainName);
    this.appService.loggedin_user = true;
    this.appService.userLoggedIn = true;

    if(userName === "undefined undefined" || userName == undefined || !userName || userName === ""){
      this.localStorageService.set('UserName',  phone_number =='' || phone_number == undefined ? 'New User' : phone_number);
      this.appService.userName = phone_number =='' || phone_number == undefined ? 'New User' : phone_number;
    }
    else{
      this.localStorageService.set('UserName', userName);
      this.appService.userName = userName;
    }
    console.log(this.registrationOfferDetails.available, this.individualCustomerRegistration, this.businessCustomerRegistration, this.offerAvailed , !this.offerOrderId)   
    if(this.productId){
      this.router.navigate(['/company-details1',this.productId,], { queryParams: { fromLogin:'fromLogin' },relativeTo:this.route,  skipLocationChange: false })
    } else  if(!this.offerAvailed && this.offerOrderId === ""){
      this.router.navigateByUrl(this.backToUrl);
    }
    
  }

  availOffer(){
    let htmlcheckBox = document.getElementById('availOffer') as HTMLInputElement
    this.offerAvailed = htmlcheckBox.checked;
    if(this.offerAvailed){
      this.openMsgModel();
    }
    else{
      this.shippingForm.reset();
      this.selectedAddress = null;
    }
  }

  backtoHome(){
    document.getElementById('cancelBtn1').click();
  }

  openMsgModel(){
    document.getElementById('launch-address').click();
  }

  openMsgModel1(){
    document.getElementById('launch-surprise').click();
  }

  closeModel1(){
    document.getElementById('cancelBtn2').click();
    this.router.navigateByUrl(this.backToUrl);
  }

  clearRegisterOffers(){
    this.registrationOfferDetails = {
      available:false,
      inidividual:false,
      business:false,
      product_ids:[]
    }
    this.validateShippingForm  = false;
    this.location_bloked  = false;
    this.is_deliverable  = true;
    this.pickupLatitude = undefined ;
    this.pickupLongitude = undefined  ;
    this.offerAvailed = false;
    this.latitude;
    this.longitude;
    this.zoom;
    this.geocoder;
    this.enabledistanceValidation = false;
    this.distance = 0;
    this.validAddress  = "";
    this.selectedAddress  = null;
      this.optionss = {
      types: [],
      componentRestrictions: { country: 'IN' },
    };
  }

}
