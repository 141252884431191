<!-- <div [innerHtml] = "content"></div> -->
<!DOCTYPE html>
<html *ngIf="config.title!=='Haven' && config.title!=='Deera'">
  <head><title>privacy policy</title></head>
  <body>
    <div class="paddingTB60">
      <div class="container-fluid text-center">
          
          <h3>Privacy Policy</h3>
          <hr>
        </div>
    
    <div style="margin-left: 5px;" id = "privacy-content"></div>
    </div>
  </body>
</html>

<section>
  <div style="padding: 30px;padding-left: 18%;"  *ngIf="config.title==='Deera'">
  <div class="cardtext1" style="height: 100% !important;">
    <div class="card-header">
      <h2 class="text-center text-primary"> Privacy Policy</h2>
      <p class="innerHTML" [innerHTML]="content1"></p>
    
    <!-- </div>   -->
  <!-- </div> -->
</div>
</div>
</div>
</section>

<section>
  <div style="padding: 30px;padding-left: 18%;"  *ngIf="config.title==='Haven'">
    <!-- <h3 class="text-center">TERMS & CONDITIONS</h3> -->
    <!-- <div [innerHTML]="content"></div> -->
    <div class="cardtext" style="height: 100% !important;">
      <div class="card-header" id="delivery-card">
          <!-- <h2 class="card-txt" style="color: #7e8552;">TERMS & CONDITIONS</h2> -->
          <h2 class="text-center card-txt" style="color: #7e8552;">PRIVACY POLICY</h2>
          <h4 class="card-txt" style="color: #805245;margin-top: 10px;">1.What information do we collect?</h4>
              <p class="text-black">We collect information from you when you fill out a form. When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address or phone number.
              </p>
              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">2.What do we use your information for?</h4>
              <p class="text-black">
                Any of the information we collect from you may be used in one of the following ways:<br>
                To improve our website: we continually strive to improve our website offerings based on the information and feedback we receive from you<br>
                To improve customer service: your information helps us to more effectively <br>
                respond to your customer service requests and support needs<br>               
                To process transactions: Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.   
              </p> 
              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">3.Do we use cookies?</h4>
              <p class="text-black">
                We use cookies to manage your order preference, your location preference and keep you signed in to the account.
              </p>
              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">4.Do we disclose any information to outside parties?</h4>
              <p class="text-black">
                We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information 

                may be provided to other parties for marketing, advertising, or other uses.
              </p>
              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">5.Online Privacy Policy Only</h4>
              <p class="text-black">
                This online privacy policy applies only to information collected through our

                website and not to information collected offline.
              </p>
              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">6.Your Consent</h4>
              <p class="text-black">
                By using our site, you consent to our websites privacy policy. Changes to our Privacy Policy If we decide to change our privacy policy, we will send an email notifying you of any changes.
              </p>
              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">7.How users can modify or delete their personal information.</h4>
              <p class="text-black">
                The user can modify of delete their personal information by emailing <b>mahen.nr@gmail.com</b>.
              </p>
              <h4 class="card-txt" style="color: #805245;margin-top: 10px;">8.How users can opt-out of future communications.</h4>
              <p class="text-black">
                Users can opt-out of future communications by sending email to <b>mahen.nr@gmail.com</b>
              </p>
                            
                             
                             
                              
                          </div>
  </div>
  </div>
  
</section>

<!-- <div class="paddingTB60">
    <div class="container-fluid text-center">
        <br>
        <h1>Privacy Policy</h1>
        <br>
        <div class="container-fluid text-center" style="width:765px;" id="accordion" *ngFor="let d of data;let i = index;">
          <div class="form-group row">  
            <div class="input-group">
              
              <a data-toggle="collapse" href="#collapse{{i}}" aria-expanded="true" aria-controls="collapseOne" ><button style="border: 1px solid #d3d3d3;"  id={{i}} (click)="show1(i)" >{{d.title}}</button></a>
              <div class="input-group-append" style="padding-right:5px;">
                
                <a data-toggle="collapse" href="#collapse{{i}}" aria-expanded="true" aria-controls="collapseOne" ><span class="input-group-text" id={{i}} (click)="show1(i)"><i style="color: white;padding-left:10px;"
                [class]="showContent[i] ? 'fa fa-minus' : 'fa fa-plus'" aria-hidden="true"></i></span></a>
              </div>
            </div>
        
            <div style="width:760px;" id="collapse{{i}}" class="collapse" >
              <div class="card" style="margin-top: 0;" >
                <small class="form-text text-muted text-muted-color">
                {{d.content}}
                </small>
              </div>
            </div>
            <br>
        </div>

       
  </div>
</div> -->