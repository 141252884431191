import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeSlider } from '../shared/data/slider';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { AppService } from '../app.service';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';
import { NavbarService} from '../navbar/navbar.service';
import {CartService} from '../cart-page/cart-page.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import { HomeService } from './home.service';
import { config } from '../../configs/config';
import * as metadata from '../../assets/data/default/product-listing.json'
import { MetaDataMapService } from '../meta-data-map.service';
import { CartNewService } from '../cart-new/cart-new.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
// import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, AfterViewInit {
  CONFIG = config;
  totalPrice = 0;
  banner = "";
  public executionData=[];
  promotionData:any;
  featureProductsApiData:any;
  recommededProducts:any = []
  public cartData=[];
  public productList=[];
  products=[];
  fields = metadata.groups[0].fields;
  carouselData:any = [];
  swapData:any;
  slidesStore:any = [];
  showHint:boolean;
  allCategories:any
  category:any
  category1:any
  categoryIcon:any
  active:any
  allCategories1:any
  subCategory:any
  categoryName:any
  subCategory1:any 
  subCategory2:any
  reviews1 = [{"user":"Raj","comment":"Very reliable product"},{"user":"Kumar","comment": "Customized products"},{"user":"Vijay","comment":"High quality product"}, {"user":"Rathika","comment":"Excellent craftmanship"}]
  reviews:any
  customOptions: OwlOptions = {

    
    // navContainerClass: 'owl-nav',
    autoplay: true,
    loop: false,
    navSpeed: 300,
    autoplayHoverPause : true,
    // navClass: [ 'owl-prev', 'owl-next' ],
    navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true,
  }
  showAlerts = true;
  totalLength:any;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(
    public appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private navbarService: NavbarService,
    private cartService : CartService,
    private localService: LocalStorageService,
    private alertService : AlertsService,
    private homeService: HomeService,
    private cartNewService: CartNewService,
    public metaDataMapService: MetaDataMapService
) { }
  public HomePageBannerSlider: any =  HomeSlider;
  
  ngOnInit(): void {
    // console.log(this.CONFIG.HOME_SETTINGS.banner)
    this.cartData=[];
    if(config.title==='Nesma')
    {
      if(this.cartNewService.getCartItems() !== null){
        this.cartData = this.cartNewService.getCartItems();
        
      }

      this.cartNewService.updateToCart(this.cartData);
      
    }
    else{
      this.cartService.updateCartItem(this.appService.cartProducts.results);

    }
    

    
    this.getAllCategories()
    this.getAllReviews()
    var req_feature_product_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 300
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": -1
        }
      }
    }
    this.appService.postApiCall(req_feature_product_data, ENDPOINTS.GET_FEATURED_PRODUCTS).subscribe(
      resp => {
        this.featureProductsApiData = resp;
        this.recommededProducts = this.featureProductsApiData.result.data;
        // this.products=this.recommededProducts
        console.log(this.products)
        this.productList=[];

        // this.totalLength = this.recommededProducts.result.total ? this.recommededProducts.result.total : 0;
        this.recommededProducts.forEach(element => {
          if(element.product_type != 'Variation'){
            this.products.push(element)
            }
         if(element.product_type == 'Variable' || element.type == 'Variable'){
          element.sizeVariable=element.variant.option_values[0];
          element.checkVariantInCart=element.variant.option_values[0].id;
          element.showHint=true;

           element.showPricse =false;
           element.showVariant=true;
           element.price=element.pricing.raw.price_range;
          //  element.isDisabled=true;
        
        
         }else{
           element.showPricse = true;
           element.showVariant=false;
           element.price=element.pricing.raw.price;
           element.isDisabled=false;
    
         }
        });
        if(this.products.length === 2){
          this.products.push(this.products[0]);
          this.products.push(this.products[1]);
        }
        else if(this.products.length === 1){
          this.products.push(this.products[0]);
          this.products.push(this.products[0]);
          this.products.push(this.products[0]);
        }
        else if(this.products.length === 3){
          this.products.push(this.products[0]);
        }
      
        for(let i=0;i<this.recommededProducts.length;i++){
          
          this.slidesStore.push({
            id:this.recommededProducts[i].id,
            img:this.recommededProducts[i].assets[0].image_source,
            alt:'Image_1',
            item:this.recommededProducts[i].name,
            type: this.recommededProducts[i].product_type,
            backtitle: this.recommededProducts[i].name,
            backpara:this.recommededProducts[i].description ? this.recommededProducts[i].description.description : "",
            backtype: this.recommededProducts[i].product_type,
            price:this.recommededProducts[i].pricing.raw.price,
            priceRange:this.recommededProducts[i].pricing.raw.price_range,
            points:'5 Points',
            button:'ADD TO CART',
          


          })
        }
        
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
      this.navbarService.updateAdminFormEvent('clearSearchTerm');
      this.homeService.currentData.subscribe(
        currentData => {
          this.cartData=[]
          if (currentData === 'cartUpdate') {
            for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
              console.log("cartProductsss"+this.appService.cartProducts.results[i].product_id);
              this.cartData.push(this.appService.cartProducts.results[i].product_id);
            }
          }
        }
      )
      
  

  }

  ngAfterViewInit(): void {
    this.getCarouseldetails();
    this.cdRef.detectChanges(); 
    this.route.queryParams.subscribe((param)=>
    {
      // if(this.route.snapshot.queryParams != {} && this.route.snapshot.queryParams.gotoPrepare == 'true')
      if(this.route.snapshot.queryParams.gotoPrepare == 'true')
      {
        var elmnt = document.getElementById('how_to_prepare');
        
        elmnt.scrollIntoView()
      }
    })
  }
  productlistnesma()
  {
    var link="/menu"
    this.router.navigate(['/product-listing'], {queryParams: {menutype:link}, relativeTo:this.route,  skipLocationChange: false });
  }
  getAllReviews() {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 10
        },
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    // MAKE A SERVICE CALL HERE...
    this.appService.postApiCall(requestBody,ENDPOINTS.GETALLREVIEW,).subscribe(
      resp => {
      
        if (resp) {
          
          this.reviews = resp.result.data[0];
          if(this.reviews==undefined || this.reviews.length == 0){
            this.reviews = this.reviews1
          }
          
        
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          this.alertService.error('Session Time Out! Please login Again', this.options)
          this.router.navigate([`/login-new`], { skipLocationChange: false });
        }
        else if (err.error.message) {
          this.alertService.error(err.error.message, this.options)
        }
        else {
          this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
  }


  getProductByFilter(category){
    this.router.navigate(['/product'], {state: { category :  category }, relativeTo:this.route,  skipLocationChange: false })
    this.category = category.name;
    this.category1 = category.name;
    this.categoryIcon = category.category_icon ? category.category_icon : undefined;
    this.active = category.name;
    let reqData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": false,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 25
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "name",
          "sortorder": -1
        }
      }
    }
    this.appService.postApiCall(reqData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
      resp => {
        this.allCategories1 = resp.result.data;
        this.subCategory = '';
        if(this.allCategories1.length > 1){
          for(let i =0;i<this.allCategories1.length;i++){
            if(this.allCategories1[i].name == this.category){
              this.categoryName='';
              // for(let i =0;i<this.allCategories1[i].length;i++){
              if(this.allCategories1[i].subcategories!==undefined){
                this.categoryName=this.allCategories1[i].name;
                // this.subCategory1.push(this.allCategories1[i].subcategories);
                this.subCategory2 = this.subCategory1.concat(this.allCategories1[i].subcategories);
              }
              // }
            } 
          }
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
    //this.getFilterByProduct();
      }

//GET FILTER PRODUCTS
  productDetails(id){
    this.router.navigate(['/product-details',id], {skipLocationChange: false })
  }
  gotoProductList(){
    this.router.navigate(['/product'], {skipLocationChange: false })
  }
  navigateTo(){
    this.router.navigate(['/product'])
  }
  increaseItem(index)
{
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0

  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  { 
    this.showAlerts = true;
    window.scrollTo(0,0);
    // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    if(config.title==='BombayHardware')
    {
      this.alertService.error("Maximum allowed quantity is 1 only",this.options)
    } else {
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    return
  }
  tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}

decreaseItem(index)
{
  if(this.appService.cartProducts.results[index].quantity === 1)
  {
      this.appService.cartProducts.results.splice(index,1);
      this.cartData = [];
      for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
        this.cartData.push(this.appService.cartProducts.results[i].product_id);
      }
      this.appService.totalCartItems = this.cartService.totalCartItems();
      this.cartService.updateCartItem(this.appService.cartProducts.results);
      // for(let i =0;i<this.cartData.length;i++){ 
      //   console.log("decreasee"+this.cartData[i]);
      // }
      // for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
      //   console.log("cartdecreasee"+this.appService.cartProducts.results[i].product_id);
      // }
      return
      
  }
  let tempArray = this.appService.cartProducts.results;
  tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}
showPrice(e){
  let item=e[0];
  let productId=e[2];
  this.products.forEach(element => {
  
    if(element.id === productId){
      element.showHint=false;
       element.checkVariantInCart=item.id;
       element.sizeVariable = item;
       element.pricee=item.pricing.raw.price
   element.showPricse=true;
   element.isactive=item.option;
   element.isDisabled=false;
  
    }else{
      // element.showPricse=false;
    }
  });   
 }

emitter(e){
  console.log(e)
}
addToCarts(item) {
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0
  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  {
    this.showAlerts = true;
    window.scrollTo(0,0);
    // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    if(config.title==='BombayHardware')
    {
      this.alertService.error("Maximum allowed quantity is 1 only",this.options)
    } else {
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    return
  }
  if(item.product_type == 'Simple'){

    this.cartService.addToCart(item);
    
  }
  else if(item.product_type == 'Variation'){

    this.cartService.addToCart(item);
    
  }
else if (item.product_type =='Variable'){
  
  item.sizeVariable.brand={
    name:"",
   }
   item.sizeVariable.categories=[{
     categories:"",
     id:1
   }]
   item.sizeVariable.assets=item.assets;
   item.sizeVariable.name = item.name + ' - ' + item.sizeVariable.option

   
   item.sizeVariable.parentcategories=[]

 
  this.cartService.addToCart(item.sizeVariable)
  console.log(this.appService.cartProducts.results)
  console.log(this.appService.productIdinCart)
}
}

calculateTotal()
{

  if(this.appService.cartProducts.results.length > 0)
  {
    this.totalPrice = 0;
    this.appService.cartProducts.results.forEach((obj)=>
    {
        this.totalPrice += (obj.cost * obj.quantity);
    })
  }
  else
  {
    this.totalPrice = 0;
  }
}


  addtoCart(item){
    this.alertService.clear();
    let tempArray = this.appService.cartProducts.results;
    let cartQty = 0
  
    tempArray.forEach((item)=>
    {
        cartQty += item.quantity;
    })
  
    if(Number(this.localService.get('max_cart_qty')) <= cartQty)
    { 
      this.showAlerts = true;
      window.scrollTo(0,0);
      // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      if(config.title==='BombayHardware')
      {
        this.alertService.error("Maximum allowed quantity is 1 only",this.options)
      } else {
        this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      }
      return
    }

    this.cartService.addToCart(this.recommededProducts[item])
    for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
      console.log("cartProductsss"+this.appService.cartProducts.results[i].product_id);
      this.cartData.push(this.appService.cartProducts.results[i].product_id);
    }
    if(this.CONFIG.title == 'Haven') {
      this.getPromotionBanner(item);
    }
  }

  getPromotionBanner(item)
{
  let obj =
  {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
          "find": {

          }
      }

  }

  this.appService.postApiCall(obj,ENDPOINTS.PROMOTIONS_BANNER).subscribe(
    resp => {
      if (resp) {
        if (resp.success === 1 && resp.status_code === 200) {
          this.banner = resp.result.data[0]['eligible_banner_message']
          if(this.CONFIG.title ==='Haven' || this.CONFIG.title ==='Deera'){
            this.promotionData = resp.result.data;
            // let tempArray = this.appService.cartProducts.results;
            
            // this.appService.cartProducts.results.forEach((item)=>
            // {
              console.log("1111111111"+item.name);
        
              this.promotionData.forEach((data)=>
              {
                console.log("3333333333"+item.pricing.raw.price);
                console.log("eeeeee"+data.product_discount.amount_or_percentage.discounted_percentage);
                // this.productData = data.product_discount.amount_or_percentage.product_details;
                console.log("productnnnn"+data.product_discount.amount_or_percentage.product_details[0].product_name)
                data.product_discount.amount_or_percentage.product_details.forEach((data1)=>{
                  console.log("data1.product_name"+data1.product_name);
                  console.log("item.product_name"+item.name);
                  if(data1.product_name===item.name){
                    console.log("trueeee");
                    this.executionData.push(data1.order_execution);
                    let max = this.executionData.reduce((op, item) => op = op > item ? op : item, 0);
                    console.log("max"+max);
                    if(max===data1.order_execution){
                    if(data.product_discount.amount_or_percentage.discounted_percentage!==0){
                      console.log("truwwwwwww");
                      function minusPercent(n,p) {
                        return n - (n * (p/100));
                      }
                      // let price = minusPercent(475,20);
                      let price = minusPercent(item.pricing.raw.price,data.product_discount.amount_or_percentage.discounted_percentage); 
                      // item.pricing.raw.price = price;
                      console.log("price"+price);
                      this.appService.cartProducts.results.forEach((item1)=>
                      {  
                        if(item.name===item1.product_name){
                          item1.cost=price
                          this.calculateTotal();
                          this.cartService.updateCartItem(this.appService.cartProducts.results);
                        }
                      })
      
                    } else if (data.product_discount.amount_or_percentage.discounted_amount!==0){
                      console.log("item.pricing.raw.price"+item.pricing.raw.price);
                      console.log("discounted_amount"+data.product_discount.amount_or_percentage.discounted_amount);
                     let price = item.pricing.raw.price - data.product_discount.amount_or_percentage.discounted_amount;
                    //  item.pricing.raw.price = price;
                     console.log("price"+price);
                     this.appService.cartProducts.results.forEach((item1)=>
                     {  
                       if(item.name===item1.product_name){
                         item1.cost=price
                         this.calculateTotal();
                         this.cartService.updateCartItem(this.appService.cartProducts.results);
                       }
                     })
                    }
                  }
                  }

                })
         
              })
            // })
          }
        }
        else if (resp.success === 0) {
          if (resp.message) {
            //this.alertService.error(resp.message, this.options);
          }
        }
        else if (resp.message && resp.status_code !== 200) {
          //this.alertService.error(resp.message, this.options);

        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    },
    err => {
      if (err.error.statusCode === 403) {
        //this.alertService.error('Session Time Out! Please login Again', this.options)

      }
      else if (err.error.error.message) {
        //this.alertService.error(err.error.error.message, this.options)
      }
      else {
        //this.alertService.error('Something bad happened. Please try again!', this.options);
      }
    }
  )


}


  getCarouseldetails(){
    // REQUEST DATA
    let request_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
          "find": {}
      }
    }
    // MAKE AN API CALL
    this.appService.postApiCall(request_data, ENDPOINTS.STORE_GALLERY).subscribe(  //LINE 
      resp => {
        let respData = resp.result.data[0];    
        // console.log(resp)
        if(respData.carousel){
          
          if(respData.carousel.slides.length > 0){
            if(respData.carousel.swap_period && respData.carousel.swap_period !== ""){
              this.HomePageBannerSlider['autoplayTimeout'] = Number(respData.carousel.swap_period)
            }            
            this.carouselData = respData.carousel.slides;
            this.swapData = respData.carousel; 
            console.log("data", this.carouselData)
          }
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }


  brandName(brandName){
    return brandName.length > 13 ? brandName.substring(0,11) + '...' : brandName;
  }

  sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
  
        if (typeof x == "string")
        {
            x = (""+x).toLowerCase(); 
        }
        if (typeof y == "string")
        {
            y = (""+y).toLowerCase();
        }
  
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }

  getAllCategories() {
    let reqData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": false,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 25
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "name",
          "sortorder": -1
        }
      }
    }
    this.appService.postApiCall(reqData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
      resp => {
        if(config.title == 'Mayan'){
          this.allCategories = this.sortByKey(resp.result.data,'name');
        }
        else{
          this.allCategories = resp.result.data;
        }
        
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  

  categoryListing(categoryName){
    console.log("categoryName",categoryName)
    this.router.navigate([`/productListing`], { queryParams: { terms: [categoryName, 'Category'], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
  }

  goto(){
    this.router.navigate(['/about-us'])
  }

}
