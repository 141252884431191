<div *ngIf="CONFIG.title !== 'LuckMe' && CONFIG.title !== 'Aiema' && CONFIG.title !== 'BombayHardware'">
    <div class="earthy-card" style="cursor: pointer;">
        <div class="earthy-image" *ngIf="CONFIG.title !== 'Deera'">
            <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                <a (click)="sendProductDetails(product.id)"><img  [src]='product.assets[0].image_source' class="img-fluid"></a>
            </div>
            <div *ngIf="product.assets.length==0 || !product.assets[0].image_source">
                <a (click)="sendProductDetails(product.id)"><img src='../../../../../assets/images/ITRSample.png' class="img-fluid"></a>
            </div>
        </div>
        <div class="earthy-image" *ngIf="CONFIG.title === 'Deera'">
            <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                <a (click)="sendProductDetails(product.id)"><img  style="width: 65%!important;margin-left: 15%!important;" [src]='product.assets[0].image_source' class="img-fluid"></a>
            </div>
            <div *ngIf="product.assets.length==0 || !product.assets[0].image_source">
                <a (click)="sendProductDetails(product.id)"><img style="width: 65%!important;margin-left: 15%!important;" src='../../../../../assets/images/ITRSample.png' class="img-fluid"></a>
            </div>
        </div>
        <div class="earthy-description text-center" style="transform: translate3d(0px, 0px, 0px);">
            <div class="earthy-cart-btn">
                <button *ngIf="product.type === 'Variable'" class="btn btn-primary earthy-btn" a (click)="sendProductDetails(product.id)">SELECT OPTIONS</button>
                <button *ngIf="product.type !== 'Variable' && CONFIG.title != 'ShubhCards' && CONFIG.title != 'Deera'" class="btn btn-primary earthy-btn" a (click)="sendProductDetails(product.id)">VIEW DETAILS</button>
                <button *ngIf="product.type !== 'Variable' && CONFIG.title == 'ShubhCards' || CONFIG.title == 'Deera'" class="btn btn-primary shubhcards-btn" a (click)="sendProductDetails(product.id)">VIEW DETAILS</button>
            </div>
            <div class="earthy-product-title">
                {{product.name | titlecase | productBoxOne : product.name}}
            </div>
            <div class="earthy-worthy-price">
                <span *ngIf="showPricekey==='Sale Price'"> {{CONFIG.title === 'LuckMe' ? 'SR ' : '₹'}} {{product.pricing.raw.price_range? product.pricing.raw.price_range:product.pricing.raw.price}}</span>

                <span *ngIf="priceStrikeOutInHomeScreen==='true' && strikeOutPriceKey==='MSRP'" style="font-size: 12px !important;margin-left:5px !important;text-decoration: line-through;color: grey!important;">₹{{product.pricing.raw.msrp}}</span>
                <span *ngIf="CONFIG.title === 'Deera'" style="font-size: 12px !important;color: grey!important;margin-left:5px;">- ₹{{product.pricing.raw.price/product.unit | number : '1.2-2'}}/piece</span>   

            </div>
            <!-- <div class="earthy-worthy-price"> -->
                <span *ngIf="CONFIG.title === 'Deera'" style="font-size: 12px !important;color: grey!important;">(taxes extra)</span>
            <!-- </div> -->
           

        </div>
        </div>
</div>
<div *ngIf="CONFIG.title === 'LuckMe'">
    <div class="luckme-card" style="cursor: pointer;">
        <div class="luckme-image">
            <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                <a (click)="sendProductDetails(product.id)"><img  [src]='product.assets[0].image_source' class="img-fluid" style="object-fit: fill !important;"></a>
            </div>
            <div *ngIf="product.assets.length==0 || !product.assets[0].image_source">
                <a (click)="sendProductDetails(product.id)"><img src='../../../../../assets/images/ITRSample.png' class="img-fluid" style="object-fit: fill !important;"></a>
            </div>
        </div>
        <div class="luckme-description text-center" style="transform: translate3d(0px, 0px, 0px);">
            <div class="luckme-cart-btn">
                <button *ngIf="product.type === 'Variable'" class="btn btn-primary luckme-btn" a (click)="sendProductDetails(product.id)">SELECT OPTIONS</button>
                <button *ngIf="product.type !== 'Variable'" class="btn btn-primary luckme-btn" a (click)="sendProductDetails(product.id)">ADD TO CART</button>
            </div>
            <div class="luckme-product-title">
                {{product.name | titlecase | productBoxOne : product.name}}
            </div>
            <div class="luckme-worthy-price">
                {{CONFIG.title === 'LuckMe' ? 'SR ' : '₹'}} {{product.pricing.raw.price_range? product.pricing.raw.price_range:product.pricing.raw.price}}
            </div>
        </div>
        </div>
</div>
<div *ngIf="CONFIG.title === 'Aiema'">
    <div id="aiemacards">
        <div class="aiema text-center">
            <div class="aiema-img-holder" style="cursor: pointer;" (click)="sendProductDetails(product.id)">
                <div class="aiema-img text-center">
                    <img style="object-fit: contain !important;" [src]="product.company_profile.logo">
                </div>
            </div>
            <div class="aiema-description-holder">
                <div class="aiema-title">{{product.name}}</div>
                <div class="aiema-subtitle">{{product.categories[0].categories}}</div>
                <div class="aiema-tabs">
                    <ul class="nav nav-tabs">
                        <li class="nav-item" data-toggle="tooltip" data-placement="top" title="Company Profile"><a  data-toggle="tab" href="#profile{{index}}" role="tab"><i class="fa fa-info-circle" aria-hidden="true"></i></a></li>
                        <li class="nav-item" data-toggle="tooltip" data-placement="top" title="Company Contacts"><a data-toggle="tab" href="#contacts{{index}}" role="tab"><i class="fa fa-globe" aria-hidden="true"></i></a></li>
                        <li data-toggle="tooltip" data-placement="top" title="Company Address"><a data-toggle="tab" href="#location{{index}}" role="tab"><i class="fa fa-map-marker" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
                <div class="tab-content">
                    <div class="aiema-profile tab-pane fade show active" id="profile{{index}}">
                        <div class="row">
                            <div class="col-sm-3 profile-title">Services:</div>
                            <div class="col-sm-9 profile-description">{{product.company_profile.type_of_services ? product.company_profile.type_of_services : '-'}}</div>
                        </div>
                        <div class="row" style="padding-top: 5px;">
                            <div class="col-sm-3 profile-title">Business:</div>
                            <div class="col-sm-9 profile-description">{{product.company_profile.nature_of_business}}</div>
                        </div>
                    </div>
                    <div class="aiema-contact tab-pane fade" id="contacts{{index}}">
                        <div class="row">
                            <div class="col-sm-3 contact-title">Mobile:</div>
                            <div class="col-sm-9 contact-description"><a href="tel:{{product.contact_details.mobile}}">{{product.contact_details.mobile}}</a></div>
                        </div>
                        <div class="row" style="padding-top: 5px;">
                            <div class="col-sm-3 contact-title">Email:</div>
                            <div class="col-sm-9 contact-description"><a href = "mailto: {{product.contact_details.email}}">{{product.contact_details.email}}</a></div>
                        </div>
                        <div class="row" style="padding-top: 5px;">
                            <div class="col-sm-3 contact-title">Website:</div>
                            <div class="col-sm-9 contact-description"><a [attr.href]="'//' + product.contact_details.website" target="_blank" target="_blank">{{product.contact_details.website}}</a></div>
                        </div>
                    </div>
                    <div class="aiema-contact tab-pane fade" id="location{{index}}">
                        <div class="row">
                            <div class="col-sm-4 contact-title">Company Location:</div>
                            <div class="col-sm-8 contact-description">{{product.company_details.location}}</div>
                        </div>
                    </div>
                </div>
                <div class="aiema-submit-btn">
                    <!-- <button class="btn btn-block aiema-btn" (click)="submitEnquiry(product)">Submit Enquiry</button> -->
                    <button class="btn btn-block aiema-btn" (click)="sendProductDetails(product.id)">View Details</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="CONFIG.title === 'BombayHardware'">
    <div id="bombayhardwarecards">
        <div class="bombayhardware text-center">
            <div class="bombayhardware-img-holder" style="cursor: pointer;" (click)="sendProductDetails(product.id)">
                <div class="bombayhardware-img text-center">
                    <img style="object-fit: contain !important;" [src]="product.assets[0].image_source">
                </div>
            </div>
            <div class="bombayhardware-description-holder">
                <div class="bombayhardware-title">{{product.name}}</div>
                <div class="bombayhardware-subtitle">{{product.categories[0].categories}}</div>
                <!-- <div class="bombayhardware-tabs">
                    <ul class="nav nav-tabs">
                        <li class="nav-item" data-toggle="tooltip" data-placement="top" title="Company Profile"><a  data-toggle="tab" href="#profile{{index}}" role="tab"><i class="fa fa-info-circle" aria-hidden="true"></i></a></li>
                        <li class="nav-item" data-toggle="tooltip" data-placement="top" title="Company Contacts"><a data-toggle="tab" href="#contacts{{index}}" role="tab"><i class="fa fa-globe" aria-hidden="true"></i></a></li>
                        <li data-toggle="tooltip" data-placement="top" title="Company Address"><a data-toggle="tab" href="#location{{index}}" role="tab"><i class="fa fa-map-marker" aria-hidden="true"></i></a></li>
                    </ul>
                </div> -->
                <div class="tab-content">
                    <div class="bombayhardware-profile tab-pane fade show active" id="profile{{index}}">
                        <div class="row">
                            <div class="col-sm-3 profile-title">SKU:</div>
                            <div class="col-sm-9 profile-description">{{product.sku ? product.sku : '-'}}</div>
                        </div>
                        <div class="row" style="padding-top: 5px;">
                            <div class="col-sm-3 profile-title">Brand:</div>
                            <div class="col-sm-9 profile-description">{{product.brand.name ? product.brand.name : '-'}}</div>
                        </div>
                        <div class="row" style="padding-top: 5px;">
                            <div class="col-sm-3 profile-title">Description:</div>
                            <div class="col-sm-9 profile-description"></div>
                        </div>
                    </div>
                    <!-- <div class="bombayhardware-contact tab-pane fade" id="contacts{{index}}">
                        <div class="row">
                            <div class="col-sm-3 contact-title">Mobile:</div>
                            <div class="col-sm-9 contact-description"><a href="tel:{{product.contact_details.mobile}}">{{product.contact_details.mobile}}</a></div>
                        </div>
                        <div class="row" style="padding-top: 5px;">
                            <div class="col-sm-3 contact-title">Email:</div>
                            <div class="col-sm-9 contact-description"><a href = "mailto: {{product.contact_details.email}}">{{product.contact_details.email}}</a></div>
                        </div>
                        <div class="row" style="padding-top: 5px;">
                            <div class="col-sm-3 contact-title">Website:</div>
                            <div class="col-sm-9 contact-description"><a [attr.href]="'//' + product.contact_details.website" target="_blank" target="_blank">{{product.contact_details.website}}</a></div>
                        </div>
                    </div> -->
                    <!-- <div class="bombayhardware-contact tab-pane fade" id="location{{index}}">
                        <div class="row">
                            <div class="col-sm-4 contact-title">Company Location:</div>
                            <div class="col-sm-8 contact-description">{{product.company_details.location}}</div>
                        </div>
                    </div> -->
                </div> 
                <div class="bombayhardware-submit-btn">
                    <!-- <button class="btn btn-block bombayhardware-btn" (click)="submitEnquiry(product)">Submit Enquiry</button> -->
                                              <!-- <button class="btn btn-block bombayhardware-btn" (click)="submitEnquiry(product)">Submit Enquiry</button> -->
                                              <button  *ngIf="(appService.productIdinCart.indexOf(product.id) == -1 && product.product_type!='Variable') && (appService.productIdinCart.indexOf(product.id) == -1 && product.type!='Variable')" class="btn btn-primary shubhcards-btn" (click)="addToCart(product)">Add to Cart</button>
                                              <button    *ngIf="product.product_type=='Variable' ||product.type=='Variable'" class="btn btn-primary earthy-btn" (click)='productDetails(product.id)'>Select Options</button>
                                              <!-- <button     class="btn btn-secondary listing"   *ngIf="(appService.productIdinCart.indexOf(product.id) != -1 && product.product_type!='Variable' && appService.productIdinCart.indexOf(product.id) > -1)"  routerLink="/cart">View Cart</button> -->
                                              <div *ngFor="let cart of appService.cartProducts.results;let i = index"> 
                                              <div class="btn btn-primary inq-btn-st ml-1 viewCart"      *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >
                                   
                                      
                                                  <span>
                                                      <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                          style="font-size:12px;cursor:pointer;"></i></span>
                                                  <span class="inq-sp-2"
                                                      style="color: white;">{{cart.quantity}}</span>
                                                  <span>
                                                      <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                          style="font-size:12px;cursor:pointer;"></i></span>
                                             </div>
                                             </div>                </div>
            </div>
        </div>
    </div>
</div>

<!-- <button id="launch-model" style="display: none;" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#statusModel">
    Launch demo modal
</button> -->
<ng-template #content let-c="close" let-d="dismiss">
<!-- <div class="modal show" id="statusModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle" aria-hidden="true"> -->
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document" >
        <div class="modal-content" style="width: 100% !important;">
            <div class="modal-body" style="border: none !important;">
                <div class="model-content m-content">
                    <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="submit(c)">
                        <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                    </button>
                    <div class="title-container">
                        <div class="m-title">Enquiry Form</div>
                    </div>
                    <form [formGroup]="enquiryForm" class="gift-form" style="width: 435px!important;"novalidate>
                        <div class="form-group row">
                            <div class="col-md-3">
                                <label for="name" class="form-label">Name <span class="required">*</span></label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="name"
                                    [class.is-invalid]="f.name.invalid && validateForm" formControlName="name" required>
                                    <div class="invalid-feedback" *ngIf="f.name.errors">
                                        <div *ngIf="f.name.errors.required">
                                            Name is required.
                                        </div>
                                        <div *ngIf="f.name.errors.maxlength">
                                            Name can't be more than 80 characters long.
                                          </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-3">
                                <label for="content" class="form-label">Email<span
                                    class="required">*</span></label>
                            </div>
                            <div class="col-md-8">
                                <input type="email" class="form-control" id="email"
                                    [class.is-invalid]="(f.email.invalid && validateForm)"
                                    formControlName="email" maxlength="50" email>
                                <div class="invalid-feedback" *ngIf="f.email.errors">
                                    <div *ngIf="f.email.errors.required">
                                        Email is required.
                                    </div>
                                    <div *ngIf="f.email.errors.maxlength">
                                        Seneder Email can't be more than 50 characters long.
                                    </div>
                                    <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                                        Email must be a valid email address
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-3">
                                <label for="phone_number" class="form-label">Mobile<span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="phone_number"
                                    [class.is-invalid]="f.phone_number.invalid && validateForm" formControlName="phone_number"
                                    required>
                                <div class="invalid-feedback" *ngIf="f.phone_number.errors">
                                    <div *ngIf="f.phone_number.errors.required">
                                        Mobile Number is required.
                                    </div>
                                    <div *ngIf="f.phone_number.errors.maxlength">
                                        Mobile Number can't be more than 10 characters long.
                                    </div>
                                    <div *ngIf="f.phone_number.errors.pattern">
                                        Mobile Number is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-3">
                                <label for="category" class="form-label">Category<span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="category"
                                    [class.is-invalid]="f.category.invalid && validateForm" formControlName="category"
                                    required readonly>
                                    <div class="invalid-feedback" *ngIf="f.category.errors">
                                        <div *ngIf="f.category.errors.required">
                                            Category is required.
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-3">
                                <label for="category" class="form-label">Message<span
                                    class="required">*</span></label>
                            </div>
                            <div class="col-md-8">
                                <textarea class="form-control" id="message"
                                    [class.is-invalid]="f.message.invalid && validateForm" formControlName="message"
                                    required rows="5"></textarea>
                                    <div class="invalid-feedback" *ngIf="f.message.errors">
                                        <div *ngIf="f.message.errors.required">
                                            Message is required.
                                        </div>
                                        <div *ngIf="f.message.errors.maxlength">
                                            Message can't be more than 600 characters long.
                                          </div>
                                    </div>
                            </div>
                        </div>
                        <div style="margin-bottom: 45px;">
                            <button class="btn btn-primary" style="border-radius: 5px !important; font-size: 1rem; padding: 5px 25px;margin-top: 5px;float: right;" (click)="saveForm()">SUBMIT</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
<!-- </div> -->
</ng-template>