<section id="customize-listing">
    <app-alerts *ngIf="showAlerts"></app-alerts>
    <div *ngIf="!loadErroPage" class="product-customize">
        <div class="row">
            <div class="col-md-3" style="padding-right: 0px !important;">
                <div *ngFor="let category of allCategories" class="menu-container d-flex justify-content-end"
                    style="padding-top: 12px;">
                    <div class="menu-content">
                        <div (click)="getSelectedCategory(category)"
                            [class.single-category]="!category.subcategories || category.subcategories.length === 0"
                            [class.selected-item]="selectedCategory.name === category.name" class="menu-item"><span
                                *ngIf="category.category_icon"><img
                                    [class.selected-item]="selectedCategory.name === category.name"
                                    class="category-icon" src="{{category.category_icon}}"></span>{{category.name}}
                            <span *ngIf="category.subcategories && category.subcategories.length > 0"><i
                                    class="fa fa-angle-down" aria-hidden="true"></i></span></div>
                        <div [class]="(selectedCategory && (selectedCategory.name === category.name)) ? 'show-menu':'hide-menu'"
                            *ngIf="category.subcategories && category.subcategories.length > 0">
                            <div *ngFor="let subcategory of category.subcategories"
                                class="sub-menu-container d-flex justify-content-end">
                                <div [class.sub-selected-item]="selectedSubCategory && selectedSubCategory.name === subcategory.name"
                                    class="sub-menu-item" (click)="getSubCategory(subcategory)">{{subcategory.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6" style="padding-left: 0px !important;">
                <div class="category-products-container">
                    <div *ngIf="selectedCategoryProducts.length > 0" class="menus-title">{{selectedSubCategory ?
                        selectedSubCategory.name : selectedCategory.name}}
                        <span
                            *ngIf="selectedSubCategory ? selectedSubCategory.category_icon : selectedCategory.category_icon">
                            <img class="selected-icon"
                                src="{{selectedSubCategory ? selectedSubCategory.category_icon : selectedCategory.category_icon}}">
                        </span>
                    </div>
                    <div class="product-list-container">
                        <div *ngIf="selectedCategoryProducts.length > 0">
                            <div
                                *ngFor="let categoryProduct of selectedCategoryProducts | paginate: { itemsPerPage: pageSize, currentPage: page, id: 'foo', totalItems:pageCount }">
                                <div class="category-product-card" *ngIf="categoryProduct.product_type!='Configurable'">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="cp-title">{{categoryProduct.name | titlecase}}</div>
                                            <div class="inner-star-icon">
                                                <!--<span [class]="categoryProduct.reviews && categoryProduct.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                <span [class]="categoryProduct.reviews && categoryProduct.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                <span [class]="categoryProduct.reviews && categoryProduct.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                <span [class]="categoryProduct.reviews && categoryProduct.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                <span  [class]="categoryProduct.reviews && categoryProduct.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                 <a class="rating-text" routerLink="/product-review/{{categoryProduct.id}}"> {{categoryProduct.reviews ? categoryProduct.reviews.total_reviews : 0}} customer reviews</a> -->
                                            </div>
                                            <div class="cp-price"><i
                                                    class="fa fa-rupee"></i>{{categoryProduct.pricing.raw.price +
                                                getBundleAmount('obj', categoryProduct.bundle_products ?
                                                categoryProduct.bundle_products : "")}} </div>
                                            <div class="cp-description" *ngIf="categoryProduct.description">
                                                {{removehtmltag(categoryProduct.description.short_desc)}}</div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="cp-image-container">
                                                <img class="cp-image"
                                                    *ngIf="categoryProduct.assets.length > 0 && categoryProduct.assets[0].image_source !== ''"
                                                    [src]="categoryProduct.assets[0].image_source">
                                                <img class="cp-image"
                                                    *ngIf="categoryProduct.assets.length === 0 || categoryProduct.assets[0].image_source === ''"
                                                    src="../../assets/images/vendor/No_Image_old2.png">
                                                <div class="cp-button-container">
                                                    <button
                                                        *ngIf="!getselectedProductIds().includes(categoryProduct.id)"
                                                        class="btn cp-button"
                                                        (click)="CheckPincode(categoryProduct)" id="inproductlistcustomize">ADD</button>
                                                    <button
                                                        *ngIf="getselectedProductIds().includes(categoryProduct.id)"
                                                        class="btn cp-button" class="btn cp-button">
                                                        <span>
                                                            <i class="fa fa-minus"
                                                                (click)="decreaseItem(categoryProduct.id, 'normal', 1)"
                                                                style="font-size:12px;cursor:pointer;"></i>
                                                        </span>
                                                        <span
                                                            class="inq-sp-2  pl-1 pr-1">{{getproductQuantity(categoryProduct.id)}}</span>
                                                        <span><i class="fa fa-plus" (click)="addToCart(categoryProduct)"
                                                                style="font-size:12px;cursor:pointer;"></i></span>
                                                    </button>
                                                    
                                                    </div>
                                                    
                                                </div>
                                                <div class="col-sm-3" style="    font-size: 11px !important;
                                                color: #8b8b8b !important;position: relative;left: 10px;"
                                                    *ngIf="categoryProduct.customise_products && categoryProduct.customise_products.customised">
                                                    customisable</div>
                                            </div>
                                           
                                    </div>
                                </div>
                            </div>
                            <pagination-controls id="foo" class="current"
                                style="float:right;margin-top: 15px;font-size: 1rem;"
                                (pageChange)="getPageProducts($event)"></pagination-controls>
                        </div>
                        <div *ngIf="selectedCategoryProducts.length === 0">
                            <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/noresult.jpeg"
                                class="img-fluid1">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div *ngIf="selectedProducts.length > 0" class="menus-title ">Cart Items</div>
                <div class="cart-container">
                    <div *ngIf="selectedProducts.length > 0">
                        <div *ngFor="let sps of selectedProducts; let i = index">
                            <div class="row" style="padding-top: 18px;">
                                <div class="col-md-6">
                                    <span class="cart-item-name">{{sps.name | titlecase}}</span><br>
                                    <span *ngIf="sps.customized" (click)="customizeItem(sps,i)"
                                        class="customize-txt">customize ></span>
                                </div>
                                <div class="col-md-4" style="padding-right:0px !important;">
                                    <button class="btn cp-sm-btn btn-sm">
                                        <span> <i class="fa fa-minus" (click)="decreaseItemspecifically(sps.id, 'package', i)"
                                                style="font-size:12px;cursor:pointer;"></i></span>
                                        <span class="inq-sp-2  pl-1 pr-1">{{sps.package_quantity}}</span>
                                        <span><i class="fa fa-plus" (click)="addToCart(sps.product)"
                                                style="font-size:12px;cursor:pointer;"></i></span>
                                    </button>
                                </div>
                                <div class="col-md-2 rating-text" style="padding-left:8px !important">
                                    ₹{{sps.package_quantity*sps.package_total}}</div>
                            </div>
                        </div>

                        <button type="button" style="margin-top: 35px;" class="btn btn-outline-dark btn-block"
                            (click)="redirectPage('cart')">VIEW CART</button>
                        <div class="text-center mt-1 mb-1"><small style="font-size: 0.95rem;">(or)</small></div>
                        <div class="row">
                            <div class="col-md-12">
                                <button class="btn btn-primary btn-block"
                                    style="background-color: #023D4F !important;border: 1px solid #023D4F !important;"
                                    (click)="redirectPage('checkout')">PROCEED TO CHECKOUT</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedProducts.length === 0">
                        <div
                            style="border-left: 1px solid rgb(216, 214, 214);padding-top: 20px; padding-left: 15px;padding-bottom: 60px;">
                            <img class="customize-empty-cart" src="../../assets/images/emptycart1.png"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loadErroPage">
        <div class="text-center">
            <img class="error-img" src="../../assets/images/errorpage.png">
            <div>
                <h4>Something Went Wrong!</h4>
            </div>
            <p class="pb-1" style="color: rgb(136, 136, 133);">Please try again to load the data.</p>
            <button class="btn btn-primary custom-btn" (click)="reloadCurrentRoute()">Try Again</button>
        </div>
    </div>
</section>
<div>
    <button id="launch-model" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#statusModel">
        Launch demo modal
    </button>
    <div class="modal fade" data-backdrop="false" id="statusModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document"
            style="max-width: 640px !important;">
            <div class="modal-content status-model-container" style="width: max-content;">
                <div class="modal-body" style="border: none !important;">
                    <div class="model-content m-content">
                        <button type="button" class="close" id="addclose" data-dismiss="modal" aria-label="Close" (click)="addclose(false)">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <button type="button" class="close" id="hideaddclose" style="display:none;" data-dismiss="modal" aria-label="Close" (click)="addclose(true)">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <div class="title-container" *ngIf="selectedProduct">
                            <div class="m-title">{{selectedProduct.name | titlecase}}</div>
                            <div class="m-price"><i class="fa fa-rupee"></i>{{selectedProduct.pricing.raw.price +
                                getBundleAmount('obj', selectedProduct.bundle_products ? selectedProduct.bundle_products
                                : "")}}</div>
                        </div>
                        <div class="options-container">
                            <div
                                *ngIf="selectedProduct && selectedProduct.bundle_products && selectedProduct.bundle_products.make_bundle && selectedProduct.bundle_products.products.length > 0">
                                <div class="options-title">Default Add-on</div>
                                <div *ngFor="let sproduct of selectedProduct.bundle_products.products; let i = index">
                                    <input class="largerCheckbox" type="checkbox" [id]="'sproduct'+i" [name]="sproduct.product_id" [value]="sproduct.product_id" checked disabled>
                                    <label class="option-product">{{sproduct.product_name.includes('-')?sproduct.product_name.substring(0, sproduct.product_name.lastIndexOf('-')):sproduct.product_name}}</label><br>
                                </div>
                            </div>
                        </div>
                        <div class="addon-container">
                            <div
                            *ngIf="selectedProduct && selectedProduct.customise_products && selectedProduct.customise_products.customised && selectedProduct.customise_products.customisedCategory.length>0">

                            <div
                            *ngFor="let aproduct of selectedProduct.customise_products.customisedCategory; let i = index">
                            
                            <div class="options-title">
                               
                                {{aproduct.title}}
                                <!-- Pick Your Choice Of
                                {{aproduct.title}}
                                (Upto {{aproduct.no_of_products}} of
                                {{aproduct.customisedProducts.length}} items) -->
                                <app-alerts [id]="'alert'+i"*ngIf="showaddonalert"></app-alerts>
                               <!-- <p [id]="'alert'+i" style="color:red;display:none;"> You may have {{aproduct.no_of_products}} choice of your liking</p> -->
                          
                             </div>
                <input type="hidden" [id]="'no_of_products'+i" [value]="aproduct.no_of_products">
                <!--radio button greater than 5-->
           
                    <!--radio button less than 5-->
                    <div *ngIf="aproduct.no_of_products==1" style="-moz-column-count:3; /* Firefox */
                    -webkit-column-count:3; /* Safari and Chrome */
                    column-count:3;">
                        <div
                            *ngFor="let bproduct of aproduct.variants; let j = index">
            
            
                            <label
                            class="option-product" style="cursor:pointer;"> <input [ngClass]="'largerCheckbox'+i" [name]="i+'aproduct'" type="radio" [id]="i+'bproduct'+j"
                                 [value]="bproduct.id"
                                (click)="getaddonItem(i,$event, i+'bproduct'+j,aproduct.no_of_products,aproduct.chargeable,bproduct.sale_price)">
                            {{bproduct.name.includes('-')?bproduct.name.substring(0,
                                bproduct.name.lastIndexOf('-')):bproduct.name}}{{aproduct.chargeable?'(Rs.'+bproduct.sale_price+')':''}}
                                <!-- - <span class="rating-text"> ₹{{aproduct.sale_price}}</span> -->
                            </label><br>
            
            
                        </div>
            
                    </div>
                               
                                    <div *ngIf="aproduct.no_of_products!=1" style="-moz-column-count:3; /* Firefox */
            -webkit-column-count:3; /* Safari and Chrome */
            column-count:3;">
                <div
                    *ngFor="let bproduct of aproduct.variants; let j = index">
    
    
                    <label
                    class="option-product" style="cursor:pointer;"> <input [ngClass]="'largerCheckbox'+i" type="checkbox" [id]="i+'bproduct'+j"
                        [name]="bproduct.id" [value]="bproduct.id"
                        (click)="getaddonItem(i,$event, i+'bproduct'+j,aproduct.no_of_products,aproduct.chargeable,bproduct.sale_price)">
                    {{bproduct.name.includes('-')?bproduct.name.substring(0,
                        bproduct.name.lastIndexOf('-')):bproduct.name}}{{aproduct.chargeable?'(Rs.'+bproduct.sale_price+')':''}}
                        <!-- - <span class="rating-text"> ₹{{aproduct.sale_price}}</span> -->
                    </label><br>
    
    
                </div>
    
            </div>
                            </div>
                            
                            </div>                    
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="d-flex justify-content-around">
                            <div *ngIf="selectedProduct" class="m-total">Total: <span
                                    style="padding-left: 2px;">₹{{selectedProduct.pricing.raw.price+add_on_prices}}</span></div>
                            <button type="button" class="btn m-btn"  (click)="addItemstocart()">ADD
                                ITEM</button>
                        </div>

                    </div>
                    <button style="display: none;" type="button" id="cancelBtn"
                        class="btn btn-light btn-md text-primary shadow-sm" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div>
    <button id="launch-model1" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal"
    data-target="#statusModel1">
    Launch demo modal
</button>
<div class="modal fade" data-backdrop="false" id="statusModel1" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document"
    style="max-width: 640px !important;">
    <div class="modal-content status-model-container" style="width: max-content;">
        <div class="modal-body" style="border: none !important;">
            <div class="model-content m-content">
                <button type="button" class="close" id="updateclose" aria-label="Close" (click)="updateclose(false)">
                    <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                </button>
                <button type="button" class="close" id="updatehideclose" style="display:none;" data-dismiss="modal" aria-label="Close">
                    <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                </button>
                <div class="title-container" *ngIf="selectedPackageProduct">
                    <div class="m-title">{{selectedPackageProduct.name | titlecase}}</div>
                    <div class="m-price"><i class="fa fa-rupee"></i>{{selectedPackageProduct.product.pricing.raw.price}}</div>
                </div>
                <div class="options-container">
                    <div
                        *ngIf="selectedPackageProduct && selectedPackageProduct.product.bundle_products && selectedPackageProduct.product.bundle_products.make_bundle && selectedPackageProduct.product.bundle_products.products.length > 0">
                        <div class="options-title">Default Add-on</div>
                        <div *ngFor="let sproduct of selectedPackageProduct.product.bundle_products.products; let i = index">
                            <input class="largerCheckbox" type="checkbox" [id]="'sproduct'+i" [name]="sproduct.product_id" [value]="sproduct.product_id" checked disabled>
                            <label class="option-product">{{sproduct.product_name.includes('-')?sproduct.product_name.substring(0, sproduct.product_name.lastIndexOf('-')):sproduct.product_name}}</label><br>
                        </div>
                    </div>
                </div>
                <div class="addon-container">
                    <div
                    *ngIf="selectedPackageProduct && selectedPackageProduct.product.customise_products && selectedPackageProduct.product.customise_products.customised && selectedPackageProduct.product.customise_products.customisedCategory.length>0">

                    <div
                    *ngFor="let tproduct of selectedPackageProduct.product.customise_products.customisedCategory; let i = index">
                    
                    <div class="options-title">
                       
                        {{tproduct.title}}
                        <!-- Pick Your Choice Of
                        {{sproduct.title}}
                        (Upto {{sproduct.no_of_products}} of
                        {{sproduct.customisedProducts.length}} items) -->
                        <app-alerts [id]="'updatealert'+i"*ngIf="showaddonalert"></app-alerts>
                       <!-- <p [id]="'alert'+i" style="color:red;display:none;"> You may have {{tproduct.no_of_products}} choice of your liking</p> -->
                  
                     </div>
                     
                     <input type="hidden" [id]="'updateno_of_products'+i" [value]="tproduct.no_of_products">
                    
                        <div *ngIf="tproduct.no_of_products==1" style="-moz-column-count:3; /* Firefox */
-webkit-column-count:3; /* Safari and Chrome */
column-count:3;">
    <div
        *ngFor="let vproduct of tproduct.variants; let j = index">

        <label
        class="option-product" style="cursor:pointer;">
        <input [ngClass]="'largerCheckboxes'+i" type="radio" [id]="i+'vproduct'+j"
        [checked]="selectedPackageProduct.checked_products.includes(i+'a'+vproduct.id)"
        [name]="i+'tproduct'" [value]="vproduct.id"
            (click)="updateaddonItem(i,$event, i+'vproduct'+j,tproduct.no_of_products,tproduct.chargeable,vproduct.sale_price)">
        {{vproduct.name.includes('-')?vproduct.name.substring(0,
            vproduct.name.lastIndexOf('-')):vproduct.name}}{{tproduct.chargeable?'(Rs.'+vproduct.sale_price+')':''}}
            <!-- - <span class="rating-text"> ₹{{sproduct.sale_price}}</span> -->
        </label><br>


    </div>

</div>
                            <div *ngIf="tproduct.no_of_products!=1" style="-moz-column-count:3; /* Firefox */
    -webkit-column-count:3; /* Safari and Chrome */
    column-count:3;">
        <div
            *ngFor="let vproduct of tproduct.variants; let j = index">

            <label
            class="option-product" style="cursor:pointer;">
            <input [ngClass]="'largerCheckboxes'+i" type="checkbox" [id]="i+'vproduct'+j"
            [checked]="selectedPackageProduct.checked_products.includes(i+'a'+vproduct.id)"
                [name]="vproduct.id" [value]="vproduct.id"
                (click)="updateaddonItem(i,$event, i+'vproduct'+j,tproduct.no_of_products,tproduct.chargeable,vproduct.sale_price)">
            {{vproduct.name.includes('-')?vproduct.name.substring(0,
                vproduct.name.lastIndexOf('-')):vproduct.name}}{{tproduct.chargeable?'(Rs.'+vproduct.sale_price+')':''}}
                <!-- - <span class="rating-text"> ₹{{sproduct.sale_price}}</span> -->
            </label><br>


        </div>

    </div>
                    </div>
                    
                    </div>                    
                </div>
            </div>
            <div class="modal-footer">
                <div class="d-flex justify-content-around">
                    <div *ngIf="selectedPackageProduct" class="m-total">Total: <span
                            style="padding-left: 2px;">₹{{selectedPackageProduct.package_total+add_on_prices}}</span></div>
                    <button type="button" class="btn m-btn"  (click)="updateclose(true)">UPDATE ITEM
                        </button>
                </div>

            </div>
            <button style="display: none;" type="button" id="cancelBtn"
                class="btn btn-light btn-md text-primary shadow-sm" data-dismiss="modal">Cancel</button>
        </div>
    </div>
</div>
</div>

</div>


<!--<div>
    <button id="launch-model1" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#statusModel1">
        Launch demo modal
    </button>
    <div class="modal fade" data-backdrop="false" id="statusModel1" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document"
            style="max-width: 640px !important;">
            <div class="modal-content status-model-container" >
                <div class="modal-body" style="border: none !important;">
                    <div class="model-content m-content">
                        <button type="button" id="updateclose" class="close" (click)="updateclose()" aria-label="Close">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <button type="button" id="updatehideclose" style="display:none;"class="close" data-dismiss="modal" aria-label="Close">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                        <div class="title-container" *ngIf="selectedPackageProduct">
                            <div class="m-title">{{selectedPackageProduct.name | titlecase}}</div>
                            <div class="m-price"><i
                                    class="fa fa-rupee"></i>{{selectedPackageProduct.product.pricing.raw.price +
                                getBundleAmount('obj', selectedPackageProduct.product.bundle_products ?
                                selectedPackageProduct.product.bundle_products : "")}}</div>
                        </div>
                        <div class="options-container">
                            <div
                                *ngIf="selectedPackageProduct && selectedPackageProduct.product.bundle_products && selectedPackageProduct.product.bundle_products.make_bundle">
                                <div class="options-title">Default Add-on</div>
                                <div *ngIf="selectedPackageProduct.product.bundle_products.products.length > 0">
                                    <div
                                        *ngFor="let sproduct of selectedPackageProduct.product.bundle_products.products; let i = index">
                                        <input class="largerCheckbox" type="checkbox" [id]="'sproduct'+i"
                                            [name]="sproduct.id" [value]="sproduct.id" checked disabled>
                                        <label
                                            class="option-product">{{sproduct.name.includes('-')?sproduct.name.substring(0,
                                            sproduct.name.lastIndexOf('-')):sproduct.name}}</label><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="addon-container">
                            <div
                                *ngIf="selectedPackageProduct && selectedPackageProduct.product.customise_products && selectedPackageProduct.product.customise_products.customised && selectedPackageProduct.product.customise_products.customisedProducts.length > 0">
                                <div class="options-title">Add-ons <span class="rating-text">(optional)</span></div> 
                                <div class="options-title">Pick Your Choice Of
                                    {{selectedPackageProduct.product.customise_products.customisedProducts[0].name.includes('Sambar')?"Sambar":"Chutney"}}
                                    (Upto {{maxselecteditems}} of
                                    {{selectedPackageProduct.product.customise_products.customisedProducts.length}}
                                    items) </div>
                                <app-alerts *ngIf="showaddonalert"></app-alerts>
                                
                                <div
                                    *ngIf="selectedPackageProduct.product.customise_products.customisedProducts.length > 0">
                                    <div *ngIf="selectedPackageProduct.product.customise_products.customisedProducts.length>5" style="-moz-column-count:2; /* Firefox */
                                        -webkit-column-count:2; /* Safari and Chrome */
                                        column-count:2;">
                                         <div
                                         *ngFor="let aproduct of selectedPackageProduct.product.customise_products.customisedProducts; let i = index">
                                         <input class="largerCheckbox" type="checkbox" [id]="'aproducts'+i"
                                             [name]="aproduct.id" [value]="aproduct.id"
                                             (click)="updateaddonItem($event, 'aproducts'+i)"
                                             [checked]="selectedPackageProduct.add_onproducts.includes(aproduct.id)">
                                         <label class="option-product">
                                             {{aproduct.name.includes('-')?aproduct.name.substring(0,
                                             aproduct.name.lastIndexOf('-')):aproduct.name}}
                                              <span class="rating-text"> ₹{{aproduct.sale_price}}</span>
                                         </label><br>
                                     </div>
                                        </div>
                                        
                                        <div *ngIf="selectedPackageProduct.product.customise_products.customisedProducts.length<=5">
                                            <div
                                            *ngFor="let aproduct of selectedPackageProduct.product.customise_products.customisedProducts; let i = index">
                                            <input class="largerCheckbox" type="checkbox" [id]="'aproducts'+i"
                                                [name]="aproduct.id" [value]="aproduct.id"
                                                (click)="updateaddonItem($event, 'aproducts'+i)"
                                                [checked]="selectedPackageProduct.add_onproducts.includes(aproduct.id)">
                                            <label class="option-product">
                                                {{aproduct.name.includes('-')?aproduct.name.substring(0,
                                                aproduct.name.lastIndexOf('-')):aproduct.name}}
                                                 - <span class="rating-text"> ₹{{aproduct.sale_price}}</span>
                                            </label><br>
                                        </div>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="d-flex justify-content-around">
                        <div *ngIf="selectedPackageProduct" class="m-total">Total: <span
                                style="padding-left: 2px;">₹{{selectedPackageProduct.product.pricing.raw.price +
                                getBundleAmount('obj', selectedPackageProduct.product.bundle_products ?
                                selectedPackageProduct.product.bundle_products : "") + getAddOnProductAmount('array',
                                customizedIds)}}</span></div>
                        <button type="button" class="btn m-btn"
                            (click)="updateItemstoCart()">UPDATE ITEM</button>
                    </div>
                </div>
                <button style="display: none;" type="button" id="cancelBtn1"
                    class="btn btn-light btn-md text-primary shadow-sm" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>-->
<!-- <div>
    <button id="pincode-model" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#pincodeModel">
        Launch demo modal
    </button>
    <div class="modal fade" data-backdrop="false" id="pincodeModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document"
            style="max-width: 640px !important;">
            <div class="modal-content status-model-container">
                    <div class="model-content m-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                        </button>
                    
                            <div class="form-group">
                              <input type="text" class="form-control" style="width: 90%" [(ngModel)]="pincode" placeholder="Please Enter Pincode">
                              <p id="error" style="color:red;display: none;"> Sorry,we do not deliver to this pincode</p>
                       
                            </div>
                            <div class="d-flex justify-content-around" style="margin-bottom:10px">
                                <button type="button" class="btn m-btn" (click)="validatepincode()">ACCEPT</button>
                        
                            </div>
                       
                     
                            
                     
                   
                </div>
            </div>
        </div>
    </div>
</div> -->
