import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { ProductSlider } from '../shared/data/slider';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import {HeaderService} from '../header/header-two/header.service';
import { config }  from 'src/configs/config';
import { AlertsService } from '../shared/alerts/alerts.service';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
})
export class HomeTwoComponent implements OnInit {
  reviews = [{"user":"Shanthi n Vasu","comment":"Shubh Cards is the best destination for wedding cards, no doubt! They custom designed our card so well and at a very good price. The quality of the card was phenomenal! Everybody appreciated the card and were awe struck at the beautiful design and fantastic quality of the card and printing. Inspite of heavy rains, our cards were delivered on time. We thank Shubh Cards for the fantastic experience. Very professional people!! Thank you Shubh Cards!!"},
  {"user":"Geetha Krishna","comment": "Great choices & lovely designs. Dilip's communication has been perfect at every stage of the invitation delivery. Time within which we had requested the cards to be delivered was met way ahead of our expectation. Everyone we have given our invitation has been saying how beautiful it is. Thank you Dilip for your help & wonderful service."},
  {"user":"Srinivasan Krishnamurthi","comment":"I visited Shubh Cards about three weeks ago to place an order for wedding cards, and also met with Dilip Kumar who owns and runs the business. I was both impressed with the place and Dilip Kumar, a soft spoken gentleman. Dilip was very helpful and keen to help with what you want and at the same time share his knowledge and experience to bring out the best. They were prompt, accommodative and delivered a good product and service. The staff I briefly met with were also very kind and warm. I will definitely recommend Shubh Cards. Thank you for the great services."},
   {"user":"Vani Ravikumar","comment":"We had a very happy experience with shubh cards. Mr Dhilip was very cordial and patient and all ears to our suggestions he gave his thoughts to suit our requirements. The delivery was on time and work was impeccable."},
    {"user":"Sam Rajah","comment":"I ordered my wedding cards from Shubh Cards. The service from start to finish was perfect and the cards were tailored to my exact requirements. Also, I received cards 3 days after final payment (India to UK). Very good service and great cards!"}]
 
    reviews1 = [{"user":"Kausalya's Kitchen Stories ","comment":"I recently purchased some zip lock paper pouches, disposable spoons and straws. I must say, I am pretty impressed with the quality of all the products. The prices are really affordable."},
    {"user":"Rakshitha Pole","comment": "All packaging needs in one place, I highly recommend this place. You get every thing from biodegradable packaging products to medical supplies such as masks, shoe covers and gloves."},
    {"user":"Swetha K S","comment":"Trust worthy Dealer. I really appreciate Mr. Deepak's response, as mine is a new startup and I had many queries. Thanks a lot for clarifying my queries and I got my parcel on time."},
    ]
 
    customOptions: OwlOptions = {

    
    // navContainerClass: 'owl-nav',
    autoplay: true,
    loop: false,
    navSpeed: 220,
    autoplayHoverPause : true,
    // navClass: [ 'owl-prev', 'owl-next' ],
    navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false,
  }
  
  public ProductSliderConfig: any = ProductSlider;
  featureProductsApiData:any;
  showalertmsg: boolean = true;
  checkoutAlerts:boolean = false;
  recommededProducts:any = []
  config=config;
  title = config.title;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

	 featuredCollections1 = [
     {
			image: '../../assets/images/n2.png',
			title: 'category',
      subtitle: false,
      link: '/listing'
		},
    {

			image:'../../assets/images/n1.png',
			title: 'listing',
      subtitle: false,
      link: '/listing'
		},
		{
			image:  '../../assets/images/n3.png',
			title: 'MEMBERS',
      subtitle: false,
      link: '/listing'
		},
		{
			image:  '../../assets/images/n4.png',
			title: 'NEWS & EVENTS',
      subtitle: false,
      link: '/listing'
		},
  ];

//   featuredCollections2 = [
//     {
//      image: '../../assets/images/n2.png',
//      title: 'category',
//      subtitle: false,
//      link: '/listing'
//    },
//    {

//      image:'../../assets/images/n1.png',
//      title: 'listing',
//      subtitle: false,
//      link: '/listing'
//    },
//    {
//      image:  '../../assets/images/n3.png',
//      title: 'MEMBERS',
//      subtitle: false,
//      link: '/listing'
//    },
//    {
//      image:  '../../assets/images/n4.png',
//      title: 'NEWS & EVENTS',
//      subtitle: false,
//      link: '/listing'
//    },
//  ];


 featuredCollections2 = [
  {
    // image: 'assets/images/bombayhardware/brand/Pipes.jpg',
    image: 'assets/images/bombayhardware/button-blue-006.jpg',
    // title: 'Pipes',
    title: 'GI',
    subtitle: false,
    link: '/listing'
  },
  {
    // image: 'assets/images/bombayhardware/brand/Angles.jpg',
    image: 'assets/images/bombayhardware/button-blue-003.jpg',
    // title: 'Angles',
    title: 'GP',
    subtitle: false,
    link: '/listing'
  },
  {
    // image: 'assets/images/bombayhardware/brand/Flats.jpg',
    image: 'assets/images/bombayhardware/button-blue-004.jpg',
    title: 'RHS&SHS',
    // title: 'Flats',
    subtitle: false,
    link: '/listing'
  },
  {
    // image: 'assets/images/bombayhardware/brand/HotRolledProducts.jpg',
    image: 'assets/images/bombayhardware/button-blue-005.jpg',
    // title: 'Hot Rolled Products',
    title: 'MS',
    subtitle: false,
    link: '/listing'
  },
  {
    // image: 'assets/images/bombayhardware/brand/Channels.jpg',
    image: 'assets/images/bombayhardware/button-blue-002.jpg',
    title: 'SpiralWeilded',
    // title: 'Channels',
    subtitle: false,
    link: '/listing'
  },
  {
    // image: 'assets/images/bombayhardware/brand/Channels.jpg',
    image: 'assets/images/bombayhardware/button-blue-001.jpg',
    title: 'Seamless',
    // title: 'Channels',
    subtitle: false,
    link: '/listing'
  },
  // {
  //   image: 'assets/images/bombayhardware/brand/PipeFittings.jpg',
  //   // title: 'Pipe Fittings',
  //   subtitle: false,
  //   link: '/listing'
  // },
  // {
  //   image: 'assets/images/bombayhardware/brand/Seamed.jpg',
  //   // title: 'Seamed',
  //   subtitle: false,
  //   link: '/listing'
  // },
  // {
  //   image: 'assets/images/bombayhardware/brand/Pumps.jpg',
  //   // title: 'Pumps',
  //   subtitle: false,
  //   link: '/listing'
  // },
  // {
  //   image: 'assets/images/bombayhardware/brand/Pumps.jpg',
  //   // title: 'Pumps',
  //   subtitle: false,
  //   link: '/listing'
  // },
  // {
  //   image: 'assets/images/bombayhardware/brand/Pumps.jpg',
  //   // title: 'Pumps',
  //   subtitle: false,
  //   link: '/listing'
  // },
];


featuredCollections3 = [
  {
    // image: 'assets/images/bombayhardware/brand/HotRolledProducts.jpg',
    image: 'assets/images/bombayhardware/button-blue-009.jpg',
    title: 'CPVC',
    subtitle: false,
    link: '/listing'
  },
  {
    // image: 'assets/images/bombayhardware/brand/Angles.jpg',
    image: 'assets/images/bombayhardware/button-blue-010.jpg',
    title: 'SWR',
    subtitle: false,
    link: '/listing'
  },
  {
    // image: 'assets/images/bombayhardware/brand/Pipes.jpg',
    image: 'assets/images/bombayhardware/button-blue-008.jpg',
    title: 'UPVC',
    subtitle: false,
    link: '/listing'
  },
  {
    //  image: 'assets/images/bombayhardware/brand/Flats.jpg',
     image: 'assets/images/bombayhardware/button-blue-007.jpg',
    title: 'PVC',
    subtitle: false,
    link: '/listing'
  },
];


featuredCollections4 = [
  {
    image: 'assets/images/bombayhardware/brand/Pumps.jpg',
    title: 'Pumps',
    subtitle: false,
    link: '/listing'
  },
  {
    image: 'assets/images/bombayhardware/brand/Angles.jpg',
    title: 'Angles',
    subtitle: false,
    link: '/listing'
  },
  {
    image: 'assets/images/bombayhardware/brand/Flats.jpg',
    title: 'Flats',
    subtitle: false,
    link: '/listing'
  },
];




  featuredCollections = [{
    image: 'https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/designer.jpg',
    title: 'DESIGNER',
    subtitle: false,
    link: '/listing'
  },
  {

    image:'https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/kidswear.jpg',
    title: 'KIDS WEAR',
    subtitle: false,
    link: '/listing'
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/ethnic.jpg',
    title: 'ETHINIC',
    subtitle: false,
    link: '/listing'
  },
  {
    image:  'https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/westernwear.jpg',
    title: 'WESTERN WEAR',
    subtitle: false,
    link: '/listing'
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/winter.jpg',
    title: 'WINTER WEAR',
    subtitle: false,
    link: '/listing'
  },
  {
    image:  'https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/T-shirt.jpg',
    title: 'T-shirt',
    subtitle: false,
    link: '/listing'
  },
];
featuredCollectionsBBold = [{
  image: 'https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/designer.jpg',
  title: 'Bras',
  subtitle: false,
  link: '/listing'
},
{

  image:'https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/kidswear.jpg',
  title: 'Panties',
  subtitle: false,
  link: '/listing'
},
{
  image: 'https://d18xkwaipu1whh.cloudfront.net/EarthyWorthyAsset/ethnic.jpg',
  title: 'Nightwears',
  subtitle: false,
  link: '/listing'
},
];

  featuredCollectionsLuckme = [{
    image: 'https://d18xkwaipu1whh.cloudfront.net/LuckMeAsset/heritage.jpg',
    title: 'Heritage',
    subtitle: false,
    link: '/listing'
  },
  {

    image:'https://d18xkwaipu1whh.cloudfront.net/LuckMeAsset/sauce.jpg',
    title: 'Heinz',
    subtitle: false,
    link: '/listing'
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/LuckMeAsset/maggi.jpg',
    title: 'MAGGI',
    subtitle: false,
    link: '/listing'
  },
  {
    image:  'assets/images/luckme/brands/nesacafe.jfif',
    title: 'NESCAFE GOLD',
    subtitle: false,
    link: '/listing'
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/LuckMeAsset/colgate.jpg',
    title: 'COLGATE',
    subtitle: false,
    link: '/listing'
  },
  {
    image:  'https://d18xkwaipu1whh.cloudfront.net/LuckMeAsset/torku.jpg',
    title: 'CARAFEST',
    subtitle: false,
    link: '/listing'
  },
];
featuredCollectionsShubhCards = [
  {
    image:  'https://d18xkwaipu1whh.cloudfront.net/Shubhcards/Common/shubhcards3.jpg',
    title: 'Classic',
    subtitle: false,
    link: '/listing'
  },
  {
  image: 'https://d18xkwaipu1whh.cloudfront.net/Shubhcards/Common/shubhcards4.jpg',
  title: 'Elite',
  subtitle: false,
  link: '/listing'
},
{
  image: 'https://d18xkwaipu1whh.cloudfront.net/Shubhcards/Common/shubhcards2.jpg',
  title: 'Luxury',
  subtitle: false,
  link: '/listing'
},
{

  image:'https://d18xkwaipu1whh.cloudfront.net/Shubhcards/Common/shubhcards1.jpg',
  title: 'Premium',
  subtitle: false,
  link: '/listing'
}


];

featuredCollectionsDeera = [
  {
    image: 'https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Shubhcards/Common/Bakeware.png',
    title: 'Baking',
    subtitle: false,
    link: '/listing'
  },
  {
    image: 'https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Shubhcards/Common/Disposable+Wooden+Cutlery.png',
    title: 'Disposable Cutlery',
    subtitle: false,
    link: '/listing'
  },
  {

    // image:'https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Shubhcards/Common/Smart+Packaging.png',
    image:  'https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Shubhcards/Common/Stand+up+%26+Ziplock+Pouches.png',
    title: 'Packaging',
    subtitle: false,
    link: '/listing'
  },
  {
    image:  'https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Shubhcards/Common/Rippled.png',
    // image:  'https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Shubhcards/Common/Stand+up+%26+Ziplock+Pouches.png',
    title: 'Dining Disposable',
    subtitle: false,
    link: '/listing'
  },


];

  // recommededProducts = [
  //   {
  //     name: '1U3352 Unik Teeth Yellow ITR',
  //     price: '3,648',
  //     image: 'assets/images/product-1.png',
  //     link: '/listing'
  //   },
  //   {
  //     name: 'Breaker Parts ITR',
  //     price: '2,620',
  //     image: 'assets/images/product-2.png',
  //     link: '/listing'
  //   },
  //   {
  //     name: 'OTR Pattern-Siteking-HD',
  //     price: '3,900',
  //     image: 'assets/images/product-3.png',
  //     link: '/listing'
  //   },
  //   {
  //     name: 'Breaker Parts ITR',
  //     price: '2,620',
  //     image: 'assets/images/product-2.png',
  //     link: '/listing'
  //   }, {
  //     name: '1U3352 Unik Teeth Yellow ITR',
  //     price: '3,648',
  //     image: 'assets/images/product-1.png',
  //     link: '/listing'
  //   },
  //   {
  //     name: 'OTR Pattern-Siteking-HD',
  //     price: '3,900',
  //     image: 'assets/images/product-3.png',
  //     link: '/listing'
  //   },
  // ]
	constructor(
    private appService:AppService,
    private headerService : HeaderService,
    private alertService: AlertsService,
    private router: Router,
    private route: ActivatedRoute,

  ) {}
	ngOnInit(): void {
    document.documentElement.scrollTop = 0; 
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    // GET THE FEATURE PRODUCTS
    var req_feature_product_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 300
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": -1
        }
      }
    }
    this.appService.postApiCall(req_feature_product_data, ENDPOINTS.GET_FEATURED_PRODUCTS).subscribe(
      resp => {
        this.featureProductsApiData = resp;
        this.recommededProducts = this.featureProductsApiData.result.data;
        if(this.recommededProducts.length > 0){
          if(this.recommededProducts.length > 0 && this.recommededProducts.length < 3){
            if(this.recommededProducts.length === 1){
              this.recommededProducts.push("");
              this.recommededProducts.push("");
            }
            else if(this.recommededProducts.length === 2){
              this.recommededProducts.push("");
            }
          }
        }
        // console.log(this.recommededProducts)
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })

    }

}
