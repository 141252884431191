<!DOCTYPE html>
<html>

<head>
    <title>Cancellation Policy</title>
</head>

<body>
    <div class="paddingTB60">
        <div class="container-fluid text-center">
            <h3 *ngIf="domainname!=='Nesma'">Cancellation Policy</h3>
            <h3 *ngIf="domainname==='Nesma'">Cancellation and Refund Policy</h3>
            <hr>
        </div>
        <div style="margin-left: 5px;" id="cancellation-content"></div>
    </div>
</body>

</html>