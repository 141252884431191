import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { AppService } from '../app.service';
import { LoaderService } from '../shared/loader/loader.service';
import { CartNewService } from '../cart-new/cart-new.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { NavbarService} from '../navbar/navbar.service';
import { invalid } from 'moment';

@Component({
  selector: 'app-product-list-customize',
  templateUrl: './product-list-customize.component.html'
})
export class ProductListCustomizeComponent implements OnInit {

  allCategories:any = [];
  selectedCategory:any = "";
  selectedCategoryProducts = [];
  loadErroPage:boolean = false;
  selectedSubCategory:any;
  allProducts:any = [];
  selectedProducts:any = [];
  selectedProduct:any;
  addonIds:any = [];
  temp1:any = [];
  selectedPackageProduct:any;
  customizedIds:any =[];
  previouscustomizedIds:any=[];
  customizedtemp:any =[];
  previousupdateprod;
  customizedIndex:any;
  searchCategory:any;
  maxQuantity:any = 0;
  // enabledisableaddbutton:boolean=false;
  // enabledisableupdatebutton:boolean=false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  showAlerts = true;
  showaddonalert=true;
  pageSize = 50;
  page = 1;
  pageCount:any = 25;
  searchedcategory;

  constructor(
    public appService:AppService,
    public loaderService:LoaderService,
    private router : Router,
    private route: ActivatedRoute,
    private cartNewService: CartNewService,
    private alertService : AlertsService,
    private localStorageService: LocalStorageService,
    private checkoutApiService: CheckoutApiService,
    private navbarService: NavbarService,
  ) { }
maxselecteditems;
add_on_prices=0;

  ngOnInit(): void {
    //this.getCheckoutSettings();
    this.loaderService.emitLoading("customize");
    if(this.localStorageService.get('max_cart_qty')){
      this.maxQuantity = Number(this.localStorageService.get('max_cart_qty'))
    }
    if(this.cartNewService.getCartItems() !== null){
      this.selectedProducts = this.cartNewService.getCartItems();
    }
    // if (this.localStorageService.get("is_pincode_validated") != 'undefined' && this.localStorageService.get("is_pincode_validated")=='true') {
    //   //The front-end should change when this is called.
    //  this.is_pincode_validated=true;
    //  this.pincode=this.localStorageService.get("pincode");
    // }
    // else{
    //   this.is_pincode_validated=false;
    // }
    this.getAllProducts();
    this.route.queryParams.subscribe(params => {
       if(params.menutype ==='/menu'||params.menutype ==='/batter'){
        if(params.type=='searchresults')
        {
          this.searchCategory = this.selectedCategory = params.terms[0];
        }
        this.getAllCategories(params.menutype).then(
          resp => {
            let res:any = resp;
            if(res){
              this.getCategoryProducts(this.selectedCategory).then(
                resp1 => {
                  let res1:any = resp1;
                  if(res1){
                    this.loaderService.emitComplete();
                    this.loadErroPage = false;
                  }
                  else{
                    this.loadErroPage = true;
                  }
                }
              )
            }
            else{
              this.loadErroPage = true;
            }
          }
        )
      }
      else if(params.menutype==undefined)
      {
        if(params.category!==undefined)
        {
          this.searchCategory = this.selectedCategory = params.category[0];
        }
        else{
          this.searchCategory = this.selectedCategory = params.terms[0];
        }
        
        var menutype;
        if(!this.searchCategory.toLocaleLowerCase().includes('batter'))
          
        {
          menutype ='/menu';
        }
        else{
          menutype ='/batter';
        }
        this.getAllCategories(menutype).then(
          resp => {
            let res:any = resp;
            if(res){
              this.getCategoryProducts(this.selectedCategory).then(
                resp1 => {
                  let res1:any = resp1;
                  if(res1){
                    this.loaderService.emitComplete();
                    this.loadErroPage = false;
                  }
                  else{
                    this.loadErroPage = true;
                  }
                }
              )
            }
            else{
              this.loadErroPage = true;
            }
          }
        )
      }
    
      // if (params.type=='searchresults') {
      //   var menutype=params.menutype;
      //   this.route.queryParamMap.subscribe(params => {
      //     if(params.getAll('terms')[1] !== "" && params.getAll('terms')[1].toUpperCase() === "CATEGORY"){
      //       this.searchedcategory=params.getAll('terms')[0];
      //       this.searchCategory = this.selectedCategory = params.getAll('terms')[0];
      //       this.getAllCategories(menutype).then(
      //         resp => {
      //           let res:any = resp;
      //           if(res){
      //             this.getCategoryProducts({name: this.searchCategory}).then(
      //               resp1 => {
      //                 let res1:any = resp1;
      //                 if(res1){
      //                   this.loaderService.emitComplete();
      //                   this.loadErroPage = false;
      //                 }
      //                 else{
      //                   this.loadErroPage = true;
      //                 }
      //               }
      //             )
      //           }
      //           else{
      //             this.loadErroPage = true;
      //           }
      //         }
      //       )
      //     }
      //     else if(params.getAll('terms')[1] !== "" && params.getAll('terms')[1].toUpperCase() === "PRODUCT NAME"){
      //       this.searchCategory = this.selectedCategory = params.getAll('category')[0];
      //       this.getAllCategories('').then(
      //         resp => {
      //           let res:any = resp;
      //           if(res){
      //             this.getCategoryProducts({name: this.searchCategory}).then(
      //               resp1 => {
      //                 let res1:any = resp1;
      //                 if(res1){
      //                   this.loaderService.emitComplete();
      //                   this.loadErroPage = false;
      //                 }
      //                 else{
      //                   this.loadErroPage = true;
      //                 }
      //               }
      //             )
      //           }
      //           else{
      //             this.loadErroPage = true;
      //           }
      //         }
      //       )
      //     }
      //     else if(params.getAll('terms')[1] === ""){
      //       this.getAllCategories('').then(
      //         resp => {
      //           let res:any = resp;
      //           if(res){
      //             this.selectedCategoryProducts = [];
      //             this.loaderService.emitComplete();
      //           }
      //           else{
      //             this.loaderService.emitComplete();
      //             this.loadErroPage = false;
      //           }
      //         }
      //       );
      //     }
      //     else{
      //       this.getAllCategories('').then(
      //         resp => {
      //           let res:any = resp;
      //           if(res){
      //             this.getCategoryProducts(this.selectedCategory).then(
      //               resp1 => {
      //                 let res1:any = resp1;
      //                 if(res1){
      //                   this.loaderService.emitComplete();
      //                   this.loadErroPage = false;
      //                 }
      //                 else{
      //                   this.loadErroPage = true;
      //                 }
      //               }
      //             )
      //           }
      //           else{
      //             this.loadErroPage = true;
      //           }
      //         }
      //       )
      //     }
      //   })
      // }
      // else if(params.menutype ==='/menu'||params.menutype ==='/batter'){
      //   this.getAllCategories(params.menutype).then(
      //     resp => {
      //       let res:any = resp;
      //       if(res){
      //         this.getCategoryProducts(this.selectedCategory).then(
      //           resp1 => {
      //             let res1:any = resp1;
      //             if(res1){
      //               this.loaderService.emitComplete();
      //               this.loadErroPage = false;
      //             }
      //             else{
      //               this.loadErroPage = true;
      //             }
      //           }
      //         )
      //       }
      //       else{
      //         this.loadErroPage = true;
      //       }
      //     }
      //   )
      // }
      // else {
      //   this.getAllCategories('').then(
      //     resp => {
      //       let res:any = resp;
      //       if(res){
      //         this.getCategoryProducts(this.selectedCategory).then(
      //           resp1 => {
      //             let res1:any = resp1;
      //             if(res1){
      //               this.loaderService.emitComplete();
      //               this.loadErroPage = false;
      //             }
      //             else{
      //               this.loadErroPage = true;
      //             }
      //           }
      //         )
      //       }
      //       else{
      //         this.loadErroPage = true;
      //       }
      //     }
      //   )
      // }
    })
    this.cartNewService.currentData.subscribe(
      currentData => {
        let curData:any = currentData;
        if(curData.type === "cartData"){
          this.selectedProducts = curData.value;
        }
      }
    )
  }
  
  getAllCategories(menu){
    return new Promise((resolve, reject) => {
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: null,
        extras: {
          find:{},
          pagination: false,
          paginationDetails: {
            limit: 1,
            pagesize: 50
          },
          sorting: true,
          sortingDetails: {
            sortfield: "sort_order",
            sortorder: -1
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
        resp => {
          let res:any = resp;
          
          if(res.status_code === 200 && res.success === 1){
            this.allCategories = res.result.data;
            if(this.allCategories.length > 0){
              var ind=0;
             
              if(menu=='/menu')
              {

                this.allCategories=this.allCategories.filter(element=>!element.name.toLocaleLowerCase().includes('batter'))
              }
              else if(menu=='/batter')
              {
                this.allCategories=this.allCategories.filter(element=>element.name.toLocaleLowerCase().includes('batter'))
        
              }
              if(this.searchedcategory!=undefined)
              {
                ind= this.allCategories.findIndex(x => x.name ===this.searchedcategory);
              }
              if(this.selectedCategory!=undefined)
              {
                ind= this.allCategories.findIndex(x => x.name ===this.selectedCategory);
              }
              (ind==-1)?ind=0:ind;
              this.selectedCategory =this.allCategories[ind]
              
              // if(this.searchedcategory==undefined)
              // {
              //   this.selectedCategory = this.allCategories[0];
              // }
              // else{
              //   this.selectedCategory =this.allCategories.filter(sid => sid.name===this.searchedcategory)
              // }
              
            }
            resolve(true)
          }
        },
        err => {
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
          resolve(false)
        })
    })
  }

  getAllProducts(){
    let requestData = {
      domain_name: ORG_DETAILS.DOMAIN_NAME,
      user_id: null,
      extras: {
        find:{},
        pagination: false,
          paginationDetails: {
            limit:10,
            pagesize: 50
          },
        sorting:false,
        sortingDetails: {
          sortfield: "id",
          sortorder: 1
        }
      }
      
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_ALL_PRODUCTS).subscribe(
      resp => {
        let res:any = resp;
        if(res.status_code === 200 && res.success === 1){
          this.allProducts = res.result.data;
        }
      }
    )
  }

  getCategoryProducts(category){
    return new Promise((resolve, reject) => {
        var requestData = {
          domain_name: ORG_DETAILS.DOMAIN_NAME,
          user_id: null,
          extras: {
              find: {
                  category:[category.name ? category.name : ""],
                  last90days: false,
                  last30days: false,
                  includeoutofstock: false
              },
              pagination: true,
              paginationDetails: {
                limit: this.page,
                pagesize: 50
              },
              sorting: true,
              sortingDetails: {
                  sortfield: "name",
                  sortorder: 1
              },
              subcategoryfilter: false
          }
        }
        this.appService.postApiCall(requestData, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
          resp => {
            let res:any = resp;
            if(res.status_code === 200 && res.success === 1){
              if(res.result.data && res.result.data.length > 0){
                this.selectedCategoryProducts = res.result.data;
                this.pageCount = res.result.total;
              }
              resolve(true);
            }
          },
          err => {
            if (err.error.error.message) {
              console.log(err.error.error.message)
            }
            else {
              console.log('Something bad happened; Please try again!')
            }
            resolve(false);
          })
    })
  }

  getSelectedCategory(category){
    this.selectedCategory = category;
    this.getCategoryProducts(category);
    this.selectedSubCategory = undefined;
  }

  getSubCategory(subCategory){
    this.selectedSubCategory = subCategory;
    this.getCategoryProducts(subCategory);
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

  getBundleAmount(type, bundleObj):any{
    // if(type==='obj'){
    //   if(bundleObj){
    //     if(bundleObj.make_bundle && bundleObj.products && bundleObj.products.length > 0 && bundleObj.chargable){
    //       let totalBundleAmount = 0
    //       bundleObj.products.forEach(element => {
    //         totalBundleAmount += this.allProducts.find(pd => pd.id === element.product_id) !== undefined ? this.allProducts.find(pd => pd.id === element.product_id).pricing.raw.price : 0;
            
    //       });
    //       return totalBundleAmount;
    //     }
    //     else{
    //       return 0;
    //     }
    //   }
    //   else return 0;
    // }
    // else if(type === "arrays"){
    //   if(bundleObj && bundleObj.length > 0){
    //     let totalBundleAmount = 0;
    //     bundleObj.forEach(element => {
    //       totalBundleAmount += this.allProducts.find(pd => pd.id === element) !== undefined ? this.allProducts.find(pd => pd.id === element).pricing.raw.price : 0 
    //     });
    //     return totalBundleAmount;
    //   }
    //   else{
    //     return 0;
    //   }
    // }
    return 0;
  }

  getAddOnProductAmount(type, addonObj):any{
    // if(type="array"){
      return 0;
    //   if(addonObj && addonObj.length > 0){
    //     let totalAddOnAmount = 0
    //     addonObj.forEach(element => {
    //       totalAddOnAmount += this.allProducts.find(pd => pd.id === element) !== undefined ? this.allProducts.find(pd => pd.id === element).pricing.raw.price : 0;
    //     });
    //     return totalAddOnAmount
    //   }
    //   else{
    //     return 0;
    //   }
    // }
  }

  getselectedProductIds():Array<any>{
    if(this.selectedProducts.length > 0){
      return this.selectedProducts.map(function (sp){return sp.id})
    }
    else return [];
  }

  getproductQuantity(id):any{
    
    
    if(this.selectedProducts.filter(sp => sp.id === id).length>1)
    {
      var selected=this.selectedProducts.filter(sp => sp.id === id);
      var selectedquantity=0;

      selected.forEach(element=>{

        selectedquantity=selectedquantity+element.quantity;
      })
      return selectedquantity;
    }
    else{
      return this.selectedProducts.find(sp => sp.id === id).quantity;
    }
  }
// enableadd()
// {
//   if(this.addonIds.length<this.maxselecteditems)
//   {
//     this.enabledisableaddbutton=false;
//   }
//   else{
//     this.enabledisableaddbutton=true;
//   }
  
// }
addclose(val)
{
  // if(val==false)
  // {
  //   this.selectedProduct =[];

  // }
  // this.addonIds=[]
}
oldpackage_total=0;
updateclose(val)
{
  if(!val)
  {
    document.getElementById('updatehideclose').click();
    this.selectedProducts[this.customizedIndex]=this.initialselected;
    this.initialselected=''
    this.selectedPackageProduct='';
    if(this.oldpackage_total!=0)
    {
      this.selectedProducts[this.customizedIndex].package_total=this.oldpackage_total;
      this.oldpackage_total=0;
    }
    return false;

  }
  this.oldpackage_total=0;
  let totalcount=0;
   var cs= this.selectedProducts[this.customizedIndex].product.customise_products.customisedCategory.length;
   let i=0;
   for(i=0;i<cs;i++)
   {
    var c=document.getElementsByClassName('largerCheckboxes'+i) as HTMLCollection;
    console.log(c);
     var j;
     var count=0;
     for( j=0;j<c.length;j++)
     {
       let ele = document.getElementById(i+'vproduct'+j) as HTMLInputElement;
       if(ele.checked)
       {
           count++;
           this.checkedids.push(i+'a'+ele.value)
       }
     }
     let no_of_products = (<HTMLInputElement>document.getElementById('updateno_of_products'+i)).value;
     if(count<Number(no_of_products))
     {
      this.alertService.error("You may have "+no_of_products + " choice of your liking",{id:'updatealert'+i,autoClose:true })
return false;
     }
    // totalcount=totalcount+count;
   }
    
    // let totalnumberofproducts=0;
    // this.selectedProducts[this.customizedIndex].product.customise_products.customisedCategory.forEach(element=>
    //   {
    //     totalnumberofproducts=totalnumberofproducts+Number(element.no_of_products);
    //   })
    // if(totalcount<totalnumberofproducts)
    // {
    //   this.alertService.error("Please Select Minimum Quantity On All Categories",this.options)
    //   return false;
    // }
    // console.log(this.checkedids);
    // this.selectedProducts[this.customizedIndex].checked_products = this.checkedids;
    // this.selectedProducts[this.customizedIndex].package_total = this.selectedProducts[this.customizedIndex].package_total+this.add_on_prices;
    // this.add_on_prices=0;
    // this.checkedids=[];
  // if(Number(this.customizedIds.length)<this.maxselecteditems)
  // {
  //   this.showAlerts=false;
  //   this.alertService.error("You may have "+this.maxselecteditems + " for this item",this.options)
    
  //   return false;
  // }
  // else
  // {
  //   this.selectedProducts[this.customizedIndex].add_onproducts = this.customizedIds;z
    // this.updateProductstoCart();
    // document.getElementById('updatehideclose').click();
  //}
  document.getElementById('updatehideclose').click();
  var existingproduct=this.selectedProducts.filter(sp => sp.id === 
    this.selectedProducts[this.customizedIndex].id&&sp.checked_products
    &&sp.checked_products.length === this.checkedids.length && 
    sp.checked_products.every((value, index) => value === this.checkedids[index]));

    var existingproductindex=this.selectedProducts.findIndex(sp => sp.id === 
      this.selectedProducts[this.customizedIndex].id&&sp.checked_products
      &&sp.checked_products.length === this.checkedids.length && 
      sp.checked_products.every((value, index) => value === this.checkedids[index]));
   
    if(existingproduct.length>0&&this.customizedIndex!=existingproductindex&&existingproductindex!=-1)
    {
        var additionquantity=this.selectedProducts[this.customizedIndex].quantity;
        var additionpackagequantity=this.selectedProducts[this.customizedIndex].package_quantity;
        var customizedindexid=this.selectedProducts[this.customizedIndex].id;
      this.selectedProducts.splice(this.customizedIndex,1);
      existingproductindex=this.selectedProducts.findIndex(sp => sp.id === 
        Number(customizedindexid)&&sp.checked_products
        &&sp.checked_products.length === this.checkedids.length && 
        sp.checked_products.every((value, index) => value === this.checkedids[index]));

        this.selectedProducts[existingproductindex].quantity=this.selectedProducts[existingproductindex].quantity+additionquantity;
        this.selectedProducts[existingproductindex].package_quantity=this.selectedProducts[existingproductindex].package_quantity+additionpackagequantity;
      
    }
   else{
    console.log(this.checkedids);
  this.selectedProducts[this.customizedIndex].checked_products = this.checkedids;
  this.selectedProducts[this.customizedIndex].package_total = this.selectedProducts[this.customizedIndex].package_total+this.add_on_prices;
 
   }
    
    this.updateProductstoCart();
    this.add_on_prices=0;
    this.checkedids=[];
}
// enableupdate()
// {
//   if(this.customizedtemp.length+this.customizedIds.length<this.maxselecteditems)
//   {
//     this.enabledisableupdatebutton=false;
//   }
//   else{
//     this.enabledisableupdatebutton=true;
//   }
//   // if(this.customizedIds.length<this.maxselecteditems)
//   // {
//   //   this.enabledisableupdatebutton=false;
//   // }
//   // else{
//   //   this.enabledisableupdatebutton=true;
//   // }
  
// }
// pincoderanges;
// pincode:any;
// is_pincode_validated:boolean=false;
// validatepincode()
// {
//   var breakme=false;
//   this.pincoderanges.forEach(element => {
//     if(breakme==false)
//     {
//       if((Number(this.pincode)>Number(element.key)&&Number(this.pincode)<Number(element.value))||(Number(this.pincode)==Number(element.value))||(Number(this.pincode)==Number(element.key)))
//       {
//         this.localStorageService.set('is_pincode_validated','true');
//         this.localStorageService.set('pincode',this.pincode);
//         this.is_pincode_validated=true;
//         breakme=true;
//       }
//       else{
        
//         this.is_pincode_validated=false;
//       }
//     }
   
//   });
//   if(!this.is_pincode_validated)
//   {
//     document.getElementById('error').style.display='block';
//   }
//   else{
//     window.location.reload();

//   }


// }
// getCheckoutSettings(){
//   return new Promise((resolve, reject) => {
//     this.checkoutApiService.checkoutAPICall(ENDPOINTS.CHECKOUT_SETTINGS).then(
//       respData => {
//         let res:any = respData;
//         this.pincoderanges=res.data[0].attributes;
//         resolve(res.data[0])
//       }
//     )
//   })
// }
CheckPincode(product)
{
  if(this.localStorageService.get('is_pincode_validated')=='true')
  {
    this.addToCart(product);
  }
  else{
    this.localStorageService.set('add_button_clicked','true');
    
    document.getElementById('pincode-model').click();
  }
  
}
  addToCart(product){
    console.log('addtocart'+JSON.stringify(product));
    let totalItems = this.selectedProducts.length > 0 ? this.selectedProducts.map(function (sp){return sp.package_quantity}).reduce((a,b) => a + b) : 0;
    if(totalItems >= this.maxQuantity){
      this.showAlerts=true;
      this.alertService.error("Sorry, you can not order more than "+this.maxQuantity + " in an order.",this.options)
    }
    else{
      if((product.customise_products && product.customise_products.customised)||(product.bundle_products && product.bundle_products.make_bundle)){
        

        this.selectedProduct = product
        this.maxselecteditems=product.customise_products.no_of_products;
        document.getElementById('launch-model').click();
        
      }
      else{
        if(this.selectedProducts.find(pd => pd.id === product.id) !== undefined){
          let spindex = this.selectedProducts.findIndex(pd => pd.id === product.id);
          this.selectedProducts[spindex].quantity += 1;
          this.selectedProducts[spindex].package_quantity += 1;
        }
        else{
          let bundle_amount = 0;
          if(product.bundle_products && product.bundle_products.make_bundle && product.bundle_products.chargable && product.bundle_products.products.length > 0){
            bundle_amount = this.getBundleAmount('arrays',  product.bundle_products.products.map(function (sp){return sp.product_id}))
          }
          this.selectedProducts.push({
            id: product.id,
            name: product.name,
            quantity: 1,
            package_quantity: 1,
            package_total: product.pricing.raw.price + bundle_amount,
            product_cost: product.pricing.raw.price,
            bundle_products: [],
            checked_products: [],
            add_onproducts: [],
            customized:false,
            bundle_chargable: product.bundle_products && product.bundle_products.make_bundle && product.bundle_products.chargable,
            product:product
          })
        }
      }
      this.updateProductstoCart();
    } 
  }

  decreaseItem(id, type, index){
    var length=this.selectedProducts.filter(sp => sp.id === id).length; 
    if(length==1)
    {
      let spindex = this.selectedProducts.findIndex(pd => pd.id === id);
      if(this.selectedProducts[spindex].quantity === 1){
        this.selectedProducts.splice(spindex, 1)
      }
      else{
        this.selectedProducts[spindex].quantity -= 1;
        this.selectedProducts[spindex].package_quantity -= 1;
      }
    }
    else{
      this.router.navigateByUrl('/cart-new', {skipLocationChange:false})
    }

    
    
    this.updateProductstoCart();
  }
  decreaseItemspecifically(id, type, index){

      if(this.selectedProducts[index].quantity === 1){
        this.selectedProducts.splice(index, 1)
      }
      else{
        this.selectedProducts[index].quantity -= 1;
        this.selectedProducts[index].package_quantity -= 1;
      }
    


    
    
    this.updateProductstoCart();
  }
  getaddonItem(i,e,id,no_of_products,chargeable,saleprice){

   // document.getElementById('alert'+i).style.display='none';
    var c=document.getElementsByClassName('largerCheckbox'+i) as HTMLCollection;
   console.log(c);
    var j;
    var count=0;
    for( j=0;j<c.length;j++)
    {
      let ele = document.getElementById(i+'bproduct'+j) as HTMLInputElement;
      if(ele.checked)
      {
          count++;
      }
    }
  if(Number(no_of_products)!=0&&count>Number(no_of_products))
  { 
 
   //document.getElementById('alert'+i).style.display='block';
    
  this.alertService.error("You may have "+no_of_products + " choice of your liking",{id:'alert'+i,autoClose:true })

    return false;
  }
 
   // this.temp1 = this.temp1.filter(sid => sid.name ===this.selectedProduct.name);
//     let docid = document.getElementById(id) as HTMLInputElement;
//     if(docid.checked){

    
//     //this.addonIds=this.temp1.find(o => o.name ===this.selectedProduct.name);
//     this.addonIds.push(Number(e.target.value))
//       if(this.addonIds.length>this.maxselecteditems)
//      {
//       this.showAlerts=false;
//   this.alertService.error("You may have "+this.maxselecteditems + " items",this.options)
  
//  this.addonIds = this.addonIds.filter(sid => sid !== Number(e.target.value))
//   return false;
//       }
//     }
//     else{
//       this.addonIds = this.addonIds.filter(sid => sid !== Number(e.target.value))
//     //   let obj = this.temp1.find(o => o.name ===this.selectedProduct.name);
//     //  obj['values'].splice(obj['values'].indexOf(Number(e.target.value)),1)
//     }
    // if(this.addonIds.length<this.maxselecteditems)
    // {
    //   this.enabledisableaddbutton=false;
    // }
    // else{
    //   this.enabledisableaddbutton=true;
    // }
    var tempids=[];
    this.add_on_prices=0;
    var cs= this.selectedProduct.customise_products.customisedCategory.length;
    let ind=0;
    for(ind=0;ind<cs;ind++)
    {
     var c=document.getElementsByClassName('largerCheckbox'+ind) as HTMLCollection;
     console.log(c);
      var j;
      var count=0;
      for( j=0;j<c.length;j++)
      {
        let ele = document.getElementById(ind+'bproduct'+j) as HTMLInputElement;
        if(ele.checked)
        {
            count++;
            tempids.push(Number(ele.value))
        }
      }

    }
    var ab=this.selectedProduct.customise_products.customisedCategory;
    for(i=0;i<ab.length;i++)
    {

      const result = ab[i].variants.filter(element => tempids.includes(element.id));
      //item.variants=result;
      if(result.length>0)
      {
        if(ab[i].chargeable)
        {
          result.forEach(element=>{
this.add_on_prices=this.add_on_prices+element.sale_price;

          })

        }
         }
    }
   
  }
  
  updateaddonItem(i,e,id,no_of_products,chargeable,saleprice){
  if(this.oldpackage_total==0)
  {
    this.oldpackage_total=this.selectedPackageProduct.package_total;
  }
    
    // document.getElementById('alert'+i).style.display='none';
     var c=document.getElementsByClassName('largerCheckboxes'+i) as HTMLCollection;
    console.log(c);
     var j;
     var count=0;
     for( j=0;j<c.length;j++)
     {
       let ele = document.getElementById(i+'vproduct'+j) as HTMLInputElement;
       if(ele.checked)
       {
           count++;
       }
     }
 
   if(Number(no_of_products)!=0&&count>no_of_products)
   { 
  
    //document.getElementById('alert'+i).style.display='block';
    this.alertService.error("You may have "+no_of_products + " choice of your liking",{id:'updatealert'+i,autoClose:true })

     
   // this.alertService.error("You may have "+no_of_products + " choice of your liking",this.options)
 
     return false;
   }
  //  let eles = document.getElementById(id) as HTMLInputElement;
 
  //  if(chargeable&&eles.checked)
  //  {
  //    this.add_on_prices=this.add_on_prices+saleprice;
 
  //  }
  //  if(chargeable&&!eles.checked)
  //  {
  //    this.add_on_prices=this.add_on_prices-saleprice;
 
  //  }
    // this.temp1 = this.temp1.filter(sid => sid.name ===this.selectedProduct.name);
 //     let docid = document.getElementById(id) as HTMLInputElement;
 //     if(docid.checked){
 
     
 //     //this.addonIds=this.temp1.find(o => o.name ===this.selectedProduct.name);
 //     this.addonIds.push(Number(e.target.value))
 //       if(this.addonIds.length>this.maxselecteditems)
 //      {
 //       this.showAlerts=false;
 //   this.alertService.error("You may have "+this.maxselecteditems + " items",this.options)
   
 //  this.addonIds = this.addonIds.filter(sid => sid !== Number(e.target.value))
 //   return false;
 //       }
 //     }
 //     else{
 //       this.addonIds = this.addonIds.filter(sid => sid !== Number(e.target.value))
 //     //   let obj = this.temp1.find(o => o.name ===this.selectedProduct.name);
 //     //  obj['values'].splice(obj['values'].indexOf(Number(e.target.value)),1)
 //     }
     // if(this.addonIds.length<this.maxselecteditems)
     // {
     //   this.enabledisableaddbutton=false;
     // }
     // else{
     //   this.enabledisableaddbutton=true;
     // }
     var tempids=[];
     
     this.add_on_prices=0;
     var cs= this.selectedPackageProduct.product.customise_products.customisedCategory.length;
    let ind=0;
    for(ind=0;ind<cs;ind++)
    {
     var c=document.getElementsByClassName('largerCheckboxes'+ind) as HTMLCollection;
     console.log(c);
      var j;
      var count=0;
      for( j=0;j<c.length;j++)
      {
        let ele = document.getElementById(ind+'vproduct'+j) as HTMLInputElement;
        if(ele.checked)
        {
            count++;
            tempids.push(Number(ele.value))
        }
      }

    }
    var ischargeable=false;
    var ab=this.selectedPackageProduct.product.customise_products.customisedCategory;
    for(i=0;i<ab.length;i++)
    {

      const result = ab[i].variants.filter(element => tempids.includes(element.id));
      //item.variants=result;
      if(result.length>0)
      {
        if(ab[i].chargeable)
        {
           ischargeable=true;
          
          result.forEach(element=>{
this.add_on_prices=this.add_on_prices+element.sale_price;

          })

        }
         }
         if(ischargeable)
         {
          this.selectedPackageProduct.package_total=this.selectedPackageProduct.product.pricing.raw.price;
         }
    }
   }
initialselected;
  customizeItem(sp, i){
    this.add_on_prices=0;
    this.customizedIndex = i;
    console.log(sp)
    this.initialselected=sp;
    this.selectedPackageProduct = sp;
    // this.customizedIds = sp.add_onproducts ? sp.add_onproducts : [];
    //   this.maxselecteditems=sp.product.customise_products.no_of_products;
    document.getElementById('launch-model1').click();
    
  }
checkedids=[];
  addItemstocart(){
    
    let totalcount=0;
   var cs= this.selectedProduct.customise_products.customisedCategory.length;
   let i=0;
   for(i=0;i<cs;i++)
   {
    var c=document.getElementsByClassName('largerCheckbox'+i) as HTMLCollection;
    console.log(c);
     var j;
     var count=0;
     for( j=0;j<c.length;j++)
     {
       let ele = document.getElementById(i+'bproduct'+j) as HTMLInputElement;
       if(ele.checked)
       {
           count++;
           this.checkedids.push(i+'a'+ele.value)
       }
     }
     let no_of_products = (<HTMLInputElement>document.getElementById('no_of_products'+i)).value;
     if(count<Number(no_of_products))
     {
      this.alertService.error("You may have "+no_of_products + " choice of your liking",{id:'alert'+i,autoClose:true })
return false;
     }
     //totalcount=totalcount+count;
   }
    
    // let totalnumberofproducts=0;
    // this.selectedProduct.customise_products.customisedCategory.forEach(element=>
    //   {
    //     totalnumberofproducts=totalnumberofproducts+Number(element.no_of_products);
    //   })
    // if(totalcount<totalnumberofproducts)
    // {
    //   this.alertService.error("Please Select Minimum Quantity On All Categories",this.options)
    //   return false;
    // }
    // console.log(this.checkedids);
    // this.selectedProducts.push({
    //   id: this.selectedProduct.id,
    //   name: this.selectedProduct.name,
    //   quantity: 1,
    //   package_quantity: 1,
    //   package_total: this.selectedProduct.pricing.raw.price + this.add_on_prices,
    //   product_cost: this.selectedProduct.pricing.raw.price,
    //   bundle_products: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.products.length > 0 ? this.selectedProduct.bundle_products.products.map(function (sp){return sp.product_id}): [],
    //   add_onproducts: this.addonIds,
    //   customized:true,
    //   bundle_chargable: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.chargable,
    //   product:this.selectedProduct,
    //   checked_products:this.checkedids
    // })
    // this.add_on_prices=0;
    // this.checkedids=[];
    var existingproduct=this.selectedProducts.find(sp => sp.id === this.selectedProduct.id&&sp.checked_products&&sp.checked_products.length === this.checkedids.length && sp.checked_products.every((value, index) => value === this.checkedids[index]))
   
   if(existingproduct!=undefined)
    {
      existingproduct.quantity=existingproduct.quantity+1;
      existingproduct.package_quantity=existingproduct.package_quantity+1;

    }
    
    else{
      this.selectedProducts.push({
        id: this.selectedProduct.id,
        name: this.selectedProduct.name,
        quantity: 1,
        package_quantity: 1,
        package_total: this.selectedProduct.pricing.raw.price + this.add_on_prices,
        product_cost: this.selectedProduct.pricing.raw.price,
        bundle_products: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.products.length > 0 ? this.selectedProduct.bundle_products.products.map(function (sp){return sp.product_id}): [],
        add_onproducts: this.addonIds,
        customized:true,
        bundle_chargable: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.chargable,
        product:this.selectedProduct,
        checked_products:this.checkedids
      })

    }


   
    this.add_on_prices=0;
    this.checkedids=[];
    this.selectedProduct='';
   // this.temp1 = this.temp1.filter(sid => sid.name !=this.selectedProduct.name);
  //  if(Number(this.addonIds.length)<this.maxselecteditems)
  //   {
  //     this.showAlerts=false;
  //     this.alertService.error("You may have "+this.maxselecteditems + " for this item",this.options)
      
  //     return false;
  //   }
 
    // let bundle_amount = 0;
    // if(this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.chargable && this.selectedProduct.bundle_products.products.length > 0){
    //   bundle_amount = this.getBundleAmount('arrays',  this.selectedProduct.bundle_products.products.map(function (sp){return sp.product_id}))
    // }
    // if(this.selectedProducts.find(sp => sp.id === this.selectedProduct.id)){
    //   if(this.selectedProducts.find(sp => {return (sp.id === this.selectedProduct.id) && (sp.add_onproducts.length === this.addonIds.length) && ((sp.add_onproducts.sort().every((value, index) => value === this.addonIds.sort()[index])))})){
    //     let sindex = this.selectedProducts.findIndex(sp => {return (sp.id === this.selectedProduct.id) && (sp.add_onproducts.length === this.addonIds.length) && ((sp.add_onproducts.sort().every((value, index) => value === this.addonIds.sort()[index])))})
    //     if(sindex >= 0){
    //       this.selectedProducts[sindex].quantity = this.selectedProducts[sindex].quantity + 1;
    //       this.selectedProducts[sindex].package_quantity = this.selectedProducts[sindex].package_quantity + 1;
    //     }
    //     else{
    //       this.selectedProducts.push({
    //         id: this.selectedProduct.id,
    //         name: this.selectedProduct.name,
    //         quantity: 1,
    //         package_quantity: 1,
    //         package_total: this.selectedProduct.pricing.raw.price + bundle_amount + this.getAddOnProductAmount('array', this.addonIds),
    //         product_cost: this.selectedProduct.pricing.raw.price,
    //         bundle_products: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.products.length > 0 ? this.selectedProduct.bundle_products.products.map(function (sp){return sp.product_id}): [],
    //         add_onproducts: this.addonIds,
    //         customized:true,
    //         bundle_chargable: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.chargable,
    //         product:this.selectedProduct
    //       })
    //     }
    //   }
    //   else{
    //     this.selectedProducts.push({
    //       id: this.selectedProduct.id,
    //       name: this.selectedProduct.name,
    //       quantity: 1,
    //       package_quantity: 1,
    //       package_total: this.selectedProduct.pricing.raw.price + bundle_amount+ this.getAddOnProductAmount('array', this.addonIds),
    //       product_cost: this.selectedProduct.pricing.raw.price,
    //       bundle_products: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.products.length > 0 ? this.selectedProduct.bundle_products.products.map(function (sp){return sp.product_id}): [],
    //       add_onproducts: this.addonIds,
    //       customized:true,
    //       bundle_chargable: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.chargable,
    //       product:this.selectedProduct
    //     })
    //   }
    // }
    // else{
    //   this.selectedProducts.push({
    //     id: this.selectedProduct.id,
    //     name: this.selectedProduct.name,
    //     quantity: 1,
    //     package_quantity: 1,
    //     package_total: this.selectedProduct.pricing.raw.price + bundle_amount + this.getAddOnProductAmount('array', this.addonIds),
    //     product_cost: this.selectedProduct.pricing.raw.price,
    //     bundle_products: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.products.length > 0 ? this.selectedProduct.bundle_products.products.map(function (sp){return sp.product_id}): [],
    //     add_onproducts: this.addonIds,
    //     customized:true,
    //     bundle_chargable: this.selectedProduct.bundle_products && this.selectedProduct.bundle_products.make_bundle && this.selectedProduct.bundle_products.chargable,
    //     product:this.selectedProduct
    //   })
    // }
    // if(this.addonIds.length > 0){
    //   this.selectedProduct.customise_products.customisedProducts.forEach((element,i) => {
    //     let docid = document.getElementById('aproduct'+i) as HTMLInputElement;
    //     docid.checked = false;
    //   });
    // }
    // this.addonIds = [];
    
    this.updateProductstoCart();
    document.getElementById('addclose').click();
    console.log(this.selectedProducts)
  }
removehtmltag(content)
{
  if(content!=''&&content!=undefined){
    var html = content;
    var div = document.createElement("div");
    div.innerHTML = html;
    content= div.textContent || div.innerText || "";
  }
  else{
    content='';
  }
 
  return content;

}
  updateaddonItemold(e,id){
 

    
    let docid = document.getElementById(id) as HTMLInputElement;
    if(docid.checked){
      this.customizedIds.push(Number(e.target.value))
      if(this.customizedIds.length>this.maxselecteditems)
      {
        this.showAlerts=false;
        this.alertService.error("You may have "+this.maxselecteditems + " items",this.options)
        
        this.customizedIds = this.customizedIds.filter(sid => sid !== Number(e.target.value))
        return false;
      }
    }
    else{
      this.customizedIds = this.customizedIds.filter(sid => sid !== Number(e.target.value))
    }
  }

  updateItemstoCart(){
    // this.customizedIds=this.customizedIds.concat(this.customizedtemp)
    // this.selectedProducts[this.customizedIndex].add_onproducts = this.customizedIds;
    if(Number(this.customizedIds.length)<this.maxselecteditems)
    {
      this.showAlerts=false;
      this.alertService.error("You may have "+this.maxselecteditems + " for this item",this.options)
      
      return false;
    }
    // if (!((this.selectedProducts[this.customizedIndex].add_onproducts.length === this.customizedIds.length) && (this.selectedProducts[this.customizedIndex].add_onproducts.sort().every((value, index) => value === this.customizedIds.sort()[index])))){
    //   if(this.selectedProducts.find((sp, index) => {return (sp.id === this.selectedPackageProduct.id) && (index !== this.customizedIds)})){
    //     if(this.selectedProducts.find((sp, indx) => {return (sp.id === this.selectedProduct.id)&& (indx !== this.customizedIds) && (sp.add_onproducts.length === this.customizedIds.length) && ((sp.add_onproducts.sort().every((value, index) => value === this.customizedIds.sort()[index])))})){
    //       let eindex = this.selectedProducts.findIndex((sp, indx) => {return (sp.id === this.selectedProduct.id)&& (indx !== this.customizedIds) && (sp.add_onproducts.length === this.customizedIds.length) && ((sp.add_onproducts.sort().every((value, index) => value === this.customizedIds.sort()[index])))})
    //       if(eindex >= 0){
    //         this.selectedProducts[eindex].quantity = this.selectedProducts[eindex].quantity + this.selectedProducts[this.customizedIndex].quantity;
    //         this.selectedProducts[eindex].package_quantity = this.selectedProducts[eindex].package_quantity + this.selectedProducts[this.customizedIndex].package_quantity;
    //         this.selectedProducts.splice(this.customizedIndex, 1)
    //       }
    //       else{
    //         this.selectedProducts[this.customizedIndex].add_onproducts = this.customizedIds;
    //       }
    //     }
    //     else{
    //       this.selectedProducts[this.customizedIndex].add_onproducts = this.customizedIds;
    //     }
    //   }
    //   else{
    //     this.selectedProducts[this.customizedIndex].add_onproducts = this.customizedIds;
    //   }
    // }
    this.selectedProducts[this.customizedIndex].add_onproducts = this.customizedIds;
    this.updateProductstoCart();
    
    document.getElementById('updatehideclose').click();
  }

  updateProductstoCart(){
    this.cartNewService.updateToCart(this.selectedProducts);
  }

  redirectPage(page){
    if(page === "cart"){
      this.router.navigateByUrl('/cart-new', {skipLocationChange:false})
    }
    else{
      this.router.navigateByUrl('/checkout-new', {skipLocationChange:false})
    }
  }

  getPageProducts(pageNo){
    this.page = pageNo;
    this.getCategoryProducts(this.selectedCategory);
  }
}
