<app-alerts *ngIf="showAlerts"></app-alerts>

<section>
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" style="margin-bottom: 20px;">
        <!-- <div  *ngIf="CONFIG.title != 'Tams'" class="carousel-inner"  style="box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;">
              <div class="carousel-item" [class.active]="i==0"  *ngFor="let carousel1 of carouselData; let i=index;">
                  <img style="max-height: 400px;" [src]="carousel1.image_source" class="d-block w-100" alt="...">
                </div>
        </div>
        <div *ngIf="CONFIG.title === 'Tams'" class="carousel-inner"  style="box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;">
            <div class="carousel-item" [class.active]="i==0"  *ngFor="let carousel1 of carouselData; let i=index;">
                <img style="max-height: 400px;" [src]="carousel1.image_source" class="d-block w-100" alt="carousel1.alt_text">
                <div class="text-primary natural-main-text" style="position: absolute;top: 50%;left: 26%;transform: translate(-50%, -50%);">{{carousel1.alt_text}}</div>
            </div>
      </div> -->

      <div *ngIf="CONFIG.title != 'Tams' && CONFIG.title != 'Mayan'" class="carousel-inner"  style="box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;">
        <div class="carousel-item" [class.active]="i==0"  *ngFor="let carousel1 of carouselData; let i=index;">
            <img style="max-height: 400px;" [src]="carousel1.image_source" class="d-block w-100" alt="...">
          </div>
     </div>
      <div *ngIf="CONFIG.title === 'Tams' || CONFIG.title === 'Mayan'" class="carousel-inner"  style="box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;">
        <div class="carousel-item" [class.active]="i==0"  *ngFor="let carousel1 of carouselData; let i=index;">
            <img style="max-height: 400px;" [src]="carousel1.image_source" class="d-block w-100" alt="...">
            <div class="text-center text-primary" style="margin-top:21px;">
                <h4>{{carousel1.alt_text}}</h4>
            </div>
          </div>
     </div>
        <a *ngIf="CONFIG.title !== 'Nesma'" class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span style="background-color: #E48C8C; border-radius: 50%; padding: 10px 15px; opacity: 1 !important;"><img src="../../assets/images/vendor/previous.png"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a *ngIf="CONFIG.title !== 'Nesma'" class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span style="background-color: #E48C8C; border-radius: 50%; padding: 10px 15px; opacity: 1 !important;"><img src="../../assets/images/vendor/next.png"></span>
          <span class="sr-only">Next</span>
        </a>
        <a *ngIf="CONFIG.title === 'Nesma'" class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span style="background-color: #53bcd1; border-radius: 50%; padding: 10px 15px; opacity: 1 !important;"><img src="../../assets/images/vendor/previous.png"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a *ngIf="CONFIG.title === 'Nesma'" class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span style="background-color: #53bcd1; border-radius: 50%; padding: 10px 15px; opacity: 1 !important;"><img src="../../assets/images/vendor/next.png"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
</section>

<!-- new template-->
<section *ngIf="CONFIG.title === 'Mayan'">
    <div class="top-category-container">
        <div class="text-center mb-2" style="margin-top:40px">
            <p class="mayanwelcom-text">Our Collection</p>
            <!--<h3 class="text-primary">Shop from Top Categories</h3>-->
        </div>

            <div  style="padding-left: 10px;">
                <div class="row image-container-top d-flex justify-content-center">
                    <div *ngFor="let category of allCategories" class="top-image-cover">
                        <img class='mayantopcategory-img' [src]="(category.image != '') ? category.image : 'assets/images/mayan/NoImage.png'" (click)="getProductByFilter(category);">
                        <p class="topcategory-text">{{category.name}}</p>
                    </div>
                </div>
            </div>


        <!--<div style="padding-left: 10px;">
            <div class="row image-container-top d-flex justify-content-center">
                <div class="top-image-cover">
                    <img class="mayantopcategory-img" src="assets/images/mayan/BUDDHA.jpg" (click)="gotoProductList()">
                    <p class="topcategory-text">Buddha</p>
                </div>
                <div class="top-image-cover">
                    <img class="mayantopcategory-img" src="assets/images/mayan/ELEPHANT - 2.jpg" (click)="gotoProductList()">
                    <p class="topcategory-text">Elephant</p>
                </div>
                <div class="top-image-cover">
                    <img class="mayantopcategory-img" src="assets/images/mayan/RELAXING GANESHA.jpg" (click)="gotoProductList()">
                    <p class="topcategory-text">Relaxing Ganesha</p>
                </div>
                <div class="top-image-cover">
                    <img class="mayantopcategory-img" src="assets/images/mayan/CRANE.jpg" (click)="gotoProductList()">
                    <p class="topcategory-text">Crane</p>
                </div>
            </div>
        </div>-->
        <div class="row">
            <div class="col-md-6">
                <div class="text-center mb-2" style="margin-top:60px">
                    <p class="mayanwelcom-text text-right">Mayan Handicrafts</p>
                    <p class="font-weight-light text-right">Every block of stone is said to have a statue inside it</p>
                    <p class="font-weight-light text-right">The sculpture is said to be an art of intelligence</p>
                    <p class="font-weight-light text-right">It is the task of the sculptor to discover it.</p>
                    <button type="button" (click)="goto()" class="btn btn-outline-primary btn-rounded" style="float: right;margin-top: 10px;">LEARN MORE</button>
                </div>
            </div>
            <div class="col-md-6">
                <img src="assets/images/mayan/BUDDHA.jpg" style="width: 100%;">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <img src="assets/images/mayan/ELEPHANT - 2.jpg" style="width: 100%;">
            </div>
            <div class="col-md-6">
                <div class="text-center mb-2" style="margin-top:60px">
                    <p class="mayanwelcom-text text-left">Our general product and design philosophy</p>
                    <p class="font-weight-light text-left">Develop products that not only had more integrity but which were also made in less dehumanishing way.</p>
                    <p class="font-weight-light text-left">Any sculpture beautifully reflects its own time, place, and society.</p>
                    <p class="font-weight-light text-left">Greatly represents a particular history or a story in an art form</p>
                    <button type="button" (click)="goto()" class="btn btn-outline-primary btn-rounded" style="float: left;;margin-top: 10px;">KNOW MORE</button>

                </div>
            </div>
        </div>

        <div class="text-center mb-2" style="margin-top:40px">
            <p class="mayanwelcom-text">FEATURED @ MAYAN HANDICRAFTS</p>
            <!--<h3 class="text-primary">Shop from Top Categories</h3>-->
        </div>
    </div>
</section>
<section *ngIf="CONFIG.title == 'Murgasen'">

    <div class="top-category-container">
        <div class="text-center mb-2" style="margin-top:40px">
            <p class="welcom-text">Welcome to Murugesan Stores !!</p>
            <h3 class="text-primary">Shop from Top Categories</h3>
        </div>
        <div style="padding-left: 10px;">
            <div class="row image-container-top d-flex justify-content-center">
                <div class="top-image-cover">
                    <img class="topcategory-img" src="https://d18xkwaipu1whh.cloudfront.net/MurugesanAsset/murr4.jpg" (click)="categoryListing('Instant Noodles')">
                    <p class="topcategory-text">Instant Noodles</p>
                </div>
                <div class="top-image-cover">
                    <img class="topcategory-img" src="https://d18xkwaipu1whh.cloudfront.net/MurugesanAsset/muu5.jpg" (click)="categoryListing('Potato Chips')">
                    <p class="topcategory-text">Snacks And Biscuits</p>
                </div>
                <div class="top-image-cover">
                    <img class="topcategory-img" src="https://d18xkwaipu1whh.cloudfront.net/MurugesanAsset/mur6.jpg" (click)="categoryListing('Basmati')">
                    <p class="topcategory-text">Rice</p>
                </div>
                <div class="top-image-cover">
                    <img class="topcategory-img" src="https://d18xkwaipu1whh.cloudfront.net/MurugesanAsset/mur3.jpg" (click)="categoryListing('Coffee Tea and Beverages')">
                    <p class="topcategory-text">Coffee Tea and Beverages</p>
                </div>
                <div class="top-image-cover">
                    <img class="topcategory-img" src="https://d18xkwaipu1whh.cloudfront.net/MurugesanAsset/mur7.jpg" (click)="categoryListing('Chicken')">
                    <p class="topcategory-text">Soups</p>
                </div>
                <div class="top-image-cover">
                    <img class="topcategory-img" src="https://d18xkwaipu1whh.cloudfront.net/MurugesanAsset/mur5.jpg" (click)="categoryListing('Penne')">
                    <p class="topcategory-text">Pasta</p>
                </div>
                <div class="top-image-cover">
                    <img class="topcategory-img" src="https://d18xkwaipu1whh.cloudfront.net/MurugesanAsset/mur4.jpg" (click)="categoryListing('Meat')" >
                    <p class="topcategory-text">Meat</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="container inq-home-sec-st">
    <div *ngIf="CONFIG.title != 'Murgasen' && CONFIG.title != 'Tams' && CONFIG.title != 'Mayan' && CONFIG.title != 'Haven' && CONFIG.title != 'Nesma'" class="text-center mb-5">
        <b><h6><i>Welcome to {{CONFIG.name}}</i></h6></b>
    </div>
    <div *ngIf="CONFIG.title == 'Haven'" class="text-center mb-5">
        <b><h4>Welcome to {{CONFIG.name}}</h4></b>
        <!-- <b><h4>Customer Favourites</h4></b> -->
    </div>
    <div *ngIf="CONFIG.title == 'Tams'" class="text-center mb-5">
        <h4 class="text-primary">12 Hours Naturally Fermenting Flours</h4>
    </div>

    <div *ngIf="CONFIG.title == 'Murgasen'" class="text-center mb-5">
        <h3 class="text-primary">Featured Products</h3>
    </div>
    <ng-container *ngIf="CONFIG.title !== 'Nesma'">
        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let product of products; let i=index;">
                <ng-template carouselSlide>
                    <div class="home-page">
                        <div class="cardBox">
                            <div class="card">
                                <div class="product-card" *ngIf="CONFIG.title != 'Murgasen'">
                                    <div class="front">
                                        <div id="innerpartFront">
                                            <img height="150" [src]="product.assets[0].image_source"
                                                onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"
                                                (click)="productDetails(product.id)" *ngIf='product.assets[0].image_source!=""' alt="No Image">
                                            <img height="150" src="assets/images/vendor/No_Image_old.png"
                                                style="cursor: pointer;" (click)="productDetails(product.id)"
                                                *ngIf='!product.assets[0].image_source || product.assets[0].image_source==""' alt="No Image">
                                            <h5 class="text-bold " style="cursor: pointer;"
                                                (click)="productDetails(product.id)">  {{metaDataMapService.findData(product,fields[1].nestedinfo)}}</h5>
                                            <p class="text-center">{{product.parentcategories[0].name}}</p>
                                        </div>
                                    </div>
                                    <div class="back">
                                        <div id="innerpart">
                                            <h3 class="text-bold textblack1">{{product.name}}</h3>
                                            <p *ngIf="product.description" class="textblack">{{product.description.description}}</p>
                                            <h5 class="text-center textblack">{{product.parentcategories[0].name}}</h5>
                                            <p class="textbrrown">&#x20b9; {{product.pricing.raw.price}}</p>
                                            <p class="inq-bg-primary py-1 text-white">5 Points</p>
                                            <button class="mt-2" (click)="addtoCart(i)"
                                                *ngIf="appService.cartProducts.results.length == 0 && product.stock_status == 'Available'">ADD TO CART </button>
                                            <div *ngIf="appService.cartProducts.results.length > 0">
                                                <div class="mt-2" *ngIf="cartData.indexOf(product.id) > -1">
                                                    <div
                                                        *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                        <!-- <br> -->
                                                        <div class="inq-cart-bw-st" style="padding-top: 10%;"
                                                            *ngIf="cart.product_id===product.id">
                                                            <div class="quantity-btn">
                                                                <span class="inq-sp-1">
                                                                    <i class="fa fa-minus" (click)="decreaseItem(i)"
                                                                        style="font-size:12px"></i></span>
                                                                <span class="inq-sp-2" style="color: #ffffff;">
                                                                    {{cart.quantity}} </span>
                                                                <span class="inq-sp-3">
                                                                    <i class="fa fa-plus" (click)="increaseItem(i)"
                                                                        style="font-size:12px"></i></span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <button class="mt-2" (click)="addtoCart(i)"
                                                    *ngIf="cartData.indexOf(product.id) === -1">ADD TO CART</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-------------------------->
                                <div *ngIf="CONFIG.title != 'Murgasen'">
                                    <div class="tams-card" *ngIf="CONFIG.title != 'Tams' && CONFIG.title != 'Mayan'">
                                        <img class="card-img-top" style="cursor: pointer;"
                                            (click)="productDetails(product.id)" height="185" [src]="product.assets[0].image_source"
                                            alt="Card image cap">
                                        <div class="card-body text-center">
                                            <h5 class="text-bold" style="cursor: pointer;"
                                                (click)="productDetails(product.id)"> {{metaDataMapService.findData(product,fields[1].nestedinfo)}}</h5>
                                            <p class="textbrrown">&#x20b9; {{product.pricing.raw.price}}</p>
                                            <button class="btn add-cart-btn" (click)="addtoCart(i)"
                                                *ngIf="cartData.indexOf(product.id) === -1">ADD TO CART</button>

                                            <div class="mt-2" *ngIf="cartData.indexOf(product.id) > -1">
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index">

                                                    <div class="added-to-cart" *ngIf="cart.product_id===product.id">

                                                        <div>
                                                            <p>
                                                                <span><i class="fa fa-minus mr-2 minus custom-pointer"
                                                                        (click)="decreaseItem(i)"></i></span>
                                                                <span class="inq-amt-st">{{ cart.quantity }}</span><i
                                                                    class="fa fa-plus ml-2 plus custom-pointer"
                                                                    (click)="increaseItem(i)"></i><span></span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!---------------------->
                                <!-- ---------Prodict TAMS listing-->
                                <div *ngIf="CONFIG.title == 'Tams'">
                                    <div class="product-list-cards" style="color: lightgray;">
                                        <div>
                                            <!------------tams img------>
                                            <img [src]="product.assets[0].image_source" id="img-list"
                                                (click)="productDetails(product.id)"
                                                onerror="this.onerror=null;this.src='assets/images/tams/NoImage.png';"
                                                alt="{{ product.name}}" class="product-list-image">
                                        </div>
                                        <div class="product-list-description">
                                            <div class="d-flex justify-content-between">
                                                <div (click)="productDetails(product.id)" style="cursor:pointer;">
                                                    <p class="product-list-tile">
                                                        {{metaDataMapService.findData(product,fields[1].nestedinfo)}}
                                                    </p>
                                                </div>
                                                <div>

                                                    <p class="product-list-price"  *ngIf="!product.showPricse">
                                                        ₹{{product.variant.option_values[0].pricing.raw.price}}.00</p>
                                                <p class="product-list-price"*ngIf="product.showPricse && product.product_type=='Variable'" >₹{{product.pricee}}.00</p>

                                                        <!-- <p class="product-list-price"*ngIf="!product.showPricse">₹{{product.price}}.00</p> -->
                                                        <!-- <p class="product-list-price"*ngIf="!product.showPricse" style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:5px !important">&nbsp;  ₹{{product.pricing.raw.price_range.split('-')[1] + '.00'}}</p> -->
                                                        <!-- <p class="product-list-price"*ngIf="product.showPricse && (product.product_type=='Variable')" >₹{{product.pricee}}.00</p> -->
                                                        <p class="product-list-price"*ngIf="product.showPricse &&( product.product_type =='Simple' ||product.product_type =='Variation')" >₹{{product.pricing.raw.price}}.00</p>
                                                        <!-- <p class="product-list-price"  *ngIf="product.product_type=='Simple' ||product.product_type =='Variation' "style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price + '.00'}}</p>  -->
                                                        <p class="product-list-price"  *ngIf="product.product_type=='Variable'"style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price_range_from}}-{{product.pricing.raw.price_range_to}}</p>

                                                </div>
                                            </div>
                                            <div *ngIf="CONFIG.title === 'Tams' && product.shipping">
                                                <small style="color: #FF6E40;">
                                                    {{product.shipping.weight === 5000 ? 'Yields 12.5kg batter': 'Yields 2.5kg batter'}}
                                                </small>
                                            </div>
                                            <!----------variants------>
                                            <app-shared-variants [product]="product" [products]="products" (selectEmitter)="showPrice($event)"></app-shared-variants>

                                            <!-- <div>

                                                <label class='note'  style="font-size: 11px !important; font-weight: 500 !important;color: black !important;">Note: Please select option to add item in
                                                    cart</label>

                                            </div> -->

                                            <!---------------------reviews---------------->
                                            <div class="d-flex justify-content-between mt-1">
                                                <div class="inner-star-icon pd-1">
                                                    <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <a routerLink="/product-review/{{product.id}}" style="font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a>
                                                </div>
                                                <div>
                                                    <!--------- button---------->

                                                    <!-------------Add to cart-->
                                                    <button class=" cartButton"
                                                        style="font-size: small;font-weight: bold;width:80px;background-color: transparent !important;border:1px solid #9ab973 !important;color: #9ab973 !important;"
                                                        (click)="addToCarts(product)"
                                                        *ngIf="(product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  )">ADD
                                                        <span> <i class="fa fa-shopping-cart"></i></span></button>
                                                    <!---------view cart button-->
                                                    <div
                                                        *ngIf="appService.productIdinCart.indexOf(product.id) != -1 || appService.productIdinCart.indexOf(product.checkVariantInCart) != -1 ">
                                                        <div
                                                            *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                            <div class=" viewCart"
                                                                style="font-size: small;font-weight: bold;padding-left:4px;padding-right:4px;"
                                                                *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart">
                                                                <span>
                                                                    <i class="fa fa-minus mr-3"
                                                                        (click)="decreaseItem(i)"
                                                                        style="font-size:12px;cursor:pointer;"></i></span>
                                                                <span class="inq-sp-2"
                                                                    style="color: white;">{{cart.quantity}}</span>
                                                                <span>
                                                                    <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                                        style="font-size:12px;cursor:pointer;"></i></span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="CONFIG.title == 'Mayan'">
                                    <div class="mayan-product-list-cards">
                                        <div>
                                            <!------------tams img------>
                                            <img [src]="product.assets[0].image_source" id="img-list"
                                                (click)="productDetails(product.id)"
                                                onerror="this.onerror=null;this.src='assets/images/tams/NoImage.png';"
                                                alt="{{ product.name}}" class="product-list-image">
                                        </div>
                                        <div class="product-list-description">
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <p class="product-list-tile-mayan">
                                                        {{metaDataMapService.findData(product,fields[1].nestedinfo)}}
                                                    </p>
                                                </div>
                                                <div>

                                                    <p class="mayan-product-list-price"  *ngIf="!product.showPricse">
                                                        ₹{{product.variant.option_values[0].pricing.raw.price}}.00</p>
                                                <p class="mayan-product-list-price"*ngIf="product.showPricse && product.product_type=='Variable'" >₹{{product.pricee}}.00</p>

                                                        <!-- <p class="product-list-price"*ngIf="!product.showPricse">₹{{product.price}}.00</p> -->
                                                        <!-- <p class="product-list-price"*ngIf="!product.showPricse" style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:5px !important">&nbsp;  ₹{{product.pricing.raw.price_range.split('-')[1] + '.00'}}</p> -->
                                                        <!-- <p class="product-list-price"*ngIf="product.showPricse && (product.product_type=='Variable')" >₹{{product.pricee}}.00</p> -->
                                                        <p class="mayan-product-list-price"*ngIf="product.showPricse &&( product.product_type =='Simple' ||product.product_type =='Variation')" >₹{{product.pricing.raw.price}}.00</p>
                                                        <!-- <p class="product-list-price"  *ngIf="product.product_type=='Simple' ||product.product_type =='Variation' "style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price + '.00'}}</p>  -->
                                                        <p class="mayan-product-list-price"  *ngIf="product.product_type=='Variable'"style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price_range_from}}-{{product.pricing.raw.price_range_to}}</p>

                                                </div>
                                            </div>
                                            <!----------variants------>
                                            <app-shared-variants [product]="product" [products]="products" (selectEmitter)="showPrice($event)"></app-shared-variants>

                                            <!-- <div>

                                                <label class='note'  style="font-size: 11px !important; font-weight: 500 !important;color: black !important;">Note: Please select option to add item in
                                                    cart</label>

                                            </div> -->

                                            <!---------------------reviews---------------->
                                            <div class="d-flex justify-content-between mt-1">
                                                <div class="mayan-inner-star-icon pd-1">
                                                    <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <a routerLink="/product-review/{{product.id}}" style="color:#000000 !important;font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a>
                                                </div>
                                                <div>
                                                    <!--------- button---------->

                                                    <!-------------Add to cart-->
                                                    <button class="mayancartButton"
                                                        style="font-size: small;font-weight: bold;width:80px;border:1px solid #3c707c !important;color: #000000 !important;"
                                                        (click)="addToCarts(product)"
                                                        *ngIf="(product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  )">ADD
                                                        <span> <i class="fa fa-shopping-cart"></i></span></button>
                                                    <!---------view cart button-->
                                                    <div
                                                        *ngIf="appService.productIdinCart.indexOf(product.id) != -1 || appService.productIdinCart.indexOf(product.checkVariantInCart) != -1 ">
                                                        <div
                                                            *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                            <div class="mayanviewCart"
                                                                style="font-size: small;font-weight: bold;padding-left:4px;padding-right:4px;"
                                                                *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart">
                                                                <span>
                                                                    <i class="fa fa-minus mr-3"
                                                                        (click)="decreaseItem(i)"
                                                                        style="font-size:12px;cursor:pointer;"></i></span>
                                                                <span class="inq-sp-2"
                                                                    style="color: white;">{{cart.quantity}}</span>
                                                                <span>
                                                                    <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                                        style="font-size:12px;cursor:pointer;"></i></span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="CONFIG.title == 'Murgasen'">
                                    <div class="murugesan-card" [attr.data-label]="brandName(product.brand.name)">
                                        <div>
                                            <img [src]="product.assets[0].image_source" id="img-list"
                                                (click)="productDetails(product.id)"
                                                onerror="this.onerror=null;this.src='assets/images/tams/NoImage.png';"
                                                alt="{{ product.name}}" class="murugasen-list-image">
                                        </div>
                                        <div class="murugesan-description">
                                            <div class="murugesan-card-title" style="white-space: nowrap; width: 100%;overflow: hidden;text-overflow: ellipsis; ">
                                                {{ product.name}}
                                            </div>
                                            <div>
                                            <p class="murugesan-price"  *ngIf="!product.showPricse">SR {{product.variant.option_values[0].pricing.raw.price}}</p>
                                            <p class="murugesan-price"*ngIf="product.showPricse && product.product_type=='Variable'" >SR {{product.pricee}}</p>
                                            <p class="murugesan-price"*ngIf="product.showPricse &&( product.product_type =='Simple' ||product.product_type =='Variation')" >SR {{product.pricing.raw.price}}</p>
                                            <p class="murugesan-price"  *ngIf="product.product_type=='Variable'"style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">SR {{product.pricing.raw.price_range_from}}-{{product.pricing.raw.price_range_to}}</p>
                                            </div>
                                            <div class="d-flex justify-content-between mt-1">
                                                <div class="inner-star-icon pd-1">
                                                    <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <a routerLink="/product-review/{{product.id}}" style="font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a>
                                                </div>
                                                <div>
                                                    <i class="fa fa-info-circle fa-lg" style="color:#E48C8C" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="padding-top:15px">
                                            <button class="murugesan-addcart" (click)="addToCarts(product)" *ngIf="(product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  )">Add to Cart</button>
                                            <div *ngIf="appService.productIdinCart.indexOf(product.id) != -1 || appService.productIdinCart.indexOf(product.checkVariantInCart) != -1 ">
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                    <div class="murugesan-addcart" style="font-size: small;font-weight: bold;padding-left:4px;padding-right:4px;" *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart">
                                                        <span><i class="fa fa-minus mr-3" (click)="decreaseItem(i)" style="font-size:12px;cursor:pointer;"></i></span>
                                                        <span class="inq-sp-2" style="color: white;">{{cart.quantity}}</span>
                                                        <span> <i class="fa fa-plus ml-3" (click)="increaseItem(i)"style="font-size:12px;cursor:pointer;"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </ng-container>

    <!-- new template-->

    <section *ngIf="CONFIG.title == 'Mayan'">
        <div class="text-center mb-2" style="margin-top:40px">
            <p class="mayanwelcom-text">WHAT DO OUR CUSTOMER SAY</p>
            <!--<h3 class="text-primary">Shop from Top Categories</h3>-->
        </div>

        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let review of reviews; let i=index;">
                <ng-template carouselSlide>
                    <div class="home-page">
                        <div class="cardBox">
                            <div class="card">
        <div *ngIf="CONFIG.title == 'Mayan'">
            <div class="review-list-card">
                <div class="product-list-image" style="padding-top: 5px;">
                    <img src="assets/images/mayan/user icon.png"
                        class="img-fluid profile-image d-block mx-auto rounded"  style="width:40%;margin-top: 10px;">
                        <p style="text-align: center;padding-top: 4px;"><i>{{review.user}}</i></p>
                    <hr>
                    <div class="product-list-description">
                        <div class="d-flex justify-content-between">

                            <div class="sec-heading text-center" style="margin-top: 25px;">
                                <h4>{{review.comment}}</h4>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
        </div>
        </div>
        </div>
        </ng-template>
        </ng-container>
        </owl-carousel-o>
    </section>
    <!--end of new template-->

    <div class="row inq-secs-mt-5" *ngIf="CONFIG.title === 'Haven' || CONFIG.title === 'Dosapark'">
        <div class="col-md-3" *ngFor="let item of CONFIG.HOME_SETTINGS.about_st">
            <div class="text-center">
                <div class="inq-home-about-st">
                    <img src="{{item.image}}">
                    <h5>{{item.title}}</h5>
                    <div class=""></div>
                    <p class="pt-4">{{item.text}}</p>
                </div>
            </div>
        </div>

    </div>

    <ng-container *ngIf="CONFIG.title === 'Nesma'">
        <div class="row" style="height: 350px;justify-content: center;
        display: flex;">
            <div class="col-md-6 text-center text-white" style="background-color: #3c707c;padding-right: 0px; padding-top: 110px;">
                <div style="margin: auto;">
                    <h2>See Our Menu</h2>
                    <p style="font-size: 22px;font-weight: lighter;">Here we can write Something about Menu items</p>
                    <button class="btn btn-primary" style="padding:5px 60px; margin-top: 20px;" (click)="productlistnesma()">Menu</button>
                </div>
            </div>
            <div class="col-md-1.5" style="padding-left: 0px;">
                <img style="height: 350px;" src="../../assets/images/idlysam.jpg">
            </div>
        </div>
    </ng-container>
    <div *ngIf="CONFIG.title !== 'Mayan' && CONFIG.title !== 'Haven'" class="row inq-secs-mt-5" id="how_to_prepare">
        <div class="col-md-3" *ngFor="let item of CONFIG.HOME_SETTINGS.about_st">
            <div class="text-center">
                <div class="inq-home-about-st">
                    <img src="{{item.image}}">
                    <h5>{{item.title}}</h5>
                    <div class=""></div>
                    <p class="pt-4">{{item.text}}</p>
                </div>
            </div>
        </div>

    </div>

</section>
<section *ngIf="CONFIG.title !== 'Tams' && CONFIG.title !== 'Mayan' && CONFIG.title !== 'Haven'">
    <div class="row inq-secs-mt-5 no-gutters" >
        <div class="col-md-3" *ngFor="let item of CONFIG.HOME_SETTINGS.gutters">
            <div class="inq-home-ser-st">
                <img src="{{item.image}}" height="350">
            </div>
        </div>
    </div>
</section>
<section *ngIf="CONFIG.title === 'Haven'">
    <div class="row inq-secs-mt-5 no-gutters" >
        <div class="col-md-3" *ngFor="let item of CONFIG.HOME_SETTINGS.gutters">
            <div class="inq-home-ser-st">
                <img src="{{item.image}}">
            </div>
        </div>
    </div>
</section>
<section *ngIf="CONFIG.title === 'Tams' || CONFIG.title === 'cMayan'">
    <div class="row inq-secs-mt-5 no-gutters" >
        <div class="col-md-3" *ngFor="let item of CONFIG.HOME_SETTINGS.gutters">
            <div class="inq-home-ser-st">
                <img src="{{item.image}}" height="220">
            </div>
        </div>
    </div>
</section>
<section class="container" *ngIf="CONFIG.title != 'Mayan'">
    <div class="row justify-content-center">
        <div class="col-md-5">
            <div class="inq-testi-st text-center inq-secs-mt-5" *ngFor="let item of CONFIG.HOME_SETTINGS.testimonials">
                <h5 *ngIf="CONFIG.title === 'Dosapark'" style="text-transform:uppercase;">{{item.title}}</h5>
                <h5 *ngIf="CONFIG.title != 'Dosapark'">{{item.title}}</h5>
                <p class="mt-3" [innerHTML]="item.comment"></p>
                <p>- {{item.name}} -</p>
            </div>
        </div>
    </div>
</section>

<div class="inq-onl-del-st" *ngIF="CONFIG.HOME_SETTINGS.float_button.show">
    <img src="assets/images/tams/online.svg" class="online-image" routerLink="/product" alt="online">
    <img src="assets/images/havenSampoorna/online.svg" class="online-image1" routerLink="/product" alt="online">
</div>
