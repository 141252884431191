<section>
    <div class="jumbotron jumbotron-fluid">
        <div class="container">
            <div class="text-center">
                <h1>Cart</h1>
                <div><span class="home-breadcrumb" routerLink="">Home</span>
                    <span> <i class="fa fa-long-arrow-right px-2"></i> Cart</span>
                </div>
            </div>
        </div>
    </div>
    <app-alerts *ngIf="showAlerts"></app-alerts>
    <div class="inq-htop-st inq-row" *ngIf="banner !== '' && CONFIG.title !== 'Deera'">
        <div class="text-center">
            <p [innerHTML]="banner" class="text-center"></p>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-md-8 col-sm-12">
            <h4 *ngIf="appService.cartProducts.results.length > 0" class="pt-4">
                {{appService.cartProducts.results.length}} item(s) in your cart.
                <button style="float: right;" class="btn btn-primary inq-btn-st submit-action"
                (click)="ClearCart()">Clear Cart</button>
            </h4>
                
            <h4 *ngIf="appService.cartProducts.results.length === 0" class="pt-4">Your cart is empty.</h4>

            <div class="cart-table-container">
                <table class="cart-table table table-responsive">
                    <thead *ngIf="CONFIG.title !== 'BombayHardware'">
                        <th style="width: 3%;"></th>
                        <th style="width: 10%;"></th>
                        <th style="width: 5%;"></th>
                        <th style="width: 3%;"></th>
                        <th style="width: 3%;"></th>
                    </thead>
                    <!-- <thead *ngIf="CONFIG.title === 'BombayHardware'"> -->
                    <thead *ngIf="CONFIG.title === 'BombayHardware' && appService.cartProducts.results.length !== 0">
                        <th style="width: 3%;"></th>
                        <th style="width: 10%;" >Name</th>
                        <th style="width: 8%;" >SKU</th>
                        <th style="width: 6%;" >Brand</th>
                        <th style="width: 6%;">Quantity</th>
                    </thead>
                    <tbody *ngFor="let cart of appService.cartProducts.results; let i = index;">
                        <tr>
                            <td *ngIf="CONFIG.title !== 'Haven'" (click)="navigateToDetails(cart.product_id)" style="cursor: pointer;">
                                <img class="item-image" *ngIf="cart.image_source !== ''" [src]="cart.image_source" onerror="this.onerror=null;this.src='assets/images/No_Image_old.png';"/>
                                <img class="item-image" *ngIf="cart.image_source === ''"
                                    [src]="imageNotFound"> 
                            </td>
                            <td *ngIf="CONFIG.title === 'Haven'">

                            </td>
                            <td (click)="navigateToDetails(cart.product_id)" style="cursor: pointer;">
                                <span style="float: left">
                                    <h5>{{cart.product_name}}</h5>
                                </span>
                            </td>
                            <td>
                                <span *ngIf="CONFIG.title === 'BombayHardware'" style="float: left">
                                    <h5>{{cart.sku}}</h5>
                                </span>
                            </td>
                            <td>
                                <span *ngIf="CONFIG.title === 'BombayHardware'" style="float: left">
                                    <h5>{{cart.brand}}</h5>
                                </span>
                            </td>
                            
                            <td *ngIf="CONFIG.title !== 'Deera'">
                                <div class="add-cart1" style="min-width:fit-content">
                                    <div class="inq-fimg-mt2-st">
                                        <p class="inq-amt-st">
                                            <span><i class="fa fa-minus mr-2 minus"
                                                    (click)="decreaseItem(i)"></i></span>
                                                    <span *ngIf="CONFIG.title === 'ShubhCards'"><input type="number" style="width:60%;text-align:center;" (change)="ChangeValue(i)"[(ngModel)]="cart.quantity"></span> 
                                            <span *ngIf="CONFIG.title !== 'ShubhCards'" class="inq-amt-st">{{ cart.quantity }}</span>
                                            <span><i class="fa fa-plus ml-2 plus" (click)="increaseItem(i)"></i></span>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="CONFIG.title === 'Deera'" style="width: 20%;">
                                <div class="add-cart1" style="min-width:fit-content">
                                    <div class="inq-fimg-mt2-st">
                                        <p class="inq-amt-st">
                                            <span><i class="fa fa-minus mr-2 minus"
                                                    (click)="decreaseItem(i)"></i></span> 
                                                    <span *ngIf="CONFIG.title === 'Deera'"><input type="number" style="width:50%;text-align:center;" (input)="changeDeeraValue(i)" [(ngModel)]="cart.quantity"></span> 
                                            <span><i class="fa fa-plus ml-2 plus" (click)="increaseItem(i)"></i></span>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="CONFIG.title !== 'BombayHardware'">
                                <p class="inq-amt-st l cost">{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{ cart.quantity *
                                    cart.cost | number : '1.2-2'}}</p>
                            </td>
                            <td>
                                <p class="close" (click)="removeItem(i)">&#x292C;</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <hr *ngIf="config.title ==='BombayHardware' && appService.cartProducts.results.length !== 0">
            <hr *ngIf="config.title !=='BombayHardware'">
            <div class="row float-right mr-4 mt-2" *ngIf="CONFIG.title !== 'BombayHardware'">
                <h4 *ngIf="appService.cartProducts.results.length > 0">Total {{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}} {{totalPrice | number : '1.2-2'}}
                </h4>
            </div>
            <div class="row pt-3" style="width :100%;">
                <button *ngIf="config.title =='EarthyWorthy' || config.title == 'BBold'|| config.title == 'Murgasen'|| config.title == 'ShubhCards' || CONFIG.title == 'Deera'" class="btn btn-primary inq-btn-st ml-3" (click)="productListing()">CONTINUE SHOPPING</button>
                <button *ngIf="config.title !='EarthyWorthy' && config.title != 'BBold'&& config.title != 'Murgasen' && config.title != 'ShubhCards' && CONFIG.title !== 'Deera'&& config.title != 'BombayHardware'" class="btn btn-primary inq-btn-st ml-3" routerLink="/product">CONTINUE SHOPPING</button>

                <button *ngIf="config.title !='BombayHardware'" class="btn btn-primary inq-btn-st submit-action"
                    [disabled]="appService.cartProducts.results.length === 0" (click)="goToCheckout()">PROCEED TO
                    CHECKOUT</button>
                    <!-- <button *ngIf="config.title ==='BombayHardware'" class="btn btn-primary inq-btn-st submit-action"
                    [disabled]="appService.cartProducts.results.length === 0" (click)="submitEnquiry()">SUBMIT ENQUIRY</button> -->
                    
                    <button *ngIf="config.title ==='BombayHardware' && appService.cartProducts.results.length !== 0" class="btn btn-primary inq-btn-st submit-action"
                     (click)="submitEnquiry()">SUBMIT ENQUIRY</button>

                </div>
            <br>
        </div>
    </div>

    <!--recommended products-->
    <div *ngIf="slidesStore.length !== 0">
        <div class="mt-5" style="padding-left:20px !important">
            <h4>Customers Also Selected</h4>
            <owl-carousel-o [options]="customOptions">
                <ng-container *ngFor="let slide of slidesStore">
                    <ng-template carouselSlide>
                        <div>
                            <div class="cardBox">
                                <div class="customer-card">
                                    <div class="front">
                                        <div id="innerpartFront">
                                            <img *ngIf="slide.img !== ''" [src]="slide.img" class="img-fluid" onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';">
                                            <img *ngIf="slide.img === ''" src="assets/images/No_Image_old.png"
                                                class="img-fluid">
                                            <h5 class="text-bold ">{{slide.item}}</h5>
                                            <p class="text-center"> {{slide.type}}</p>
                                        </div>
                                    </div>
                                    <div class="back">
                                        <div id="innerpart">
                                            <h4 class="text-bold  textblack1">{{slide.backtitle}}</h4>
                                            <p class="textblack">{{slide.backpara}}</p>
                                            <h5 class="text-center textblack"> {{slide.backtype}}</h5>
                                            <p class="textbrrown">&#x20b9; {{slide.price}}</p>
                                            <p class="inq-bg-primary py-1 text-white">{{slide.points}}</p>
                                            <button class="mt-2" (click)="addToCart(slide)"
                                                *ngIf="appService.productIdinCart.indexOf(slide.id) === -1">{{slide.button}}</button>

                                            <div *ngIf="appService.productIdinCart.indexOf(slide.id) > -1">

                                                <div
                                                    *ngFor="let existing_cart of appService.cartProducts.results;let i = index">
                                                    <div class="d-flex justify-content-center pt-2"
                                                        *ngIf="existing_cart.product_id === slide.id">
                                                        <div class="add-cart2">
                                                            <span class="inq-sp-1">
                                                                <i class="fa fa-minus mr-2"
                                                                    (click)="decreaseItem(i)"></i></span>
                                                            <span class="inq-sp-2">{{existing_cart.quantity}}</span>
                                                            <span class="inq-sp-3">
                                                                <i class="fa fa-plus ml-2"
                                                                    (click)="increaseItem(i)"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--tams card-->
                                <div class="tams-card" *ngIf="config.title != 'Tams'">
                                    <img class="card-img-top" height="185" [src]="slide.img" onerror="this.onerror=null;this.src='assets/images/tams/NoImage.png';" alt="Card image cap">
                                    <div class="card-body text-center">
                                        <h5 class="text-bold">{{slide.item}}</h5>
                                        <p class="textbrrown">&#x20b9; {{slide.price}}</p>
                                        <button class="btn add-cart-btn" (click)="addToCart(slide)"
                                            *ngIf="appService.productIdinCart.indexOf(slide.id) === -1">{{slide.button}}</button>
                                        <div class="mt-2" *ngIf="appService.productIdinCart.indexOf(slide.id) > -1">
                                            <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                <div class="added-to-cart" *ngIf="cart.product_id===slide.id">
                                                    <div>
                                                        <p>
                                                            <span><i class="fa fa-minus mr-2 minus"
                                                                    (click)="decreaseItem(i)"></i></span>
                                                            <span class="inq-amt-st">{{ cart.quantity }}</span><i
                                                                class="fa fa-plus ml-2 plus"
                                                                (click)="increaseItem(i)"></i><span></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--tams card-->
                                <!--earthworthycard-->
                                <div class="earthy-card" *ngIf="config.title == 'EarthyWorthy'" style="cursor: pointer;">
                                    <div class="earthy-image">
                                        <div *ngIf="slide.img">
                                            <a (click)="navigateToDetails(slide.id)"><img  [src]='slide.img' class="img-fluid" onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"></a>
                                        </div>
                                        <div *ngIf="!slide.img">
                                            <a (click)="navigateToDetails(slide.id)"><img src='../../../../../assets/images/ITRSample.png' class="img-fluid"></a>
                                        </div>
                                    </div>
                                    <div class="earthy-description text-center" style="transform: translate3d(0px, 0px, 0px);">
                                        <div class="earthy-cart-btn">
                                            <button *ngIf="slide.product_type === 'Variable'" class="btn btn-primary earthy-btn" (click)="navigateToDetails(slide.id)">SELECT OPTIONS</button>
                                            <button *ngIf="slide.product_type !== 'Variable' && appService.productIdinCart.indexOf(slide.id) == -1" class="btn btn-primary earthy-btn" (click)="addToCart(slide)">ADD TO CART</button>
                                            <div *ngIf="slide.product_type !== 'Variable' && appService.productIdinCart.indexOf(slide.id) != -1">
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                    <div  class="btn btn-primary inq-btn-st ml-1 viewCart"  *ngIf="cart.product_id===slide.id">
                                                        <div>
                                                            <p class="inq-amt-st">
                                                                <span><i class="fa fa-minus mr-2 minus"
                                                                        (click)="decreaseItem(i)"></i></span>
                                                                <span class="inq-amt-st">{{ cart.quantity }}</span><i
                                                                    class="fa fa-plus ml-2 plus"
                                                                    (click)="increaseItem(i)"></i><span></span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="earthy-product-title">
                                            {{slide.name}}
                                        </div>
                                        <div class="earthy-worthy-price">
                                            ₹{{slide.priceRange? slide.priceRange:slide.price + '.00'}}
                                        </div>
                                    </div>
                                    </div>
                                <!--earthworthycard-->

                                 <!--bboldcard-->
                                 <div class="earthy-card" *ngIf="config.title == 'BBold'" style="cursor: pointer;">
                                    <div class="earthy-image">
                                        <div *ngIf="slide.img">
                                            <a (click)="navigateToDetails(slide.id)"><img  [src]='slide.img' class="img-fluid" onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"></a>
                                        </div>
                                        <div *ngIf="!slide.img">
                                            <a (click)="navigateToDetails(slide.id)"><img src='../../../../../assets/images/ITRSample.png' class="img-fluid"></a>
                                        </div>
                                    </div>
                                    <div class="earthy-description text-center" style="transform: translate3d(0px, 0px, 0px);">
                                        <div class="earthy-cart-btn">
                                            <button *ngIf="slide.product_type === 'Variable'" class="btn btn-primary earthy-btn" (click)="navigateToDetails(slide.id)">SELECT OPTIONS</button>
                                            <button *ngIf="slide.product_type !== 'Variable' && appService.productIdinCart.indexOf(slide.id) == -1" class="btn btn-primary earthy-btn" (click)="addToCart(slide)">ADD TO CART</button>
                                            <div *ngIf="slide.product_type !== 'Variable' && appService.productIdinCart.indexOf(slide.id) != -1">
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                    <div  class="btn btn-primary inq-btn-st ml-1 viewCart"  *ngIf="cart.product_id===slide.id">
                                                        <div>
                                                            <p class="inq-amt-st">
                                                                <span><i class="fa fa-minus mr-2 minus"
                                                                        (click)="decreaseItem(i)"></i></span>
                                                                <span class="inq-amt-st">{{ cart.quantity }}</span><i
                                                                    class="fa fa-plus ml-2 plus"
                                                                    (click)="increaseItem(i)"></i><span></span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="earthy-product-title">
                                            {{slide.name}}
                                        </div>
                                        <div class="earthy-worthy-price">
                                            ₹{{slide.priceRange? slide.priceRange:slide.price + '.00'}}
                                        </div>
                                    </div>
                                    </div>
                                <!--bboldcard-->

<!------------TAMS----------------->
<div *ngIf="config.title == 'Tams'">
    <div class="product-list-cards" style="color: lightgray;">
        <div>
            <!------------tams img------>
            <img [src]="slide.img" id="img-list"
                (click)="navigateToDetails(slide.id)"
                onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"
                alt="{{ slide.name}}" class="product-list-image">
        </div>
        <div class="product-list-description">
            <div class="d-flex justify-content-between">
                <div>
                    <p class="product-list-tile">
                   {{slide.name}}
                    </p>
                </div>
                <div>

                    <p class="product-list-price"  *ngIf="!slide.showPricse">
                        ₹{{slide.variant.option_values[0].pricing.raw.price}}.00</p>
                        <p class="product-list-price"*ngIf="slide.showPricse && slide.product_type=='Variable'" >₹{{slide.pricee}}.00</p>

                        <!-- <p class="product-list-price"*ngIf="!product.showPricse">₹{{product.price}}.00</p> -->
                        <!-- <p class="product-list-price"*ngIf="!product.showPricse" style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:5px !important">&nbsp;  ₹{{product.pricing.raw.price_range.split('-')[1] + '.00'}}</p> -->
                        <!-- <p class="product-list-price"*ngIf="product.showPricse && (product.product_type=='Variable')" >₹{{product.pricee}}.00</p> -->
                        <p class="product-list-price"*ngIf="slide.showPricse &&( slide.product_type =='Simple' ||slide.product_type =='Variation')" >₹{{slide.pricing.raw.price}}.00</p>
                        <!-- <p class="product-list-price"  *ngIf="product.product_type=='Simple' ||product.product_type =='Variation' "style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price + '.00'}}</p>  -->
                        <p class="product-list-price"  *ngIf="slide.product_type=='Variable'"style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{slide.pricing.raw.price_range_from}}-{{slide.pricing.raw.price_range_to}}</p> 

                </div>
            </div>
            <div *ngIf="config.title === 'Tams' && slide.shipping">
                <small style="color: #FF6E40;">
                    {{slide.shipping.weight === 5000 ? 'Yields 12.5kg batter': 'Yields 2.5kg batter'}}
                </small>
            </div>
            <!----------variants------>
            <app-shared-variants [product]="slide" [products]="slidesStore" (selectEmitter)="showPrice($event)"></app-shared-variants>
                  <!---------------------reviews---------------->
            <div class="d-flex justify-content-between mt-1">
                <div class="inner-star-icon pd-1">
                    <span [class]="slide && slide.reviews && slide.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                    <span [class]="slide && slide.reviews && slide.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                    <span [class]="slide && slide.reviews && slide.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                    <span [class]="slide && slide.reviews && slide.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                    <span  [class]="slide && slide.reviews && slide.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                    <a routerLink="/product-review/{{slide.id}}" style="font-size: 11px !important; font-weight: 500 !important;"> {{slide && slide.reviews ? slide.reviews.total_reviews : 0}} reviews</a>
                </div>
                <div>
                    <!--------- button---------->

                    <!-------------Add to cart-->
                    <button class=" cartButton"
                        style="font-size: small;font-weight: bold;width:80px;background-color: transparent !important;border:1px solid #09404b !important;color: #09404b !important;"
                        (click)="addToCarts(slide)"

                        *ngIf="(slide.product_type != 'Variable'&& appService.productIdinCart.indexOf(slide.id) == -1  ) || (slide.product_type == 'Variable' && appService.productIdinCart.indexOf(slide.checkVariantInCart) == -1) ||(slide.product_type == 'Simple' && appService.productIdinCart.indexOf(slide.id) == -1  ) || (slide.product_type == 'Variation' && appService.productIdinCart.indexOf(slide.id) == -1  )">ADD
                        <span> <i class="fa fa-shopping-cart"></i></span></button>
                    <!---------view cart button-->
                    <div
                        *ngIf="appService.productIdinCart.indexOf(slide.id) != -1 || appService.productIdinCart.indexOf(slide.checkVariantInCart) != -1 ">
                        <div
                            *ngFor="let cart of appService.cartProducts.results;let i = index">
                            <div class=" viewCart"
                                style="font-size: small;font-weight: bold;padding-left:4px;padding-right:4px;"
                                *ngIf="cart.product_id === slide.id || cart.product_id === slide.checkVariantInCart">
                                <span>
                                    <i class="fa fa-minus mr-3"
                                        (click)="decreaseItem(i)"
                                        style="font-size:12px;cursor:pointer;"></i></span>
                                <span class="inq-sp-2"
                                    style="color: white;">{{cart.quantity}}</span>
                                <span>
                                    <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                        style="font-size:12px;cursor:pointer;"></i></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!---------------------->
                                
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
    <!--recommeded products-->
</section>