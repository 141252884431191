<section>
    <app-alerts></app-alerts>
    <div class="product-details-wrapper">
        <div class="details-top container" id="product_breadcrumbs">
            <div class="aside-group">
            </div>
        </div>
        <div class="row container details-wrapper">
            <div class="col-sm-6">
                <div class="slider">
                    <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar
                        class="homepage-banner-slider rounder-corner-nav product-slick"
                        >
                      <ng-container >
                            <ng-template carouselSlide >
                                <div class="productDetailImage">
                                <!-- <img [src]="mainImage" id="brokenImage" (error)="finndBroken()"> -->

                                <!-------set default and preview image for tams no zoom-->
                                <!-- <div *ngIf="!image_preview && !video_preview  && config.title == 'Tams' || config.title =='Mayan'"> -->
                                    <div *ngIf="!image_preview  && config.title == 'Tams' || config.title =='Mayan'">
                                    <lib-ngx-image-zoom [thumbImage]="mainImage"
                                        [fullImage]="mainImage" [enableLens]=true [lensWidth]=200
                                        [lensHeight]=200></lib-ngx-image-zoom>
                                </div>

                                <div *ngIf="image_preview && config.title == 'Tams' || config.title =='Mayan'">
                                    <lib-ngx-image-zoom [thumbImage]="mainImagePreview"
                                    [fullImage]="mainImagePreview" [enableLens]=true [lensWidth]=200
                                    [lensHeight]=200></lib-ngx-image-zoom>
                                </div>
                                    <!-----------set default image with preview-->
                                    <div *ngIf="!image_preview && !video_preview && config.title != 'Tams' && config.title !='Mayan'">
                                        <lib-ngx-image-zoom [thumbImage]="mainImage"
                                        [fullImage]="mainImage" [enableLens]=true [lensWidth]=375
                                        [lensHeight]=325></lib-ngx-image-zoom>
                                    </div>

                                    <div *ngIf="image_preview && config.title != 'Tams' && config.title !='Mayan'">
                                        <lib-ngx-image-zoom [thumbImage]=mainImagePreview
                                        [fullImage]=mainImagePreview [enableLens]=true [lensWidth]=375
                                        [lensHeight]=325></lib-ngx-image-zoom>
                                    </div>
                                    <!--------video preview-->
                                    <!-- <div *ngIf="video_preview">
                                        <iframe [src]="mainViewVideo"  width="600" height="350" title="YouTube video player" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                    </div> -->
                            <!-- <img [src]="mainImage" id="brokenImage" (error)="finndBroken()"> -->
    

                                  
                                   <hr>
                                   <div class="rounder-corner-nav">
                                    <owl-carousel-o [options]="customOptions">
                                                <!------------------image---------------->
                                        
                                        <ng-container  *ngFor="let item of imageAssets;let i =index">
                                            <ng-template carouselSlide  *ngIf="item.image_source != ''" class="image-highlight" >

                                                <img [src]="item.image_source"  class="image-preview-product" 
                                                style="width: 80px;height: 80px;"  [class.active]="isActive===i || newActive===i"
                                                    (click)="imagePreview(item,i)">
                                            </ng-template>
                                        </ng-container>

                                                            <!-----------video--------->

                                                            <ng-container *ngFor="let item of ImageVideoPreview">
                                                                <ng-template carouselSlide>
                                                                    <div (click)="videoPreview(item)"   >
                                                                        <iframe style="pointer-events: none;"
                                                                         [src]="item.safeLink"
                                                                            width="80" height="80" title="YouTube video player"
                                                                            frameborder="0"
                                                                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowfullscreen></iframe>
                                                                    </div>
                                                                 </ng-template>
                                                            </ng-container>
                                        
                                    </owl-carousel-o>
                                    </div>



                                </div>
                            </ng-template>
                        </ng-container>


                    </owl-carousel-o>


                    
                </div>
            </div>
            <!-------------->
            <!--bread crumbs-->
            
            <div class="col-sm-6 example " id="size" *ngIf="newProductDetails" style="overflow-y: scroll; height:100%;">
                <div class="titleCase-product">
                    <!-- <h2 class="text-primary" id="titleProduct">Product Detail</h2> -->
                    <div class="breadCrumb-productTitle"><span class="home-breadcrumb" id="breadCrumbProductDetails"
                            routerLink="">Home</span>
                        <span *ngIf="config.title == 'EarthyWorthy'" class="customer-breadcrumb" id="breadCrumbProductDetails" (click)="productListing()"> <i
                                class="px-2" id="breadCrumbProductDetails">/</i>Products</span>
                                <span *ngIf="config.title != 'EarthyWorthy'" class="customer-breadcrumb" id="breadCrumbProductDetails" (click)="productListing()"> <i
                                    class="px-2" id="breadCrumbProductDetails">/</i>Products</span>
                        <span id="breadCrumbProductDetails"><i class="px-2" id="breadCrumbProductDetails">/</i>Product
                            Detail</span>
                    </div>
                </div>
                <div>
                    <!----------------product title price-------------------->
                    <div class="col-left" *ngIf="groups[0].showondetailpage">
                        <div class="product-detail">
                            <h2 class="product-name" id="prodName" style="font-weight:bolder;" *ngIf="groups[0].fields[0].visibility">
                                <!-- {{newProductDetails.name | titlecase }} -->
                                {{metaDataMapService.findData(newProductDetails, groups[0].fields[0].nestedinfo) |
                                titlecase }}
                                <div class="col-right">
                                    <div class="price-wrapper">
                                        <h4 class="priceRange" *ngIf="newProductDetails.product_type == 'Variable'">{{config.title === 'LuckMe' || config.title === 'Murgasen' ? 'SR ' : '₹'}} {{newProductDetails.pricing.raw.price_range_from}}-{{newProductDetails.pricing.raw.price_range_to}}&nbsp;

                                            <!-----rating and reviews------------------>
                                                <div style="display: inline;" *ngIf="groups[3].showondetailpage">
                                                    <ngb-rating [max]="5"  [(rate)]="currentRating" [readonly]="true"></ngb-rating>
                                                   <a style="font-size:small !important;" routerLink="/product-review/{{newProductDetails.id}}"
                                                       *ngIf="groups[3].fields[1].visibility">{{reviews}} customer reviews</a>
                                                       <!-----------mini review----->
                                                       <!-------->
                                              </div>
                                        </h4>
                                        <a *ngIf="config.title !== 'BombayHardware'">
                                            <!-- <h4 class="priceRange" *ngIf="newProductDetails.product_type == 'Simple' || newProductDetails.product_type == 'Variation'">{{config.title === 'LuckMe' || config.title === 'Murgasen' ? 'SR ' : '₹'}} {{newProductDetails.pricing.raw.price }}&nbsp; -->
                                                <h4 class="priceRange" *ngIf="newProductDetails.product_type == 'Simple' || newProductDetails.product_type == 'Variation'">{{config.title === 'LuckMe' || config.title === 'Murgasen' ? 'SR ' : '₹'}} {{newProductDetails.pricing.raw.price }}

                                                    <div *ngIf="config.title === 'Deera'" style="display: inline;" >
                                                        <span  style="font-size: 16px !important;text-decoration: line-through;color: grey!important;margin-left:7px;">₹{{newProductDetails.pricing.raw.msrp}}</span>
                                                           
                                                  </div>
                                                    <!-- <span *ngIf="config.title === 'Deera'" style="font-size: 16px !important;color: grey!important;">(incl of all taxes)</span>  -->
                                                    <span *ngIf="config.title === 'Deera'" style="font-size: 16px !important;color: grey!important;margin-left:5px;">- ₹{{perPeice | number : '1.2-2'}}/piece</span>   

                                            
                                                <!-----rating and reviews------------------>
                                               <div style="display: inline;margin-left:8px !important;" *ngIf="groups[3].showondetailpage">
                                                   <ngb-rating [max]="5"  [(rate)]="currentRating" [readonly]="true"></ngb-rating>
                                                  <a style="font-size:small !important;" routerLink="/product-review/{{newProductDetails.id}}"
                                                      *ngIf="groups[3].fields[1].visibility">{{reviews}} customer reviews</a>
                                                      <!-----------mini review----->
                                                      <!-------->
                                             </div>
                                           </h4>
                                        </a>
                                        <span *ngIf="config.title === 'Deera'" style="font-size: 16px !important;color: grey!important;">(taxes extra)</span>

                                            </div>
                                            <div *ngIf="config.title !== 'Tams' && config.title !== 'Deera'">Minimum Order Quantity  :  {{newProductDetails.pricing.raw.minimumorderquantity}}</div>
                                        </div>
                            </h2>
                            <hr *ngIf="config.title !== 'BombayHardware'">
                          </div>
                          <div class="col-right" *ngIf="config.title === 'Deera'">
                            <!-- <div id="accordion"> -->
                                <!-- <a class="sub-heading" data-toggle="collapse" data-target="#collapseDiscount" aria-expanded="true"
                                    aria-controls="collapseDiscount" style="cursor: pointer;color: black !important;">Discounts Available<i
                                    class="fa fa-angle-down arrow-size"></i> </a> -->
                                    <h6>Discounts Available</h6>
                                   
                            <!-- </div> -->
                            <!-- <div id="collapseDiscount" class="collapse" aria-labelledby="headingOne" data-parent="#accordion"> -->
                                <!-- <div> -->
                                <!-- <div class="card-body"> -->
                                    <!-- <ng-container> -->
                                        <!-- <p class="innerHTML" [innerHTML] = "eligibleBannerMessage"></p> -->
                                        <!-- <p class="innerHTML" [innerHTML] = "congratulationsBannerMessage"></p> -->
                                        <!-- <p class="innerHTML" [innerHTML] = "upsellBannerMessage"></p> -->
                                        <div style="font-size:15px; padding-left: 30px;"  *ngFor="let item of eligibleBannerMessage; let i = index">
                                          <!-- <div class="innerHTML" [innerHTML]="item.eligible_banner_message"></div> -->
                                          <div class="innerHTML" [innerHTML]="item"></div>
                                          <!-- <div class="innerHTML" [innerHTML]="congratulationsBannerMessage"></div> -->
                                          <!-- <div class="innerHTML" [innerHTML]="upsellBannerMessage"></div> -->
                                        </div>
                                    <!-- </ng-container> -->
                                <!-- </div> -->
                            <!-- </div> -->
                            <!-- <h6>Discounts Available</h6>                             -->
                                
                
                                 
                                  <hr *ngIf="config.title === 'Deera'">
                            
                        </div>
                            <div class="breadcurmb" > 
                                <div class="normalSize" [innerHTML]="shortDescription">
                                <hr *ngIf="config.title !== 'BombayHardware'">
                            </div>
                          
                        </div>

                       
                        <hr *ngIf="config.title !== 'BombayHardware'">
                        
                    </div>
                    <div class="breadcurmb" *ngIf="config.title === 'ShubhCards'">
                        <h6>Dimensions</h6>
                        <div class="normalSize">
                            <div class="product-addtional-details">
                                <ul class="one-column">
                                    <li class="normalSize">
                                        <label class="list-label">Width: </label>
                                        <label class="list-value"> {{newProductDetails.size_dimension.width}}</label>
                                       
                                    </li>
                                    <li class="normalSize">
                                        <label class="list-label">Height: </label>
                                        <label class="list-value"> {{newProductDetails.size_dimension.height}}</label>
                                       
                                    </li>
                                    <li class="normalSize">
                                        <label class="list-label">Length: </label>
                                        <label class="list-value"> {{newProductDetails.size_dimension.length}}</label>
                                       
                                    </li>
                                    <li class="normalSize">
                                        <label class="list-label">Card Weight: </label>
                                        <label class="list-value"> {{newProductDetails.card_weight}}</label>
                                       
                                    </li>
                                </ul>
                            </div>
                    </div>
                   <hr>
                </div>


                    <!------variants------>
                    <div class="col-right" id="simple" *ngIf="showSize">
                        <div class="col-right">
                            <p class="sizeText">{{variantName}}</p>
                        </div>
                        <br>
                        <div class="col-right btn-group " *ngFor="let items of variants">
                            <button type="button" class="sizebox" id="button"
                                [class.active]="activeMenu === items.option" class="btn btn-primary sizebox"
                                (click)="onConfirm(items)" value="items.id">
                                {{items.option}}
                            </button>
                            <hr>
                        </div>
                    </div>
                    <!-----price of variant-->
                    <div class="col-right" *ngIf="showPrice">
                        <h1 class="product-price">
                            <span class="price">&#8377;{{priceOfVariant}}</span>
                        </h1>
                        <hr>
                    </div>
                    <!---------note message---------->
                    <div class="col-right" *ngIf="showSize && showHint">

                        <label class='hint-message addSize' id="hint">Note: Please select option to add item in
                            cart</label>

                    </div>
                    
                    <div class="col-right" *ngIf="groups[1].showondetailpage">
                        <div class="add-to">
                            <!--------- button---------->
                            <!-------------Add to cart-->
                            <div *ngIf="config.title === 'Deera' && promotionType==='Product discount' && productQuantity1===true">
                                <div class="innerHTML" [innerHTML]="congratulationBanner"></div>
                                <span  *ngIf="percentageValue!==0" style="font-size: 17px !important;color: orange!important;font-weight: 600;">{{percentageValue}}% Discount Applied</span>
                                <span  *ngIf="fixedAmount!==0" style="font-size: 17px !important;color: orange!important;font-weight: 600;">₹{{fixedAmount}} Discount Applied</span>

                                <h4 class="priceRange">₹{{discountValue | number : '1.2-2'}}

                                    <div style="display: inline;" >
                                        <!-- <span  style="font-size: 16px !important;text-decoration: line-through;color: grey!important;margin-left:7px;">₹{{newProductDetails.pricing.raw.price}}</span> -->
                                        <!-- <span  style="font-size: 16px !important;text-decoration: line-through;color: grey!important;margin-left:7px;">₹{{newProductDetails.pricing.raw.msrp}}</span>   -->
                                        <span  style="font-size: 16px !important;text-decoration: line-through;color: grey!important;margin-left:7px;">₹{{newProductDetails.pricing.raw.msrp * productQuantity}}</span>   
 

                                           
                                  </div>
                                    <!-- <span *ngIf="config.title === 'Deera'" style="font-size: 16px !important;color: grey!important;">(incl of all taxes)</span>  -->
                                    <!-- <span style="font-size: 16px !important;color: grey!important;margin-left:5px;">- ₹{{discountValue / newProductDetails.unit | number : '1.2-2'}}/piece</span>    -->
                                    <span style="font-size: 16px !important;color: grey!important;margin-left:5px;">- ₹{{discountValue / (newProductDetails.unit * productQuantity) | number : '1.2-2'}}/piece</span>   
                           </h4>
                           <span *ngIf="config.title === 'Deera'" style="font-size: 16px !important;color: grey!important;">(taxes extra)</span>
                            </div>
                            <div *ngIf="config.title === 'Deera' && promotionType==='Quantity discount' && productQuantity1===true">
                                <div class="innerHTML" [innerHTML]="congratulationBanner"></div>
                                <span  *ngIf="percentageValue!==0" style="font-size: 17px !important;color: orange!important;font-weight: 600;">{{percentageValue}}% Discount Applied for {{productQuantity}} Quantity</span>
                                <span  *ngIf="fixedAmount!==0" style="font-size: 17px !important;color: orange!important;font-weight: 600;">₹{{fixedAmount}} Discount Applied for {{productQuantity}} Quantity</span>

                                <!-- <h4 class="priceRange">₹{{discountValue}} -->
                                    <h4 class="priceRange">₹{{discountValue / productQuantity | number : '1.2-2'}}
                                    <div style="display: inline;" >
                                        <!-- <span  style="font-size: 16px !important;text-decoration: line-through;color: grey!important;margin-left:7px;">₹{{newProductDetails.pricing.raw.price}}</span> -->
                                        <span  style="font-size: 16px !important;text-decoration: line-through;color: grey!important;margin-left:7px;">₹{{newProductDetails.pricing.raw.msrp}}</span>   
                                  </div>
                                    <!-- <span *ngIf="config.title === 'Deera'" style="font-size: 16px !important;color: grey!important;">(incl of all taxes)</span>  -->
                                    <!-- <span style="font-size: 16px !important;color: grey!important;margin-left:5px;">- ₹{{discountValue / newProductDetails.unit | number : '1.2-2'}}/piece</span>    -->
                                    <span style="font-size: 16px !important;color: grey!important;margin-left:5px;">- ₹{{discountValue / newProductDetails.unit / productQuantity | number : '1.2-2'}}/piece</span>   
                                </h4>
                                <span *ngIf="config.title === 'Deera'" style="font-size: 16px !important;color: grey!important;">(taxes extra)</span>
                            </div>
                            <div *ngIf="config.title != 'ShubhCards'">
                                <button class="btn btn-primary inq-btn-st ml-1 cartButton" [disabled]="isDisabled"
                                (click)="addToCart()"
                               *ngIf="(appService.productIdinCart.indexOf(newProductDetails.id) == -1 && !showSize) || (showSize && appService.productIdinCart.indexOf(checkVariantInCart) == -1 )">{{groups[1].fields[0].labelname}}
                               <span> <i class="fa fa-shopping-cart"></i></span></button> 
                               <span *ngIf="config.title === 'Deera' && (appService.productIdinCart.indexOf(newProductDetails.id) == -1 && !showSize) || (showSize && appService.productIdinCart.indexOf(checkVariantInCart) == -1 )" style="font-size: 16px !important;color: grey!important;margin-left: 5px;" (click)="buyNow()"> <button class="btn btn-primary inq-btn-st ml-1">Buy Now<i class="fa fa-shopping-bag" style="margin-left: 1px;"></i></button></span>   

                                <span *ngIf="config.title === 'Deera' && (appService.productIdinCart.indexOf(newProductDetails.id) == -1 && !showSize) || (showSize && appService.productIdinCart.indexOf(checkVariantInCart) == -1 )" style="font-size: 16px !important;color: grey!important;margin-left: 5px;" class="innerHTML" [innerHTML]="upsellBannerMessage"></span>   

                               <!-- <span *ngIf="config.title === 'Deera' && (appService.productIdinCart.indexOf(newProductDetails.id) == -1 && !showSize) || (showSize && appService.productIdinCart.indexOf(checkVariantInCart) == -1 )" style="font-size: 16px !important;color: grey!important;margin-left: 5px;">(Shipping will be calculated based on total quantity at check out)</span>    -->

                            </div>
                            <span *ngIf="config.title === 'Deera' && (appService.productIdinCart.indexOf(newProductDetails.id) == -1 && !showSize) || (showSize && appService.productIdinCart.indexOf(checkVariantInCart) == -1 )" style="font-size: 16px !important;color: grey!important;margin-left: 5px;">(Shipping will be calculated based on total quantity at check out)</span>   

                            <div *ngIf="config.title == 'ShubhCards'">
                                <button class="btn btn-primary inq-btn-st ml-1 shubhcardscartButton" [disabled]="isDisabled"
                                (click)="addToCart()"
                               *ngIf="(appService.productIdinCart.indexOf(newProductDetails.id) == -1 && !showSize) || (showSize && appService.productIdinCart.indexOf(checkVariantInCart) == -1 )">{{groups[1].fields[0].labelname}}
                               <span> <i class="fa fa-shopping-cart"></i></span></button>
                            </div>
                           
                       
                            <!---------view cart button-->
                            <div  style="width:45%;" *ngIf="appService.productIdinCart.indexOf(newProductDetails.id) != -1 || appService.productIdinCart.indexOf(checkVariantInCart) != -1 ">
                            <div *ngFor="let cart of appService.cartProducts.results;let i = index"> 
                            <div class="btn btn-primary inq-btn-st ml-1 viewCart" style="width:48%;"    *ngIf="cart.product_id === newProductDetails.id || cart.product_id === checkVariantInCart" >
                          
                             
                                <span>
                                    <i class="fa fa-minus mr-2" (click)="decreaseItem(i)"
                                        style="font-size:12px;cursor:pointer;"></i></span>
                                        <input type="number" style="width:40%;text-align:center;" *ngIf="config.title == 'ShubhCards'" (change)="ChangeValue(i)"[(ngModel)]="cart.quantity">
                                        <input type="number" style="width:46%;text-align:center;" *ngIf="config.title=='Deera'" (input)="changeDeeraValue(i)" [(ngModel)]="cart.quantity">
                                        <span class="inq-sp-2" *ngIf="config.title != 'ShubhCards' && config.title != 'Deera'"
                                    style="color: white;">{{cart.quantity}}</span>
                                <span> 
                                    <i class="fa fa-plus ml-2" (click)="increaseItem(i)"
                                        style="font-size:12px;cursor:pointer;"></i></span>

                           </div>
                           <!-- <span *ngIf="config.title === 'Deera' && cart.product_id === newProductDetails.id || cart.product_id === checkVariantInCart" style="font-size: 16px !important;color: grey!important;margin-left: 5px;">(Shipping will be calculated based on total quantity at check out)</span>    -->
                            <span *ngIf="config.title === 'Deera' && (cart.product_id === newProductDetails.id || cart.product_id === checkVariantInCart)" style="font-size: 16px !important;color: grey!important;" (click)="buyNow1()"> <button class="btn btn-primary inq-btn-st ml-1">Buy Now<i class="fa fa-shopping-bag" style="margin-left: 1px;"></i></button></span>   
                          
                            </div>

                            </div>

                            <!-- <button class="btn btn-primary inq-btn-st ml-1 viewCart" routerLink="/cart"
                                *ngIf="appService.productIdinCart.indexOf(newProductDetails.id) != -1 || appService.productIdinCart.indexOf(checkVariantInCart) != -1 ">{{groups[1].fields[1].labelname}}<span>
                                    <i class="fa fa-shopping-cart"></i></span></button> -->
                            <!----wishlist button------->
                            <!-- <button class="btn btn-primary inq-btn-st ml-1 wishlist-button"
                                *ngIf="groups[1].fields[2].visibility">{{groups[1].fields[2].labelname}} <span> <i
                                        class="fa fa-heart"></i></span>
                            </button> -->
                            <hr>
                        </div>
                    </div>
                    <!--------------------------Delivery option----------------->
                    <div class="col-right col-md-12" *ngIf="groups[2].showondetailpage">
                        <div class="delivery-option" *ngIf="config.title !=='BombayHardware' && config.title !=='Deera'">
                            <form [formGroup]="detailForm" >
                                <h6>DELIVERY OPTIONS <span> <i class="fa fa-truck"></i></span></h6>
                                <input id="zipcode" class="zipcode" formControlName="zipcode"   [class.is-invalid]="validateForm && f.zipcode.errors" type="text" value="" style="height: 40px;">
                               
                                <button class="btn btn-primary inq-btn-st ml-1" (click)="checkServiceAvailablity()">Check<span *ngIf="showSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></button>
                                <br>
                                <div class="invalid-feedback" *ngIf="f.zipcode.errors">
                                    <div *ngIf="f.zipcode.errors.minlength">Invalid Pincode</div>
                                    <div *ngIf="f.zipcode.errors.pattern">Invalid Pincode</div>
                                    <div *ngIf="f.zipcode.errors.maxlength">Invalid Pincode</div>
                                </div> 
                                <label class='hint-message note zipcode-nonavailable' *ngIf="notAvailableLocation">We are currently not delivering at this location</label>
                                <label class='hint-message note zipcode-available' *ngIf="availableLocation">Delivery available
                                    <br>
                                 <span class="hint-message addSize" style="font-size:large;color: black;font-weight: normal;" > Get it by </span><span class="estd-delivery">{{estdDelivery}}</span></label><br>

                              
                            </form>
                        </div>
                        <hr *ngIf="config.title !== 'BombayHardware' && config.title !=='Deera'">
                    </div>
                    <!----------------------------------------------------------->
                    <!------delivery option details--->
                    <!-- <div class="col-right" *ngIf="groups[2].showondetailpage">
                        <div class="delivery-option">
                            <form [formGroup]="detailForm" >
                                <h6>DELIVERY OPTIONS <span> <i class="fa fa-truck"></i></span></h6>
                                <div class="input-group col-8">
                                    <input type="number" formControlName="zipcode"    class="form-control">
                                    <div class="invalid-feedback" *ngIf="f.zipcode.errors">
                                        <div *ngIf="f.zipcode.errors.minlength">Invalid Pincode</div>
                                        <div *ngIf="f.zipcode.errors.pattern">Invalid Pincode</div>
                                        <div *ngIf="f.zipcode.errors.maxlength">Invalid Pincode</div>
                                    </div> 
                                    <div class="input-group-prepend">
                                        <button class="btn btn-primary inq-btn-st ml-1" (click)="checkZipcode()">Check</button>
                                    </div>
                                </div>
                                <label class='hint-message note' *ngIf="notAvailableLocation" style="color:#ef1d3c;font-weight: bold ;">We are currently not delivering at this location</label>
                                <label class='hint-message note' *ngIf="availableLocation" style="color:green;font-weight: bold ">Delivery available</label><br>

                                <label class='hint-message note'>Note: Enter PIN code to check availability</label>
                              
                            </form>
                        </div>
                        <hr>
                    </div> -->
                </div>
                <!----------how to store and cook -->
                <div *ngIf="newProductDetails.description.instruction_description != '' " >
                <div class="row col-left" >
                    <div class="col-md-3" >
                    </div>
                    <div class="col-md-7" id="instruction">
                      {{newProductDetails.description.instruction_title}}
                        </div>

                    </div>

                      <div class="row col-left" *ngIf="newProductDetails.description.instruction_description != '' && config.title!=='Deera'">
                    <div class="col-md-3">
                        <img [src]="mainImage" class="detail-preview" >
                    </div>
                    <div class="col-md-9" >
                        
                        <div class="inner-html-list"  [innerHTML]="instructionDesc"></div>
                        </div>

                    </div>

                <hr *ngIf="config.title !== 'Deera'">
                </div>
                <!-----------------How to store-->
                <div class="row" *ngIf="config.title == 'Tams'">
                  <div class="col-md-12" *ngIf="showStorage">
                    <div class="col-lg-12  storage" >
                        <div class="card-header"  >
                         How To Store
                        </div>
                        <div class="col-sm-12" >
                            <p>{{newProductDetails.additional_info.how_to_store}}</p>
                        </div>
                      </div>
                      <hr>
                  </div>
                 
                  </div>
            
                     <!-----------------points-->
                <div class="row"  *ngIf="newProductDetails.description.bullet_points != '' && config.title !== 'Deera'">
                    <div class="col-md-8">
                <div class="col-lg-12 "style=" color:#3b8c9c;font-weight:900;"  >
                    <div class="col-sm-12" >
                    <div class="innerHTML" [innerHTML]="bulletPoints"></div>
                    </div>
                  </div>
                  <hr>
                  </div>
                  
                  </div>

                  <div class="breadcurmb" *ngIf="config.title === 'Deera'">
                    <h6>PRODUCT DIMENSIONS</h6>
                    <div class="normalSize">
                        <div class="product-addtional-details">
                            <ul class="one-column">
                                <li class="normalSize">
                                    <label class="list-label">Width: </label>
                                    <label class="list-value"> {{newProductDetails.shipping.product_width}} cm</label>
                                   
                                </li>
                                <li class="normalSize">
                                    <label class="list-label">Height: </label>
                                    <label class="list-value"> {{newProductDetails.shipping.product_height}} cm</label>
                                   
                                </li>
                                <li class="normalSize">
                                    <label class="list-label">Length: </label>
                                    <label class="list-value"> {{newProductDetails.shipping.product_length}} cm</label>
                                   
                                </li>
                                <li class="normalSize">
                                    <label class="list-label">Weight: </label>
                                    <label class="list-value"> {{newProductDetails.shipping.product_weight}} grams</label>
                                   
                                </li>
                            </ul>
                        </div>
                        <hr>
                </div>
            </div>

            <div class="col-right" *ngIf="config.title === 'Deera'">
                <!-- <h6>ABOUT THIS ITEM </h6> -->
                <h6>Product Features </h6>
                <!-- <div id="accordion"> -->
                    <!-- <a class="sub-heading" data-toggle="collapse" data-target="#collapseContent" aria-expanded="true"
                        aria-controls="collapseContent" style="cursor: pointer;" style="color:black!important">ABOUT THIS ITEM<i
                        class="fa fa-angle-down arrow-size"></i> </a> -->
                       
                <!-- </div> -->
                <!-- <div id="collapseContent" class="collapse" aria-labelledby="headingOne" data-parent="#accordion"> -->
                    <!-- <div class="card-body"> -->
                        <!-- <ng-container> -->
    
                            <!-- <div class="collection-collapse-block"  style="padding-left: 30px;"> -->
                                <!-- <div class="collection-collapse-block"> -->
                            
                                <div style="font-size:15px; padding-left: 30px;"  >
                                    <!-- <div class="innerHTML" style="display: list-item;list-style-type: disc;" [innerHTML]="bulletPoints"></div> -->
                                    <div class="innerHTML" [innerHTML]="bulletPoints"></div>
                                </div>
    
                            <!-- </div> -->
                        <!-- </ng-container> -->
                    <!-- </div> -->
                <!-- </div> -->
                <!-- <div class="product-detail">
                    <div class="product-addtional-details">
                        <ul class="one-column"> 
                                <div class="innerHTML" [innerHTML]="bulletPoints"></div>
                        </ul>
                    </div>
                </div> -->
                
                    <!-- <div style="font-size:15px;"  >
                        <div class="innerHTML" [innerHTML]="bulletPoints"></div> 
                      </div> -->
                     
                      <hr *ngIf="config.title === 'Deera'">
                
            </div>
            
                  <!-----product detailss-->
                <div class="col-right" *ngIf="groups[4].showondetailpage && config.title != 'Tams' || groups[4].showondetailpage">
                    <h6>PRODUCT DETAILS</h6>
                    <div class="product-detail">
                        <div class="product-addtional-details">
                            <ul class="one-column">
                                <li class="normalSize" *ngFor="let field of groups[4].fields">
                                    <label class="list-label">{{field.labelname}}: </label>
                                    <label class="list-value"
                                        *ngIf="field.nestedinfo.type == 'object'">{{metaDataMapService.findData(newProductDetails,
                                        field.nestedinfo)}} </label>
                                    <label class="list-value"
                                        *ngIf="field.nestedinfo.type == 'array' && field.labelname == 'Category'">{{descriptionData}}
                                    </label>
                                    <!-- <label class="list-value"
                                        *ngIf="field.nestedinfo.type == 'array' && field.labelname == 'Themes'">{{themes}}
                                    </label> -->
                                </li>
                                <li class="normalSize" *ngIf="config.title == 'Deera'">
                                    <label class="list-label">Brand: </label>
                                    <label class="list-value"> {{newProductDetails.brand.name}}</label>
                                   
                                </li>
                                <li class="normalSize" *ngIf="config.title == 'Deera'">
                                    <label class="list-label">Color: </label>
                                    <label class="list-value"> {{newProductDetails.color.name}} gms</label>
                                   
                                </li>
                                <li class="normalSize" *ngIf="config.title == 'Deera'">
                                    <label class="list-label">Material: </label>
                                    <label class="list-value"> {{newProductDetails.product_material.name}}</label>
                                   
                                </li>
                                <li class="normalSize" *ngIf="config.title == 'Deera'">
                                    <label class="list-label">Type: </label>
                                    <label class="list-value"> {{newProductDetails.type.name}}</label>
                                   
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="breadcurmb" *ngIf="config.title === 'BombayHardware'">
                        <div class="normalSize">
                            <div class="product-addtional-details">
                                <ul class="one-column">
                                    <li class="normalSize">
                                        <label class="list-label">Pipe Type: </label>
                                        <label class="list-value"> {{newProductDetails.pipe_type.name}}</label>
                                       
                                    </li>
                                    <li class="normalSize">
                                        <label class="list-label">Pipe Variant: </label>
                                        <label class="list-value"> {{newProductDetails.pipe_variant.name}}</label>
                                       
                                    </li>
                                    <li class="normalSize">
                                        <label class="list-label">Size: </label>
                                        <label class="list-value"> {{newProductDetails.size.name}}</label>
                                       
                                    </li>
                                    <li class="normalSize">
                                        <label class="list-label">Class: </label>
                                        <label class="list-value"> {{newProductDetails.class.name}}</label>
                                       
                                    </li>
                                </ul>
                            </div>
                    </div>
                   <hr>
                </div>
                    
                </div>
                <hr *ngIf="config.title != 'Tams'">
<!------------------------additional info---------->
            

            </div>



        </div>
        <hr>
        <!-------tabs-------------------------->
        <div class="product-details-tabs" *ngIf="groups[5].showondetailpage">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a class="nav-link headingTab" id="{{'nav-' + field.labelname + 'tab'}}"
                        *ngFor="let field of groups[5].fields" data-toggle="tab" href="#nav-home" role="tab"
                        aria-controls="nav-home" aria-selected="true"
                        (click)="changeTab(field.labelname)">{{field.labelname}}</a>

                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade" *ngFor="let field of groups[5].fields" id="{{'nav-' + field.labelname}}"
                    role="tabpanel" aria-labelledby="nav-home-tab">
                    <!----------general info-->
                    <div *ngIf="field.labelname == 'General Information' && field.nestedinfo!=undefined && showData ">
                         <div class="container"  *ngFor="let item of groups[6].fields">
                        <div class="row" > 
                          <div class="col-md-3 general-info-label">
                            {{item.labelname}}
                          </div>
                          <div class="col-md-4 general-info">
                            {{metaDataMapService.findData(newProductDetails,
                                item.nestedinfo)}}
                          </div>
                        
                        </div>
                      </div></div>
                      <!----nutri facts-->
                      <div  *ngIf="field.labelname == 'Additional Information'">
                         <span class="product-additional-info">{{attributeTitle}}</span>
                          <table *ngIf="config.title!='Mayan'">

                            <thead>
                    
                                <tr class="product-table-head" >
                                <th  *ngFor="let heads of tableHead;" class="align">{{heads}}</th>
                                </tr>
                    
                            </thead>
                    
                            <tbody>
                    
                                <tr  class="productTableRow" *ngFor="let bodys of tableData;let i = index">
                    
                                    <td  *ngFor="let body of bodys">{{body}}</td>
                    
                                   
                    
                                </tr>
                    
                            </tbody>
                    
                        </table>
                        
                      </div>

                     
                      <!-----reviews------>
                      <div *ngIf="field.labelname == 'Reviews' ">
                        <div class="container">
                       <div class="row" >
                           <div class="col-md-12"> 
                         <app-product-review></app-product-review>
                       </div>
                       </div>
                     </div></div>
                     <!--------------->

                       <!-----manufacture------>
                       <div *ngIf="field.labelname == 'Manufacture Details' && newProductDetails.additional_info.manufacture!= ''">
                        <div class="container">
                       <div class="row" >
                           <div class="col-md-7"> 
                       {{newProductDetails.additional_info.manufacture}}
                       </div>
                       </div>
                     </div></div>
                     <!--------------->

                    
                     <div *ngIf="field.labelname != 'General Information'&& field.labelname!='Additional Information' && field.labelname!='Reviews' && field.labelname!='Manufacture Details' && field.nestedinfo!=undefined && showData"></div>
                     <div class="inner-html-list" *ngIf="field.labelname == 'Description' && config.title=='EarthyWorthy'"  [innerHTML]="shortDescription"></div>
                     <div class="inner-html-list" *ngIf="field.labelname == 'Additional Informations' && config.title=='EarthyWorthy'"  [innerHTML]="shortDescription"></div>
                     <div class="inner-html-list" *ngIf="field.labelname == 'Description'"  [innerHTML]="shortDescription"></div>
                     <div class="inner-html-list" *ngIf="field.labelname == 'Additional Informations' && config.title!=='Deera'"  [innerHTML]="shortDescription"></div>
                     <div class="inner-html-list" *ngIf="field.labelname == 'Additional Informations' && config.title==='Deera'"  [innerHTML]="otherDescription"></div>

                    <!-- <div class="innerHTML" [innerHTML]="innnerhtmlData"></div> -->

                   
                </div>

            </div>
        </div>
      
    </div>
</section>

<div (click) = "closeVideo()">
    <button id="launch-video1" type="button" style="display: none !important;" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal12">
        Launch demo modal
    </button>
    <div class="modal fade" id="exampleModal12" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-primary" id="exampleModalLabel">iLY Flour Instruction Video</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeVideo()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="padding: 0px !important;">
                    <iframe width="100%" height="325" [src]="mainViewVideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" style="padding: 5px 15px !important;" (click)="closeVideo()">Close</button>
                  </div>
            </div>
        </div>
    </div>
</div>