import { Injectable } from '@angular/core';

import { CheckoutApiService } from '../checkout-api.service';
import { LocalStorageService } from '../../auth-service/local-storage.service';
import { CustomerLoginStorageService } from '../checkout-login/customer-login-storage.service';
import { OrderUpdationService } from './order-updation.service';
import { CheckoutInitialdataService } from '../checkout-initialdata.service';
import { CheckoutSettingsService } from '../checkout-settings.service';
import { CheckoutTaxService } from '../checkout-tax.service';
import { ENDPOINTS,ORG_DETAILS } from '../../app.config';
import { CheckoutDeliveryOrderService } from '../checkout-delivery/checkout-delivery-order.service';
import { CheckoutShippingOrderService } from '../checkout-shipping/checkout-shipping-order.service';
import { OrderSummaryOrderService } from '../order-summary/order-summary-order.service';
import { CheckoutOrderdetailsOrderService } from '../checkout-orderdetails/checkout-orderdetails-order.service';
import { CheckoutCalculationsService } from '../checkout-calculations.service';
import { CheckoutShippingApiService } from '../checkout-shipping/checkout-shipping-api.service';
import { AppService } from '../../app.service';
import { config } from '../../../configs/config';

@Injectable({
  providedIn: 'root'
})
export class OrderUpdationValuesService {

  CONFIG = config;
  banner = "";
  promotionData:any;
  public executionData=[];
  public percentageValues=[];
  public amountValues=[];
  PERCENTAGE_LABEL = "PERCENTAGE";
  FIXEDAMOUNT_LABLE = "FIXED AMOUNT";

  constructor(
    public appService:AppService,
    private checkoutApiService: CheckoutApiService,
    private localStorageService: LocalStorageService,
    private customerLoginStorageService: CustomerLoginStorageService,
    private orderUpdationService: OrderUpdationService,
    private checkoutInitialdataService: CheckoutInitialdataService,
    private checkoutSettingsService: CheckoutSettingsService,
    private checkoutTaxService: CheckoutTaxService,
    private checkoutDeliveryOrderService: CheckoutDeliveryOrderService,
    private checkoutShippingOrderService: CheckoutShippingOrderService,
    private orderSummaryOrderService: OrderSummaryOrderService,
    private checkoutOrderdetailsOrderService: CheckoutOrderdetailsOrderService,
    private checkoutCalculationsService: CheckoutCalculationsService,
    private checkoutShippingApiService: CheckoutShippingApiService
  ) { }

  // GET CUSTOMER DETAILS
  getCustomerDetails(){
    return new Promise ((resolve, reject) => {
      if(this.customerLoginStorageService.getCustomerData()){
        let customerData = this.customerLoginStorageService.getCustomerData();
        this.orderUpdationService.orderObj.customer.customer_id = customerData.userid;
        this.orderUpdationService.orderObj.customer.customer_name = customerData.userName
        this.orderUpdationService.orderObj.customer.customer_type = customerData.type
        this.orderUpdationService.orderObj.customer.is_email_opt_in = customerData.subscribed
        this.orderUpdationService.orderObj.customer.phone_number = customerData.mobileNo
        this.orderUpdationService.orderObj.customer_id = customerData.userid;
        resolve(true);
      }
      else{
        resolve(true);
      }
    })
  }

  // DELIVERY OBJECT DETAILS
  getcheckoutDeliveryOrderService(){
    return new Promise((resolve, reject) => {
      if(this.checkoutDeliveryOrderService.deliveryObj.delivery_type !== ""){
        this.orderUpdationService.orderObj.delivery.outlet_id = this.checkoutDeliveryOrderService.deliveryObj.outlet_id;
        this.orderUpdationService.orderObj.delivery.outlet_name = this.checkoutDeliveryOrderService.deliveryObj.outlet_name;
        this.orderUpdationService.orderObj.delivery.outlet_latitude = this.checkoutDeliveryOrderService.deliveryObj.outlet_latitude;
        this.orderUpdationService.orderObj.delivery.outlet_longitude = this.checkoutDeliveryOrderService.deliveryObj.outlet_longitude;
        this.orderUpdationService.orderObj.delivery.delivery_preferred_time = this.checkoutDeliveryOrderService.deliveryObj.delivery_preferred_time;
        this.orderUpdationService.orderObj.delivery.delivery_date = this.checkoutDeliveryOrderService.deliveryObj.delivery_date;
        this.orderUpdationService.orderObj.delivery.delivery_time = this.checkoutDeliveryOrderService.deliveryObj.delivery_time;
        this.orderUpdationService.orderObj.delivery.delivery_type = this.checkoutDeliveryOrderService.deliveryObj.delivery_type;
        resolve(true);
      }
      else{
        resolve(true);
      }
    })
  }

  // SHIPPING DETAILS
  getShippingDetails(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETSHIPPINGCARRIERS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let carrierDetails:any = res.data[0];
            this.orderUpdationService.orderObj.shipping.shipping_carrier.shipping_carrier_id = carrierDetails.id;
            this.orderUpdationService.orderObj.shipping.shipping_carrier.shipping_carrier_name = carrierDetails.carrier_name;
            this.orderUpdationService.orderObj.shipping.shipping_carrier.api_integration_id = carrierDetails.trackable_integrator_reference_id;
            this.orderUpdationService.orderObj.shipping.shipping_carrier.api_integration_name = carrierDetails.trackable_integrator_reference_name;
          }
          this.orderUpdationService.orderObj.shipping.shipping_method.delivery_charge = Number(this.checkoutShippingOrderService.shippingMethodObj.delivery_charge);
          if(this.checkoutShippingApiService.enableFixedShipping){
            this.orderUpdationService.orderObj.shipping.shipping_method.total_shipping_price = this.checkoutShippingApiService.selectedShippingMethod.logisticCharge;
            this.orderUpdationService.orderObj.shipping.pay_to_logistics = this.orderUpdationService.orderObj.shipping.shipping_method.total_shipping_price - this.orderUpdationService.orderObj.shipping.shipping_method.delivery_charge;
            this.orderUpdationService.orderObj.shipping.shipping_method.pay_to_logistics = this.orderUpdationService.orderObj.shipping.shipping_method.total_shipping_price - this.orderUpdationService.orderObj.shipping.shipping_method.delivery_charge;
          }
          this.orderUpdationService.orderObj.shipping.fixed_shippping_price = this.checkoutShippingApiService.enableFixedShipping;
          this.orderUpdationService.orderObj.shipping.shipping_method.name = this.checkoutShippingOrderService.shippingMethodObj.name;
          this.orderUpdationService.orderObj.shipping.shipping_addresses = [this.checkoutShippingOrderService.shippingAddress];
          this.orderUpdationService.orderObj.shipping.billing_address = this.checkoutShippingOrderService.billingAddress;
          this.orderUpdationService.orderObj.shipping.free_shipping = this.orderSummaryOrderService.freeShipping;
          this.orderUpdationService.orderObj.shipping.delivery_instructions = this.checkoutOrderdetailsOrderService.deliveryInstructions;
          this.orderUpdationService.orderObj.shipping.customer_comment = this.checkoutOrderdetailsOrderService.deliveryNotes;
          this.orderUpdationService.orderObj.shipping.shipping_address_count = 1;
          resolve(true);
        }
      )
    })
  }


    //GET PROMOTION DETAILS

    getPromotionBanner()
    {
      let obj =
      {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
              "find": {
              }
          }
        }
    
      this.appService.postApiCall(obj,ENDPOINTS.PROMOTIONS_BANNER).subscribe(
        resp => {
          if (resp) {
            if (resp.success === 1 && resp.status_code === 200) {
              this.banner = resp.result.data[0]['eligible_banner_message']
              if(this.CONFIG.title ==='Deera'){
                this.promotionData = resp.result.data;    
              }
            }
            else if (resp.success === 0) {
              if (resp.message) {
               
              }
            }
            else if (resp.message && resp.status_code !== 200) {
            
    
            }
            else {
            
            }
          }
        },
        err => {
          if (err.error.statusCode === 403) {
            
    
          }
          else if (err.error.error.message) {
           
          }
          else {
           
          }
        }
      )
    
    
    }

   // GET PRODUCT DETAILS
   getProductDetails(){
    let obj =
    {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
            }
        }
      }
  
    this.appService.postApiCall(obj,ENDPOINTS.PROMOTIONS_BANNER).subscribe(
      resp => {
        if (resp) {
          if (resp.success === 1 && resp.status_code === 200) {
            this.banner = resp.result.data[0]['eligible_banner_message']
            if(this.CONFIG.title ==='Deera'){
              console.log("promotionDataaaaaaaa")
              this.promotionData = resp.result.data;
            }
          }
          else if (resp.success === 0) {
            if (resp.message) {
             
            }
          }
          else if (resp.message && resp.status_code !== 200) {
          
  
          }
          else {
          
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          
  
        }
      }
    )
    return new Promise((resolve, reject) => {
      if(ORG_DETAILS.NAME!='Nesma')
      {
        this.checkoutInitialdataService.getCartItems().then(
          respcart => {
            let resc:any = respcart;
            if(resc){
              this.checkoutInitialdataService.getCartItems();
              let cartItems:any = this.checkoutInitialdataService.cartProducts;
              this.orderUpdationService.orderObj.shipping.items_total = cartItems.length;
              let itemsQuantity = 0;
              cartItems.forEach(item => {
                itemsQuantity = itemsQuantity + item.quantity;
              });
              this.orderUpdationService.orderObj.shipping.items_total_quantity = itemsQuantity;
              this.checkoutSettingsService.getTaxSettings().then(
                respData => {
                  let res:any = respData;
                  if(res){
                    this.checkoutSettingsService.getTaxRates().then(
                      respData1 => {
                        let res1:any = respData1;
                        let productList = [];
                        this.checkoutTaxService.getProductTaxList(cartItems, this.checkoutSettingsService.taxclasses).then(
                          respData2 => {
                            console.log("order-updation")
                            if(this.CONFIG.title !== "Deera"){
                              let productTaxList:any = respData2;
                            cartItems.forEach(cartItem => {
                              let productObj = {
                                product_id:"",
                                product_name:"",
                                sku:"",
                                quantity:0,
                                image_source:"",
                                price:0,
                                price_inc_tax:0,
                                price_ex_tax:0,
                                discount_percentage:0,
                                discount_amount:0,
                                tax_percentage:0,
                                tax_amount:0,
                                total_amount:0,
                                variant:{
                                  variant_id:"",
                                  variant_name:"",
                                  options:{
                                    option_key:"",
                                    option_value:""
                                  }
                                }
                              }
                              let productTaxDetails = productTaxList.find(productTax => productTax.pid === cartItem.product_id);
                              productObj.product_id = cartItem.product_id;
                              productObj.product_name = cartItem.product_name;
                              productObj.sku = cartItem.sku;
                              productObj.quantity = cartItem.quantity;
                              productObj.image_source = cartItem.image_source;
                              productObj.price = cartItem.cost;
                              productObj.price_ex_tax = Number(cartItem.cost) * Number(cartItem.quantity);
                              let priceIncludingTax = (Number(cartItem.cost) * Number(cartItem.quantity) * productTaxDetails.taxrate)/100;
                              productObj.price_inc_tax = (Math.round((priceIncludingTax + Number.EPSILON) * 100) / 100) + productObj.price_ex_tax;
                              productObj.tax_percentage = productTaxDetails.taxrate;
                              let producttax = (productObj.price_ex_tax * productTaxDetails.taxrate)/100;
                              productObj.tax_amount = (Math.round((producttax + Number.EPSILON) * 100) / 100);
                              productObj.total_amount = productObj.price_ex_tax + productObj.tax_amount;
                              productList.push(productObj);
                            });
                            } else if(this.CONFIG.title === "Deera"){
                              // this.getPromotionBanner();
                              let productTaxList:any = respData2;
                              cartItems.forEach(cartItem => {
                                let productObj = {
                                  product_id:"",
                                  product_name:"",
                                  sku:"",
                                  quantity:0,
                                  image_source:"",
                                  price:0,
                                  price_inc_tax:0,
                                  price_ex_tax:0,
                                  discount_percentage:0,
                                  discount_amount:0,
                                  // fixed_amount:0,
                                  // discount_value:0,
                                  tax_percentage:0,
                                  tax_amount:0,
                                  total_amount:0,
                                  variant:{
                                    variant_id:"",
                                    variant_name:"",
                                    options:{
                                      option_key:"",
                                      option_value:""
                                    }
                                  }
                                }
                                console.log("cartItemOrderUpppppp"+JSON.stringify(cartItem))
                                let productTaxDetails = productTaxList.find(productTax => productTax.pid === cartItem.product_id);
                                // this.promotionData.forEach((data)=>
                                // {
                                 
                                //   console.log("eeeeee"+data.product_discount.amount_or_percentage.discounted_percentage);
                                 
                                //   console.log("productnnnn"+data.product_discount.amount_or_percentage.product_details[0].product_name)
                                //   data.product_discount.amount_or_percentage.product_details.forEach((data1)=>{
                                //     console.log("data1.product_name"+data1.product_name);
                                //     console.log("item.product_name"+cartItem.product.name);
                                //     if(data1.product_name===cartItem.product.name){
                                //       if(data.product_discount.amount_or_percentage.discounted_percentage!==0){
                                //         console.log("truwwwwwww");
                               
                                        
                                //         productObj.discount_percentage = data.product_discount.amount_or_percentage.discounted_percentage;                   
                                      
                        
                                //       } else if (data.product_discount.amount_or_percentage.discounted_amount!==0){
                                      
                                //         console.log("discounted_amount"+data.product_discount.amount_or_percentage.discounted_amount);
                                     
                                //        productObj.discount_amount = data.product_discount.amount_or_percentage.discounted_amount;   
                                     
                                //       }
                                    
                                //     }
                  
                                //   })
                           
                                // })
                                productObj.product_id = cartItem.product_id;
                                console.log("cartItem.product_id"+cartItem.product_id)
                                productObj.product_name = cartItem.product_name;
                                productObj.sku = cartItem.sku;
                                productObj.quantity = cartItem.quantity;
                                productObj.image_source = cartItem.image_source;
                                productObj.price = cartItem.cost;
                                productObj.discount_percentage = cartItem.discount_percentage;
                                console.log("cartItem.discount_percentage"+cartItem.discount_percentage)
                                // productObj.discount_amount = cartItem.discount_amount;
                                productObj.discount_amount = cartItem.fixed_amount;
                                console.log("cartItem.discount_amount"+cartItem.fixed_amount)
                                productObj['hsn']=cartItem.product.hsn;
                                // productObj.fixed_amount = cartItem.fixed_amount;
                                // productObj.discount_value = cartItem.discount_value;
                                productObj.price_ex_tax = Number(cartItem.cost) * Number(cartItem.quantity);
                                let priceIncludingTax = (Number(cartItem.cost) * Number(cartItem.quantity) * productTaxDetails.taxrate)/100;
                                productObj.price_inc_tax = (Math.round((priceIncludingTax + Number.EPSILON) * 100) / 100) + productObj.price_ex_tax;
                                productObj.tax_percentage = productTaxDetails.taxrate;
                                // let producttax = (productObj.price_ex_tax * productTaxDetails.taxrate)/100;
                                let producttax;
                                if(cartItem.fixed_amount != undefined){
                                   producttax = ((productObj.price_ex_tax-cartItem.fixed_amount) * productTaxDetails.taxrate)/100;
                                }else{
                                   producttax = (productObj.price_ex_tax * productTaxDetails.taxrate)/100;
                                }
                                productObj.tax_amount = (Math.round((producttax + Number.EPSILON) * 100) / 100);
                                productObj.total_amount = productObj.price_ex_tax + productObj.tax_amount;
                                productList.push(productObj);
                              });
                            }
                          }
                        )
                        this.orderUpdationService.orderObj.product_details.products = productList;
                        resolve(true);
                      }
                    )
                  }
                }
              )
            }
          }
        )
      }
      else if(ORG_DETAILS.NAME === "Nesma"){
        let productList = [];
        if(this.checkoutInitialdataService.getCartItems()){
          let cartItems:any = this.checkoutInitialdataService.cartProducts;
          this.orderUpdationService.orderObj.shipping.items_total = cartItems.length;
          let itemsQuantity = 0;
          cartItems.forEach(item => {
            
            // item.checked_products.forEach(value=>{
            // var a=value;
            //   var result = a.substring(a.lastIndexOf("a") + 1);
            //  //v.substring(v.indexOf("a") + 1);
            //   this.checkoutTaxService.getAllProducts().then(
            //     respData3 => {
            //       let res3:any = respData3;
            //       let selectedProduct = res3.find(rprod => rprod.id === Number(result));
            //       if(selectedProduct)
            //       {
            //         productList.push({
            //           product_id: selectedProduct.id,
            //           product_name: selectedProduct.name,
            //           price:selectedProduct.pricing.raw.price,
            //           type_of_product:"Combo"

                    
                    
            //         })

            //     }})
            // })
            itemsQuantity = itemsQuantity + item.package_quantity + item.add_onproducts.length + item.bundle_products.length;
          });
          this.orderUpdationService.orderObj.shipping.items_total_quantity = itemsQuantity;
          this.checkoutSettingsService.getTaxSettings().then(
            respData => {
              let res:any = respData;
              if(res){
                this.checkoutSettingsService.getTaxRates().then(
                  respData1 => {
                    let res1:any = respData1;
                    let productList = [];
                    this.checkoutTaxService.getBundledProductTaxList(cartItems, this.checkoutSettingsService.taxclasses).then(
                      respData2 => {
                        let productTaxList:any = respData2;
                        this.checkoutTaxService.getAllProducts().then(
                          respData3 => {
                            let res3:any = respData3;
                            if(res3.length > 0){
                              productTaxList.forEach(ptax => {
                                let selectedProduct = res3.find(rprod => rprod.id === ptax.pid);
                                if(selectedProduct){
                                  productList.push({
                                    product_id: ptax.pid,
                                    product_name: selectedProduct.name,
                                    customise_products:{
                                      customisedCategory:ptax.customisedCategory
                                    },
                                    sku: selectedProduct.sku,
                                    quantity: ptax.quantity,
                                    package_id: ptax.package_id,
                                    free: ptax.free,
                                    type: ptax.type,
                                    package_product: ptax.package_product,
                                    image_source: selectedProduct.assets.length > 0 ? selectedProduct.assets[0].image_source : "",
                                    price: ptax.price,
                                    price_inc_tax: (Math.round((((Number(ptax.price) * Number(ptax.quantity) * Number(ptax.taxrate))/100) + Number.EPSILON) * 100) / 100) + (Number(ptax.price) * Number(ptax.quantity)),
                                    price_ex_tax: Number(ptax.price) * Number(ptax.quantity),
                                    discount_percentage:0,
                                    discount_amount:0,
                                    tax_percentage: ptax.taxrate,
                                    tax_amount: (Math.round(((((Number(ptax.price) * Number(ptax.quantity)) *  Number(ptax.taxrate))/100) + Number.EPSILON) * 100) / 100) ,
                                    total_amount: (Number(ptax.price) * Number(ptax.quantity)) + (Math.round(((((Number(ptax.price) * Number(ptax.quantity)) *  Number(ptax.taxrate))/100) + Number.EPSILON) * 100) / 100),
                                    variant:{
                                      variant_id:"",
                                      variant_name:"",
                                      options:{
                                        option_key:"",
                                        option_value:""
                                      }
                                    }
                                  })
                                }
                              });
                              this.orderUpdationService.orderObj.product_details.products = productList;
                              resolve(true);
                            }
                          }
                        )
                      })
                  })
              }
            })
        }
      }
     
      
    })
  }

  // GET CART ID
  getCartId(){
    return new Promise((resolve, reject) => {
      if(this.localStorageService.get('session_id')){
        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
            "find": {
              "session_id":this.localStorageService.get('session_id')
            }
          }
        }
        this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALLCARTITEMS, requestData).then(
          respData => {
            let res:any = respData;
            if(res.success){
              if(res.data.length > 0){
                this.orderUpdationService.orderObj.other_details.cart_id = res.data[0].id;
                resolve(true);
              }
            }
          }
        )
      }
    })
  }

  // CURRENCY LABELS
  getCurrencyLabels(){
    return new Promise ((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_CURRENCIES).then(
        respData => {
          let res:any = respData;
          if(res.success){
            if(res.data.length > 0){
              let currencyDetails = res.data[0];
              this.orderUpdationService.orderObj.payment.order_currency_code = currencyDetails.currency_code;
              this.orderUpdationService.orderObj.payment.base_currency_code = currencyDetails.currency_code;
              this.orderUpdationService.orderObj.payment.currency_exchange_rate = parseFloat(currencyDetails.static_conversion_rate);
              resolve(true);
            }
            else{
              resolve(true);
            }
          }
        }
      )
    })
  }

  // GET ORDER STATUS VALUES
  getOrderStatus(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_ORDERSTATUS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let orderapiData:any = res.data;
            orderapiData.forEach(element => {
              if(element.system_label.toUpperCase() === 'AWAITING PAYMENT'){
                this.orderUpdationService.orderObj.status = element.custom_label;
                this.orderUpdationService.new_value.status = element.custom_label;
                this.orderUpdationService.orderObj.status_id= element.id;
              }
              if(element.system_label.toUpperCase() === 'PENDING'){
                this.orderUpdationService.old_value.status = element.custom_label;
              }
              if(element.system_label.toUpperCase() === 'CONFIRMED'){
                this.orderUpdationService.new_value1.status = element.custom_label;
                this.orderUpdationService.confirmationId = element.id;
              }
              resolve(true);
            });
          }
        }
      )
    })
  }

  // ORDER DETAILS
  getOrderDetails(){
    // CALCULATE SUBTOTAL
    return new Promise((resolve, reject) => {
      // DERIVED COST
      let deliveryCostObject = {
        subtotal_ex_tax:0,
        subtotal_inc_tax:0,
        shipping_cost_ex_tax:0,
        shipping_cost_inc_tax:0,
        handling_cost_ex_tax:0,
        handling_cost_inc_tax:0,
        wrapping_cost_ex_tax:0,
        wrapping_cost_inc_tax:0,
        subtotal_tax:0,
        discount_amount:0,
        total_ex_tax:0,
        total_inc_tax:0,
      }
      deliveryCostObject.subtotal_ex_tax = this.orderSummaryOrderService.orderObj.subTotal;
      deliveryCostObject.subtotal_inc_tax = this.orderSummaryOrderService.orderObj.productTax + this.orderSummaryOrderService.orderObj.subTotal;
      if(this.orderSummaryOrderService.freeShipping === true){
        this.checkoutShippingApiService.getDeliveryCharges().then(
          respData1 => {
            let res1:any = respData1;
            deliveryCostObject.shipping_cost_ex_tax = res1.deliveryCharge;
            deliveryCostObject.shipping_cost_inc_tax = res1.deliveryCharge + res1.deliveryTax;
            if(res1.deliverType === "Flat Rate"){
              this.orderUpdationService.orderObj.shipping.shipping_method.name = "Flat Rate";
            }
            this.orderUpdationService.orderObj.shipping.shipping_method.delivery_charge = res1.deliveryCharge;
            if(this.checkoutShippingApiService.enableFixedShipping){
              this.orderUpdationService.orderObj.shipping.shipping_method.total_shipping_price = this.checkoutShippingApiService.selectedShippingMethod.logisticCharge;
              this.orderUpdationService.orderObj.shipping.pay_to_logistics = this.orderUpdationService.orderObj.shipping.shipping_method.total_shipping_price - this.orderUpdationService.orderObj.shipping.shipping_method.delivery_charge;
              this.orderUpdationService.orderObj.shipping.shipping_method.pay_to_logistics = this.orderUpdationService.orderObj.shipping.shipping_method.total_shipping_price - this.orderUpdationService.orderObj.shipping.shipping_method.delivery_charge;
            }
            this.orderUpdationService.orderObj.shipping.fixed_shippping_price = this.checkoutShippingApiService.enableFixedShipping;
          }
        )
      }
      else{
        deliveryCostObject.shipping_cost_ex_tax = this.orderSummaryOrderService.orderObj.shippingCharges;
        this.orderUpdationService.orderObj.shipping.shipping_method.delivery_charge = this.orderSummaryOrderService.orderObj.shippingCharges;
        if(this.checkoutShippingApiService.enableFixedShipping){
          this.orderUpdationService.orderObj.shipping.shipping_method.total_shipping_price = this.checkoutShippingApiService.selectedShippingMethod.logisticCharge;
          this.orderUpdationService.orderObj.shipping.pay_to_logistics = this.orderUpdationService.orderObj.shipping.shipping_method.total_shipping_price - this.orderUpdationService.orderObj.shipping.shipping_method.delivery_charge;
          this.orderUpdationService.orderObj.shipping.shipping_method.pay_to_logistics = this.orderUpdationService.orderObj.shipping.shipping_method.total_shipping_price - this.orderUpdationService.orderObj.shipping.shipping_method.delivery_charge;
        }
        this.orderUpdationService.orderObj.shipping.fixed_shippping_price = this.checkoutShippingApiService.enableFixedShipping;
        this.orderUpdationService.orderObj.shipping.shipping_method.name = this.orderSummaryOrderService.orderObj.shippingMethodName;
        deliveryCostObject.shipping_cost_inc_tax = this.orderSummaryOrderService.orderObj.shippingChargesTax + this.orderSummaryOrderService.orderObj.shippingCharges
      }
      deliveryCostObject.handling_cost_ex_tax = this.orderSummaryOrderService.orderObj.handlingFee;
      deliveryCostObject.handling_cost_inc_tax = this.orderSummaryOrderService.orderObj.handlingFeeTax + this.orderSummaryOrderService.orderObj.handlingFee;
      deliveryCostObject.subtotal_tax = this.orderSummaryOrderService.orderObj.productTax;
      deliveryCostObject.discount_amount = this.orderSummaryOrderService.orderObj.discountAmount;
      deliveryCostObject.total_ex_tax = deliveryCostObject.subtotal_ex_tax + deliveryCostObject.shipping_cost_ex_tax + deliveryCostObject.handling_cost_ex_tax
      deliveryCostObject.total_inc_tax =  deliveryCostObject.total_ex_tax + this.orderSummaryOrderService.orderObj.totalTax;

      this.orderUpdationService.orderObj.order_review.derived_cost = deliveryCostObject;
      
      // BASE COST OBJECT
      let baseObj = {
        base_shipping_cost : 0,
        shipping_cost_tax : 0,
        shipping_cost_tax_class_id : 0,
        base_handling_cost : 0,
        base_handling_cost_type : "",
        handling_cost_tax : 0,
        handling_cost_tax_class_id : 0,
        base_wrapping_cost : 0,
        wrapping_cost_tax : 0,
        wrapping_cost_tax_class_id : 0
      }

      // GET TAX RATES
      this.checkoutSettingsService.getTaxSettings().then(
        respData => {
          let res:any = respData;
          if(res){
            this.checkoutSettingsService.getTaxRates().then(
              respData1 => {
                let res1:any = respData1;
                if(res1){
                  // SHIPPING CHARGES
                  baseObj.base_shipping_cost = deliveryCostObject.shipping_cost_ex_tax;
                  if(this.orderSummaryOrderService.freeShipping === true){
                    baseObj.shipping_cost_tax = 0;
                  }
                  else{
                    baseObj.shipping_cost_tax = this.orderSummaryOrderService.orderObj.shippingChargesTax;
                  }
                  baseObj.shipping_cost_tax_class_id = this.checkoutSettingsService.shippingTax.id;

                  // HANDLING FEE
                  baseObj.base_handling_cost = this.orderSummaryOrderService.orderObj.handlingFee;
                  baseObj.base_handling_cost_type = this.orderSummaryOrderService.orderObj.handlingCostType;
                  baseObj.handling_cost_tax = this.orderSummaryOrderService.orderObj.handlingFeeTax;
                  baseObj.handling_cost_tax_class_id = this.checkoutSettingsService.handlingFeeTax.id;
                  this.orderUpdationService.orderObj.order_review.base_cost = baseObj;

                  // ORDER TAXES
                  let ordertaxrates = JSON.parse(this.localStorageService.get('ORDERTAXLABELS'))
                  let ordertaxes = [];
                  // PRODUCTS RELATED
                  this.getProductOrderTaxes().then(
                    respTax =>{
                      let respt:any = respTax;
                      ordertaxes = respt;
                      // SHIPPING RELATED
                      if(this.orderSummaryOrderService.freeShipping === false && this.orderSummaryOrderService.orderObj.shippingCharges > 0){
                        let shippingObject = {
                          tax_rate_id:0,
                          tax_class_id:0,
                          tax_class_name:"",
                          type_of_tax:"",
                          rate:0,
                          priority:0,
                          priority_amount:0,
                          line_amount:0,
                          order_product_id:0,
                          line_item_type:""
                        }
                        shippingObject.tax_rate_id = ordertaxrates.rateId;
                        shippingObject.tax_class_id = this.checkoutSettingsService.shippingTax.id;
                        shippingObject.tax_class_name = this.checkoutSettingsService.shippingTax.name;
                        shippingObject.type_of_tax = 'Manual Tax';
                        shippingObject.rate = this.checkoutSettingsService.shippingTax.rate;
                        shippingObject.priority = 0;
                        shippingObject.priority_amount = shippingObject.line_amount = this.orderSummaryOrderService.orderObj.shippingChargesTax;
                        shippingObject.order_product_id = 0;
                        shippingObject.line_item_type = "shipping";
                        ordertaxes.push(shippingObject);
                      }
                      // HANDLING FEE
                      let handligFeeObj = {
                        tax_rate_id:0,
                        tax_class_id:0,
                        tax_class_name:"",
                        type_of_tax:"",
                        rate:0,
                        priority:0,
                        priority_amount:0,
                        line_amount:0,
                        order_product_id:0,
                        line_item_type:""
                      }
                      handligFeeObj.tax_rate_id = ordertaxrates.rateId
                      handligFeeObj.tax_class_id = this.checkoutSettingsService.handlingFeeTax.id;
                      handligFeeObj.tax_class_name = this.checkoutSettingsService.handlingFeeTax.name;
                      handligFeeObj.type_of_tax = 'Manual Tax';
                      handligFeeObj.rate = this.checkoutSettingsService.handlingFeeTax.rate;
                      handligFeeObj.priority = 0;
                      handligFeeObj.priority_amount = handligFeeObj.line_amount = this.orderSummaryOrderService.orderObj.handlingFeeTax;
                      handligFeeObj.order_product_id = 0;
                      handligFeeObj.line_item_type = "handling";
                      ordertaxes.push(handligFeeObj)
                      this.orderUpdationService.orderObj.order_review.order_taxes = ordertaxes;
                      // console.log("ORDER UPDATION OBJ1", this.orderUpdationService.orderObj)
                      resolve(true);
                    }
                  )

                  
                }
              }
            )
          }
        }
      )
      
    })
  }

  getProductOrderTaxes(){
    return new Promise((resolve, reject) => {
      // ORDER TAXES
      let ordertaxrates = JSON.parse(this.localStorageService.get('ORDERTAXLABELS'))
      let ordertaxes = [];
      // PRODUCTS RELATED
      this.checkoutInitialdataService.getCartItems().then(
        respData => {
          let res:any = respData;
          if(res){
            this.checkoutSettingsService.getTaxSettings().then(
              respData1 => {
                let res1:any = respData1;
                if(res1){
                  this.checkoutSettingsService.getTaxRates().then(
                    respData2 => {
                      let res2:any = respData2;
                      this.checkoutTaxService.getProductTaxList(this.checkoutInitialdataService.cartProducts, this.checkoutSettingsService.taxclasses).then(
                        respData3 => {
                          console.log("order-updation2")
                          let res3:any = respData3;
                          let productTaxList = res3;
                          if(productTaxList.length > 0){
                            productTaxList.forEach(productTax => {
                              let productTaxObj = {
                                tax_rate_id:0,
                                tax_class_id:0,
                                tax_class_name:"",
                                type_of_tax:"",
                                rate:0,
                                priority:0,
                                priority_amount:0,
                                line_amount:0,
                                order_product_id:0,
                                line_item_type:""
                              }
                              productTaxObj.tax_rate_id = ordertaxrates.rateId;
                              productTaxObj.tax_class_id = productTax.taxId;
                              productTaxObj.tax_class_name = productTax.taxClassName;
                              productTaxObj.type_of_tax = 'Manual Tax';
                              productTaxObj.rate = productTax.taxrate;
                              productTaxObj.priority = 0;
                              if(this.orderSummaryOrderService.orderObj.discountAmount > 0 && this.orderSummaryOrderService.orderObj.discountType.toUpperCase() === this.PERCENTAGE_LABEL){
                                let discountforProduct = (productTax.quantity * productTax.price * this.orderSummaryOrderService.orderObj.discountPercentage)/100;
                                discountforProduct = (Math.round((discountforProduct + Number.EPSILON) * 100) / 100);
                                let productSubtotal = (productTax.quantity * productTax.price) - discountforProduct;
                                productTaxObj.priority_amount = productTaxObj.line_amount = Math.round((((productSubtotal * productTax.taxrate)/100) + Number.EPSILON) * 100) / 100;
                              }
                              else{
                                productTaxObj.priority_amount = productTaxObj.line_amount = Math.round((((productTax.price * productTax.quantity * productTax.taxrate)/100) + Number.EPSILON) * 100) / 100;
                              }
                              productTaxObj.order_product_id = productTax.pid;
                              productTaxObj.line_item_type = "item"
                              ordertaxes.push(productTaxObj);
                              resolve(ordertaxes);
                            });
                            
                          }
                        }
                      )
                    }
                  )
                }
              }
            )
          }
        }
      )
    })
  }

  getOrderSummary(){
    return new Promise((resolve, reject) => {
      this.orderUpdationService.orderObj.order_review.order_summary = this.orderSummaryOrderService.orderSummary;
      resolve(true);
    })
  }

  getOrderFlow(flowId, paymentMethodName, paymentId){
    return new Promise((resolve, reject) =>{
      this.orderUpdationService.orderObj.payment.payment_method = paymentMethodName;
      this.orderUpdationService.orderObj.payment.payment_method_id = paymentId;
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "id": Number(flowId)
          }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.ORDER_STATUS_FLOW, reqData).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let orderFlowObj = {
              name:"",
              title:"",
              statuses:[]
            }
            let resData = res.data[0];
            orderFlowObj.name = resData.name;
            orderFlowObj.title = resData.title;
            if(resData.statuses.length > 0){
              resData.statuses.forEach(element => {
                let orderObj = {
                  name:"",
                  title:"",
                  status_id:"",
                  status_name:"",
                  status_system_label:"",
                  status_custom_label:"",
                  process:"",
                  sequence:0,
                  sms_notification:false,
                  email_notification:false,
                  push_notification:false
                };
                orderObj.status_id = element.status_id;
                orderObj.status_name = element.status_name;
                orderObj.status_system_label = element.system_label;
                orderObj.status_custom_label = element.custom_label;
                orderObj.process = element.process;
                orderObj.sequence = element.sequence;
                orderObj.sms_notification = element.sms_notification;
                orderObj.email_notification = element.email_notification;
                orderObj.push_notification = element.push_notification;
                orderFlowObj.statuses.push(orderObj);
              });
              this.orderUpdationService.orderObj.payment.status_flow = orderFlowObj;
              resolve(true);
            }
          }
        }
      )
    })
  }
  
}
