import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { TableBookingService } from './table-booking.service';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { AlertsService } from '../shared/alerts/alerts.service';
import { NavbarService} from '../navbar/navbar.service';
import * as moment from 'moment';
import {DatePickerComponent} from 'ng2-date-picker';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { LoaderService } from '../shared/loader/loader.service';

@Component({
  selector: 'app-table-booking',
  templateUrl: './table-booking.component.html'
})
export class TableBookingComponent implements OnInit {


  tableBookingForm: FormGroup;
  validateForm: boolean = false;
  queryParams: any;
  showmsg: boolean = false;
  date: any;
  today: any;
  public addOutlet = true;
  outletList = [];
  tableBookingSlot = [];
  noOfPerson:Number;
  showmsg1: boolean = false;
  showmsg2: boolean = false;
  termsAndConditions:any;
  daysAllowedToBook:any;
  description:any;
  rules:any;
  image:any;
  advanceamount:any;
  otpResendDuration:Number;
  restaurentTimings:any = "";
  resendMsg:string;
  //slot_perhead:any;
  per_head_cost:any;

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  datePickerConfig = {
    format : "yyyy-MM-DD",
    min : moment().format("YYYY-MM-DD")
  }
  @ViewChild('dayPicker') datePicker: DatePickerComponent;
  customerList = [];

  editPage: boolean = false;
  mindeliveryDate: any = "";
  maxDate:any="";
  show_on_page_list: any = ["Home Page", "Category Page", "Search Results"];
  location_list: any = ["Top", "Bottom"];
  start_time: any;
  end_time: any;
  slotTime:any;
  showmsg3: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private checkoutApiService: CheckoutApiService,
    private tableBookingService: TableBookingService,
    private alertService: AlertsService,
    private router: Router,
    private appService: AppService,
    private atp: AmazingTimePickerService,
    private localService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private navbarService: NavbarService,
    private loaderService: LoaderService
    ) { }

  ngOnInit(): void {
    if(this.advanceamount == undefined ){
      
      this.advanceamount = 0
        }
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    var dtToday = new Date();
    var month: any = dtToday.getMonth() + 1;
    var day: any = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10)
      month = '0' + month.toString();
    if (day < 10)
      day = '0' + day.toString();
    this.mindeliveryDate = year + '-' + month + '-' + day;
    console.log("this.mindeliveryDate"+this.mindeliveryDate);
    this.tableBookingForm = new FormGroup({
      name: this.formBuilder.control('', [Validators.required, Validators.maxLength(80), Validators.pattern("^[a-zA-Z][a-z A-Z]*$")]),
      email_address: this.formBuilder.control('', [Validators.maxLength(40), Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      mobile_number: this.formBuilder.control('', [Validators.required, Validators.maxLength(10), Validators.pattern("^[+]*[(]{0,1}[0-9]{10}[)]{0,1}[-\s\./0-9]*$")]),
      date: this.formBuilder.control('', [Validators.required]),
      time: this.formBuilder.control('', [Validators.required]),
      otp: this.formBuilder.control('',[Validators.required]),
      advanceamount: this.formBuilder.control(''),
      optional_message: this.formBuilder.control('', [Validators.maxLength(1000)]),
      no_of_persons: this.formBuilder.control('', [Validators.required, Validators.max(99),Validators.min(1)]),
      add_outlet:this.formBuilder.control('', [Validators.required]),
      is_active: this.formBuilder.control(true)
    })
    this.getOutletData();
    this.getTableBookingSettings();

    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "add_outlet":"Haven Sampoorna Velachery",
          "date":this.mindeliveryDate
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GETALL_TABLEBOOKINGTIMESLOT).subscribe(
      resp => {
        if(resp.success === 1){
          this.tableBookingSlot = resp.result.data;
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }          
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }          
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }

  setSlot(time){
    if(this.tableBookingForm.value.add_outlet === "" || this.tableBookingForm.value.add_outlet === null){
      console.log("pass")
      this.tableBookingForm.controls.add_outlet.setErrors({"required": true});
      this.validateForm = true;
    } else if(this.tableBookingForm.value.date === "" || this.tableBookingForm.value.date === null){
      console.log("pass")
      this.tableBookingForm.controls.date.setErrors({"required": true});
      this.validateForm = true;
    } 
    else {
         
      this.tableBookingForm.controls['time'].setValue(time);
      console.log("timeeee"+this.tableBookingForm.controls.time.value)
      this.slotTime = this.tableBookingForm.controls.time.value;
      this.showmsg2 = false;
      // for(let i = 0;i<this.tableBookingSlot.length;i++)
      // {
        // if(this.tableBookingSlot[i].slot_time==time){
          // this.tableBookingForm.controls['no_of_persons'].setValue(this.tableBookingSlot[i].available_seats);
          // this.noOfPerson=this.tableBookingSlot[i].available_seats;
        // }
      // }
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "date":this.tableBookingForm.value.date,
            "time":time,
            "add_outlet":this.tableBookingForm.value.add_outlet,
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.GETALL_TABLEBOOKINGAVAILABLESEAT).subscribe(
        resp => {
          if(resp.success === 1){
            // this.noOfPerson = resp.result.data[0].maximum_persons;
            this.noOfPerson = resp.result.data;
          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options)
            }          
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          }          
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        })
    }
  
  }

  saveForm() {
    // if(this.tableBookingForm.controls.otp.value === ""){
    //   console.log("pass")
    //   this.tableBookingForm.controls['otp'].setErrors({'incorrect':true});
    // }
    console.log("valueeeee"+this.tableBookingForm.value.time)
    if(this.tableBookingForm.value.time === "" || this.tableBookingForm.value.time === null){
      console.log("pass")
      this.showmsg2 = true;
      this.validateForm = true;
    }
    if(parseInt(this.tableBookingForm.value.no_of_persons) > this.noOfPerson){
      console.log("pass2222")
      this.tableBookingForm.controls.no_of_persons.setErrors({'incorrect':true});
      this.validateForm = true;
     this.showmsg1 = true;
    }
    this.showmsg=true
    //   let bookedTime=moment(this.tableBookingForm.value.time, 'hh:mmA').format('HH:mm')
    //   let startTime=moment(this.start_time, 'hh:mm a').format('HH:mm')
    //   let endTime=moment(this.end_time, 'hh:mm a').format('HH:mm')
    // if (bookedTime <= startTime || bookedTime >= endTime)
    //     {
    //       this.tableBookingForm.controls.time.setErrors({"unavailable_time" : true})
    //       this.validateForm = true;
    //       return
    //     }
    let currentData = new Date()

      let date = currentData.getDate() < 10 ? "0"+ (currentData.getDate()).toString() : (currentData.getDate()).toString()
      console.log("date"+date);
      let month = currentData.getMonth() + 1 < 10 ? "0" + (currentData.getMonth()+ 1).toString() : (currentData.getMonth()+ 1).toString()
      console.log("month"+month);
      let hour = currentData.getHours() < 10 ? "0"+ (currentData.getHours()).toString() : (currentData.getHours()).toString()
      console.log("hour"+hour);
      let minute = currentData.getMinutes() < 10 ? "0"+ (currentData.getMinutes()).toString() : (currentData.getMinutes()).toString()
      console.log("minute"+minute);
      let currentTime = hour + ":" + minute
      
      //TODAY DATE
      let today = currentData.getFullYear().toString() + "-" + month + "-" +  date
      console.log("today"+today);
      if (this.tableBookingForm.value.date === "" || this.tableBookingForm.value.date === null )
      {
        this.tableBookingForm.controls.date.setErrors({"required": true});
        this.validateForm = true;
        return
      }
      //CHECKING TODAY WITH SELECTED DATE
      // else if(this.tableBookingForm.value.date < today)
      // {
      //   this.tableBookingForm.controls.date.setErrors({"previous_date": true});
      //   this.validateForm = true;
      //   return
      // }
      // else
      // {
      //   this.tableBookingForm.controls.date.setErrors(null)
      // }
      
        
      // if (this.tableBookingForm.value.date === today)
      // {
        // if (this.tableBookingForm.value.time <= currentTime)
        // {
        //   this.tableBookingForm.controls.time.setErrors({"previous_time" : true})
        //   this.validateForm = true;
        //   return
        // }
        // else
        // {
        //   this.tableBookingForm.controls.time.setErrors(null)
        // }
      // }
      // if (this.tableBookingForm.value.date > today)
      // {
      //     this.tableBookingForm.controls.time.setErrors(null)
      // }
      console.log("outsideeeeeee")
    if (this.tableBookingForm.valid && this.addOutlet === true) {
      // if (this.tableBookingForm.valid) {
        this.loaderService.emitLoading();
        console.log("insideeeeeee")
      this.validateForm = false;
      var formData = {}
      var formDataJson;
      this.verifyOTP(this.tableBookingForm.controls.mobile_number.value, this.tableBookingForm.controls.otp.value).then(
        respData1 => {
          console.log("333")
          let res1:any = respData1;
          if(res1.status){
      formData["name"] = this.tableBookingForm.value.name;
      formData["email_address"] = this.tableBookingForm.value.email_address;
      formData["mobile_number"] = this.tableBookingForm.value.mobile_number;
      formData["date"] = this.tableBookingForm.value.date;
      formData["time"] = this.tableBookingForm.value.time;
      formData["no_of_persons"] = parseInt(this.tableBookingForm.value.no_of_persons);
      formData["add_outlet"] = this.tableBookingForm.value.add_outlet;
      formData["optional_message"] = this.tableBookingForm.value.optional_message;
      formData['is_active'] =  this.tableBookingForm.value.is_active
      formData['status'] = 'New'
      formDataJson = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        payload: {
          tablebooking_creation: formData
        }
      }
      // MAKE A SERVICE CALL HERE...
      this.appService.postApiCall(formDataJson, ENDPOINTS.ADD_TABLEBOOKING).subscribe(
        resp => {
          if (resp) {
            if (resp.success === 1 && resp.status_code === 200) {
              this.alertService.success('Table Booking Confirmed', this.options);
          
                var form = document.createElement("form");
                var element1 = document.createElement("input");
                form.method = "POST";
                form.action = ORG_DETAILS.DOMAIN_NAME+':5621/api/payment/generateicicitablebookingweb';
                form.target = '_self';
                element1.value= resp.result.data[0].table_order_id;
                element1.name="oid";
                form.appendChild(element1);
                document.body.appendChild(form);
                this.loaderService.emitComplete();
                form.submit();
                // document.getElementById('icicibtn').click()  ;
              
              // this.tableBookingForm.reset();
              // this.slotTime = "";
              // this.noOfPerson = 0;
              // this.loaderService.emitComplete();
              // window.scrollTo({top: 0, behavior: 'smooth'});
              // window.location.reload();

            }
            else if (resp.success === 0) {
              if (resp.message) {
                this.alertService.error(resp.message, this.options);
                window.scrollTo({top: 0, behavior: 'smooth'});
              }
            }
            else if (resp.message && resp.status_code !== 200) {
              this.alertService.error(resp.message, this.options);
              window.scrollTo({top: 0, behavior: 'smooth'});
            }
            else {
              this.alertService.error('Something bad happened. Please try again!', this.options);
              window.scrollTo({top: 0, behavior: 'smooth'});
            }
          }
        },
        err => {
          if (err.error.statusCode === 403) {
            this.alertService.error('Session Time Out! Please login Again', this.options)
            this.router.navigate([`/login-new`], { skipLocationChange: false });
          }
          else if (err.error.message) {
            this.alertService.error(err.error.message, this.options)
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
          else if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
          else {
            this.alertService.error('Something bad happened. Please try again!', this.options);
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
        }
      )
        ;
    }
    else {
      this.validateForm = true;
      this.loaderService.emitComplete();
      this.alertService.error('Please Enter Valid OTP!', this.options);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  })
  }  else {
    this.validateForm = true;
  }
}
  
  getOutletData(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_OUTLETS).subscribe(
      resp => {
        if(resp.success === 1){
          this.outletList = resp.result.data;
          let open_time=resp.result.data[1].open_time
          let close_time=resp.result.data[1].close_time
          this.start_time=moment(open_time, 'hh:mmA').format('hh:mm a')
          this.end_time=moment(close_time, 'hh:mmA').format('hh:mm a')
          this.restaurentTimings = resp.result.data[0].open_time + " to "+resp.result.data[0].close_time;
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }          
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }          
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }

  getTableBookingSettings(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GETALL_TABLEBOOKINGSETTINGS).subscribe(
      resp => {
        if(resp.success === 1){
          // this.tableBookingSlot = resp.result.data[0].table_structure;
          // this.noOfPerson = resp.result.data[0].maximum_persons;
          this.termsAndConditions = resp.result.data[0].terms_and_condition;
          this.daysAllowedToBook =  resp.result.data[0].days_allowed_to_book;
          this.description = resp.result.data[0].description;
          this.rules =  resp.result.data[0].rules;
          this.image =  resp.result.data[0].image;
          this.per_head_cost = resp.result.data[0].per_head_cost;
          
          // var days_allowed_to_book = resp.result.data[0].days_allowed_to_book;
          // var dt1Today = new Date();
          // var month: any = dt1Today.getMonth() + 1;
          // var day: any = dt1Today.getDate();
          // var year = dt1Today.getFullYear();
          // if (month < 10)
          //   month = '0' + month.toString();
          // if (day < 10)
          //   day = days_allowed_to_book.toString() + day.toString();
          // this.maxDate = year + '-' + month + '-' + day;
          // console.log("maxDate"+this.maxDate);
          var today = new Date().toISOString().split('T')[0];
          this.maxDate = new Date(new Date().getTime() +  this.daysAllowedToBook * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
          // document.getElementsByName("date")[0].setAttribute('min', today);
          // document.getElementsByName("date")[0].setAttribute('max', this.maxDate)
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }          
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }          
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }


  verifyMobileNumber(){
    console.log("111")
    if(this.tableBookingForm.controls.mobile_number.valid){
      console.log("222")
      this.loaderService.emitLoading();
      this.showmsg3=false;
      // if(this.tableBookingForm.controls.mobile_number.value.length == ""){
      //   this.tableBookingForm.controls['mobile_number'].setErrors({'incorrect':true});
      // }
      // if(this.tableBookingForm.controls.mobile_number.value.length !== 10){
      //   this.tableBookingForm.controls['mobile_number'].setErrors({'incorrect':true});
      // }
      this.showmsg=true;
      this.sendOTP(this.tableBookingForm.controls.mobile_number.value).then(
        respData => {
          let res:any = respData;
          if(res){
            this.otpResendDuration = new Date().getTime() + 5*60*1000;
            this.resendMsg=this.tableBookingForm.controls.mobile_number.value;
            this.loaderService.emitComplete();
            this.alertService.success('OTP has been sent to '+this.tableBookingForm.controls.mobile_number.value, this.options);
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
        }
      )
    
    } else {
      console.log("333")
      this.tableBookingForm.controls['mobile_number'].setErrors({'pattern':true});
      this.showmsg3=true;
  }
}


  pickTime() {
    const amazingTimePicker = this.atp.open({
      time: this.tableBookingForm.value.time,
      theme: 'light',
      arrowStyle: {
        background: '#afacac',
      }
    });

    amazingTimePicker.afterClose().subscribe(time => {
      this.tableBookingForm.controls.time.setValue(time)
    })
  }

  get f() { return this.tableBookingForm.controls; }

  termsAndCondition(){
    document.getElementById('launch-model').click();
  }

  handleOutlet(evt) {
    console.log("evt.target.value"+evt.target.value)
    if (evt.target.value === "" || evt.target.value === null) {
      this.addOutlet = true
    }
    else {
      this.outletList.forEach((element)=>
      {
        if (element.name == evt.target.value)
        {
         if(element.allow_table_booking === 'No'){
          this.addOutlet = false
         } else if(element.allow_table_booking === 'Yes'){
          this.addOutlet = true
         }
        }
      })
    }
    this.getTableBookingSettings1();
    
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "date":this.tableBookingForm.value.date,
          "time":this.tableBookingForm.value.time,
          "add_outlet":this.tableBookingForm.value.add_outlet,
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GETALL_TABLEBOOKINGAVAILABLESEAT).subscribe(
      resp => {
        if(resp.success === 1){
          // this.noOfPerson = resp.result.data[0].maximum_persons;
          this.noOfPerson = resp.result.data;
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }          
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }          
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }


  
  handleDate(evt) {
    console.log("evt.target.value"+evt.target.value)
    if(this.tableBookingForm.value.add_outlet === "" || this.tableBookingForm.value.add_outlet === null){
      console.log("pass")
      this.tableBookingForm.controls.add_outlet.setErrors({"required": true});
      this.validateForm = true;
    } else if(this.tableBookingForm.value.date === "" || this.tableBookingForm.value.date === null){
      console.log("passyyyyy")
      this.tableBookingForm.controls.date.setErrors({"required": true});
      this.validateForm = true;
    } if ((this.tableBookingForm.value.add_outlet === "" || this.tableBookingForm.value.add_outlet === null) && this.tableBookingForm.value.date){
      this.tableBookingForm.reset();
    }
    else {
    this.getTableBookingSettings1();
  }
}

    
  sendOTP(mobileNo){
    this.showmsg=true;
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "data": {
            "otp": {
                "countrycode": "+91",
                "mobile": mobileNo,
                "otppurpose": "login"
            }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.SEND_OTP, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success === 1){
            resolve(true);
          }
          else{
            resolve(false);
            console.log("res.message"+res.message)
            this.alertService.error(res.message, this.options);
          }
        }
      )
    })
  }

  verifyOTP(mobile, otp){
    return new Promise((resolve, reject) => {
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "data": {
          "otp": {
              "userotp": otp,
              "mobile": mobile
          }
        }
      }
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.VERIFY_OTP, requestData).then(
      respdata => {
        let res:any = respdata;
        if(res.success === 1){
          resolve({status:true, msg:res.message});
        }
        else{
          resolve({status:false, msg:res.message});
        }
    })
    })
  }

  
  getTableBookingSettings1(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "add_outlet": this.tableBookingForm.value.add_outlet,
          "date":this.tableBookingForm.value.date
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GETALL_TABLEBOOKINGTIMESLOT).subscribe(
      resp => {
        if(resp.success === 1){
          this.tableBookingSlot = resp.result.data;
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }          
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }          
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }
  
    toCalculateAdvanceAmount(noOfPerson): void {  
       this.advanceamount = (noOfPerson * this.per_head_cost);
    
   

}
}

