import { Component, OnInit } from '@angular/core';
import { HomeSliders } from '../shared-two/slider/slider';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { AppService } from '../app.service';
import { config } from '../../configs/config';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',

})
export class BannerComponent implements OnInit {
  carouselData:any = [];
  swapData:any;
  config = config;

  constructor(
    public appService: AppService,
  ) { }
  public HomePageBannerSlider: any =  HomeSliders;
  ngOnInit(): void {
    this.getCarouseldetails()
  }

  ngAfterViewInit(): void {
    this.getCarouseldetails();
  }
  getCarouseldetails(){
   
    // REQUEST DATA
    let request_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
          "find": {}
      }
    }
    // MAKE AN API CALL
    this.appService.postApiCall(request_data, ENDPOINTS.STORE_GALLERY).subscribe(  //LINE 
      resp => {
        let respData = resp.result.data[0];    
        // console.log(resp)
        if(respData.carousel){
          console.log("banner")
          if(respData.carousel.slides.length > 0){
            if(respData.carousel.swap_period && respData.carousel.swap_period !== ""){
              this.HomePageBannerSlider['autoplayTimeout'] = Number(respData.carousel.swap_period)
            }            
            this.carouselData = respData.carousel.slides;
            this.swapData = respData.carousel; 
            console.log(this.carouselData)
          }
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }

}
