<br>
<app-alerts *ngIf="showalertmsg"></app-alerts>
<section class="container order-details-page">
    <p class="logo-header text-primary">Order Details</p>
    <div class="row">
        <h5 class="col-md-10">
            Ordered on {{orderedOn}} <span class="verticalBar">&#124;</span>
            Order# {{orderId}} <span class="verticalBar">&#124;</span>
            {{orderStatus}}
        </h5>
        <button class="btn btn-md btn-primary col-md-1" routerLink='/my-orders'>Back</button>
    </div>

</section>
<section class="container p-3 my-3 border">
    <div class="row ">
        <div class="row col-md-9 ">
            <div class="col-sm-1">
            </div>
            <div class="col-sm-5">
                <br>
                <br>
                <h5>Delivery Details</h5>
                <div *ngIf="summaryShippingInfo==undefined">
                    Details not found.
                </div>
                <div class="row" *ngIf="summaryShippingInfo!=undefined">
                    <div class="col">
                        {{summaryShippingInfo.first_name}} {{summaryShippingInfo.last_name}},
                    </div>
                </div>
                <div class="row" *ngIf="summaryShippingInfo!=undefined">
                    <div class="col">
                        {{summaryShippingInfo.entire_location}}
                        <!-- {{summaryShippingInfo.phone}},
                        {{summaryShippingInfo.address1}},
                        <br> -->
                        <!-- {{summaryShippingInfo.city}},
                        {{summaryShippingInfo.state}},
                        {{summaryShippingInfo.country}},
                        {{summaryShippingInfo.zipcode}} -->
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <br>
                <br>
                <h5>Payment Information</h5>
                <div class="row" *ngIf="(payment_information| json) != ({} | json)">
                    <div class="col">
                        {{payment_information}}
                    </div>
                </div>
                <div *ngIf="!payment_information || (payment_information| json) == ({} | json)">
                    Details not found.
                </div>
            </div>
            <div class="col-sm-1">
            </div>
            <div class="order-traccking-st col-sm-11">
                <div class="row">
                    <div class="col-12 col-md-12 hh-grayBox">
                        <div class="row d-flex justify-content-start">
                            <div class="order-tracking completed">
                                <span class="is-complete"></span>
                                <p>Order Confirmed<br><span>{{orderDateTime}}</span></p>
                            </div>
                            <div *ngIf="orderCancelled" class="order-tracking completed">
                                <span class="is-complete"></span>
                                <p>{{ordercancelledLabel}}<br><span>{{cancelledTime}}</span></p>
                            </div>
                            <div *ngFor = "let track of trackDetails" class="order-tracking completed">
                                <span class="is-complete"></span>
                                <p>{{track.custom_status}}<br><span>{{track.created_date}}</span></p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <section class="container p-3 my-3 border" style="width: 80%; margin-left: 4%;">
                
                <div class="inq-menu-right-st">
                    <div class="row mt-4 inq-pro-sec-st" *ngFor="let product of checkoutsumaryproducts;let i = index">
                        <div class="col-md-8">
                            <div class="col-md-10 inq-fo-des-in-st">
                                <div class="inq-dis-sec-st">
                                    <div class="inq-ft-st mb-1 row">
                                        <div class="col-md-1" id="veg-img-prt">
                                            <img src="assets/images/vendor/veg.svg" alt="veg-items">
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-10 inq-pro-col-le-st">
                                            <h5>{{product.product_name}} ({{product.quantity}})</h5>
                                            <span><strong>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{product.quantity*product.price}}</strong></span><br>
                                            <span *ngIf="CONFIG.title!=='Haven' && product.customise_products.customisedCategory.length>0" (click)="customizeItem(product,i)"
                                            class="customize-txt">Customized details</span>
                                            <!-- <span class="ml-5"><img class="inq-pro-rating-st" src="assets/images/vendor/rating.svg" alt="rating"> <strong class="inq-primary mr-1"> 5 Points</strong></span> -->
                                        </div>
                                    </div>
                                    <div class="inq-ft-st mb-1 row">
                                        <div class="col-md-1" id="veg-img-prt">
                                        </div>
                                        <div class="col-md-1"></div>
                                        <!-- <div class="col-md-10 inq-pro-col-le-st">
                                            <p [innerHTML] = "product.description.description"></p>

                                        </div> -->
                                    </div>

                                    <div class="inq-ft-st mb-1 row">
                                        <div class="col-md-1" id="veg-img-prt">
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-10 inq-pro-col-le-st">
                                              <button class="btn btn-sm btn-primary col-md-5" (click)="addReview(id,i,product.product_id)"><i class="fa fa-pencil"></i> Write a review</button> 
                                            <!-- <button class="btn btn-sm btn-primary col-md-5" routerLink='/add-review/{{id}}/{{product.id}}/{{i}}'><i class="fa fa-pencil"></i> Write a review</button> -->
                                            <!-- <button class="btn btn-sm btn-primary col-md-5" routerLink='/add-review/{{id}}/{{i}}'><i class="fa fa-pencil"></i> Write a review</button> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2">
                            <div class="inq-fo-im-st ">
                                <img *ngIf="product.image_source!=undefined && product.image_source"
                                    [src]="product.image_source" onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';" alt="..." class="img-fluid product-image">
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </section>
            
        </div>

        <br>
        <div class="row col-md-3 " style="border-left: 1px solid #cdcdcd">
            <div class="col-sm-12 ">
                <br>
                <br>
                <h5 class="text-left inq-cart-ord-ti-st">Order Summary</h5>
                <div class="row  inq-order-inner-p-st">
                    <table style="width: 100%;" class="table table-striped">
                        <ng-container>
                            <tr>
                                <td>SubTotal</td>
                                <td class="text-right">{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}} {{orderdetails.sub_total}}</td>
                            </tr>
                            <tr *ngIf="orderdetails.shipping_cost > 0">
                                <td>Delivery Charge</td>
                                <td class="text-right">{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}} {{orderdetails.shipping_cost}}</td>
                            </tr>
                            <tr *ngIf="CONFIG.title !== 'Tams'">
                                <td>{{orderdetails.handling_display}}</td>
                                <td class="text-right">{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}} {{orderdetails.packing_cost}}</td>
                            </tr>
                            <tr *ngIf="orderdetails.discount_display !== ''">
                                <td>{{orderdetails.discount_display}}</td>
                                <td class="text-right">- {{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{orderdetails.discount_amount}}
                                </td>
                            </tr>
                            <tr>
                                <td>Tax</td>
                                <td class="text-right">{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}} {{orderdetails.tax}}</td>
                            </tr>
                            <tr>
                                <td><strong>Total</strong></td>
                                <td class="text-right"><strong>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}
                                        {{orderdetails.order_total_amount}}</strong>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div>
        <button id="launch-model1" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#statusModel1">
        Launch demo modal
    </button>
    <div class="modal fade" data-backdrop="true" id="statusModel1" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document"
        style="max-width: 640px !important;">
        <div class="modal-content status-model-container" style="width: max-content;">
            <div class="modal-body" style="border: none !important;">
                <div class="model-content m-content">
                    <button type="button" class="close" id="updateclose" data-dismiss="modal" aria-label="Close">
                        <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                    </button>
                    <div class="title-container" *ngIf="selectedPackageProduct">
                        <div class="m-title">{{selectedPackageProduct.product_name | titlecase}}</div>
                        <!-- <div class="m-price"><i class="fa fa-rupee"></i>{{selectedPackageProduct.product.pricing.raw.price}}</div> -->
                    </div>
                
                </div>
                <div class="addon-container">
                    <div
                    *ngIf="selectedPackageProduct && selectedPackageProduct.customise_products && selectedPackageProduct.customise_products.customisedCategory.length>0">

                    <div
                    *ngFor="let tproduct of selectedPackageProduct.customise_products.customisedCategory; let i = index">
                    
                    <div class="options-title">
                       
                      <strong>  {{tproduct.title}}</strong>
                                   
                     </div>
                     
                         
                         <div style="-moz-column-count:3; /* Firefox */
                            -webkit-column-count:3; /* Safari and Chrome */
                            column-count:3;">
                
                                <div
                                    *ngFor="let vproduct of tproduct.variants; let j = index">
                    
               
                                  {{vproduct.name.includes('-')?vproduct.name.substring(0,
                                        vproduct.name.lastIndexOf('-')):vproduct.name}}{{tproduct.chargeable?'(Rs.'+vproduct.sale_price+')':''}}
                                        <!-- - <span class="rating-text"> ₹{{sproduct.sale_price}}</span> -->
                                   <br>
                    
                    
                                </div>
                    
                            </div>
                     </div></div></div>
    
                               
                    
                           
            </div>
        </div>
    </div>
    </div>
    
    </div>
    <!-- <button class="btn btn-md btn-primary col-md-2" style="margin-left: 4%;" routerLink='/add-review/{{id}}'>Write Review</button> -->
</section>
<br>


