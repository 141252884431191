import { Injectable } from '@angular/core';

import { RegistrationOfferInitiationService } from './registration-offer-initiation.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { RegistrationOfferApiService } from './registration-offer-api.service';
import { RegistrationOfferSettingsService } from './registration-offer-settings.service';
import { RegisterOfferCalculationsService } from './register-offer-calculations.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { config } from '../../configs/config';

@Injectable({
  providedIn: 'root'
})
export class RegistrationOfferOrderService {

  totalItems = 0;
  CONFIG = config;
  constructor(
    private offerOrder: RegistrationOfferInitiationService,
    private checkoutApiService: CheckoutApiService,
    private registrationOfferApiService: RegistrationOfferApiService,
    private registrationOfferSettingsService: RegistrationOfferSettingsService,
    private registerOfferCalculationsService: RegisterOfferCalculationsService,
    private localStorageService: LocalStorageService
  ) { }

  setCustomerDetails(customerObj){
    return new Promise ((resolve, reject) => {
      this.offerOrder.orderObj.customer.customer_id = customerObj.userid;
      this.offerOrder.orderObj.customer.customer_name = customerObj.userName
      this.offerOrder.orderObj.customer.customer_type = customerObj.type
      this.offerOrder.orderObj.customer.is_email_opt_in = customerObj.subscribed
      this.offerOrder.orderObj.customer.phone_number = customerObj.mobileNo
      this.offerOrder.orderObj.customer_id = customerObj.userid;
      this.offerOrder.orderObj.customer_id = customerObj.user_id;
      resolve(true);
    })
  }

  setDeliveryDetails(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_OUTLETS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let outletData = res.data[0];
            this.offerOrder.orderObj.delivery.outlet_id = outletData.id;
            this.offerOrder.orderObj.delivery.outlet_name = outletData.name;
            this.offerOrder.orderObj.delivery.outlet_latitude = outletData.latitude;
            this.offerOrder.orderObj.delivery.outlet_longitude = outletData.longitude;
            this.offerOrder.orderObj.delivery.delivery_preferred_time = "Now";
            this.offerOrder.orderObj.delivery.delivery_date = new Date().toString();
            this.offerOrder.orderObj.delivery.delivery_time = new Date().toLocaleTimeString();
            this.offerOrder.orderObj.delivery.delivery_type = "Delivery";
            resolve(true);
          }
        }
      )
    })
  }

  setShippingAddress(address){
    return new Promise((resolve, reject) => {
      this.offerOrder.shippingAddress.first_name = address.first_name,
      this.offerOrder.shippingAddress.last_name = address.last_name,
      this.offerOrder.shippingAddress.company_name = address.company_name,
      this.offerOrder.shippingAddress.entire_location = address.entire_location,
      this.offerOrder.shippingAddress.address1 = address.address1,
      this.offerOrder.shippingAddress.landmark = address.landmark,
      this.offerOrder.shippingAddress.city = address.city,
      this.offerOrder.shippingAddress.country = address.country,
      this.offerOrder.shippingAddress.country_code = address.country_code,
      this.offerOrder.shippingAddress.state = address.state,
      this.offerOrder.shippingAddress.zipcode = address.zipcode,
      this.offerOrder.shippingAddress.email = address.email,
      this.offerOrder.shippingAddress.phone_number = address.phone_number,
      this.offerOrder.shippingAddress.latitude = address.latitude,
      this.offerOrder.shippingAddress.longitude = address.longitude
      this.offerOrder.orderObj.shipping.shipping_addresses = [this.offerOrder.shippingAddress]
      this.offerOrder.orderObj.shipping.free_shipping = false;
      this.offerOrder.orderObj.shipping.shipping_address_count = 1;
    resolve(true);
    })
  }

  setRemainingShippingDetails(){
    return new Promise((resolve, reject) => {
      this.offerOrder.orderObj.shipping.items_total = this.registrationOfferApiService.registrationOffer.product_ids.length;
          this.offerOrder.orderObj.shipping.items_total_quantity = this.registrationOfferApiService.registrationOffer.product_ids.length;
          this.registrationOfferApiService.getShippingCarrier().then(
            respData1 => {
              let res1:any = respData1;
              this.offerOrder.orderObj.shipping.shipping_carrier.api_integration_id = res1.trackable_integrator_reference_id;
              this.offerOrder.orderObj.shipping.shipping_carrier.api_integration_name = res1.trackable_integrator_reference_name;
              this.offerOrder.orderObj.shipping.shipping_carrier.shipping_carrier_id = res1.id;
              this.offerOrder.orderObj.shipping.shipping_carrier.shipping_carrier_name = res1.carrier_name;
              resolve(true);
            }
          )
    })
  }
  
  setProductDetails(){
    return new Promise((resolve, reject) => {
      this.registrationOfferSettingsService.getTaxSettings().then(
        respData => {
          let res:any = respData;
          if(res){
            this.registrationOfferSettingsService.getTaxRates().then(
              respData1 => {
                let res1:any = respData1;
                let productList = [];
                this.registrationOfferSettingsService.getProductTaxList(this.registrationOfferApiService.productDetails, this.registrationOfferSettingsService.taxclasses).then(
                  respData2 => {
                    if(this.CONFIG.title!=="Deera"){
                    let productTaxList:any = respData2;
                    this.registrationOfferApiService.productDetails.forEach(cartItem => {
                        let productObj = {
                          product_id:"",
                          product_name:"",
                          sku:"",
                          quantity:0,
                          image_source:"",
                          price:0,
                          price_inc_tax:0,
                          price_ex_tax:0,
                          discount_percentage:0,
                          discount_amount:0,
                          tax_percentage:0,
                          tax_amount:0,
                          total_amount:0,
                          variant:{
                            variant_id:"",
                            variant_name:"",
                            options:{
                              option_key:"",
                              option_value:""
                            }
                          }
                        }
                        let productTaxDetails = productTaxList.find(productTax => productTax.pid === cartItem.id);
                        productObj.product_id = cartItem.id.toString();
                        productObj.product_name = cartItem.name;
                        productObj.sku = cartItem.sku;
                        productObj.quantity = 1;
                        productObj.image_source = cartItem.assets[0].image_source;
                        productObj.price = cartItem.pricing.raw.cost;
                        productObj.price_ex_tax = parseInt(cartItem.pricing.raw.cost) * 1;
                        let priceIncludingTax = (parseInt(cartItem.pricing.raw.cost) * 1 * productTaxDetails.taxrate)/100;
                        productObj.price_inc_tax = (Math.round((priceIncludingTax + Number.EPSILON) * 100) / 100) + productObj.price_ex_tax;
                        productObj.tax_percentage = productTaxDetails.taxrate;
                        let producttax = (productObj.price_ex_tax * productTaxDetails.taxrate)/100;
                        productObj.tax_amount = (Math.round((producttax + Number.EPSILON) * 100) / 100);
                        productObj.total_amount = productObj.price_ex_tax + productObj.tax_amount;
                        productList.push(productObj);
                      });

                      } else if(this.CONFIG.title==="Deera") {
                        let productTaxList:any = respData2;
                        this.registrationOfferApiService.productDetails.forEach(cartItem => {
                            let productObj = {
                              product_id:"",
                              product_name:"",
                              sku:"",
                              quantity:0,
                              image_source:"",
                              price:0,
                              price_inc_tax:0,
                              price_ex_tax:0,
                              discount_percentage:0,
                              discount_amount:0,
                              fixed_amount:0,
                              discount_value:0,
                              tax_percentage:0,
                              tax_amount:0,
                              total_amount:0,
                              variant:{
                                variant_id:"",
                                variant_name:"",
                                options:{
                                  option_key:"",
                                  option_value:""
                                }
                              }
                            }
                            let productTaxDetails = productTaxList.find(productTax => productTax.pid === cartItem.id);
                            productObj.product_id = cartItem.id.toString();
                            productObj.product_name = cartItem.name;
                            productObj.sku = cartItem.sku;
                            productObj.quantity = 1;
                            productObj.image_source = cartItem.assets[0].image_source;
                            productObj.price = cartItem.pricing.raw.cost;
                            productObj.discount_percentage = cartItem.discount_percentage;
                            productObj.discount_amount = cartItem.discount_amount;
                            productObj.fixed_amount = cartItem.fixed_amount;
                            productObj.discount_value = cartItem.discount_value;
                            productObj.price_ex_tax = parseInt(cartItem.pricing.raw.cost) * 1;
                            let priceIncludingTax = (parseInt(cartItem.pricing.raw.cost) * 1 * productTaxDetails.taxrate)/100;
                            productObj.price_inc_tax = (Math.round((priceIncludingTax + Number.EPSILON) * 100) / 100) + productObj.price_ex_tax;
                            productObj.tax_percentage = productTaxDetails.taxrate;
                            let producttax = (productObj.price_ex_tax * productTaxDetails.taxrate)/100;
                            productObj.tax_amount = (Math.round((producttax + Number.EPSILON) * 100) / 100);
                            productObj.total_amount = productObj.price_ex_tax + productObj.tax_amount;
                            productList.push(productObj);
                          });
                      }
                  }
                )
                this.offerOrder.orderObj.product_details.products = productList;
                resolve(true);
              }
            )
          }
        }
      )
    })
  }

  // CURRENCY LABELS
  setCurrencyLabels(){
    return new Promise ((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_CURRENCIES).then(
        respData => {
          let res:any = respData;
          if(res.success){
            if(res.data){
              let currencyDetails = res.data;
              this.offerOrder.orderObj.payment.order_currency_code = currencyDetails.currency_code;
              this.offerOrder.orderObj.payment.base_currency_code = currencyDetails.currency_code;
              this.offerOrder.orderObj.payment.currency_exchange_rate = parseFloat(currencyDetails.static_conversion_rate);
              resolve(true);
            }
            else{
              resolve(true);
            }
          }
        }
      )
    })
  }

  // GET ORDER STATUS VALUES
  getOrderStatus(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_ORDERSTATUS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let orderapiData:any = res.data;
            orderapiData.forEach(element => {
              if(element.system_label.toUpperCase() === 'CONFIRMED'){
                this.offerOrder.orderObj.status = element.custom_label;
                this.offerOrder.orderObj.status_id = element.id;
                this.offerOrder.new_value.status = element.custom_label;
                resolve(true)
              }
            });
          }
        }
      )
    })
  }

  setOrderDetails(){
    return new Promise((resolve, reject) => {
      // DERIVED COST
      let deliveryCostObject = {
        subtotal_ex_tax:0,
        subtotal_inc_tax:0,
        shipping_cost_ex_tax:0,
        shipping_cost_inc_tax:0,
        handling_cost_ex_tax:0,
        handling_cost_inc_tax:0,
        wrapping_cost_ex_tax:0,
        wrapping_cost_inc_tax:0,
        subtotal_tax:0,
        discount_amount:0,
        total_ex_tax:0,
        total_inc_tax:0,
      }
       // BASE COST OBJECT
       let baseObj = {
        base_shipping_cost : 0,
        shipping_cost_tax : 0,
        shipping_cost_tax_class_id : 0,
        base_handling_cost : 0,
        base_handling_cost_type : "",
        handling_cost_tax : 0,
        handling_cost_tax_class_id : 0,
        base_wrapping_cost : 0,
        wrapping_cost_tax : 0,
        wrapping_cost_tax_class_id : 0
      }
      // ORDER SUMMARY
      let orderSummary = {
        sub_total : 0,
        packing_cost : 0,
        shipping_cost : 0,
        tax : 0,
        discount_amount:0,
        order_total_amount : 0,
        handling_display:"",
        discount_display:""
      }
      // ORDER TAXES
      let orderTaxes = []
      let handlingTax = 0;
      let shippingTax = 0;
      let productTax = 0;

      this.registerOfferCalculationsService.calculateSubTotal(this.registrationOfferApiService.productDetails).then(
        respData => {
          let res:any = respData;
          deliveryCostObject.subtotal_ex_tax = res;
          orderSummary.sub_total = res;
          this.registrationOfferSettingsService.getProductTaxList( this.registrationOfferApiService.productDetails, this.registrationOfferSettingsService.taxclasses).then(
            respDatat => {
              let rest:any = respDatat;
              this.registerOfferCalculationsService.calculateProductTax(rest).then(
                respData1 => {
                  let res1:any = respData1;
                  deliveryCostObject.subtotal_inc_tax = res + res1;
                  deliveryCostObject.subtotal_tax = res1;
                  productTax = res1;
                  this.registrationOfferApiService.saveCustomerAddress(this.registrationOfferApiService.selectedAddress).then(
                    respData2 => {
                      let res2:any = respData2;
                      if(res2 === "FLATRATE"){
                        this.offerOrder.shippingObj.shipping_method.name = "Flat Rate"
                        if(this.registrationOfferApiService.flatRate.type === "PER ITEM")
                        deliveryCostObject.shipping_cost_ex_tax = this.registrationOfferApiService.flatRate.rate * this.registrationOfferApiService.productDetails.length;
                        baseObj.base_shipping_cost = deliveryCostObject.shipping_cost_ex_tax;
                        orderSummary.shipping_cost = deliveryCostObject.shipping_cost_ex_tax;
                        this.offerOrder.shippingObj.shipping_method.delivery_charge = deliveryCostObject.shipping_cost_ex_tax
                        this.registrationOfferSettingsService.getTaxSettings().then(
                          respData3 => {
                            let res3:any = respData3;
                            if(res3){
                              if(this.registrationOfferSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                this.registrationOfferSettingsService.getTaxRates().then(
                                  respData4 => {
                                    let res4:any = respData4;
                                    if(res4){
                                      let shippingCostTax = (deliveryCostObject.shipping_cost_inc_tax * this.registrationOfferSettingsService.shippingTax.rate)/100
                                      shippingCostTax = Math.round((shippingCostTax + Number.EPSILON) * 100) / 100;
                                      shippingTax = shippingCostTax;
                                      baseObj.shipping_cost_tax = shippingCostTax;
                                      baseObj.shipping_cost_tax_class_id = this.registrationOfferSettingsService.shippingTax.id;
                                      deliveryCostObject.shipping_cost_inc_tax = shippingCostTax + deliveryCostObject.shipping_cost_ex_tax;
                                      let ordertaxrates = JSON.parse(this.localStorageService.get('ORDERTAXLABELS'))
                                      let shippingObject = {
                                        tax_rate_id:"",
                                        tax_class_id:"",
                                        tax_class_name:"",
                                        type_of_tax:"",
                                        rate:0,
                                        priority:0,
                                        priority_amount:0,
                                        line_amount:0,
                                        order_product_id:0,
                                        line_item_type:""
                                      }
                                      shippingObject.tax_rate_id = ordertaxrates.rateId.toString();
                                      shippingObject.tax_class_id = this.registrationOfferSettingsService.shippingTax.id.toString();
                                      shippingObject.tax_class_name = this.registrationOfferSettingsService.shippingTax.name;
                                      shippingObject.type_of_tax = 'Manual Tax';
                                      shippingObject.rate = this.registrationOfferSettingsService.shippingTax.rate;
                                      shippingObject.priority_amount = shippingObject.line_amount = shippingCostTax;
                                      shippingObject.line_item_type = "shipping";
                                      orderTaxes.push(shippingObject)
                                    }
                                  }
                                )
                              }
                            }
                            else{
                              deliveryCostObject.shipping_cost_inc_tax = this.registrationOfferApiService.flatRate.rate * this.registrationOfferApiService.productDetails.length;
                              baseObj.base_shipping_cost = deliveryCostObject.shipping_cost_ex_tax;
                              orderSummary.shipping_cost = deliveryCostObject.shipping_cost_ex_tax;
                              this.offerOrder.shippingObj.shipping_method.delivery_charge = deliveryCostObject.shipping_cost_ex_tax;
                            }
                          }
                        )
                        
                      }
                      else if (this.registrationOfferApiService.deliverDetails.name !== ""){
                        deliveryCostObject.shipping_cost_ex_tax = deliveryCostObject.shipping_cost_inc_tax = this.registrationOfferApiService.deliverDetails.charge;
                        baseObj.base_shipping_cost = deliveryCostObject.shipping_cost_ex_tax;
                        this.offerOrder.shippingObj.shipping_method.delivery_charge = deliveryCostObject.shipping_cost_ex_tax;
                        orderSummary.shipping_cost = deliveryCostObject.shipping_cost_ex_tax;
                      }
                      this.registrationOfferSettingsService.getTaxSettings().then(
                        respData5 => {
                          let res5:any = respData5;
                          if(res5){
                            this.registrationOfferSettingsService.getTaxRates().then(
                              respData6 => {
                                let res6:any = respData6;
                                if(res6){
                                  this.registrationOfferSettingsService.getShippingSettings().then(
                                    respData7 => {
                                      let res7:any = respData7;
                                      if(res7){
                                        this.registerOfferCalculationsService.calculateHandlingFee(deliveryCostObject.subtotal_ex_tax, 0, this.registrationOfferSettingsService.initialHanglingFee.type, this.registrationOfferSettingsService.initialHanglingFee.rate).then(
                                          respData8 => {
                                            let res8:any = respData8;
                                            deliveryCostObject.handling_cost_ex_tax = res8;
                                            baseObj.base_handling_cost = res8;
                                            orderSummary.packing_cost = res8;
                                            baseObj.base_handling_cost_type = this.registrationOfferSettingsService.initialHanglingFee.type;
                                            this.registerOfferCalculationsService.calculateHandlingFeeTax(res8, this.registrationOfferSettingsService.handlingFeeTax.rate).then(
                                              respData9 => {
                                                let res9:any = respData9;
                                                deliveryCostObject.handling_cost_inc_tax = deliveryCostObject.handling_cost_ex_tax + res9;
                                                baseObj.handling_cost_tax = res9;
                                                handlingTax = res9;
                                                baseObj.handling_cost_tax_class_id = this.registrationOfferSettingsService.handlingFeeTax.id;
                                                // HANDLING FEE
                                                let handligFeeObj = {
                                                  tax_rate_id:"",
                                                  tax_class_id:"",
                                                  tax_class_name:"",
                                                  type_of_tax:"",
                                                  rate:0,
                                                  priority:0,
                                                  priority_amount:0,
                                                  line_amount:0,
                                                  order_product_id:0,
                                                  line_item_type:""
                                                }
                                                let ordertaxrates = JSON.parse(this.localStorageService.get('ORDERTAXLABELS'))
                                                handligFeeObj.tax_rate_id = ordertaxrates.rateId.toString();
                                                handligFeeObj.tax_class_id = this.registrationOfferSettingsService.handlingFeeTax.id.toString();
                                                handligFeeObj.tax_class_name = this.registrationOfferSettingsService.handlingFeeTax.name;
                                                handligFeeObj.type_of_tax = 'Manual Tax';
                                                handligFeeObj.rate = this.registrationOfferSettingsService.handlingFeeTax.rate;
                                                handligFeeObj.priority = 0;
                                                handligFeeObj.priority_amount = handligFeeObj.line_amount = res9;
                                                handligFeeObj.order_product_id = 0;
                                                handligFeeObj.line_item_type = "handling";
                                                deliveryCostObject.total_ex_tax = deliveryCostObject.subtotal_ex_tax + deliveryCostObject.shipping_cost_ex_tax + deliveryCostObject.handling_cost_ex_tax;
                                                deliveryCostObject.total_inc_tax = deliveryCostObject.subtotal_inc_tax + deliveryCostObject.shipping_cost_inc_tax + deliveryCostObject.handling_cost_inc_tax;
                                                orderSummary.tax = productTax + shippingTax + handlingTax;
                                                orderSummary.handling_display = this.registrationOfferSettingsService.initialHanglingFee.name;
                                                orderSummary.order_total_amount = deliveryCostObject.total_inc_tax;
                                                orderTaxes.push(handligFeeObj)
                                                this.getProductOrderTaxes().then(
                                                  respData9 => {
                                                    let res9:any = respData9;
                                                    res9.forEach(element => {
                                                      orderTaxes.push(element)
                                                    });
                                                    this.offerOrder.orderObj.order_review.order_taxes = orderTaxes;
                                                    this.offerOrder.orderObj.order_review.base_cost = baseObj;
                                                    this.offerOrder.orderObj.order_review.derived_cost = deliveryCostObject;
                                                    this.offerOrder.orderObj.order_review.order_summary = orderSummary;
                                                    resolve(true);
                                                  }
                                                )
                                                
                                              }
                                            )
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                              }
                            )
                          }
                        }
                      )
                    }
                  )
                  
                }
              )
            }
          )
        }
      )
    })

  }

  getProductOrderTaxes(){
    return new Promise((resolve, reject) => {
      // ORDER TAXES
      let ordertaxrates = JSON.parse(this.localStorageService.get('ORDERTAXLABELS'))
      let ordertaxes = [];
      // PRODUCTS RELATED
      this.registrationOfferSettingsService.getTaxSettings().then(
        respData => {
          let res:any = respData;
          if(res){
            this.registrationOfferSettingsService.getTaxRates().then(
              respData1 => {
                let res1:any = respData1;
                if(res1){
                  this.registrationOfferSettingsService.getProductTaxList(this.registrationOfferApiService.productDetails, this.registrationOfferSettingsService.taxclasses).then(
                    respData2 => {
                      let res2:any = respData2;
                      let productTaxList = res2;
                      if(productTaxList.length > 0){
                        productTaxList.forEach(productTax => {
                          let productTaxObj = {
                            tax_rate_id:"",
                            tax_class_id:"",
                            tax_class_name:"",
                            type_of_tax:"",
                            rate:0,
                            priority:0,
                            priority_amount:0,
                            line_amount:0,
                            order_product_id:0,
                            line_item_type:""
                          }
                          productTaxObj.tax_rate_id = ordertaxrates.rateId.toString();
                          productTaxObj.tax_class_id = productTax.taxId.toString();
                          productTaxObj.tax_class_name = productTax.taxClassName;
                          productTaxObj.type_of_tax = 'Manual Tax';
                          productTaxObj.rate = productTax.taxrate;
                          productTaxObj.priority = 0;
                          productTaxObj.priority_amount = productTaxObj.line_amount = Math.round((((productTax.price * productTax.quantity * productTax.taxrate)/100) + Number.EPSILON) * 100) / 100;
                          productTaxObj.order_product_id = productTax.pid;
                          productTaxObj.line_item_type = "item";
                          ordertaxes.push(productTaxObj);
                        })
                        resolve(ordertaxes);
                      }
                    }
                  )
                }
              }
            )
          }
        }
      )
    })
  }
  
}
