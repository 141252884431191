import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { NavbarService} from '../navbar/navbar.service';
import { config } from '../../configs/config';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
})
export class BlogComponent implements OnInit {
  CONFIG = config;
  content: any;
  content1: any;
  config=config;
  data1:any = [];

  constructor(
    private localService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private navbarService: NavbarService,
  ) { }

  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.getFAQ();
  }

  getFAQ() {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {

        },
        "pagination": true,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 10
        },
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    // MAKE A SERVICE CALL HERE...
    this.appService.postApiCall(requestBody, ENDPOINTS.GET_ALL_BLOG).subscribe(
      resp => {
        if (resp.result.data[0].length !== 0) {
          this.data1 = resp.result.data[0]
          this.content = this.data1.content;
          this.content1 = this.data1.content;
          document.getElementById('blog-content').innerHTML = this.content1;
         
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          // this.alertService.error('Session Time Out! Please login Again', this.options)
          this.router.navigate([`/login-new`], { skipLocationChange: false });
        }
        // else if (err.error.message) {
        //   this.alertService.error(err.error.message, this.options)
        // }
        // else {
        //   this.alertService.error('Something bad happened. Please try again!', this.options);
        // }
      }
    )
  }


}
