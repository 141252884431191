<section>
    <div class="customer-container">
        <div *ngIf="paymentMethods.length > 0">
            <div class="row">
                <div class="col-md-2 offset-md-1">
                    <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/visa.jpg" height="50">
                </div>
                <div class="col-md-2">
                    <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/rupay.jpg" height="50">
                </div>
                <div class="col-md-2">
                    <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/mastercard.jpg" height="50">
                </div>
                <div *ngIf="config.title == 'Tams' " class="col-md-2">
                    <img src="https://d18xkwaipu1whh.cloudfront.net/CommonAsset/upi.jpg" height="50">
                </div>
            </div>
            <div *ngFor="let paymentMethod of paymentMethods;let i= index">
                <div *ngIf="(checkoutDeliveryOrderService.deliveryObj.delivery_type === 'Delivery' && paymentMethod.name !=='Pay In Store') || (checkoutDeliveryOrderService.deliveryObj.delivery_type === 'PickUp In Store' && paymentMethod.name !=='Cash On Delivery') || checkoutDeliveryOrderService.deliveryObj.delivery_type === ''">
                    <div class="form-check">
                        <input class="form-check-input" style="width:20px;height:20px"type="radio" name="paymentMethods" [id]="i" [value]="paymentMethod.settings.title" (change)="changePaymentMethod(paymentMethod, paymentMethod.name)">
                        <label class="form-check-label" for="paymentMethods1">
                            {{paymentMethod.settings.title}}
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedPaymentTitle !== ''">
                <div class="row">
                    <div class="col-md-4 offset-md-8">
                        <button id="paymentBtn" class="btn btn-block btn-primary btn-primary1" (click)="processPayment()">CONTINUE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- PAYMENT -->
<div style="display: none; width: 0px;height: 0px;">
    <form ngNoForm [action]="paymentSettingData.PaymentgatewayURL" name="payuform" method="POST" target="_self">
        <input type="hidden" name="timezone" [value]="paymentSettingData.timezone" />
        <input type="hidden" name="authenticateTransaction" [value]="paymentSettingData.authenticateTransaction" />
        <input size="50" type="text" name="txntype" value="sale"/>
        <input size="50" type="text" name="txndatetime" [(ngModel)]="formattedData" />
        <input size="50" type="text" name="hash" [(ngModel)]="hashVal"/>
        <input  type="hidden" type="text" name="currency" [value]="paymentSettingData.currency" />
        <input size="50" type="text" name="mode" [value]="paymentSettingData.mode" />
        <input size="50" type="text" name="storename" [value]="paymentSettingData.storename" />
        <!-- <input size="50" type="text" name="chargetotal" value="1.00" /> -->
        <input size="50" type="text" name="chargetotal" [value]="orderTotal" />
        <input id="" name="oid" size="20" [value]="paymentSettingData.orderId" type="text">
        <input size="50" type="text" name="phone" [value]="paymentSettingData.MobieNo" />
        <!-- <input size="50" type="text" name="firstname" value="raghyl" /> -->
        <td>
            <select name="language">
                <option value="de_DE">Deutsch</option>
                <option value="en_EN" selected>English</option>
            </select>
        </td> -->
        <input type="hidden" name="hash_algorithm" [value]="paymentSettingData.hash_algorithm"/>
        <input type="hidden" name="responseSuccessURL" [value]="paymentSettingData.responseSuccessURL"/>
        <input type="hidden" name="responseFailURL" [value]="paymentSettingData.responseFailURL"/>  
        <button id="icicibtn"  type="submit" class="btn btn-danger">Submit</button>
    </form>
</div>
