import { Component, OnInit, Input,ViewChild,ElementRef,TemplateRef  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from '../../../../configs/config';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { LocalStorageService } from '../../../auth-service/local-storage.service';
import { ENDPOINTS, ORG_DETAILS } from '../../../app.config';
import { CheckoutApiService } from '../../../checkout-new/checkout-api.service';
import { CheckoutLocalStorageService } from '../../../checkout-new/checkout-local-storage.service';
import { AlertsService } from '../../../shared/alerts/alerts.service';
import { NgbModal,NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { CustomerLoginStorageService } from '../../../checkout-new/checkout-login/customer-login-storage.service';
import { AppService } from '../../../app.service';
import {CartService} from '../../../cart-page/cart-page.service';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',

})
export class ProductBoxOneComponent implements OnInit {
  @ViewChild('closeModal') private closeModal: ElementRef;
  @ViewChild("content") modalContent: TemplateRef<any>;
  
  modalReference: NgbModalRef;
  CONFIG = config;
  memberId:any = "";
  constructor(
    private route: ActivatedRoute,
    public appService:AppService,
    private cartService : CartService,
    private router: Router,
    private formBuilder: FormBuilder,
    private localService: LocalStorageService,
    private checkoutApiService: CheckoutApiService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
    private alertService: AlertsService,
    private modal: NgbModal,
    private customerLoginStorageService: CustomerLoginStorageService,
  ) { }
  @Input() product: any;
  @Input() index:any;
  enquiryForm:FormGroup;
  validateForm:boolean = false;
  companyName;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  public cartData=[];
  showAlerts = false;
  public priceStrikeOutInHomeScreen;
  public showPricekey;
  public strikeOutPriceKey;

  ngOnInit(): void {
    this.cartService.updateCartItem(this.appService.cartProducts.results)
    this.enquiryForm = new FormGroup({
      name: this.formBuilder.control('', [Validators.required,Validators.maxLength(80)]),
      email: this.formBuilder.control('', [Validators.required,Validators.maxLength(50), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      phone_number: this.formBuilder.control('', [Validators.required, Validators.maxLength(10), Validators.pattern("^[+]*[(]{0,1}[0-9]{10}[)]{0,1}[-\s\./0-9]*$")]),
      category: this.formBuilder.control('', [Validators.required]),
      message: this.formBuilder.control('', [Validators.required,Validators.maxLength(600)])
    })

    this.priceStrikeOutInHomeScreen = this.localService.get('price_strikeout_in_home_screen');
    this.showPricekey = this.localService.get('show_price_key');
    this.strikeOutPriceKey = this.localService.get('strike_out_price_key');
  }

  get f() { return this.enquiryForm.controls; }

  sendProductDetails(id){
    if(this.CONFIG.title === "Aiema"){
      this.router.navigate(['/company-details1',id], { relativeTo:this.route,  skipLocationChange: false })
    }
    else{
      this.router.navigate(['/product-details',id], { relativeTo:this.route,  skipLocationChange: false })
    }
    
  }

  saveForm() {  
    
    if(this.enquiryForm.controls.name.value === ""){
      this.validateForm = true;
      // this.enquiryForm.controls['name'].setErrors({'incorrect':true});
    } else if (this.enquiryForm.controls.message.value === ""){
  
      this.validateForm = true;
    } else if(this.enquiryForm.controls.email.value === ""){
  
      this.validateForm = true;
    } else if(this.enquiryForm.controls.phone_number.value === ""){
  
      this.validateForm = true;
    }
   
    if (this.enquiryForm.valid) {
      this.validateForm = false;
      var formData = {}
      var formDataJson;
      let session_id = this.localService.get("session_id");

      
    
  // let _key = enc.Utf8.parse("@NcRfUjXn2r5u7x!A%D*G-KaPdSgVkYp");
  // let _iv =  enc.Utf8.parse('\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f');
  // let encrypted =AES.encrypt (
  // JSON.stringify({
  //   "domain_name": ORG_DETAILS.DOMAIN_NAME,
  //   "user_id": ORG_DETAILS.USER_ID,
  //   "timestamp": new Date().getTime(),
  //   "payload": {
  //     "order_creation":{  
  //     "name":this.enquiryForm.value.name,
  //     "email":this.enquiryForm.value.email,
  //     "phone_number":this.enquiryForm.value.phone_number,
  //     "category":this.enquiryForm.value.category,
  //     "message":this.enquiryForm.value.message,
  //     "member_id":this.memberId,
  //     "status":"New",
  //     "system_label":"New"
  //   }  
  //   },
  //   "extras": {
  //     "find": {
  //       "id": ""
  //     }
  //   }
  // }), _key, {
  //     iv: _iv,
  //     format: crypto.format.Hex,
  //     mode: crypto.mode.CTR,
  //     padding: crypto.pad.NoPadding
  // }).toString();
  // let requestData = {
  //   payload: encrypted
  // }
 
  let enquiry = {
    "name":this.enquiryForm.value.name,
    "email":this.enquiryForm.value.email,
    "phone_number":this.enquiryForm.value.phone_number,
    "category":this.enquiryForm.value.category,
    "message":this.enquiryForm.value.message,
    "member_id":this.memberId,
    "status":"New",
    "system_label":"New",
    "companyName":this.companyName,
  }
  let requestData = {
    domain_name: ORG_DETAILS.DOMAIN_NAME,
    user_id: ORG_DETAILS.USER_ID,
    payload: {
      order_creation: enquiry
    },
    extras: {
      find: {
        id: ""
      }
    }
  }

  // let requestData = {
  //   payload: encrypted
  // }
  this.checkoutApiService.checkoutAPICall(ENDPOINTS.ORDER_UPDATION, requestData).then(
    respData => {
      let res:any = respData;
      if(res.success){
        console.log("111");
        let OrderId = res.data[0].id;
        // this.closeModal.nativeElement.click();   
        this.modal.dismissAll();
        this.checkoutLocalStorageService.setOrderId({orderId:OrderId});
        this.alertService.success('Request Submitted', this.options);
        this.enquiryForm.reset({  name:"",
        email:"",
        phone_number:"",
        category:"",
        message:"",
        })
      }
    }
  )
    }
   
      // formData["name"] = this.enquiryForm.value.name;
      // formData["email"] = this.enquiryForm.value.email;
      // formData["phone_number"] = this.enquiryForm.value.phone_number;
      // formData["category"] = this.enquiryForm.value.category;
      // formData["message"] = this.enquiryForm.value.message;
      // formData["member_id"] = this.memberId;
      // formData['status'] = 'New'
      // formDataJson = {
      //   domain_name: ORG_DETAILS.DOMAIN_NAME,
      //   user_id: ORG_DETAILS.USER_ID,
      //   payload: {
      //     enquiry_creation: formData
      //   }
      // }
      // MAKE A SERVICE CALL HERE...
      // this.appService.postApiCall(formDataJson, ENDPOINTS.ADD_ENQUIRY).subscribe(
      //   resp => {
      //     if (resp) {
      //       if (resp.success === 1 && resp.status_code === 200) {
      //         this.alertService.success('Request Received', this.options);
      //         window.scrollTo({top: 0, behavior: 'smooth'});

      //       }
      //       else if (resp.success === 0) {
      //         if (resp.message) {
      //           this.alertService.error(resp.message, this.options);
      //           window.scrollTo({top: 0, behavior: 'smooth'});
      //         }
      //       }
      //       else if (resp.message && resp.status_code !== 200) {
      //         this.alertService.error(resp.message, this.options);
      //         window.scrollTo({top: 0, behavior: 'smooth'});
      //       }
      //       else {
      //         this.alertService.error('Something bad happened. Please try again!', this.options);
      //         window.scrollTo({top: 0, behavior: 'smooth'});
      //       }
      //     }
      //   },
      //   err => {
      //     if (err.error.statusCode === 403) {
      //       this.alertService.error('Session Time Out! Please login Again', this.options)
      //       this.router.navigate([`/login-new`], { skipLocationChange: false });
      //     }
      //     else if (err.error.message) {
      //       this.alertService.error(err.error.message, this.options)
      //       window.scrollTo({top: 0, behavior: 'smooth'});
      //     }
      //     else if (err.error.error.message) {
      //       this.alertService.error(err.error.error.message, this.options)
      //       window.scrollTo({top: 0, behavior: 'smooth'});
      //     }
      //     else {
      //       this.alertService.error('Something bad happened. Please try again!', this.options);
      //       window.scrollTo({top: 0, behavior: 'smooth'});
      //     }
      //   }
      // )
      //   ;
    
  }

  submitEnquiry(product){
    // console.log("this.memberId"+this.memberId);
    // this.enquiryForm.controls['category'].setValue(this.product.categories[0].categories)
    // document.getElementById('launch-model').click();

    let userId = this.localService.get('UserId');
    let aiemaUserName = this.localService.get('AiemaUserName');
    this.memberId = product.memberId;
    this.companyName = product.name;
    if(userId){
      this.enquiryForm.reset({name:"", message:"",})
      // this.enquiryForm.controls['name'].setValue(aiemaUserName)
      this.enquiryForm.controls['email'].setValue(this.customerLoginStorageService.getCustomerData().email)
      this.enquiryForm.controls['phone_number'].setValue(this.customerLoginStorageService.getCustomerData().mobileNo)
      this.enquiryForm.controls['category'].setValue(product.categories[0].categories)
      // document.getElementById('launch-model').click();
      this.modal.open(this.modalContent).result.then((result) => {
      });
    } else {
      this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
    }
  }
  
  productDetails(id){
    if(this.CONFIG.title === 'Aiema'){
      this.router.navigate(['/company-details1',id], { relativeTo:this.route,  skipLocationChange: false })
    }
    else{
      this.router.navigate(['/product-details',id], { relativeTo:this.route,  skipLocationChange: false })
    }
  }

  addToCart(item)
{
  this.cartService.addToCart(item);
  for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
    this.cartData.push(this.appService.cartProducts.results[i].product_id);
  }
}

  increaseItem(index)
{
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0
   
  let increment;
  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
    
  })

  
  if(this.CONFIG.name==='ShubhCards')
  {
      
    tempArray[index].quantity = (Number(tempArray[index].quantity) + tempArray[index].product.pricing.raw.incrementvalue)
  }
  else{
    if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  { 
    this.showAlerts = true;
    window.scrollTo(0,0);
    this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    return
  }
    tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
  }
 
  //tempArray[index].quantity = (Number(tempArray[index].quantity) + 2)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}

  decreaseItem(index)
{
  let tempArray = this.appService.cartProducts.results;
  if(this.CONFIG.name==='ShubhCards')
  {
    //let tempArray = this.appService.cartProducts.results;
    if(this.appService.cartProducts.results[index].quantity - tempArray[index].product.pricing.raw.decrementvalue < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.cartData = [];
        for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
          this.cartData.push(this.appService.cartProducts.results[i].product_id);
        }
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        this.showAlerts = true;
        return
        
    }
    
    tempArray[index].quantity = (Number(tempArray[index].quantity) - tempArray[index].product.pricing.raw.decrementvalue)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.updateCartItem(this.appService.cartProducts.results);  
    
  }
  else{
    if(this.appService.cartProducts.results[index].quantity ===1)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.cartData = [];
        for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
          this.cartData.push(this.appService.cartProducts.results[i].product_id);
        }
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        return
        
    }
    
    tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
  }
 
}

  submit(c){ c('close modal'); }

 
}
