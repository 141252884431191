import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import {config} from '../../../configs/config';
import { LocalStorageService } from '../../auth-service/local-storage.service';
// import { MyOrdersService } from '../../my-orders.service';
import { NavbarService} from '../../navbar/navbar.service';
import * as moment from 'moment';
import { CustomerLoginStorageService } from '../../checkout-new/checkout-login/customer-login-storage.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { element } from 'protractor';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html'
})
export class ContactUsComponent implements OnInit {
  config = config;
  storename=ORG_DETAILS.NAME;

  contactUsForm : FormGroup;
  show : boolean = false;
  otherQuery : boolean = false;
  writeQuery : boolean = false;
  loggedOut : boolean = false;
  showalertmsg : boolean = false;
  orderQuery : boolean = false;

  var = '';
  order_id = '';
  customer_data;
  customer_id;
  customer_email = '';
  validateForm : boolean = false;
  query = '';
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  contactEmail = "";
  contactPhone = "";
  constructor(
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private navbarService: NavbarService,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private customerLoginStorageService:CustomerLoginStorageService
    ) { }

    ngOnInit(): void {
      window.scrollTo(0,0)
      this.getContacts();
      this.navbarService.updateAdminFormEvent('clearSearchTerm');

      this.contactUsForm = new FormGroup({
          queries: this.formBuilder.control('', [Validators.minLength(3),Validators.required])
      })
      this.customer_data = JSON.parse(this.localStorageService.get('CustomerData'));
      this.customer_id = this.customer_data != undefined ? this.customer_data.userid : '';
      if(this.customer_id == undefined || this.customer_id == '' || this.customer_id == null){
        this.loggedOut = true;
      }
      else{
        this.loggedOut = false;
      }
    }

  title = 'app-pagination';
  data: any = [];
  total_count: any;
  activePage = 0;

  getOrderDetails(e) {
    this.router.navigate(['my-order-details', e.target.id]);
  }

  
  showOrder()
  {
    let userId = this.localStorageService.get('UserId');
    if(userId){
      this.router.navigate(['/my-orders'])
    } else {
      this.router.navigate(['/login-new'], { queryParams: { url:'my-orders', },relativeTo:this.route,  skipLocationChange: false })
    }
  }

  showOtherQuery()
  {
    this.contactUsForm.reset(
      {
        queries : ""
      }
    )
    this.validateForm = false;
    this.order_id = ''
    this.otherQuery = true;
    this.orderQuery = false;
    this.show = false;
  }

  WriteQuery(){
    this.writeQuery = true;
  }
  setOrderId(i){
    this.order_id = i
  }
  removeOrderId(){
    this.order_id = ''
  }
  showOrderQuery()
  {
    if(!this.loggedOut){
      this.contactUsForm.reset(
        {
          queries : ""
        }
      )
    this.validateForm = false;
    this.show = true;
    this.orderQuery = true;
    this.otherQuery = false;
    this.writeQuery = false;
    if(this.customer_id != undefined || this.customer_id != ''){
      this.getAllOrders(1)
    }
  }
  }

  getAllOrders(i) {
    let requestBody = {
      domain_name: ORG_DETAILS.DOMAIN_NAME,
      user_id: ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "customer_id": this.customer_id
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 5,
          "pageSize": i
        },
        "sorting": true,
        "sortingDetails": {
          "id":-1
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.GETALL_ORDERS).subscribe(
      resp => {
        var elem = resp.result.data;
        this.total_count=resp.result.total_count
        this.data=[]
        for (var i = 0; i < elem.length; i++) {
          if(elem[i].system_label.toUpperCase() !== "PENDING"){
            elem[i].created_on = moment(elem[i].created_on).format('DD MMM, YYYY');
            this.data.push(elem[i])
          }
        }
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }

  displayActivePage(activePageNumber: number): void {
    this.activePage = activePageNumber;
    this.getAllOrders(this.activePage)
  }

  cancelData(){
    this.otherQuery = false;
    this.contactUsForm.reset( 
       {        queries : ""      }    )

  }

  get f(){
    return this.contactUsForm.controls
    }

  sendData(){
    if(this.contactUsForm.valid){
      // document.getElementById('cancel_btn').setAttribute('data-dismiss','modal');
    
    this.validateForm = false;
    let formData = {}

    formData['customer_id'] = this.customer_data.userid;
    formData['customer_name'] = this.customer_data.userName;
    formData['phone_number'] = this.customer_data.mobileNo;
    if(this.order_id != ''){
      formData['order_id'] = this.order_id;
    }
    formData['customer_email'] = this.customer_data.email;
    if(this.data.length){
      formData['order_summary'] = this.data[0].order_review.order_summary
    }
    formData['queries'] = this.contactUsForm.value.queries
    
      let FormData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        payload: {​​​​​​​​
          create_contactus :formData
        ​​​​​​​​}​​​​​​​​
    }

      this.appService.postApiCall(FormData, ENDPOINTS.CREATE_CONTACT_US).subscribe(resp => {
        if (resp) {
          if (resp.success == 1) {
            document.getElementById("close_btn").click()
            window.scrollTo(0,0)
            this.contactUsForm.reset(
              {
                queries : ""
              }
            )
            this.alertService.success("Query send Successfully", this.options);
            this.otherQuery = false;
          
            // this.router.navigate(['/my-order-details',this.parameters['id']], {state: { result: "Thanks for the rating" }, relativeTo:this.route,  skipLocationChange: false })
            // this.router.navigate(['/my-order-details',this.parameters['id']])
          }
          else {
            window.scrollTo(0,0)
            this.alertService.warn("Something bad happened", this.options);
            
          }
        }
      },
        err => {
          if (err.error.message) {
            window.scrollTo(0,0)
            this.alertService.error('Something bad happened. Please try again!', this.options);
          }
          else {
            window.scrollTo(0,0)
            this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }
  else{
    this.validateForm = true;
  }
  }

  getContacts(){
    let requestData = { 
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.CONTACTUS_GETALL).subscribe(
      resp => {
        var elem = resp.result.data[0];
        this.contactEmail = elem.contact_email;
        this.contactPhone = elem.contact_number;
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }
 
  
}
