import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { ProfileDetailsService } from '../profile-details/profile-details.service';
import { ENDPOINTS, ORG_DETAILS } from '../../app.config';
import { LocalStorageService } from '../../auth-service/local-storage.service';
import * as bcrypt from 'bcryptjs';
// import { LocalStorageService } from '../../auth-services/local-storage.service';
// import { AuthTokenService } from '../../auth-services/auth-token.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  validateForm: boolean = false;
  showalertmsg: boolean = true;
  noPasswd : boolean = false;

  otpLoginForm : FormGroup;
  vaidateotpLoginForm : boolean = false;

  showCurrPswd: Boolean = false;
  showNewPswd: Boolean = false;
  showconfirmPswd: Boolean = false;
  otpResendDuration : number;

  hide: boolean = false;

  passdata: any;

  Password: any;

  currentPassword : string = '';
  currentPhone : string = '';
  passcheck : boolean = false;

  formDataJson: any;
  queryParams: any;

  profileDetails:any;

  id:number;
  show : boolean = true;

  otpForm : boolean = false;

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  userRegistartionFormData: any;

  countryCodes = [
    {'name': "IN +91", 'value': "+91"}
  ]

  constructor(
    private router: Router,
    private ProfileDetailsService: ProfileDetailsService,
    private appService: AppService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    private localStorageService : LocalStorageService
    // private localService: LocalStorageService,
    // private authTokenService: AuthTokenService
  ) { }

  ngOnInit(): void {
    this.hide = false;
    this.changePasswordForm = new FormGroup({
      // current_password: this.formBuilder.control('', [Validators.required]),
      new_password: this.formBuilder.control('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      confirm_password: this.formBuilder.control('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
    })

    this.otpLoginForm = new FormGroup({
      otp: this.formBuilder.control('', [Validators.required])
    })

    // if (this.localService.get('UserName')) {
    //   this.router.navigate(['/']);
    // }
    // this.initial();

    this.id = Number(this.localStorageService.get('UserId'));

    if(this.id !== undefined && this.id !== NaN){
      this.getProfileDetails(this.id)
    }

  }

  get f() { return this.changePasswordForm.controls; }
  get f4() {
    return this.otpLoginForm.controls;
  }

  passCheck(){
    if(this.currentPassword === '' || this.currentPassword === null){
      this.noPasswd = true;
      window.scrollTo(0,0)
      this.alertService.warn("Please set password first!", this.options);
    }
  }

  showPassword(pass) {
    if (pass == "curr") {
      this.showCurrPswd = !this.showCurrPswd;
    }
    else if (pass == "new") {
      this.showNewPswd = !this.showNewPswd;
    }
    else if (pass == "confirm") {
      this.showconfirmPswd = !this.showconfirmPswd;
    }

  }


  resetPassword() {
    // console.log(this.userRegistartionForm.value);
    let formData = {}

    // formData['current_password'] = this.changePasswordForm.value.current_password;
    formData['new_password'] = this.changePasswordForm.value.new_password
    formData['confirm_password'] = this.changePasswordForm.value.confirm_password

  
    if (this.changePasswordForm.valid) {

      // if(formData['current_password'] === formData['new_password']){
      //   window.scrollTo(0,0)
      //   this.alertService.error("New password is same as current password", this.options);
      //   return
      // }
      // else if(this.currentPassword !== formData['current_password']){
      //   window.scrollTo(0,0)
      //   this.alertService.error("Current password is not matched", this.options);
      //   return
      // }
      if(formData['confirm_password'] !== formData['new_password']){
        window.scrollTo(0,0)
        this.alertService.error("Confirm password is not matched", this.options);
        return
      }
      // else if(this.currentPassword === ''){
      //   window.scrollTo(0,0)
      //   this.alertService.error("Please register account with password", this.options);
      //   return
      // }
      // console.log("VALID");
      this.validateForm = false;


      this.userRegistartionFormData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        payload: {​​​​​​​​
          password_details: {
            new_password: formData['new_password'],
            // old_password: ''
          }
          ​​​​​​​​}​​​​​​​​,
        extras: {
          find: {
            id : this.id
          },
      }
    }


 
      this.appService.postApiCall(this.userRegistartionFormData, ENDPOINTS.CHANGE_PASSWORD).subscribe(resp => {
        if (resp) {
          // console.log(resp);
          if (resp.success == 1) {
            // console.log(resp)
            this.reset()
            window.scrollTo(0,0)
            this.alertService.success(resp.message, this.options);
          }
          else {
            window.scrollTo(0,0)
            this.alertService.warn(resp.message, this.options);
            // alert(resp.message);
          }
        }
      },
        err => {
          // console.log(err);
          if (err.error.message) {
            window.scrollTo(0,0)
            this.alertService.error(err.error.message, this.options);
          }
          else {
            window.scrollTo(0,0)
            this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }
    else {
      // console.log("INVALID");
      this.validateForm = true;
    }
  }

  formCheck(){
    if (this.changePasswordForm.valid) {
      let mobileNumber = this.currentPhone


      this.validateForm = false;

      let formData = {}

    // formData['current_password'] = this.changePasswordForm.value.current_password;
    formData['new_password'] = this.changePasswordForm.value.new_password
    formData['confirm_password'] = this.changePasswordForm.value.confirm_password

    bcrypt.compare(formData['new_password'],this.currentPassword).then(result=>
      {
        if(result){
          window.scrollTo(0,0)
          this.alertService.error("New password is same as current password", this.options);
          return
        }
        else if(formData['confirm_password'] != formData['new_password']){
          window.scrollTo(0,0)
          this.alertService.error("Confirm password is not matched", this.options);
          return
        }
        else{
          this.otpForm = true;
          this.show = false;
          this.sendOtptoUser(mobileNumber);
        }
      })
      
    }
    else{
      this.validateForm = true;
    }
  }

  sendOtp() {
      let mobileNumber = this.currentPhone
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "phone_number": mobileNumber.toString()
          }
        }
      }
      // API CALL TO FIND EXISTING/NEW USER
      this.appService.postApiCall(reqData, ENDPOINTS.GETALL_USERS).subscribe(
        resp => {

          if(resp){

            if(resp.success === 1 && resp.status_code === 200){
              this.sendOtptoUser(mobileNumber);
            }
            else if(resp.success === 0){
              if(resp.message){
                window.scrollTo(0,0)
                this.alertService.error(resp.message, this.options);
              }
            }
            else if(resp.message && resp.status_code !== 200){
              window.scrollTo(0,0)
              this.alertService.error(resp.message, this.options);
            }
            else{
              window.scrollTo(0,0)
              this.alertService.error('Something bad happened. Please try again!', this.options);
            }
          }
          else{
            this.alertService.error('Something bad happened. Please try again!', this.options);
          }
          
        },
        err => {
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })
    
  }

  resendCode() {
    let mobileNumber = this.currentPhone
    this.showalertmsg = true;

    if(this.otpResendDuration === undefined)
    {
      this.sendOtptoUser(mobileNumber);
      window.scrollTo(0,0);
      this.alertService.success('OTP has been sent Successfully!', this.options);
    }
    else if(this.otpResendDuration < new Date().getTime())
    {
      this.sendOtptoUser(mobileNumber);
      window.scrollTo(0,0);
      this.alertService.success('OTP has been sent Successfully!', this.options);
    }
    else
    {
      window.scrollTo(0,0);
      this.alertService.error("OTP already sent to this number "+ mobileNumber, this.options)
    }
  }

  
  sendOtptoUser(mobileNo) {
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "organization_id": ORG_DETAILS.ORGANIZATION_ID,
      "store_id": ORG_DETAILS.STORE_ID,
      "data": {
        "otp": {
          "countrycode": "+91",
          "mobile": mobileNo,
          "otppurpose": "login"
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.SEND_OTP).subscribe(
      resp => {


        //set resend duration
        if(resp && resp.success === 1)
        {
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
        }

      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }

  OTPLogin() {
    if (this.otpLoginForm.valid) {
      this.showalertmsg = true;
      this.vaidateotpLoginForm = false;
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "organization_id": ORG_DETAILS.ORGANIZATION_ID,
        "store_id": ORG_DETAILS.STORE_ID,
        "data": {
          "otp": {
            "userotp": this.otpLoginForm.controls.otp.value,
            "mobile": this.currentPhone
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.VERIFY_OTP).subscribe(
        resp => {
          if (resp.success === 1) {
              this.resetPassword();

          }
          else {
            this.alertService.error(resp.message, this.options)
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })

    }
    else {
      this.vaidateotpLoginForm = true;
    }

  }

  getProfileDetails(id) {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "id" : Number(id)
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 10
        },
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    // MAKE A SERVICE CALL HERE...
    this.appService.postApiCall(requestBody, ENDPOINTS.GET_CUSTOMER).subscribe(
      resp => {
        if (resp) {

          this.profileDetails = resp.result.data[0];

          console.log(this.profileDetails)
          if(this.profileDetails !== undefined){
          this.currentPassword = this.profileDetails.password === undefined ? '' : this.profileDetails.password;
          this.currentPhone = this.profileDetails.phone_number === undefined ? '' : this.profileDetails.phone_number;
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          // this.alertService.error('Session Time Out! Please login Again', this.options)
          this.router.navigate([`/login-new`], { skipLocationChange: false });
        }
        else if (err.error.message) {
          // this.alertService.error(err.error.message, this.options)
        }
        else {
          // this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
  }

  reset(){
    this.changePasswordForm.reset({
      // current_password: '',
      new_password: '',
      confirm_password: '',
    })

    this.otpLoginForm.reset({
      otp: '',
    })

    this.otpResendDuration = undefined
    this.show = true;
    this.otpForm = false;
    this.noPasswd = false;
    this.vaidateotpLoginForm = false;
    this.validateForm= false;
  }
}















