<div class="aboutus-container">
    <div class="about-section paddingTB60" *ngIf="config.title == 'EarthyWorthy'">
        <div class="container">
            <div class="row">
                <div class="">
                    <div class="about-title clearfix">
                        <h2 class="aboutus-title">WELCOME TO EarthyWorthy</h2>
                        <p class="about-paddingB">
                        <div style="margin-left: 5px;" id= "aboutus-content"></div>
                          <!-- <p>Who We Are..</p>
      <br>
      <p style="font-weight: bolder;">Be worthy, think earthy !</p>
      <p>We are one amongst you, a research based sustainable brand, our vision “Unshakable Commitment to Sustainability”. </p>
      <p>Earthy worthy End to End Sustainability Program has led to creation of the greenest apparel ever made.</p>
      <p>Our mission was to make luxury affordable; we have made that possible by bringing Sustainable Fashion to you with Unique designs created by independent world class Artist.</p>
      <p>Come join our Moto “An earthyworthy Tshirt for every Person”. Earthy to wear, worthy to pay.</p> -->

                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="about-section paddingTB60" *ngIf="config.title == 'BBold'">
        <div class="container">
            <div class="row">
                <div class="">
                    <div class="about-title clearfix">
                        <h2 class="aboutus-title">WELCOME TO BBold</h2>
                        <p class="about-paddingB">
                        <div style="margin-left: 5px;" id= "aboutus-content"></div>

                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="aboutus-container">
        <div class="about-section paddingTB60" *ngIf="config.title == 'Haven'">
            <div class="container">
                <div class="row">
                    <div class="">
                        <div class="about-title clearfix">
                            <h2 class="aboutus-title">Welcome To Haven Sampoorna</h2>
                            <p class="about-paddingB">
                            <div style="margin-left: 5px;" id= "aboutus-content"></div>
                              <!-- <p>Who We Are..</p>
          <br>
          <p style="font-weight: bolder;">Be worthy, think earthy !</p>
          <p>We are one amongst you, a research based sustainable brand, our vision “Unshakable Commitment to Sustainability”. </p>
          <p>Earthy worthy End to End Sustainability Program has led to creation of the greenest apparel ever made.</p>
          <p>Our mission was to make luxury affordable; we have made that possible by bringing Sustainable Fashion to you with Unique designs created by independent world class Artist.</p>
          <p>Come join our Moto “An earthyworthy Tshirt for every Person”. Earthy to wear, worthy to pay.</p> -->

                        </div>
                    </div>
                </div>
            </div>
          </div>
 
      <div class="about-section paddingTB60" *ngIf="config.title == 'LuckMe'">
        <div class="container">
            <div class="row">
                <div class="">
                    <div class="about-title clearfix">
                        <h2 class="aboutus-title">WELCOME TO LUCKME</h2>
                        <p class="about-paddingB">
                          <h5 >Luckme Trading essintially is a ONE STOP SHOP for all your daily needs.</h5>
      <hr>
        <p>"BEST is what we do, and which is why LUCKME TRADING is where it is TODAY". The primary goal of Luckme Trading has always been to provide its customers and trusted retailers the Best Quality Product at the most Affordable price. With over 20+ years of experience in providing service to over 400+ outlets locally, Luckme trading has always been of customers choice for its timely service and customer friendly service. Luckme trading is dedicated on growing with brands we work with and is always looking for new opportunities to bring quality brands to consumers in all corners of Seychelles and neighboring islands of Mahe. </p>
      <hr>
      <h4 class="aboutus-title">Luckme Trading is part of Somas Group, as a company our goal is to provide our customers with 5-star experience and fulfil all their needs and wants. This what makes us who we are “the best.”.</h4>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="about-section paddingTB60" *ngIf="config.title == 'Tams'">
          <div class="container">
              <div class="row">
                  <div class="">
                      <div class="about-title clearfix">
                          <h2 class="aboutus-title">WELCOME TO TAMS</h2>
                          <p class="about-paddingB">
        <br>

        <div style="margin-left: 5px;" id= "aboutus-content"></div>
        <!-- <p>If you are a person who loves  idli  and concerned about the tiresome process of making idli batter at home or worried  about the purity of the batter bought from the shop, ILY IDLI FLOUR is the right product for you!</p>



      <p>The Naturally fermenting patented ILY idli flour is made with top graded , quality approved  Rice and Urad dal at a very  hygenic environment with no hand contact in the process of manufacturing. No preservatives, No   chemicals ,  No additives , No artificial colours  are added to the flour and it is not recombined in any form in the process.</p>



      <p>We have enormous satisfaction and joy in presenting  the traditional fermentation method of making Idli’s  with ILY naturally fermenting  flour with six month shelf life.</p>
                             -->
                      </div>
                  </div>
              </div>
          </div>
        </div>
  

        <div class="about-section paddingTB60" *ngIf="config.title == 'Mayan'">
            <div class="container">
                <div class="row">
                    <div class="">
                        <div class="about-title clearfix">
                            <h2 class="aboutus-title">WELCOME TO Mayan</h2>
                            <p class="about-paddingB">
          <br>

          <div style="margin-left: 5px;" id= "aboutus-content"></div>
          <!-- <p>If you are a person who loves  idli  and concerned about the tiresome process of making idli batter at home or worried  about the purity of the batter bought from the shop, ILY IDLI FLOUR is the right product for you!</p>



        <p>The Naturally fermenting patented ILY idli flour is made with top graded , quality approved  Rice and Urad dal at a very  hygenic environment with no hand contact in the process of manufacturing. No preservatives, No   chemicals ,  No additives , No artificial colours  are added to the flour and it is not recombined in any form in the process.</p>



        <p>We have enormous satisfaction and joy in presenting  the traditional fermentation method of making Idli’s  with ILY naturally fermenting  flour with six month shelf life.</p>
                               -->
                        </div>
                    </div>
                </div>
            </div>
          </div>
         
          <div class="about-section paddingTB60" *ngIf="config.title == 'Aiema'">
            <div class="container">
                <div class="row">
                    <div class="">
                        <div class="about-title clearfix">
                            <h2 class="aboutus-title">WELCOME TO AIEMA</h2>
                            <p class="about-paddingB">
                            <div style="margin-left: 5px;" id= "aboutus-content"></div>
                              <!-- <p>Who We Are..</p>
          <br>
          <p style="font-weight: bolder;">Be worthy, think earthy !</p>
          <p>We are one amongst you, a research based sustainable brand, our vision “Unshakable Commitment to Sustainability”. </p>
          <p>Earthy worthy End to End Sustainability Program has led to creation of the greenest apparel ever made.</p>
          <p>Our mission was to make luxury affordable; we have made that possible by bringing Sustainable Fashion to you with Unique designs created by independent world class Artist.</p>
          <p>Come join our Moto “An earthyworthy Tshirt for every Person”. Earthy to wear, worthy to pay.</p> -->

                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="about-section paddingTB60" *ngIf="config.title == 'BombayHardware'">
            <div class="container">
                <div class="row">
                    <div class="">
                        <div class="about-title clearfix">
                            <h2 class="aboutus-title">WELCOME TO Bombay Hardware</h2>
                            <p class="about-paddingB">
                            <div style="margin-left: 5px;" id= "aboutus-content"></div>
                              <!-- <p>Who We Are..</p>
          <br>
          <p style="font-weight: bolder;">Be worthy, think earthy !</p>
          <p>We are one amongst you, a research based sustainable brand, our vision “Unshakable Commitment to Sustainability”. </p>
          <p>Earthy worthy End to End Sustainability Program has led to creation of the greenest apparel ever made.</p>
          <p>Our mission was to make luxury affordable; we have made that possible by bringing Sustainable Fashion to you with Unique designs created by independent world class Artist.</p>
          <p>Come join our Moto “An earthyworthy Tshirt for every Person”. Earthy to wear, worthy to pay.</p> -->

                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="about-section paddingTB60" *ngIf="config.title == 'ShubhCards'">
            <div class="container">
                <div class="row">
                    <div class="">
                        <div class="about-title clearfix">
                            <h2 class="aboutus-title">WELCOME TO SHUBHCARDS</h2>
                            <p class="about-paddingB">
                            <div style="margin-left: 5px;" id= "aboutus-content"></div>
                              <!-- <p>Who We Are..</p>
          <br>
          <p style="font-weight: bolder;">Be worthy, think earthy !</p>
          <p>We are one amongst you, a research based sustainable brand, our vision “Unshakable Commitment to Sustainability”. </p>
          <p>Earthy worthy End to End Sustainability Program has led to creation of the greenest apparel ever made.</p>
          <p>Our mission was to make luxury affordable; we have made that possible by bringing Sustainable Fashion to you with Unique designs created by independent world class Artist.</p>
          <p>Come join our Moto “An earthyworthy Tshirt for every Person”. Earthy to wear, worthy to pay.</p> -->

                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="about-section paddingTB60" *ngIf="config.title == 'Nesma'">
            <div class="container">
                <div class="row">
                    <div class="">
                        <div class="about-title clearfix">
                            <h2 class="aboutus-title" style="font-size: 25px;">WELCOME TO IMA IDLI IN KILO</h2>
                            <p class="about-paddingB">
                            <div style="margin-left: 5px;" id= "aboutus-content"></div>
                              <!-- <p>Who We Are..</p>
          <br>
          <p style="font-weight: bolder;">Be worthy, think earthy !</p>
          <p>We are one amongst you, a research based sustainable brand, our vision “Unshakable Commitment to Sustainability”. </p>
          <p>Earthy worthy End to End Sustainability Program has led to creation of the greenest apparel ever made.</p>
          <p>Our mission was to make luxury affordable; we have made that possible by bringing Sustainable Fashion to you with Unique designs created by independent world class Artist.</p>
          <p>Come join our Moto “An earthyworthy Tshirt for every Person”. Earthy to wear, worthy to pay.</p> -->

                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="about-section paddingTB60" *ngIf="config.title == 'Deera'">
            <div class="container">
                <div class="row">
                    <div class="">
                        <div class="about-title clearfix">
                            <h2 class="aboutus-title">WELCOME TO DEERA</h2>
                            <p class="about-paddingB">
                            <div style="margin-left: 5px;" id= "aboutus-content"></div>
                              <!-- <p>Who We Are..</p>
          <br>
          <p style="font-weight: bolder;">Be worthy, think earthy !</p>
          <p>We are one amongst you, a research based sustainable brand, our vision “Unshakable Commitment to Sustainability”. </p>
          <p>Earthy worthy End to End Sustainability Program has led to creation of the greenest apparel ever made.</p>
          <p>Our mission was to make luxury affordable; we have made that possible by bringing Sustainable Fashion to you with Unique designs created by independent world class Artist.</p>
          <p>Come join our Moto “An earthyworthy Tshirt for every Person”. Earthy to wear, worthy to pay.</p> -->

                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="about-section paddingTB60" *ngIf="config.title == 'Dosapark'">
            <div class="container">
                <div class="row">
                    <div class="">
                        <div class="about-title clearfix">
                            <h2 class="aboutus-title">WELCOME TO Dosapark</h2>
                            <p class="about-paddingB">
                            <div style="margin-left: 5px;" id= "aboutus-content"></div>
                              <!-- <p>Who We Are..</p>
          <br>
          <p style="font-weight: bolder;">Be worthy, think earthy !</p>
          <p>We are one amongst you, a research based sustainable brand, our vision “Unshakable Commitment to Sustainability”. </p>
          <p>Earthy worthy End to End Sustainability Program has led to creation of the greenest apparel ever made.</p>
          <p>Our mission was to make luxury affordable; we have made that possible by bringing Sustainable Fashion to you with Unique designs created by independent world class Artist.</p>
          <p>Come join our Moto “An earthyworthy Tshirt for every Person”. Earthy to wear, worthy to pay.</p> -->

                        </div>
                    </div>
                </div>
            </div>
          </div>

</div>
