import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';


import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { AppService } from '../app.service';

@Component({
  selector: 'app-comapny-product-details',
  templateUrl: './comapny-product-details.component.html'
})
export class ComapnyProductDetailsComponent implements OnInit {

  productId:any;
  product:any;
  owner:any = "";
  establised:any = "";
  enquiryForm:FormGroup;
  validateForm:boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public appService: AppService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      let queryParams = this.route.snapshot;
      this.productId = queryParams.params['id'];
      this.getProductDetails(this.productId)
    })
    this.enquiryForm = new FormGroup({
      name: this.formBuilder.control('', [Validators.required, Validators.maxLength(80), Validators.pattern("^[a-zA-Z][a-z A-Z]*$")]),
      email_address: this.formBuilder.control('', [Validators.maxLength(40), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      mobile_number: this.formBuilder.control('', [Validators.required, Validators.maxLength(15), Validators.pattern("^[+]*[(]{0,1}[0-9]{10}[)]{0,1}[-\s\./0-9]*$")]),
      category: this.formBuilder.control('', []),
      message: this.formBuilder.control('', [])
    })
  }

  get f() { return this.enquiryForm.controls; }

  getProductDetails(id){
    let requestData ={
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "id": Number(id)
        },
        "pagination": false,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 2
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "",
          "sortorder": 1
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.PRODUCT_DETAILS).subscribe(
      resp => {
        if (resp.success == 1){
          if(resp.result.data.length > 0){
            window.scrollTo(0,0)
            this.product = resp.result.data[0]
            this.owner = this.product.company_profile['ceo/md/owner name']
            this.establised = this.product.company_profile['established_since']
          }
        }
      })
  }

  submitEnquiry(){
    this.enquiryForm.controls['category'].setValue(this.product.categories[0].categories)
    document.getElementById('launch-model').click();
  }

}
