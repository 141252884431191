    <!-- <app-alerts *ngIf="showalertmsg"></app-alerts>     -->
    <div class="box1">
    <!-- nav options -->
    <div class="row">
        <div class="col-md-10">
            <ul class="nav nav-tabs " id="pills-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true" ><h6>Profile Details</h6></a> </li>
                <li class="nav-item"> <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" ><h6>Manage Address</h6></a> </li>
                <li class="nav-item"> <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-password" role="tab" aria-controls="pills-password" aria-selected="false" ><h6>Change Password</h6></a> </li>
            </ul>
        </div>
        <div class="col-md-2">
            <a style="float: right;padding-top: 10px;">Create a list</a>
        </div>
    </div>

 <!-- content-->
    <br>
    <div class="tab-content" id="pills-tabContent p-3">
        <!-- 1st card -->
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <app-profile-details></app-profile-details>
        </div>
</div>
</div>
