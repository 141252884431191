
<app-alerts *ngIf="showalertmsg"></app-alerts>

    
<div class="box">

<section>
  <!-- <button class="btn btn-md btn-primary col-md-1" style="float: right;" routerLink='/my-order-details/{{id}}'>Back</button> -->
 <button class="btn btn-md btn-primary col-md-1" style="float: right;" (click)="back(id)">Back</button>


        <h2 class="heading">Add a review</h2>
        <form [formGroup]="addReviewForm" novalidate>
            <div class="form-group">
                <label class="form-label">Overall Rating</label>
                <div  class="input-group col-sm-12">
                <div class="rating">
                  <input type="radio" id="star5" formControlName="rating" value="5"  [class.is-invalid]="validateForm && f.rating.invalid" /><label for="star5"  title="Excellent">5 stars</label>
                  <input type="radio" id="star4" formControlName="rating" value="4" [class.is-invalid]="validateForm && f.rating.invalid" /><label for="star4" title="Good">4 stars</label>
                  <input type="radio" id="star3" formControlName="rating" value="3"  [class.is-invalid]="validateForm && f.rating.invalid" /><label for="star3" title="Not bad">3 stars</label>
                  <input type="radio" id="star2" formControlName="rating" value="2"  [class.is-invalid]="validateForm && f.rating.invalid" /><label for="star2" title="Bad">2 stars</label>
                  <input type="radio" id="star1" formControlName="rating" value="1" [class.is-invalid]="validateForm && f.rating.invalid"  /><label for="star1" title="Worst">1 star</label>
               
                  <div class="invalid-feedback" *ngIf="f.rating.errors">
                    <div *ngIf="f.rating.errors.required">
                      Rating required.
                    </div>
                  </div>
                </div>
               
                </div>
            </div>
            
          <div class="form-group">
            <label class="form-label">Add a headline</label>
            <div class="input-group col-sm-12">
            <input type="text" class="form-control" formControlName="title" placeholder="" [class.is-invalid]="validateForm && f.title.invalid">
            
            <div class="invalid-feedback" *ngIf="f.title.errors">
              <div *ngIf="f.title.errors.minlength">
                Add a headline is too short.
              </div>
              <div *ngIf="f.title.errors.maxlength">
                Add a headline can't be more than 120 characters long.
              </div>
              <div *ngIf="f.title.errors.required">
                Title required.
              </div>
            </div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Add a written review</label>
            <div class="input-group col-sm-12">

            <textarea class="form-control" formControlName="comments" placeholder=""  [class.is-invalid]="f.comments.invalid"></textarea>
            <div class="invalid-feedback" *ngIf="f.comments.errors">
              <div *ngIf="f.comments.errors.minlength">
                Add a written review is too short.
              </div>
              <div *ngIf="f.comments.errors.maxlength">
                Add a written review can't be more than 2000 characters long.
              </div>
            </div>
            </div>
          </div>

          <button class="btn btn-md btn-primary col-md-2" style="float: right;"  (click)="addReview()">Submit</button>
        </form>
        <br>
        <br>

