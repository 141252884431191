<div class="homepage-banner-slider rounder-corner-nav rotate-dot">
    <section id="inqCaro" class="carousel slide inq-Caro-st" data-ride="carousel">
        <div class="carousel-inner">
            <!-- <div class="carousel-item active" *ngFor="let banner of carouselData">
                <img *ngIf="config.title !== 'Aiema'" class="d-block w-100" src="{{banner.image_source}}" alt="{{banner.alt_text}}">
                <img *ngIf="config.title === 'Aiema'" class="d-block w-100" style="height: 24rem;" src="{{banner.image_source}}" alt="{{banner.alt_text}}">
                <div class="inq-sli-btn-st">
                    <div [innerHTML]="banner.heading"></div> -->
                    <!-- <h3>Good Food <br> Good Feelings</h3>
                    <p>with <span>Haven Sampoorna</span></p> -->
                    <!-- <button *ngIf = "banner.button_text" class="btn btn-primary inq-btn-st"
                        routerLink="{{banner.link}}">{{banner.button_text}}</button>
                </div>
            </div> -->
            <div class="carousel-item" [class.active]="i==0"  *ngFor="let banner of carouselData; let i=index;">
                <!-- <img *ngIf="config.title !== 'Aiema'" class="d-block w-100" src="{{banner.image_source}}" alt="{{banner.alt_text}}"> -->
                <img *ngIf="config.title === 'Aiema'" style="height: 24rem;" [src]="banner.image_source" class="d-block w-100" alt="...">
                <!-- <img *ngIf="config.title !== 'Aiema'" style="height: 50vh;width: 100%;background: url(banner.image_source) no-repeat;background-size: cover;color: white;" src="{{banner.image_source}}" alt="{{banner.alt_text}}"> -->
                <img *ngIf="config.title !== 'Aiema' && config.title !== 'Deera'" style="height: 70vh; border: 0px solid white;width: 100%;background: url(banner.image_source) no-repeat;background-size: cover;color: white;" src="{{banner.image_source}}" alt="{{banner.alt_text}}">
                <img *ngIf="config.title === 'Deera'" style="height: 75vh; border: 0px solid white;width: 100%;background: url(banner.image_source) no-repeat;background-size: cover;color: white;" src="{{banner.image_source}}" alt="{{banner.alt_text}}">
                <!-- <div class="text-center text-primary" style="margin-top:21px;"> -->
                    <!-- <h4>{{banner.alt_text}}</h4> -->
                <!-- </div> -->
              </div>
        </div>
        <a class="carousel-control-prev" href="#inqCaro" role="button" data-slide="prev">
            <!-- <span aria-hidden="true"> <i class=" fa fa-chevron-left fa-2x"></i></span> -->
            <!--class="carousel-control-prev-icon"-->
            <span style="background-color: #E48C8C; border-radius: 50%; padding: 10px 15px; opacity: 1 !important;"><img src="../../assets/images/vendor/previous.png"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#inqCaro" role="button" data-slide="next">
            <!-- <span aria-hidden="true"><i class=" fa fa-chevron-right fa-2x"></i></span> -->
            <span style="background-color: #E48C8C; border-radius: 50%; padding: 10px 15px; opacity: 1 !important;"><img src="../../assets/images/vendor/next.png"></span>
            <!--class="carousel-control-next-icon"-->
            <span class="sr-only">Next</span>
        </a>
    </section>
</div>