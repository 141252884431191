import { Injectable } from '@angular/core';

import { CartService } from '../../cart-page/cart-page.service';
import { CheckoutApiService } from '../checkout-api.service';
import { ENDPOINTS } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class CheckoutOrderdetailsService {

  constructor(
    private cartService: CartService,
    private checkoutApiService: CheckoutApiService,
  ) { }

  updateCart(cartItems){
    this.cartService.updateCartItem(cartItems);
  }

  getCheckoutSettings(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.CHECKOUT_SETTINGS).then(
        respData => {
          let res:any = respData;
          resolve(res.data[0].allow_comments)
        }
      )
    })
  }

  
}
 