<!DOCTYPE html>
<html>
  <head><title>privacy policy</title></head>
  <body>
    <div class="paddingTB60">
      <div class="container-fluid text-center">
          
          <h3>Import</h3>
          <hr>
        </div>
    
    <div style="margin-left: 5px;" id = "privacy-content"></div>

    <div class="container-fluid text-center">
          
        <h3>Whole Sale</h3>
        <hr>
      </div>
  
  <div style="margin-left: 5px;" id = "privacy-content"></div>
  <div class="container-fluid text-center">
          
    <h3>Distribution</h3>
    <hr>
  </div>

<div style="margin-left: 5px;" id = "privacy-content"></div>
    </div>
  </body>
</html>