import { Component, OnInit, Input, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from 'src/configs/config';

@Component({
  selector: 'app-featured-collections',
  templateUrl: './featured-collections.component.html',

})
export class FeaturedCollectionsComponent implements OnInit {
  config = config;
  @Input() collections: any;
  @Input() collections1: any;
  @Input() collections2: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }
  getClass(index) {
    if (config.title == 'ShubhCards' || config.title == 'Deera') {
      return ((index === 0 || index === 1)) ? 'col-md-5 ' : 'col-md-5';

    }
    else if (config.title == 'Aiema') {
      return ((index === 0 || index === 1)) ? 'col-md-6' : 'col-md-6';

    }
    else if (config.title == 'BombayHardware') {
      // return ((index === 0 || index === 1)) ? 'col-md-6' : 'col-md-6';
      // return ((index === 0 || index === 1)) ? 'col-md-5 ' : 'col-md-5';
      // return ((index === 0 || index === 1)) ? 'col-md-6 ' : 'col-lg-3 col-md-6';
      // return ((index === 0 || index === 1)) ? 'col-lg-3 col-md-6 ' : 'col-lg-3 col-md-6';


    }
    else if (config.title == 'BBold') {
      return ((index === 0 || index === 1)) ? 'col-md-6 ' : 'col-md-12';

    }
    else {
      return ((index === 0 || index === 1)) ? 'col-md-6 ' : 'col-lg-3 col-md-2';
    }

  }

  categoryListing(category) {
    if (config.title == 'ShubhCards' || config.title == 'Deera') {
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    }
    else
    if (config.title == 'BombayHardware') {
      console.log("insidee111")
      // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

    } else if (config.title === "BBold")
		{
			this.router.navigate([`/product`], {queryParams: { terms:[category, 'Category'], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });

		}
    else {
      console.log("insidee222")
      this.router.navigate([`/productListing`], { queryParams: { terms: [[category], [], []], type: 'combine' }, relativeTo: this.route, skipLocationChange: false });
      
    } 
  }


  categoryListing1(category) {
     if (config.title == 'Aiema'){
      // this.router.navigate([`/productListing`], { queryParams: { terms:'',type: 'last90days' }, relativeTo: this.route, skipLocationChange: false });

    //   if(category==='listing') {
    //   this.router.navigate([`/productListing`], { queryParams: { terms:'',type: 'last90days' }, relativeTo: this.route, skipLocationChange: false });

    //  } else if (category==='category'){
    //   this.router.navigate([`/category-listing`]);

    //  }

      if(category==='listing' || category==='category') {
      this.router.navigate([`/productListing`], { queryParams: { terms:'',type: 'last90days' }, relativeTo: this.route, skipLocationChange: false });

     }

    }
    if (config.title == 'BombayHardware'){
      // this.router.navigate([`/productListing`], { queryParams: { terms:'',type: 'last90days' }, relativeTo: this.route, skipLocationChange: false });
      // this.router.navigate([`/productListing`], {queryParams: { terms:[[],['GI'],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

    }
  }

  brandListing(brandName) {
    this.router.navigate([`/productListing`], { queryParams: { terms: [brandName, 'Brand'], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
  }

}
