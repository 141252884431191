import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { AppService } from '../app.service';
import { config } from 'src/configs/config';

@Component({
  selector: 'app-category-listings',
  templateUrl: './category-listings.component.html',
  styleUrls: ['./category-listings.component.scss']
})
export class CategoryListingsComponent implements OnInit {

  allCategories: any = [];
  categoryList = [];
  // pageSize = 12;
  pageSize = 80;
  page = 1;
  config = config;
  // pageCount:any = 12;
  pageCount:any = 50;
  imgPath = "../../assets/images/repair.jpg";
  // imgPath = "https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Haven/StoreGallery/1651126576857%20-%20logo-aiema.png";
  subCategory1 =[];

  allCategories1: any = [  {
    image: 'assets/images/bombayhardware/Pipes.jpg',
    name: 'Pipes',
  },
  {
    image: 'assets/images/bombayhardware/HotRolledProducts.jpg',
    name: 'Hot Rolled Products',
  },
  {
    image: 'assets/images/bombayhardware/Flats.jpg',
    name: 'Flats',
  },
  {
    image: 'assets/images/bombayhardware/Angles.jpg',
    name: 'Angles',
  },
  {
    image: 'assets/images/bombayhardware/Channels.jpg',
    name: 'Channels',
  },
  {
    image: 'assets/images/bombayhardware/PipeFittings.jpg',
    name: 'Pipe Fittings',
  },
  {
    image: 'assets/images/bombayhardware/Seamed.jpg',
    name: 'Seamed',
  },
  {
    image: 'assets/images/bombayhardware/Pumps.jpg',
    name: 'Pumps',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Seamless',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Hollow Section',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Saw Pipes',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Heatexchanger and boiler tubes',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Conduit',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'CPVC',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Pumps',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Pumps',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Pumps',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Pumps',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Pumps',
  },
  {
    image: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg',
    name: 'Pumps',
  },];

  constructor(
    public appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAllCategories();
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  getAllCategories(){
    return new Promise((resolve, reject) => {
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: null,
        extras: {
          find:{},
          pagination: true,
          paginationDetails: {
            limit: this.page,
            pagesize: this.pageSize
          },
          sorting: true,
          sortingDetails: {
            sortfield: "name",
            sortorder: -1
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
        resp => {
          let res:any = resp;
          
          if(res.status_code === 200 && res.success === 1){
            this.allCategories = res.result.data;
            let categoryData = res.result.data;
            for(let i =0;i<categoryData.length;i++){
              console.log("nameeeeooooooo"+categoryData[i].name)
              this.categoryList.push({
                name: categoryData[i].name,
                image: categoryData[i].image,
              })
              if(categoryData[i].subcategories){
                // console.log("nameeeeooooooo"+categoryData[i].subcategories)
                // this.allCategories.push(categoryData[i].subcategories);
                // this.subCategory1 = this.allCategories.concat(categoryData[i].subcategories);
                for(let j =0;j<categoryData[i].subcategories.length;j++){
                  console.log("nameeee1111111"+categoryData[i].subcategories[j].name)
                  this.categoryList.push({
                    name: categoryData[i].subcategories[j].name,
                    image: categoryData[i].subcategories[j].image,
                  })
                  if(categoryData[i].subcategories[j].subcategories){
                    console.log("insideeeee")
                    for(let k =0;k<categoryData[i].subcategories[j].subcategories.length;k++){
                      console.log("nameeee2222222"+categoryData[i].subcategories[j].subcategories[k].name)
                      this.categoryList.push({
                        name: categoryData[i].subcategories[j].subcategories[k].name,
                        image: categoryData[i].subcategories[j].subcategories[k].image,
                      })
                      if(categoryData[i].subcategories[j].subcategories[k].subcategories){
                        console.log("inside22222")
                        for(let l =0;l<categoryData[i].subcategories[j].subcategories[k].subcategories.length;l++){
                          console.log("nameeee333333"+categoryData[i].subcategories[j].subcategories[k].subcategories[l].name)
                          this.categoryList.push({
                            name: categoryData[i].subcategories[j].subcategories[k].subcategories[l].name,
                            image: categoryData[i].subcategories[j].subcategories[k].subcategories[l].image,
                          })
                          if(categoryData[i].subcategories[j].subcategories[k].subcategories[l].subcategories){
                            console.log("inside44444")
                            for(let m =0;m<categoryData[i].subcategories[j].subcategories[k].subcategories.length;m++){
                              console.log("nameeee4444"+categoryData[i].subcategories[j].subcategories[k].subcategories[l].subcategories[m].name)
                              this.categoryList.push({
                                name: categoryData[i].subcategories[j].subcategories[k].subcategories[l].subcategories[m].name,
                                image: categoryData[i].subcategories[j].subcategories[k].subcategories[l].subcategories[m].image,
                              })
                            }
                          }
                        } 

                      }
                    }

                  }
                }
            
              }
            }
            this.pageCount = res.result.total.value;
            resolve(true)
          }
        },
        err => {
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
          resolve(false)
        })
    })
  }

  // getAiemaProductByLast90Days(event: number){
  //   console.log("paginationnnnn");
  //   this.currentPageInPagination = event;
  //   this.last90Days='no';
    
  //    var req_last90days_product_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  //  "user_id": null,
  //  "extras"    :{
  //  "find":{
  //      "brand":[],
  //     "category":[],
  //    "last90days":false,
  //    "last30days":false,
  //    "includeoutofstock":false,
    
  //  },
  //  "pagination":true,
  //  "paginationDetails":{
  //      "limit":(event),
  //      "pagesize":72
  //  },
  //  "sorting":true,
  //  "sortingDetails":{
  //      "sortfield":"name",
  //    "sortorder":-1
  //  },
   
  //  }
  //  }
  
  //  this.appService.postApiCall(req_last90days_product_data, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
  //    resp => {
  //      this.last90DaysProductsApiData = resp;
  //      this.products = this.last90DaysProductsApiData.result.data;
  //      this.totalNumberOfProducts = this.last90DaysProductsApiData.result.total;
  //      this.categoryList = this.last90DaysProductsApiData.result.filters[0].categories;
  //      this.facets =  this.last90DaysProductsApiData.result.filters[1].brands;
  //      this.themes =  this.last90DaysProductsApiData.result.filters[2].themes;
  //      this.finish =  this.last90DaysProductsApiData.result.filters[3].finish;
  //      this.features =  this.last90DaysProductsApiData.result.filters[4].features;
  //      this.prices =  this.last90DaysProductsApiData.result.filters[5].prices;
  //      this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
       
  //      if(this.CONFIG.title === 'Aiema'||this.CONFIG.title === "BombayHardware"){
  //       this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
  //      }
  //      if(this.facets.length > 0){
  //        this.facets.forEach(element => {
  //          this.brandPush.push(element.name)
  //        });
   
  //        this.productFilterService.updateAdminFormEvent(this.brandPush);
         
  //      }
  //      this.totalLength = this.last90DaysProductsApiData.result.total ? this.last90DaysProductsApiData.result.total : 0;
  //      if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware"){
  //       this.totalLength = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
  //       if(this.CONFIG.title === "Aiema"||this.CONFIG.title === "ShubhCards"||this.CONFIG.title === "BombayHardware"){
  //         this.totalLength = this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total.value ? this.last90DaysProductsApiData.result.total.value : 0;
  //        }
  //      }
  //    },
  //    err => {
  //      if (err.error.error.message) {
  //        console.log(err.error.error.message)
  //      }
  //      else{
  //        console.log('Something bad happened; Please try again!')
  //      }
  //    })
  //  }

  getCategoryProducts(category){
    console.log("insideee")
    this.router.navigate([`/productListing`], { queryParams: { terms: [category.name], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
  }

  getCategoryProducts1(category){
    console.log("insideee")
    this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
  }

  getPageCategories(pageNo){
    this.page = pageNo;
    console.log("this.page"+this.page);
    this.getAllCategories();
  }

}
