<section>
    <div class="checkout-alerts">
        <app-alerts *ngIf="checkoutAlerts"></app-alerts>
    </div>
    <div class="checkout-container">
        <app-checkout-header></app-checkout-header>
        <div class="accordian-container">
            <div class="row">
                <div class="col-md-8">
                    <div class="custom-accordian">
                        <div class="accordion" id="checkout-accordian">
                            <!-- FIRST STEP -->
                            <!-- CUSTOMER -->
                            <div *ngIf="this.checkoutFlowObj.customer.sequence === 1">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="customer-card">
                                        <p class="headings">{{this.checkoutFlowObj.customer.label}}</p>
                                        <button id="customer-btn" class="hide-btn" data-toggle="collapse" data-target="#customer" aria-expanded="false" aria-controls="customer">change</button>
                                    </div>
                                    <div id="customer" class="collapse" [class.show]="enablingCardsObj.customer" aria-labelledby="customer" data-parent="#checkout-accordian">
                                        <app-checkout-login (notify)="customerEvent($event)"></app-checkout-login>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="customer-card">
                                        <p class="headings">{{this.checkoutFlowObj.customer.label}}</p>
                                        <button id="customer-btn" class="hide-btn" data-toggle="collapse" data-target="#customer" aria-expanded="false" aria-controls="customer">change</button>
                                    </div>
                                    <div id="customer" class="collapse" [class.show]="enablingCardsObj.customer" aria-labelledby="customer" data-parent="#checkout-accordian">
                                        <app-checkout-login (notify)="customerEvent($event)"></app-checkout-login>
                                    </div>
                                </div>
                            </div>
                            <!-- DELIVERY -->
                            <div *ngIf="this.checkoutFlowObj.delivery.sequence === 1">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="delivery-card">
                                        <p class="headings">{{this.checkoutFlowObj.delivery.label}}</p>
                                        <button id="delivery-btn" class="hide-btn" data-toggle="collapse" data-target="#delivery" aria-expanded="false" aria-controls="delivery">change</button>
                                    </div>
                                    <div id="delivery" class="collapse" [class.show]="enablingCardsObj.delivery" aria-labelledby="delivery" data-parent="#checkout-accordian">
                                        <app-checkout-delivery (notify)="deliveryInfoEvent($event)"></app-checkout-delivery>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="delivery-card">
                                        <p class="headings">{{this.checkoutFlowObj.delivery.label}}</p>
                                        <button id="delivery-btn" class="hide-btn" data-toggle="collapse" data-target="#delivery" aria-expanded="false" aria-controls="delivery">change</button>
                                    </div>
                                    <div id="delivery" class="collapse" [class.show]="enablingCardsObj.delivery" aria-labelledby="delivery" data-parent="#checkout-accordian">
                                        <app-checkout-delivery (notify)="deliveryInfoEvent($event)"></app-checkout-delivery>
                                    </div>
                                </div>
                            </div>
                            <!-- SHIPPING -->
                            <div *ngIf="this.checkoutFlowObj.shipping.sequence === 1">
                                <div *ngIf="!pickupinstore">
                                    <div class="card" *ngIf="config.title!=='Haven'">
                                        <div class="card-header" id="shipping-card">
                                            <p class="headings">{{this.checkoutFlowObj.shipping.label}}</p>
                                            <button id="shipping-btn" class="hide-btn" data-toggle="collapse" data-target="#shipping" aria-expanded="false" aria-controls="shipping">change</button>
                                        </div>
                                        <div id="shipping" class="collapse" [class.show]="enablingCardsObj.shipping" aria-labelledby="shipping" data-parent="#checkout-accordian">
                                            <app-checkout-shipping (notify)="ShippingEvent($event)"></app-checkout-shipping>
                                        </div>
                                    </div>
                                    <div class="havencard" *ngIf="config.title==='Haven'">
                                        <div class="card-header" id="shipping-card">
                                            <p class="headings">{{this.checkoutFlowObj.shipping.label}}</p>
                                            <button id="shipping-btn" class="hide-btn" data-toggle="collapse" data-target="#shipping" aria-expanded="false" aria-controls="shipping">change</button>
                                        </div>
                                        <div id="shipping" class="collapse" [class.show]="enablingCardsObj.shipping" aria-labelledby="shipping" data-parent="#checkout-accordian">
                                            <app-checkout-shipping (notify)="ShippingEvent($event)"></app-checkout-shipping>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ORDER DETAILS -->
                            <div *ngIf="this.checkoutFlowObj.orderinfo.sequence === 1">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="orderinfo-card">
                                        <p class="headings">{{this.checkoutFlowObj.orderinfo.label}}</p>
                                        <button id="orderinfo-btn" class="hide-btn" data-toggle="collapse" data-target="#orderinfo" aria-expanded="false" aria-controls="orderinfo">change</button>
                                    </div>
                                    <div id="orderinfo" class="collapse" [class.show]="enablingCardsObj.orderdetails" aria-labelledby="orderinfo" data-parent="#checkout-accordian">
                                        <app-checkout-orderdetails (notify)="orderSummaryEvent($event)"></app-checkout-orderdetails>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="orderinfo-card">
                                        <p class="headings">{{this.checkoutFlowObj.orderinfo.label}}</p>
                                        <button id="orderinfo-btn" class="hide-btn" data-toggle="collapse" data-target="#orderinfo" aria-expanded="false" aria-controls="orderinfo">change</button>
                                    </div>
                                    <div id="orderinfo" class="collapse" [class.show]="enablingCardsObj.orderdetails" aria-labelledby="orderinfo" data-parent="#checkout-accordian">
                                        <app-checkout-orderdetails (notify)="orderSummaryEvent($event)"></app-checkout-orderdetails>
                                    </div>
                                </div>
                            </div>
                            <!-- PAYMENT -->
                            <div *ngIf="this.checkoutFlowObj.payment.sequence === 1">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="payment-card">
                                        <p class="headings">{{this.checkoutFlowObj.payment.label}}<span *ngIf="showPaymentSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></p>
                                        <button id="payment-btn" class="hide-btn" data-toggle="collapse" data-target="#payment" aria-expanded="false" aria-controls="payment">change</button>
                                    </div>
                                    <div id="payment" class="collapse" [class.show]="enablingCardsObj.payment" aria-labelledby="payment" data-parent="#checkout-accordian">
                                        <app-checkout-payment></app-checkout-payment>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="payment-card">
                                        <p class="headings">{{this.checkoutFlowObj.payment.label}}<span *ngIf="showPaymentSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></p>
                                        <button id="payment-btn" class="hide-btn" data-toggle="collapse" data-target="#payment" aria-expanded="false" aria-controls="payment">change</button>
                                    </div>
                                    <div id="payment" class="collapse" [class.show]="enablingCardsObj.payment" aria-labelledby="payment" data-parent="#checkout-accordian">
                                        <app-checkout-payment></app-checkout-payment>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion" id="checkout-accordian1">
                            <!-- SECOND STEP -->
                            <!-- CUSTOMER -->
                            <div *ngIf="this.checkoutFlowObj.customer.sequence === 2">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="customer-card">
                                        <p class="headings">{{this.checkoutFlowObj.customer.label}}</p>
                                        <button class="hide-btn" data-toggle="collapse" data-target="#customer" aria-expanded="false" aria-controls="customer">change</button>
                                    </div>
                                    <div id="customer" class="collapse" [class.show]="enablingCardsObj.customer" aria-labelledby="customer" data-parent="#checkout-accordian1">
                                        <app-checkout-login (notify)="customerEvent($event)"></app-checkout-login>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="customer-card">
                                        <p class="headings">{{this.checkoutFlowObj.customer.label}}</p>
                                        <button class="hide-btn" data-toggle="collapse" data-target="#customer" aria-expanded="false" aria-controls="customer">change</button>
                                    </div>
                                    <div id="customer" class="collapse" [class.show]="enablingCardsObj.customer" aria-labelledby="customer" data-parent="#checkout-accordian1">
                                        <app-checkout-login (notify)="customerEvent($event)"></app-checkout-login>
                                    </div>
                                </div>
                            </div>
                            <!-- DELIVERY -->
                            <div *ngIf="this.checkoutFlowObj.delivery.sequence === 2">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="delivery-card">
                                        <p class="headings">{{this.checkoutFlowObj.delivery.label}}</p>
                                        <button id="delivery-btn" class="hide-btn" data-toggle="collapse" data-target="#delivery" aria-expanded="false" aria-controls="delivery">change</button>
                                    </div>
                                    <div id="delivery" class="collapse" [class.show]="enablingCardsObj.delivery" aria-labelledby="delivery" data-parent="#checkout-accordian1">
                                        <app-checkout-delivery (notify)="deliveryInfoEvent($event)"></app-checkout-delivery>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="delivery-card">
                                        <p class="headings">{{this.checkoutFlowObj.delivery.label}}</p>
                                        <button id="delivery-btn" class="hide-btn" data-toggle="collapse" data-target="#delivery" aria-expanded="false" aria-controls="delivery">change</button>
                                    </div>
                                    <div id="delivery" class="collapse" [class.show]="enablingCardsObj.delivery" aria-labelledby="delivery" data-parent="#checkout-accordian1">
                                        <app-checkout-delivery (notify)="deliveryInfoEvent($event)"></app-checkout-delivery>
                                    </div>
                                </div>
                            </div>
                            <!-- SHIPPING -->
                            <div *ngIf="this.checkoutFlowObj.shipping.sequence === 2">
                                <div *ngIf="!pickupinstore">
                                    <div class="card" *ngIf="config.title!=='Haven'">
                                        <div class="card-header" id="shipping-card">
                                            <p class="headings">{{this.checkoutFlowObj.shipping.label}}</p>
                                            <button id="shipping-btn" class="hide-btn" data-toggle="collapse" data-target="#shipping" aria-expanded="false" aria-controls="shipping">change</button>
                                        </div>
                                        <div id="shipping" class="collapse" [class.show]="enablingCardsObj.shipping" aria-labelledby="shipping" data-parent="#checkout-accordian1">
                                            <app-checkout-shipping (notify)="ShippingEvent($event)"></app-checkout-shipping>
                                        </div>
                                    </div>
                                    <div class="havencard" *ngIf="config.title==='Haven'">
                                        <div class="card-header" id="shipping-card">
                                            <p class="headings">{{this.checkoutFlowObj.shipping.label}}</p>
                                            <button id="shipping-btn" class="hide-btn" data-toggle="collapse" data-target="#shipping" aria-expanded="false" aria-controls="shipping">change</button>
                                        </div>
                                        <div id="shipping" class="collapse" [class.show]="enablingCardsObj.shipping" aria-labelledby="shipping" data-parent="#checkout-accordian1">
                                            <app-checkout-shipping (notify)="ShippingEvent($event)"></app-checkout-shipping>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ORDER DETAILS -->
                            <div *ngIf="this.checkoutFlowObj.orderinfo.sequence === 2">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="orderinfo-card">
                                        <p class="headings">{{this.checkoutFlowObj.orderinfo.label}}</p>
                                        <button id="orderinfo-btn" class="hide-btn" data-toggle="collapse" data-target="#orderinfo" aria-expanded="false" aria-controls="orderinfo">change</button>
                                    </div>
                                    <div id="orderinfo" class="collapse" [class.show]="enablingCardsObj.orderdetails" aria-labelledby="orderinfo" data-parent="#checkout-accordian1">
                                        <app-checkout-orderdetails (notify)="orderSummaryEvent($event)"></app-checkout-orderdetails>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="orderinfo-card">
                                        <p class="headings">{{this.checkoutFlowObj.orderinfo.label}}</p>
                                        <button id="orderinfo-btn" class="hide-btn" data-toggle="collapse" data-target="#orderinfo" aria-expanded="false" aria-controls="orderinfo">change</button>
                                    </div>
                                    <div id="orderinfo" class="collapse" [class.show]="enablingCardsObj.orderdetails" aria-labelledby="orderinfo" data-parent="#checkout-accordian1">
                                        <app-checkout-orderdetails (notify)="orderSummaryEvent($event)"></app-checkout-orderdetails>
                                    </div>
                                </div>
                            </div>
                            <!-- PAYMENT -->
                            <div *ngIf="this.checkoutFlowObj.payment.sequence === 2">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="payment-card">
                                        <p class="headings">{{this.checkoutFlowObj.payment.label}}<span *ngIf="showPaymentSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></p>
                                        <button id="payment-btn" class="hide-btn" data-toggle="collapse" data-target="#payment" aria-expanded="false" aria-controls="payment">change</button>
                                    </div>
                                    <div id="payment" class="collapse" [class.show]="enablingCardsObj.payment" aria-labelledby="payment" data-parent="#checkout-accordian1">
                                        <app-checkout-payment></app-checkout-payment>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="payment-card">
                                        <p class="headings">{{this.checkoutFlowObj.payment.label}}<span *ngIf="showPaymentSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></p>
                                        <button id="payment-btn" class="hide-btn" data-toggle="collapse" data-target="#payment" aria-expanded="false" aria-controls="payment">change</button>
                                    </div>
                                    <div id="payment" class="collapse" [class.show]="enablingCardsObj.payment" aria-labelledby="payment" data-parent="#checkout-accordian1">
                                        <app-checkout-payment></app-checkout-payment>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion" id="checkout-accordian2">
                            <!-- THIRD STEP -->
                            <!-- CUSTOMER -->
                            <div *ngIf="this.checkoutFlowObj.customer.sequence === 3">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="customer-card">
                                        <p class="headings">{{this.checkoutFlowObj.customer.label}}</p>
                                        <button class="hide-btn" data-toggle="collapse" data-target="#customer" aria-expanded="false" aria-controls="customer">change</button>
                                    </div>
                                    <div id="customer" class="collapse" [class.show]="enablingCardsObj.customer" aria-labelledby="customer" data-parent="#checkout-accordian2">
                                        <app-checkout-login (notify)="customerEvent($event)"></app-checkout-login>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="customer-card">
                                        <p class="headings">{{this.checkoutFlowObj.customer.label}}</p>
                                        <button class="hide-btn" data-toggle="collapse" data-target="#customer" aria-expanded="false" aria-controls="customer">change</button>
                                    </div>
                                    <div id="customer" class="collapse" [class.show]="enablingCardsObj.customer" aria-labelledby="customer" data-parent="#checkout-accordian2">
                                        <app-checkout-login (notify)="customerEvent($event)"></app-checkout-login>
                                    </div>
                                </div>
                            </div>
                            <!-- DELIVERY -->
                            <div *ngIf="this.checkoutFlowObj.delivery.sequence === 3">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="delivery-card">
                                        <p class="headings">{{this.checkoutFlowObj.delivery.label}}</p>
                                        <button id="delivery-btn" class="hide-btn" data-toggle="collapse" data-target="#delivery" aria-expanded="false" aria-controls="delivery">change</button>
                                    </div>
                                    <div id="delivery" class="collapse" [class.show]="enablingCardsObj.delivery" aria-labelledby="delivery" data-parent="#checkout-accordian2">
                                        <app-checkout-delivery (notify)="deliveryInfoEvent($event)"></app-checkout-delivery>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="delivery-card">
                                        <p class="headings">{{this.checkoutFlowObj.delivery.label}}</p>
                                        <button id="delivery-btn" class="hide-btn" data-toggle="collapse" data-target="#delivery" aria-expanded="false" aria-controls="delivery">change</button>
                                    </div>
                                    <div id="delivery" class="collapse" [class.show]="enablingCardsObj.delivery" aria-labelledby="delivery" data-parent="#checkout-accordian2">
                                        <app-checkout-delivery (notify)="deliveryInfoEvent($event)"></app-checkout-delivery>
                                    </div>
                                </div>
                            </div>
                            <!-- SHIPPING -->
                            <div *ngIf="this.checkoutFlowObj.shipping.sequence === 3">
                                <div *ngIf="!pickupinstore">
                                    <div class="card" *ngIf="config.title!=='Haven'">
                                        <div class="card-header" id="shipping-card">
                                            <p class="headings">{{this.checkoutFlowObj.shipping.label}}</p>
                                            <button id="shipping-btn" class="hide-btn" data-toggle="collapse" data-target="#shipping" aria-expanded="false" aria-controls="shipping">change</button>
                                        </div>
                                        <div id="shipping" class="collapse" [class.show]="enablingCardsObj.shipping" aria-labelledby="shipping" data-parent="#checkout-accordian2">
                                            <app-checkout-shipping (notify)="ShippingEvent($event)"></app-checkout-shipping>
                                        </div>
                                    </div>
                                    <div class="havencard" *ngIf="config.title==='Haven'">
                                        <div class="card-header" id="shipping-card">
                                            <p class="headings">{{this.checkoutFlowObj.shipping.label}}</p>
                                            <button id="shipping-btn" class="hide-btn" data-toggle="collapse" data-target="#shipping" aria-expanded="false" aria-controls="shipping">change</button>
                                        </div>
                                        <div id="shipping" class="collapse" [class.show]="enablingCardsObj.shipping" aria-labelledby="shipping" data-parent="#checkout-accordian2">
                                            <app-checkout-shipping (notify)="ShippingEvent($event)"></app-checkout-shipping>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ORDER DETAILS -->
                            <div *ngIf="this.checkoutFlowObj.orderinfo.sequence === 3">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="orderinfo-card">
                                        <p class="headings">{{this.checkoutFlowObj.orderinfo.label}}</p>
                                        <button id="orderinfo-btn" class="hide-btn" data-toggle="collapse" data-target="#orderinfo" aria-expanded="false" aria-controls="orderinfo">change</button>
                                    </div>
                                    <div id="orderinfo" class="collapse" [class.show]="enablingCardsObj.orderdetails" aria-labelledby="orderinfo" data-parent="#checkout-accordian2">
                                        <app-checkout-orderdetails (notify)="orderSummaryEvent($event)"></app-checkout-orderdetails>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="orderinfo-card">
                                        <p class="headings">{{this.checkoutFlowObj.orderinfo.label}}</p>
                                        <button id="orderinfo-btn" class="hide-btn" data-toggle="collapse" data-target="#orderinfo" aria-expanded="false" aria-controls="orderinfo">change</button>
                                    </div>
                                    <div id="orderinfo" class="collapse" [class.show]="enablingCardsObj.orderdetails" aria-labelledby="orderinfo" data-parent="#checkout-accordian2">
                                        <app-checkout-orderdetails (notify)="orderSummaryEvent($event)"></app-checkout-orderdetails>
                                    </div>
                                </div>
                            </div>
                            <!-- PAYMENT -->
                            <div *ngIf="this.checkoutFlowObj.payment.sequence === 3">
                                <div class="card" *ngIf="config.title!=='Haven'">
                                    <div class="card-header" id="payment-card">
                                        <p class="headings">{{this.checkoutFlowObj.payment.label}}<span *ngIf="showPaymentSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></p>
                                        <button id="payment-btn" class="hide-btn" data-toggle="collapse" data-target="#payment" aria-expanded="false" aria-controls="payment">change</button>
                                    </div>
                                    <div id="payment" class="collapse" [class.show]="enablingCardsObj.payment" aria-labelledby="payment" data-parent="#checkout-accordian2">
                                        <app-checkout-payment></app-checkout-payment>
                                    </div>
                                </div>
                                <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="payment-card">
                                        <p class="headings">{{this.checkoutFlowObj.payment.label}}<span *ngIf="showPaymentSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></p>
                                        <button id="payment-btn" class="hide-btn" data-toggle="collapse" data-target="#payment" aria-expanded="false" aria-controls="payment">change</button>
                                    </div>
                                    <div id="payment" class="collapse" [class.show]="enablingCardsObj.payment" aria-labelledby="payment" data-parent="#checkout-accordian2">
                                        <app-checkout-payment></app-checkout-payment>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion" id="checkout-accordian3">
                            <!-- FOURTH STEP -->
                             <!-- CUSTOMER -->
                             <div *ngIf="this.checkoutFlowObj.customer.sequence === 4">
                                 <div class="card" *ngIf="config.title!=='Haven'">
                                     <div class="card-header" id="customer-card">
                                         <p class="headings">{{this.checkoutFlowObj.customer.label}}</p>
                                         <button class="hide-btn" data-toggle="collapse" data-target="#customer" aria-expanded="false" aria-controls="customer">change</button>
                                     </div>
                                     <div id="customer" class="collapse" [class.show]="enablingCardsObj.customer" aria-labelledby="customer" data-parent="#checkout-accordian3">
                                         <app-checkout-login (notify)="customerEvent($event)"></app-checkout-login>
                                     </div>
                                 </div>
                                 <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="customer-card">
                                        <p class="headings">{{this.checkoutFlowObj.customer.label}}</p>
                                        <button class="hide-btn" data-toggle="collapse" data-target="#customer" aria-expanded="false" aria-controls="customer">change</button>
                                    </div>
                                    <div id="customer" class="collapse" [class.show]="enablingCardsObj.customer" aria-labelledby="customer" data-parent="#checkout-accordian3">
                                        <app-checkout-login (notify)="customerEvent($event)"></app-checkout-login>
                                    </div>
                                </div>
                             </div>
                             <!-- DELIVERY -->
                             <div *ngIf="this.checkoutFlowObj.delivery.sequence === 4">
                                 <div class="card" *ngIf="config.title!=='Haven'">
                                     <div class="card-header" id="delivery-card">
                                         <p class="headings">{{this.checkoutFlowObj.delivery.label}}</p>
                                         <button id="delivery-btn" class="hide-btn" data-toggle="collapse" data-target="#delivery" aria-expanded="false" aria-controls="delivery">change</button>
                                     </div>
                                     <div id="delivery" class="collapse" [class.show]="enablingCardsObj.delivery" aria-labelledby="delivery" data-parent="#checkout-accordian3">
                                         <app-checkout-delivery (notify)="deliveryInfoEvent($event)"></app-checkout-delivery>
                                     </div>
                                 </div>
                                 <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="delivery-card">
                                        <p class="headings">{{this.checkoutFlowObj.delivery.label}}</p>
                                        <button id="delivery-btn" class="hide-btn" data-toggle="collapse" data-target="#delivery" aria-expanded="false" aria-controls="delivery">change</button>
                                    </div>
                                    <div id="delivery" class="collapse" [class.show]="enablingCardsObj.delivery" aria-labelledby="delivery" data-parent="#checkout-accordian3">
                                        <app-checkout-delivery (notify)="deliveryInfoEvent($event)"></app-checkout-delivery>
                                    </div>
                                </div>
                             </div>
                             <!-- SHIPPING -->
                             <div *ngIf="this.checkoutFlowObj.shipping.sequence === 4">
                                <div *ngIf="!pickupinstore">
                                    <div class="card" *ngIf="config.title!=='Haven'">
                                        <div class="card-header" id="shipping-card">
                                            <p class="headings">{{this.checkoutFlowObj.shipping.label}}</p>
                                            <button id="shipping-btn" class="hide-btn" data-toggle="collapse" data-target="#shipping" aria-expanded="false" aria-controls="shipping">change</button>
                                        </div>
                                        <div id="shipping" class="collapse" [class.show]="enablingCardsObj.shipping" aria-labelledby="shipping" data-parent="#checkout-accordian3">
                                            <app-checkout-shipping (notify)="ShippingEvent($event)"></app-checkout-shipping>
                                        </div>
                                    </div>
                                    <div class="havencard" *ngIf="config.title==='Haven'">
                                        <div class="card-header" id="shipping-card">
                                            <p class="headings">{{this.checkoutFlowObj.shipping.label}}</p>
                                            <button id="shipping-btn" class="hide-btn" data-toggle="collapse" data-target="#shipping" aria-expanded="false" aria-controls="shipping">change</button>
                                        </div>
                                        <div id="shipping" class="collapse" [class.show]="enablingCardsObj.shipping" aria-labelledby="shipping" data-parent="#checkout-accordian3">
                                            <app-checkout-shipping (notify)="ShippingEvent($event)"></app-checkout-shipping>
                                        </div>
                                    </div>
                                </div>
                             </div>
                             <!-- ORDER DETAILS -->
                             <div *ngIf="this.checkoutFlowObj.orderinfo.sequence === 4">
                                 <div class="card" *ngIf="config.title!=='Haven'">
                                     <div class="card-header" id="orderinfo-card">
                                         <p class="headings">{{this.checkoutFlowObj.orderinfo.label}}</p>
                                         <button id="orderinfo-btn" class="hide-btn" data-toggle="collapse" data-target="#orderinfo" aria-expanded="false" aria-controls="orderinfo">change</button>
                                     </div>
                                     <div id="orderinfo" class="collapse" [class.show]="enablingCardsObj.orderdetails" aria-labelledby="orderinfo" data-parent="#checkout-accordian3">
                                         <app-checkout-orderdetails (notify)="orderSummaryEvent($event)"></app-checkout-orderdetails>
                                     </div>
                                 </div>
                                 <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="orderinfo-card">
                                        <p class="headings">{{this.checkoutFlowObj.orderinfo.label}}</p>
                                        <button id="orderinfo-btn" class="hide-btn" data-toggle="collapse" data-target="#orderinfo" aria-expanded="false" aria-controls="orderinfo">change</button>
                                    </div>
                                    <div id="orderinfo" class="collapse" [class.show]="enablingCardsObj.orderdetails" aria-labelledby="orderinfo" data-parent="#checkout-accordian3">
                                        <app-checkout-orderdetails (notify)="orderSummaryEvent($event)"></app-checkout-orderdetails>
                                    </div>
                                </div>
                             </div>
                             <!-- PAYMENT -->
                             <div *ngIf="this.checkoutFlowObj.payment.sequence === 4">
                                 <div class="card" *ngIf="config.title!=='Haven'">
                                     <div class="card-header" id="payment-card">
                                         <p class="headings">{{this.checkoutFlowObj.payment.label}}<span *ngIf="showPaymentSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></p>
                                         <button id="payment-btn" class="hide-btn" data-toggle="collapse" data-target="#payment" aria-expanded="false" aria-controls="payment">change</button>
                                     </div>
                                     <div id="payment" class="collapse" [class.show]="enablingCardsObj.payment" aria-labelledby="payment" data-parent="#checkout-accordian3">
                                         <app-checkout-payment></app-checkout-payment>
                                     </div>
                                 </div>
                                 <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="payment-card">
                                        <p class="headings">{{this.checkoutFlowObj.payment.label}}<span *ngIf="showPaymentSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></p>
                                        <button id="payment-btn" class="hide-btn" data-toggle="collapse" data-target="#payment" aria-expanded="false" aria-controls="payment">change</button>
                                    </div>
                                    <div id="payment" class="collapse" [class.show]="enablingCardsObj.payment" aria-labelledby="payment" data-parent="#checkout-accordian3">
                                        <app-checkout-payment></app-checkout-payment>
                                    </div>
                                </div>
                             </div>
                         </div>
                         <div class="accordion" id="checkout-accordian4">
                            <!-- FIFTH STEP -->
                             <!-- CUSTOMER -->
                             <div *ngIf="this.checkoutFlowObj.customer.sequence === 5">
                                 <div class="card" *ngIf="config.title!=='Haven'">
                                     <div class="card-header" id="customer-card">
                                         <p class="headings">{{this.checkoutFlowObj.customer.label}}</p>
                                         <button class="hide-btn" data-toggle="collapse" data-target="#customer" aria-expanded="false" aria-controls="customer">change</button>
                                     </div>
                                     <div id="customer" class="collapse" [class.show]="enablingCardsObj.customer" aria-labelledby="customer" data-parent="#checkout-accordian4">
                                         <app-checkout-login (notify)="customerEvent($event)"></app-checkout-login>
                                     </div>
                                 </div>
                                 <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="customer-card">
                                        <p class="headings">{{this.checkoutFlowObj.customer.label}}</p>
                                        <button class="hide-btn" data-toggle="collapse" data-target="#customer" aria-expanded="false" aria-controls="customer">change</button>
                                    </div>
                                    <div id="customer" class="collapse" [class.show]="enablingCardsObj.customer" aria-labelledby="customer" data-parent="#checkout-accordian4">
                                        <app-checkout-login (notify)="customerEvent($event)"></app-checkout-login>
                                    </div>
                                </div>
                             </div>
                             <!-- DELIVERY -->
                             <div *ngIf="this.checkoutFlowObj.delivery.sequence === 5">
                                 <div class="card" *ngIf="config.title!=='Haven'">
                                     <div class="card-header" id="delivery-card">
                                         <p class="headings">{{this.checkoutFlowObj.delivery.label}}</p>
                                         <button id="delivery-btn" class="hide-btn" data-toggle="collapse" data-target="#delivery" aria-expanded="false" aria-controls="delivery">change</button>
                                     </div>
                                     <div id="delivery" class="collapse" [class.show]="enablingCardsObj.delivery" aria-labelledby="delivery" data-parent="#checkout-accordian4">
                                         <app-checkout-delivery (notify)="deliveryInfoEvent($event)"></app-checkout-delivery>
                                     </div>
                                 </div>
                                 <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="delivery-card">
                                        <p class="headings">{{this.checkoutFlowObj.delivery.label}}</p>
                                        <button id="delivery-btn" class="hide-btn" data-toggle="collapse" data-target="#delivery" aria-expanded="false" aria-controls="delivery">change</button>
                                    </div>
                                    <div id="delivery" class="collapse" [class.show]="enablingCardsObj.delivery" aria-labelledby="delivery" data-parent="#checkout-accordian4">
                                        <app-checkout-delivery (notify)="deliveryInfoEvent($event)"></app-checkout-delivery>
                                    </div>
                                </div>
                             </div>
                             <!-- SHIPPING -->
                             <div *ngIf="this.checkoutFlowObj.shipping.sequence === 5">
                                <div *ngIf="!pickupinstore">
                                    <div class="card" *ngIf="config.title!=='Haven'">
                                        <div class="card-header" id="shipping-card">
                                            <p class="headings">{{this.checkoutFlowObj.shipping.label}}</p>
                                            <button id="shipping-btn" class="hide-btn" data-toggle="collapse" data-target="#shipping" aria-expanded="false" aria-controls="shipping">change</button>
                                        </div>
                                        <div id="shipping" class="collapse" [class.show]="enablingCardsObj.shipping" aria-labelledby="shipping" data-parent="#checkout-accordian4">
                                            <app-checkout-shipping (notify)="ShippingEvent($event)"></app-checkout-shipping>
                                        </div>
                                    </div>
                                    <div class="havencard" *ngIf="config.title==='Haven'">
                                        <div class="card-header" id="shipping-card">
                                            <p class="headings">{{this.checkoutFlowObj.shipping.label}}</p>
                                            <button id="shipping-btn" class="hide-btn" data-toggle="collapse" data-target="#shipping" aria-expanded="false" aria-controls="shipping">change</button>
                                        </div>
                                        <div id="shipping" class="collapse" [class.show]="enablingCardsObj.shipping" aria-labelledby="shipping" data-parent="#checkout-accordian4">
                                            <app-checkout-shipping (notify)="ShippingEvent($event)"></app-checkout-shipping>
                                        </div>
                                    </div>
                                </div>
                             </div>
                             <!-- ORDER DETAILS -->
                             <div *ngIf="this.checkoutFlowObj.orderinfo.sequence === 5">
                                 <div class="card" *ngIf="config.title!=='Haven'">
                                     <div class="card-header" id="orderinfo-card">
                                         <p class="headings">{{this.checkoutFlowObj.orderinfo.label}}</p>
                                         <button id="orderinfo-btn" class="hide-btn" data-toggle="collapse" data-target="#orderinfo" aria-expanded="false" aria-controls="orderinfo">change</button>
                                     </div>
                                     <div id="orderinfo" class="collapse" [class.show]="enablingCardsObj.orderdetails" aria-labelledby="orderinfo" data-parent="#checkout-accordian4">
                                         <app-checkout-orderdetails (notify)="orderSummaryEvent($event)"></app-checkout-orderdetails>
                                     </div>
                                 </div>
                                 <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="orderinfo-card">
                                        <p class="headings">{{this.checkoutFlowObj.orderinfo.label}}</p>
                                        <button id="orderinfo-btn" class="hide-btn" data-toggle="collapse" data-target="#orderinfo" aria-expanded="false" aria-controls="orderinfo">change</button>
                                    </div>
                                    <div id="orderinfo" class="collapse" [class.show]="enablingCardsObj.orderdetails" aria-labelledby="orderinfo" data-parent="#checkout-accordian4">
                                        <app-checkout-orderdetails (notify)="orderSummaryEvent($event)"></app-checkout-orderdetails>
                                    </div>
                                </div>
                             </div>
                             <!-- PAYMENT -->
                             <div *ngIf="this.checkoutFlowObj.payment.sequence === 5">
                                 <div class="card" *ngIf="config.title!=='Haven'">
                                     <div class="card-header" id="payment-card">
                                         <p class="headings">{{this.checkoutFlowObj.payment.label}}<span *ngIf="showPaymentSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></p>
                                         <button id="payment-btn" class="hide-btn" data-toggle="collapse" data-target="#payment" aria-expanded="false" aria-controls="payment">change</button>
                                     </div>
                                     <div id="payment" class="collapse" [class.show]="enablingCardsObj.payment" aria-labelledby="payment" data-parent="#checkout-accordian4">
                                         <app-checkout-payment></app-checkout-payment>
                                     </div>
                                 </div>
                                 <div class="havencard" *ngIf="config.title==='Haven'">
                                    <div class="card-header" id="payment-card">
                                        <p class="headings">{{this.checkoutFlowObj.payment.label}}<span *ngIf="showPaymentSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></p>
                                        <button id="payment-btn" class="hide-btn" data-toggle="collapse" data-target="#payment" aria-expanded="false" aria-controls="payment">change</button>
                                    </div>
                                    <div id="payment" class="collapse" [class.show]="enablingCardsObj.payment" aria-labelledby="payment" data-parent="#checkout-accordian4">
                                        <app-checkout-payment></app-checkout-payment>
                                    </div>
                                </div>
                             </div>
                         </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <app-order-summary></app-order-summary>
                </div>
            </div>
        </div>
        
    </div>
</section>
<button id="launch-model" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal" data-target="#statusModel">
    Launch demo modal
  </button>
  
<div class="modal fade" id="statusModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content status-model-container text-center">
        <div class="modal-body" style="border: none !important;">
            <div><img class="checkout-empty-cart" src="assets/images/vendor/emptyKart.svg"></div>
            <div>
                <h5>Oops! your Cart is empty!</h5>
                <p class="checkout-para">Looks like you have removed all selected<br> items from cart.</p>
                <button class="btn btn-primary btn-primary1" style="padding: 5px 15px !important; margin-bottom: 10px !important;" (click)="backtoHome()">GO BACK TO HOME</button>
            </div>
        </div>
        <button style="display: none;" type="button" id="cancelBtn" class="btn btn-light btn-md text-primary shadow-sm" data-dismiss="modal">Cancel</button>          
      </div>
    </div>
  </div>

  <button id="launch-surprise" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal" data-target="#statusModel1">
    Launch Surprise
  </button>
  <div class="modal fade" id="statusModel1" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content status-model-container text-center surprise-continer">
            <div class="firework-1"></div>
            <div class="firework-2"></div>
            <div class="firework-3"></div>
            <div class="firework-4"></div>
            <div class="firework-5"></div>
            <div class="firework-6"></div>
            <div class="firework-7"></div>
            <div class="firework-8"></div>
            <div class="firework-9"></div>
            <div class="firework-10"></div>
            <div class="firework-11"></div>
            <div class="firework-12"></div>
            <div class="firework-13"></div>
            <div class="firework-14"></div>
            <div class="firework-15"></div>
            <div class="title">Congratulations!!! 🎉</div>
            <div class="surprise-text text-center">
                On being in the first 1000 users. 
                your order confirmation number is {{offerOrderId}}. sign in to track status of delivery.
            </div>
            <div>
                <button style="display: none;" type="button" id="cancelBtn2" data-dismiss="modal">Cancel</button> 
                <button class="btn surprise-btn" (click)="closeModel1()">OK</button>
            </div>
        </div>
    </div>
  </div>
