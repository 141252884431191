<section id="aiema-companies" *ngIf="CONFIG.title === 'Aiema'">
    <div class="checkout-alerts">
        <app-alerts *ngIf="checkoutAlerts"></app-alerts>
    </div>
    <div class="d-flex justify-content-center company-bg">
        <img class="member-img" src="../../assets/images/n1.png">
    </div>
    <div class="member">
        <div class="member-logo">
            <img style="height: 140px; width: 140px;" [src]="product.company_profile.logo">
        </div>
        <div class="member-titles">
            <div class="mem-tile">{{product.name}}
                <!-- <span class="mem-subscript">({{product.categories[0].categories}})</span> -->
                <span *ngFor="let category of product.categories" class="mem-subscript">({{category.categories}})</span>
        </div>
            
            <div class="mem-items">
                <div class="row">
                    <div class="col-md-3">
                        <div class="item-title">CEO/Owner:</div>
                        <div class="item-val">{{owner ? owner : '-'}}</div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-title">Established In:</div>
                        <div class="item-val">{{establised ? establised : '-'}}</div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-title">Website:</div>
                        <div class="item-val">{{product.contact_details.website ? product.contact_details.website : '-'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="member-description">
            <div class="row">
                <div class="col-md-8">
                    <div class="aboutus">About us</div>
                    <div class="short-desc">{{product.description.short_desc ? product.description.short_desc : '-'}}</div>
                    <div class="others">Business</div>
                    <div class="short-desc">{{product.company_profile.nature_of_business ? product.company_profile.nature_of_business: '-'}}</div>
                    <div class="others">Services</div>
                    <div class="short-desc">{{product.company_profile.type_of_services ? product.company_profile.type_of_services: '-'}}</div>
                    <div class="others">Specialization</div>
                    <div class="short-desc">{{product.company_profile.specialized_in ? product.company_profile.specialized_in : '-'}}</div>
                </div>
                <div class="col-md-4">
                    <div class="btn btn-block" (click)="submitEnquiry(product)" style="border-radius: 0px !important;background: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de) !important; border: none;color: #fff;border-radius: 5px !important;font-weight: 500;">
                        Submit Enquiry
                    </div>
                    <div class="member-contacts">
                        <div class="contact-title">Contact Details:</div>
                        <div class="row mb-1">
                            <div class="col-sm-4">
                                <div class="item-title">Contact:</div>
                            </div>
                            <div class="col-sm-8">
                                <div class="item-val" style="font-size: 14px;overflow-wrap: break-word;">{{product.contact_details.contact_person_name ? product.contact_details.contact_person_name:'-'}}</div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-sm-4">
                                <div class="item-title">Mobile:</div>
                            </div>
                            <div class="col-sm-8">
                                <div class="item-val" style="font-size: 14px;overflow-wrap: break-word;">{{product.contact_details.mobile ? product.contact_details.mobile:'-'}}</div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-sm-4">
                                <div class="item-title">Email:</div>
                            </div>
                            <div class="col-sm-8">
                                <div class="item-val" style="font-size: 14px;overflow-wrap: break-word;">{{product.contact_details.email ? product.contact_details.email:'-'}}</div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-sm-4">
                                <div class="item-title">Address:</div>
                            </div>
                            <div class="col-sm-8">
                                <div class="item-val" style="font-size: 14px;overflow-wrap: break-word;">{{product.company_details.location ? product.company_details.location : '-'}}</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>


<section id="bombayhardware-products" *ngIf="CONFIG.title === 'BombayHardware'">
    <div class="checkout-alerts">
        <app-alerts *ngIf="checkoutAlerts"></app-alerts>
    </div>
    <!-- <div class="d-flex justify-content-center company-bg">
        <img class="member-img" src="../../assets/images/n1.png">
    </div> -->
    <div class="member">
        <div class="member-logo">
            <img style="height: 140px; width: 140px;" [src]="product.company_profile.logo">
        </div>
        <div class="member-titles">
            <div class="mem-tile">{{product.name}}
                <!-- <span class="mem-subscript">({{product.categories[0].categories}})</span> -->
                <span *ngFor="let category of product.categories" class="mem-subscript">({{category.categories}})</span>
        </div>
            
            <div class="mem-items">
                <div class="row">
                    <div class="col-md-3">
                        <div class="item-title">CEO/Owner:</div>
                        <div class="item-val">{{owner ? owner : '-'}}</div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-title">Established In:</div>
                        <div class="item-val">{{establised ? establised : '-'}}</div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-title">Website:</div>
                        <div class="item-val">{{product.contact_details.website ? product.contact_details.website : '-'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="member-description">
            <div class="row">
                <div class="col-md-8">
                    <div class="aboutus">About us</div>
                    <div class="short-desc">{{product.description.short_desc ? product.description.short_desc : '-'}}</div>
                    <div class="others">Business</div>
                    <div class="short-desc">{{product.company_profile.nature_of_business ? product.company_profile.nature_of_business: '-'}}</div>
                    <div class="others">Services</div>
                    <div class="short-desc">{{product.company_profile.type_of_services ? product.company_profile.type_of_services: '-'}}</div>
                    <div class="others">Specialization</div>
                    <div class="short-desc">{{product.company_profile.specialized_in ? product.company_profile.specialized_in : '-'}}</div>
                </div>
                <div class="col-md-4">
                    <div class="btn btn-block" (click)="submitEnquiry(product)" style="border-radius: 0px !important;background: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de) !important; border: none;color: #fff;border-radius: 5px !important;font-weight: 500;">
                        Submit Enquiry
                    </div>
                    <div class="member-contacts">
                        <div class="contact-title">Contact Details:</div>
                        <div class="row mb-1">
                            <div class="col-sm-4">
                                <div class="item-title">Contact:</div>
                            </div>
                            <div class="col-sm-8">
                                <div class="item-val" style="font-size: 14px;overflow-wrap: break-word;">{{product.contact_details.contact_person_name ? product.contact_details.contact_person_name:'-'}}</div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-sm-4">
                                <div class="item-title">Mobile:</div>
                            </div>
                            <div class="col-sm-8">
                                <div class="item-val" style="font-size: 14px;overflow-wrap: break-word;">{{product.contact_details.mobile ? product.contact_details.mobile:'-'}}</div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-sm-4">
                                <div class="item-title">Email:</div>
                            </div>
                            <div class="col-sm-8">
                                <div class="item-val" style="font-size: 14px;overflow-wrap: break-word;">{{product.contact_details.email ? product.contact_details.email:'-'}}</div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-sm-4">
                                <div class="item-title">Address:</div>
                            </div>
                            <div class="col-sm-8">
                                <div class="item-val" style="font-size: 14px;overflow-wrap: break-word;">{{product.company_details.location ? product.company_details.location : '-'}}</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>


<button id="launch-model" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal" data-target="#statusModel">
    Launch demo modal
</button>
<div class="modal fade" id="statusModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" style="max-width: 640px !important;position: relative !important;bottom: -30px !important; -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;-moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important; -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;">
        <div class="modal-content status-model-container">
            <div class="modal-body" style="border: none !important;">
                <div class="model-content m-content">
                    <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span style="font-size: 1.6rem !important;" aria-hidden="true">&times;</span>
                    </button>
                    <div class="title-container">
                        <div class="m-title">Enquiry Form</div>
                    </div>
                    <form [formGroup]="enquiryForm" class="gift-form" novalidate>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="name" class="form-label">Name <span class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="form-control" id="name"
                                    [class.is-invalid]="f.name.invalid && validateForm" formControlName="name" required>
                                <div class="invalid-feedback" *ngIf="f.name.errors">
                                    <div *ngIf="f.name.errors.required">
                                        Name is required.
                                    </div>
                                    <div *ngIf="f.name.errors.maxlength">
                                        Name can't be more than 80 characters long.
                                      </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="content" class="form-label">Email <span
                                    class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="email" class="form-control" id="email"
                                    [class.is-invalid]="(f.email.invalid && validateForm)"
                                    formControlName="email" maxlength="50" email>
                                <div class="invalid-feedback" *ngIf="f.email.errors">
                                    <div *ngIf="f.email.errors.required">
                                        Email is required.
                                    </div>
                                    <div *ngIf="f.email.errors.maxlength">
                                        Seneder Email can't be more than 50 characters long.
                                    </div>
                                    <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                                        Email must be a valid email address
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="phone_number" class="form-label">Mobile<span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="form-control" id="phone_number"
                                    [class.is-invalid]="f.phone_number.invalid && validateForm" formControlName="phone_number"
                                    required>
                                <div class="invalid-feedback" *ngIf="f.phone_number.errors">
                                    <div *ngIf="f.phone_number.errors.required">
                                        Mobile Number is required.
                                    </div>
                                    <div *ngIf="f.phone_number.errors.maxlength">
                                        Mobile Number can't be more than 10 characters long.
                                    </div>
                                    <div *ngIf="f.phone_number.errors.pattern">
                                        Mobile Number is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="category" class="form-label">Category<span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="form-control" id="category"
                                    [class.is-invalid]="f.category.invalid && validateForm" formControlName="category"
                                    required readonly>
                                    <div class="invalid-feedback" *ngIf="f.category.errors">
                                        <div *ngIf="f.category.errors.required">
                                            Category is required.
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="category" class="form-label">Message<span
                                    class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <textarea class="form-control" id="message"
                                    [class.is-invalid]="f.message.invalid && validateForm" formControlName="message"
                                    required rows="5"></textarea>
                                    <div class="invalid-feedback" *ngIf="f.message.errors">
                                        <div *ngIf="f.message.errors.required">
                                            Message is required.
                                        </div>
                                        <div *ngIf="f.message.errors.maxlength">
                                            Message can't be more than 600 characters long.
                                          </div>
                                    </div>
                            </div>
                        </div>
                        <div style="margin-bottom: 45px;">
                            <button class="btn btn-primary" style="border-radius: 5px !important; font-size: 1rem; padding: 5px 25px;margin-top: 5px;float: right;" (click)="saveForm()">SUBMIT</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>



