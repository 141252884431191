<section>
    <div class="header-section">
        <div class="d-flex justify-content-start">
            <div class="logo-section">
                <img *ngIf="config.title!=='Tams'" class="logo-images" [src]="logoImage" alt="Logo Image" (click)="navigateHome()">
                <img *ngIf="config.title==='Tams'" height="70" class="logo-image custom-pointer" [src]="logoImage" alt="Logo Image" (click)="navigateHome()">
            </div>
            <div class="banner-section">
                <p *ngIf="config.title!=='Deera'" class="text-white" [innerHTML] = "bannerMessage"></p>
            </div>
        </div>
        
    </div>
</section>
