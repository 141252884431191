import { Injectable } from '@angular/core';

import { CheckoutApiService } from '../checkout-api.service';
import { CustomerLoginStorageService } from './customer-login-storage.service';
import { LocalStorageService } from '../../auth-service/local-storage.service'
import { ENDPOINTS } from '../../app.config';
import { AppService } from '../../app.service';
import { CheckoutLocalStorageService } from '../checkout-local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerLoginService {

  enableBusinessUser:boolean = false;
  registrationSettings = {
    mandatory:false,
    customers:[]
  }

  constructor(
    private checkoutApiService: CheckoutApiService,
    private customerLoginStorageService: CustomerLoginStorageService,
    public appService: AppService,
    private localService: LocalStorageService,
    private checkoutLocalStorageService: CheckoutLocalStorageService
  ) { }

  getBusinessUserSettings(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.STORE_SETTING_GET_ALL).then(
        respData => {
          let storeData:any = respData;
          if(storeData.success){
            storeData = storeData.data[0];
            this.enableBusinessUser = storeData.others.customer_settings.is_business_customer_enabled;
            resolve(this.enableBusinessUser);
          }
        }
      )
    })
  }

  checkoutSettings(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.CHECKOUT_SETTINGS).then(
        respData => {
          let checkoutSettingData:any = respData;
          if(checkoutSettingData.success){
            checkoutSettingData = checkoutSettingData.data[0];
            this.registrationSettings.mandatory = checkoutSettingData.is_registration_mandatory;
            if(this.registrationSettings.mandatory){
              if(checkoutSettingData.registration_for.toUpperCase() === "SPECIFIC"){
                this.registrationSettings.customers = checkoutSettingData.customer_type;
              }
              else{
                this.registrationSettings.customers = ['ALL'];
              }
            }
            
            resolve(this.registrationSettings);
          }
        }
      )
    })
  }

  checkoutLogout(){
    this.appService.userLoggedIn = false;
    this.appService.userName = "";
    this.appService.loggedin_user = false;
    this.localService.remove('accessToken');
    this.localService.remove('refreshToken');
    this.localService.remove('expireTime');
    this.localService.remove('UserName');
    this.localService.remove('UserId');
    this.localService.remove('domainName');
    this.localService.remove('rexpireTime');
    this.localService.remove('AiemaUserName');
    this.customerLoginStorageService.removeCustomerData();
    this.customerLoginStorageService.removeCustomer();
    this.checkoutLocalStorageService.clearDataonLogout();
  }
}
