import { Injectable } from '@angular/core';

import { CheckoutSettingsService } from './checkout-settings.service';
import { OrderSummaryOrderService } from './order-summary/order-summary-order.service';
import { ENDPOINTS,ORG_DETAILS } from '../app.config';
import { AppService } from 'src/app/app.service';
import { config } from '../../configs/config';

@Injectable({
  providedIn: 'root'
})
export class CheckoutCalculationsService {

  PERCENTAGE_LABEL = "PERCENTAGE";
  FIXEDAMOUNT_LABEL = "FIXED AMOUNT";
  INCLUDINGTAX_LABEL = "INCLUDING TAX";
  productPrices:any = [];
  CONFIG = config;
  orderTotal = 0;

  constructor(
    private checkoutSettingsService : CheckoutSettingsService,
    private orderSummaryOrderService: OrderSummaryOrderService,
    private appService: AppService,
  ) { 
   }

  getAllProducts(){
    return new Promise((resolve, reject) => {
      if(this.productPrices.length === 0){
        let requestData = {
          domain_name: ORG_DETAILS.DOMAIN_NAME,
          user_id: null,
          extras: {
            find:{},
            pagination: false,
              paginationDetails: {
                limit:10,
                pagesize: 20
              },
            sorting:false,
            sortingDetails: {
              sortfield: "id",
              sortorder: -1
            }
          }
          
        }
        this.appService.postApiCall(requestData, ENDPOINTS.GET_ALL_PRODUCTS).subscribe(
          resp => {
            let res:any = resp;
            if(res.status_code === 200 && res.success === 1){
              if(res.result.data.length > 0){
                res.result.data.forEach(element => {
                  this.productPrices.push({
                    id: element.id,
                    price: element.pricing.raw.price
                  })
                });
                resolve(true);
              }
            }
          })
      }
      else{
        resolve(true)
      }
    })
  }

  calculateSubTotal(productList){
    return new Promise((resolve, reject) => {
      let subTotal = 0;
      if(this.CONFIG.title === "Nesma"){
        this.getAllProducts().then(
          respData => {
            let res:any = respData;
            if(res){
              productList.forEach(product => {
                
                subTotal = subTotal + (product.package_total * product.package_quantity)
                // if(product.bundle_chargable && product.bundle_products && product.bundle_products.length > 0){
                //   product.bundle_products.forEach(bproductid => {
                //     let bundleProduct = this.productPrices.find(ap => ap.id === bproductid);
                //     subTotal = subTotal + (bundleProduct.price * product.package_quantity)
                //   })
                // }
                // if(product.add_onproducts && product.add_onproducts.length > 0){
                //   product.add_onproducts.forEach(addproductid => {
                //     let addonProduct = this.productPrices.find(ap => ap.id === addproductid);
                //     subTotal = subTotal + (addonProduct.price * product.package_quantity)
                //   })
                // }
              })
              resolve(subTotal);
            }
          }
        )
      }
      else{
        productList.forEach(product => {
          subTotal = subTotal + (product.cost * product.quantity)
        });
        this.orderSummaryOrderService.orderObj.subTotal = subTotal;
        resolve(subTotal);
      }
    })
  }

  calculateHandlingFee(subtotal, discount, handlingType, handlingRate){
    return new Promise((resolve, reject) => {
      this.orderSummaryOrderService.orderObj.handlingCostType = handlingType
      if(handlingType.toUpperCase() === this.PERCENTAGE_LABEL){
        let handlingFee = 0;
        handlingFee = (handlingRate * (subtotal-discount))/100;
        this.orderSummaryOrderService.orderObj.handlingFee = Math.round((handlingFee + Number.EPSILON) * 100) / 100;
        resolve(Math.round((handlingFee + Number.EPSILON) * 100) / 100)
      }
      else{
        this.orderSummaryOrderService.orderObj.handlingFee = handlingRate;
        resolve(handlingRate);
      }
    })
  }

  calculateProductTax(productTaxList){
    return new Promise((resolve, reject) => {
      let productTax = 0;
      if(this.checkoutSettingsService.taxCalculationSettings.catalog_prices.toUpperCase() === this.INCLUDINGTAX_LABEL){
        this.orderSummaryOrderService.orderObj.productTax = 0;
        resolve(0)
      }
      else{
        productTaxList.forEach(product => {
          if(this.CONFIG.title === "Nesma"){
            if(!product.free){
              productTax = productTax + ((product.quantity * product.price * product.taxrate)/100)
            }
          }
          if(this.CONFIG.title === "Deera"){
            console.log("UUUUUUU "+JSON.stringify(product))
            // productTax = productTax + ((product.quantity * product.rawDeeraCost * product.taxrate)/100)
            productTax = productTax + ((product.rawDeeraCost * product.taxrate)/100)
            console.log("productTax111"+productTax + ((product.rawDeeraCost * product.taxrate)/100))
            console.log("productTax222"+productTax)
           
          }
          else{
             productTax = productTax + ((product.quantity * product.price * product.taxrate)/100)
            // productTax = productTax + ((product.quantity * product.rawDeeraCost * product.taxrate)/100)
          }
          
        });
        this.orderSummaryOrderService.orderObj.productTax = Math.round((productTax + Number.EPSILON) * 100) / 100;
        resolve(Math.round((productTax + Number.EPSILON) * 100) / 100)
      }
    })
  }

  calculateHandlingFeeTax(handlingFee, handlingTaxRate){
    return new Promise ((resolve, reject) => {
      let handlingTax = (handlingFee * handlingTaxRate)/100;
      this.orderSummaryOrderService.orderObj.handlingFeeTax = Math.round((handlingTax + Number.EPSILON) * 100) / 100;
      resolve(Math.round((handlingTax + Number.EPSILON) * 100) / 100)
    })
  }

  calculateShippingTax(shippingAmount, shiipingTaxRate, is_shipping){
    return new Promise ((resolve, reject) => {
      if(is_shipping){
        let shippingTax = (shippingAmount * shiipingTaxRate)/100;
        this.orderSummaryOrderService.orderObj.shippingChargesTax = Math.round((shippingTax + Number.EPSILON) * 100) / 100;
        resolve(Math.round((shippingTax + Number.EPSILON) * 100) / 100)
      }
      else{
        this.orderSummaryOrderService.orderObj.shippingChargesTax = 0;
        resolve(0)
      }
    })
  }

  calculateDiscountTax(discountAmount, discountTaxRate, isDicountTax){
    return new Promise ((resolve, reject) => {
      if(isDicountTax){
        let discountTax = (discountAmount * discountTaxRate)/100;
        this.orderSummaryOrderService.orderObj.discountTax = Math.round((discountTax + Number.EPSILON) * 100) / 100
        resolve(Math.round((discountTax + Number.EPSILON) * 100) / 100)
      }
      else{
        this.orderSummaryOrderService.orderObj.discountTax = 0;
        resolve(0)
      }
    })
  }

  calculateTotalTax(productTaxList, handlingFee, handlingTaxRate, discountAmount, shippingAmount, isDicountTax, isShippingTax, shiipingTaxRate, discountTaxRate){
    return new Promise((resolve, reject) => {
      this.calculateProductTax(productTaxList).then(
        respData => {
          let res:any = respData;
          this.calculateHandlingFeeTax(handlingFee, handlingTaxRate).then(
            respData1 => {
              let res1:any = respData1;
              this.calculateShippingTax(shippingAmount, shiipingTaxRate, isShippingTax).then(
                respData2 => {
                  let res2:any = respData2;
                  this.calculateDiscountTax(discountAmount, discountTaxRate, isDicountTax).then(
                    respData3 =>{
                      let res3:any = respData3;
                      this.orderSummaryOrderService.orderObj.totalTax = res + res1 + res2 + res3;
                      // resolve(res + res1 + res2 + res3);
                      resolve(Math.round((res + res1 + res2 + res3 + Number.EPSILON) * 100) / 100)
                    }
                  )
                }
              )
            })
        })
    })
  }

  calculateTotal(subTotal, discount, handlingFee, shipping, tax){
    this.orderSummaryOrderService.orderSummary.shipping_cost = shipping;
    this.orderSummaryOrderService.orderSummary.order_total_amount = Math.round(subTotal - discount + handlingFee + shipping + tax);
    this.orderTotal = Math.round(subTotal - discount + handlingFee + shipping + tax);
    return(Math.round(subTotal - discount + handlingFee + shipping + tax))
  }

  calculateDiscount(subTotal, disocunt){
    return new Promise((resolve, reject) => {
      let discount = (disocunt * subTotal)/100;
      resolve(Math.round((discount + Number.EPSILON) * 100) / 100)
    })
  }
}
