import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ENDPOINTS, ORG_DETAILS } from '../../app.config';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html'
})
export class ServicesComponent implements OnInit {

  content:any = "";

  constructor(
    private router: Router,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0,0)
  }


}
