import { Component, OnInit, AfterViewInit,ChangeDetectorRef } from '@angular/core';
import {CartService} from './cart-page.service';
import {AppService} from '../app.service';
import {ENDPOINTS, ORG_DETAILS} from '../app.config';
import { NavbarService} from '../navbar/navbar.service';
import {LocalStorageService} from '../auth-service/local-storage.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';
import { DatePipe } from '@angular/common';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { CheckoutLocalStorageService } from '../checkout-new/checkout-local-storage.service';
import { LoaderService } from '../shared/loader/loader.service';

import {Router, ActivatedRoute} from '@angular/router';
import * as moment from 'moment';

import {config} from '../../configs/config';

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html'
})
export class CartPageComponent implements OnInit {

  showAlerts = true;
  totalPrice = 0;
  banner = "";
  storeClosed = false;
  public executionData=[];
  config= config;
  imageNotFound=config.noImageAvailable
  acceptanceTiming:any;
  promotionData:any;

  slidesStore = [];
  existingCartProduts = [];
  CONFIG = config;
  customOptions: OwlOptions = {
    autoplay: true,
    loop: true,
    navSpeed: 300,
    autoplayHoverPause : true,
    navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true,
  }

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(private cartService : CartService,public appService : AppService,
    private route:ActivatedRoute,
    private navbarService: NavbarService,
    private localService : LocalStorageService,
    private alertService : AlertsService,
    private datePipe : DatePipe,
    private router : Router,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private checkoutApiService: CheckoutApiService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
    private loaderService: LoaderService,
    ) {
      let status = this.router.getCurrentNavigation().extras.state;

      if (status !== undefined )
      {

        if (status['shop'] == false)
        {
          this.storeClosed = true;
        }
      }
    }

  ngOnInit(): void {

    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.calculateTotal();
    this.navbarService.updateAdminFormEvent('clearSearchTerm');

    if(this.CONFIG.name!=='BombayHardware'){
      this.getPromotionBanner();

      this.getAcceptanceTimings();
    }

    this.getRelatedProductsAPI();

    this.loadExistingCart();

  }

  ngAfterViewInit(): void{

    if(this.storeClosed === true){

      // this.alertService.error("Restaurant opening soon", this.options);
      this.alertService.error('Currently orders are not being accepted!!! We are open from (11:30 AM to 3:30 PM and 6:30 PM to 10:00 PM) for taking orders',this.options)
      this.cdr.detectChanges();
    }

  }

  addToCart(product:any)
  {
    this.alertService.clear();

    let tempArray = this.appService.cartProducts.results;

    let cartQty = 0

    tempArray.forEach((item)=>
    {
        cartQty += item.quantity;
    })

    if(Number(this.localService.get('max_cart_qty')) <= cartQty)
    {
      this.showAlerts = true;
      window.scrollTo(0,0);
      // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      if(this.CONFIG.name==='BombayHardware')
      {
        this.alertService.error("Maximum allowed quantity is 1 only",this.options)
      } else {
        this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      }
      return
    }
    this.cartService.addToCart(product);
    this.loadExistingCart()
    this.calculateTotal();
  }

  increaseItem(index)
  {
    this.alertService.clear();

    let tempArray = this.appService.cartProducts.results;

    let cartQty = 0

    tempArray.forEach((item)=>
    {
        cartQty += item.quantity;
    })

    
    if(this.CONFIG.name==='ShubhCards')
    {
        
      tempArray[index].quantity = (Number(tempArray[index].quantity) + tempArray[index].product.pricing.raw.incrementvalue)
    }
    else{
      if(Number(this.localService.get('max_cart_qty')) <= cartQty)
    {
      this.showAlerts = true;
      window.scrollTo(0,0);
      // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      if(this.CONFIG.name==='BombayHardware')
      {
        this.alertService.error("Maximum allowed quantity is 1 only",this.options)
      } else if(this.CONFIG.name==='Deera'){
        // tempArray[index].quantity = 0;
        this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      }
      else {
        this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      }
      return
    }
      tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
    }
    
  if(this.CONFIG.name==='Deera'){
    let obj =
    {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
              "product_id": Number(tempArray[index].product_id),
              "product_cost": Number(tempArray[index].cost),
              "quantity": Number(tempArray[index].quantity)
            }
        }
      }
  
    this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
      resp => {
        if (resp) {
          if (resp.success === 1 && resp.status_code === 200) {
            console.log("tempArray[index].quantity"+tempArray[index].product.id);
            tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
            tempArray[index].product.discount_amount = resp.result.data.discount_amount;
            tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
            tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
            if(resp.result.data.discount_percentage>0)	
            {	
              this.appService.cartProducts.results[index]['fixed_amount'] = resp.result.data.discount_amount;	
            }	
            else{	
              this.appService.cartProducts.results[index]['fixed_amount'] =  resp.result.data.fixed_amount;	
            }	
            this.cartService.updateCartItem(this.appService.cartProducts.results);	
          }
          else if (resp.success === 0) {
            if (resp.message) {
              //this.alertService.error(resp.message, this.options);
            }
          }
          else if (resp.message && resp.status_code !== 200) {
            //this.alertService.error(resp.message, this.options);
  
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          //this.alertService.error('Session Time Out! Please login Again', this.options)
  
        }
        else if (err.error.error.message) {
          //this.alertService.error(err.error.error.message, this.options)
        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
  }
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    if(this.CONFIG.name==='Deera'){
      let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
      this.localService.set("cart", cartItemsString);
      }
    this.loadExistingCart()
  }
  ChangeValue(index)
  {
    this.alertService.clear();
    let tempArray = this.appService.cartProducts.results;
    var moq=tempArray[index].product.pricing.raw.minimumorderquantity;
    if(this.CONFIG.name==='ShubhCards')
    {
  
      if(this.appService.cartProducts.results[index].quantity< tempArray[index].product.pricing.raw.minimumorderquantity)
      {
          this.appService.cartProducts.results.splice(index,1);
          this.appService.totalCartItems = this.cartService.totalCartItems();
          this.calculateTotal();
          this.cartService.updateCartItem(this.appService.cartProducts.results);
          this.loadExistingCart()
        this.alertService.error("Minimum Order Quantity for this product is "+moq,this.options);
          return
      }
      
      tempArray[index].quantity = (Number(tempArray[index].quantity));
      tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
      this.appService.cartProducts.results = tempArray;
      this.calculateTotal();
      this.cartService.updateCartItem(this.appService.cartProducts.results);
      this.loadExistingCart()
    }
  }

  changeDeeraValue(index)
{
  console.log("indexxxx"+index)
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;

  let cartQty = 0

  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })
  
  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  {
    this.showAlerts = true;
    window.scrollTo(0,0);
    // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    if(this.CONFIG.name==='BombayHardware')
    {
      this.alertService.error("Maximum allowed quantity is 1 only",this.options)
    } else if(this.CONFIG.name==='Deera'){
      tempArray[index].quantity = 0;
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    else {
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    return
  }
    // tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
    if(this.appService.cartProducts.results[index].quantity  < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.calculateTotal();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        if(this.CONFIG.name==='Deera'){
          let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
          this.localService.set("cart", cartItemsString);
          }
        this.loadExistingCart()
        return
    }

  if(ORG_DETAILS.NAME==='Deera')
  {
    //let tempArray = this.appService.cartProducts.results;
    let obj =
    {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
              "product_id": Number(tempArray[index].product_id),
              "product_cost": Number(tempArray[index].cost),
              "quantity": Number(tempArray[index].quantity)
            }
        }
      }
  
    this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
      resp => {
        if (resp) {
          if (resp.success === 1 && resp.status_code === 200) {
            console.log("tempArray[index].quantity"+tempArray[index].product.id);
            tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
            tempArray[index].product.discount_amount = resp.result.data.discount_amount;
            tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
            tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
            if(resp.result.data.discount_percentage>0)	
            {	
              this.appService.cartProducts.results[index]['fixed_amount'] = resp.result.data.discount_amount;	
            }	
            else{	
              this.appService.cartProducts.results[index]['fixed_amount'] =  resp.result.data.fixed_amount;	
            }	
            this.cartService.updateCartItem(this.appService.cartProducts.results);	
          }
          else if (resp.success === 0) {
            if (resp.message) {
              //this.alertService.error(resp.message, this.options);
            }
          }
          else if (resp.message && resp.status_code !== 200) {
            //this.alertService.error(resp.message, this.options);
  
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          //this.alertService.error('Session Time Out! Please login Again', this.options)
  
        }
        else if (err.error.error.message) {
          //this.alertService.error(err.error.error.message, this.options)
        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )

    // tempArray[index].quantity = (Number(tempArray[index].quantity));
    // tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    // this.appService.cartProducts.results = tempArray;
    // this.appService.totalCartItems = this.cartService.totalCartItems();
    // this.cartService.updateCartItem(this.appService.cartProducts.results);  
    // tempArray[index].quantity = (Number(tempArray[index].quantity));
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    if(this.CONFIG.name==='Deera'){
      let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
      this.localService.set("cart", cartItemsString);
      }
    this.loadExistingCart();
  }
}
  
  decreaseItem(index)
  {
    let tempArray = this.appService.cartProducts.results;
    if(this.CONFIG.name==='ShubhCards')
  {

    if(this.appService.cartProducts.results[index].quantity- tempArray[index].product.pricing.raw.decrementvalue < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.calculateTotal();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        this.loadExistingCart()
        return
    }
    
    tempArray[index].quantity = (Number(tempArray[index].quantity) - tempArray[index].product.pricing.raw.decrementvalue)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.loadExistingCart()
  }
  else
  {

    if(this.appService.cartProducts.results[index].quantity === 1 || this.appService.cartProducts.results[index].quantity  < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.calculateTotal();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        if(this.CONFIG.name==='Deera'){
          let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
          this.localService.set("cart", cartItemsString);
          }
        this.loadExistingCart()
        return
    }
    tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    if(this.CONFIG.name==='Deera'){
      let obj =
      {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
              "find": {
                "product_id": Number(tempArray[index].product_id),
                "product_cost": Number(tempArray[index].cost),
                "quantity": Number(tempArray[index].quantity)
              }
          }
        }
    
      this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
        resp => {
          if (resp) {
            if (resp.success === 1 && resp.status_code === 200) {
              console.log("tempArray[index].quantity"+tempArray[index].product.id);
              tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
              tempArray[index].product.discount_amount = resp.result.data.discount_amount;
              tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
              tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
              this.cartService.updateCartItem(this.appService.cartProducts.results);	
            }
            else if (resp.success === 0) {
              if (resp.message) {
                //this.alertService.error(resp.message, this.options);
              }
            }
            else if (resp.message && resp.status_code !== 200) {
              //this.alertService.error(resp.message, this.options);
    
            }
            else {
              //this.alertService.error('Something bad happened. Please try again!', this.options);
            }
          }
        },
        err => {
          if (err.error.statusCode === 403) {
            //this.alertService.error('Session Time Out! Please login Again', this.options)
    
          }
          else if (err.error.error.message) {
            //this.alertService.error(err.error.error.message, this.options)
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }
    this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    if(this.CONFIG.name==='Deera'){
      let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
      this.localService.set("cart", cartItemsString);
      }
    this.loadExistingCart()
  }
  }
  ClearCart(){
    this.appService.cartProducts.results.splice(0,this.appService.cartProducts.results.length)
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.loadExistingCart()
  }
  removeItem(index)
  {
    this.appService.cartProducts.results.splice(index,1);
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.loadExistingCart()
  }

  calculateTotal()
  {

    if(this.appService.cartProducts.results.length > 0)
    {
      this.totalPrice = 0;
      this.appService.cartProducts.results.forEach((obj)=>
      {
          this.totalPrice += (obj.cost * obj.quantity);
      })
    }
    else
    {
      this.totalPrice = 0;
    }
  }

  getPromotionBanner()
  {
    let obj =
    {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {

            }
        }

    }

    this.appService.postApiCall(obj,ENDPOINTS.PROMOTIONS_BANNER).subscribe(
      resp => {
        if (resp) {
          if (resp.success === 1 && resp.status_code === 200) {
            this.banner = resp.result.data[0]['eligible_banner_message']
            // if(this.config.title ==='Haven'){
            //   this.promotionData = resp.result.data;
              
            //   this.appService.cartProducts.results.forEach((item)=>
            //   {
            //     console.log("1111111111"+item.product_name);
            //     this.promotionData.forEach((data)=>
            //     {
            //       console.log("eeeeee"+data.product_discount.amount_or_percentage.discounted_percentage);
            //       let productDetails = data.product_discount.amount_or_percentage.product_details;
            //       productDetails.forEach((data1)=>
            //       {
            //         if(item.product_name===data1.product_name){
                      
            //           this.executionData.push(data1.order_execution);
            //           let max = this.executionData.reduce((op, item) => op = op > item ? op : item, 0);
            //           console.log("max"+max);
              
            //           if(max===data1.order_execution){
            //             console.log("data.product_discount.amount_or_percentage.discounted_percentage"+data.product_discount.amount_or_percentage.discounted_percentage);
            //             console.log("data.product_discount.amount_or_percentage.discounted_amount"+data.product_discount.amount_or_percentage.discounted_amount);
            //             if(data.product_discount.amount_or_percentage.discounted_percentage!==0){
            //               console.log("truwwwwwww");
            //               function minusPercent(n,p) {
            //                 return n - (n * (p/100));
            //               }
            //               let price = minusPercent(item.cost,data.product_discount.amount_or_percentage.discounted_percentage); 
            //               item.cost = price;
    
            //             } else if (data.product_discount.amount_or_percentage.discounted_amount!==0){
                           
            //              let price = item.cost - data.product_discount.amount_or_percentage.discounted_amount;
            //              item.cost = price;
            //             }
            //           }
            //           this.calculateTotal();
            //           this.cartService.updateCartItem(this.appService.cartProducts.results);
                        //  this.loadExistingCart();
            //           }
                   
            //       })
  
            //     })
            //   })
            // }
          }
          else if (resp.success === 0) {
            if (resp.message) {
              //this.alertService.error(resp.message, this.options);
            }
          }
          else if (resp.message && resp.status_code !== 200) {
            //this.alertService.error(resp.message, this.options);

          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          //this.alertService.error('Session Time Out! Please login Again', this.options)

        }
        else if (err.error.error.message) {
          //this.alertService.error(err.error.error.message, this.options)
        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )


  }

  getAcceptanceTimings(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.ACCEPTANCE_TIMINGS).subscribe(
      resp => {
        if(resp.success === 1 && resp.result.data.length > 0){

          this.acceptanceTiming = resp.result.data[0].order_acceptance_timing;
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }

  goToCheckout()
  {
    var today = moment();
    this.showAlerts = true;

    var timeAvailable :boolean ;

    if(this.acceptanceTiming === [])
    {
      this.router.navigate(['/checkout-new'])
      return
    }
    else
    {

        this.acceptanceTiming.forEach(element => {
            let from = moment(today.format('YYYY-MM-DD') +" "+ element.from,"YYYY-MM-DD hh:mm A" )
            let to = moment(today.format('YYYY-MM-DD') +" "+ element.to,"YYYY-MM-DD hh:mm A")

            if (today >= from && today <= to)
            {
              timeAvailable = true
              return
            }
        });
    }
    if(timeAvailable === true)
    {
      this.router.navigate(['/checkout-new'])
    }
    else
    {
      // this.alertService.error('Restaurant opening soon',this.options)
      this.alertService.error('Currently orders are not being accepted!!! We are open from (11:30 AM to 3:30 PM and 6:30 PM to 10:00 PM) for taking orders',this.options)
    }

  }

  submitEnquiry() {     
    if (this.localService.get('UserName')) {
      let totalQuantity = 0;
      this.appService.cartProducts.results.forEach(element => {
        totalQuantity = totalQuantity + element.quantity;
      });

      let orderData = {
        customer_id: parseInt(this.localService.get('UserId')),
        customer_name: this.localService.get('UserName'),
        status: "New",
        system_label : "New",
        total_quantity: totalQuantity,
        products: this.appService.cartProducts.results
      }


      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        payload: {
          order_creation: orderData,
        },
        extras: {
          find: {
            id: ""
          }
        }
      }
      this.loaderService.emitLoading();
      // console.log(requestBody);
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.ORDER_UPDATION, requestData).then(
        respData => {
          console.log("111")
          let res:any = respData;
          if(res.success){
          // this.cartItems.result = [];
          let OrderId = res.data[0].id;
          this.checkoutLocalStorageService.setOrderId({orderId:OrderId});
          this.cartService.clearCart();
          this.loaderService.emitComplete();
          this.appService.cartProducts.results= [];
          // this.router.navigate([`/enquiry`]);
          window.scrollTo({top: 0, behavior: 'smooth'});
          this.alertService.success('Request Submitted', this.options);

          }
        },
        err => {
          if (err.error.message) {
            console.log(err.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        });
    }
    else {
      
      this.router.navigate([`/login-new`], {queryParams: { url: this.router.url }, relativeTo:this.route,  skipLocationChange: false });
    }
    
  }

    // RELATED PRODUCTS API
    getRelatedProductsAPI(){
      let cartItems = this.appService.cartProducts.results;
      let categories = [];
      let productIds = []
      cartItems.forEach(element => {
        categories.push(element.category);
        productIds.push(element.product_id);
      });

      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "category": categories,
            "productid": productIds
          },
          "pagination": false
        }
      }

      this.appService.postApiCall(requestData, ENDPOINTS.RELATEDPRODUCT_API).subscribe(
        resp => {
          if(resp.success === 1){
            let relatedProducts = resp.result.data;

            if(relatedProducts.length > 0){
              relatedProducts.forEach(element => {
                element.showHint=true;
             
               });
             

              relatedProducts.forEach(relatedP => {
                let slideObj = {
                  id:0,
                  img:'',
                  alt:'',
                  item:'',
                  type: '',
                  backtitle: '',
                  backpara:'',
                  backtype: '',
                  price:'',
                  priceRange:'',
                  points:'5 Points',
                  button:'ADD TO CART',
                  name : "",
                  brand : {},
                  pricing : {},
                  categories : [],
                  assets : [],
                  variant:[],
                  reviews:[],
                  prodType:'',
                  product_type:'',
                  shipping:{}
                }
                slideObj.id = relatedP.id
                slideObj.img = relatedP.assets[0].image_source
                slideObj.alt = relatedP.name
                slideObj.item = relatedP.name
                slideObj.type = relatedP.parentcategories[0].name
                slideObj.backtitle = relatedP.name
                slideObj.backpara = relatedP.description.description
                slideObj.backtype = relatedP.parentcategories[0].name
                slideObj.price = relatedP.pricing.raw.price
                slideObj.priceRange = relatedP.pricing.raw.price_range
                slideObj.name = relatedP.name,
                slideObj.brand = relatedP.brand
                slideObj.pricing = relatedP.pricing
                slideObj.categories = relatedP.categories,
                slideObj.assets = relatedP.assets,
                slideObj.variant=relatedP.variant,
                slideObj.reviews=relatedP.reviews,
                slideObj.product_type=relatedP.type,
                slideObj.shipping = relatedP.shipping

                this.slidesStore.push(slideObj);
              });
           this.slidesStore =this.slidesStore.filter(element=>{
             return element.variant!=undefined
           })
              this.slidesStore.forEach(element => {
                if((element.product_type=='Variable')&&(element.variant != undefined)){
                element.sizeVariable=element.variant.option_values[0];
                element.checkVariantInCart=element.variant.option_values[0].id;
                element.showPricse =false;
                element.showVariant=true;
                }
                else{
                  element.showPricse = true;
                  element.showVariant=false;
                  element.isDisabled=false;
                }
             
              });
            }

            if(this.slidesStore.length === 2){
              this.slidesStore.push(this.slidesStore[0]);
              this.slidesStore.push(this.slidesStore[1]);
            }
            else if(this.slidesStore.length === 1){
              this.slidesStore.push(this.slidesStore[0]);
              this.slidesStore.push(this.slidesStore[0]);
              this.slidesStore.push(this.slidesStore[0]);
            }
            else if(this.slidesStore.length === 3){
              this.slidesStore.push(this.slidesStore[0]);
            }

          }
          else{
            if(resp.message){
              // this.alertService.error(resp.message, this.options);
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options);
            }
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options);
          }

        })
    }

    //FOR RELATED PRODUCTS
    loadExistingCart()
    {
      this.existingCartProduts= [];
      this.appService.cartProducts.results.forEach(value=>
        {
          this.existingCartProduts.push(value.product_id);
        })
    }

    productListing(){
      if(this.CONFIG.name!=='Deera'){
        this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      } else {
        this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      }
		//this.router.navigate([`/productListing`], { queryParams: { terms:'',type: 'last90days' }, relativeTo: this.route, skipLocationChange: false });

    }

    navigateToDetails(product_id)
    {
      this.router.navigate([`product-details/${product_id}`])
    }
    emitter(e){
      console.log(e)
    }
    showPrice(e){
    let item=e[0];
      let productId=e[2];
      this.slidesStore.forEach(element => {
      
        if(element.id === productId){
          element.showHint=false;
           element.checkVariantInCart=item.id;
           element.sizeVariable = item;
           element.pricee=item.pricing.raw.price
       element.showPricse=true;
       element.isactive=item.option;
       element.isDisabled=false;
      
        }else{
          // element.showPricse=false;
        }
      });   
     }
    

addToCarts(item) {
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0
  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  {
    this.showAlerts = true;
    window.scrollTo(0,0);
    // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    if(this.CONFIG.name==='BombayHardware')
    {
      this.alertService.error("Maximum allowed quantity is 1 only",this.options)
    } else {
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    }
    return
  }
  if(item.product_type == 'Simple'){

    this.cartService.addToCart(item);
    
  }
  else if(item.product_type == 'Variation'){

    this.cartService.addToCart(item);
    
  }
else if (item.product_type =='Variable'){
  item.sizeVariable.brand={
    name:"",
   }
   item.sizeVariable.categories=[{
     categories:"",
     id:1
   }]
   item.sizeVariable.assets=item.assets;
   item.sizeVariable.name = item.name + ' - ' + item.sizeVariable.option
    item.sizeVariable.parentcategories=[]
  this.cartService.addToCart(item.sizeVariable)
}
this.loadExistingCart()
    this.calculateTotal();
}

}
